import React, { useState } from 'react';
import { Box, Typography, IconButton, Button, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';

import guestPitchBg from "../../assets/images/Pitch/Feed/guestPitchbg.png";
import chLogo from "../../assets/images/Pitch/Header/chLogo2.svg"
import { useNavigate } from 'react-router-dom';



const LoginPopup = (props) => {
    const mobileView = useMediaQuery("(max-width:768px)");
    const navigate = useNavigate();
    const [open, setOpen] = useState(props.open);
    const handleClose = () => {
        props.handleClose(false)
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: mobileView ? '90%' : '40%',
        backgroundImage: `url(${guestPitchBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        border: 'none',
        outline: 'none',
        borderRadius: '16px',
        p: 4,
    };

    return (
        <Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <IconButton
                        onClick={() => setOpen(false)}
                        sx={{
                            position: 'absolute',
                            top: 25,
                            right: 20,
                            border: '2px solid #FFFFFF',
                            padding: '2px'
                        }}>
                        <CloseIcon sx={{ color: '#FFFFFF' }} />
                    </IconButton>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <img src={chLogo} alt='' style={{ width: '100px', height: '100px' }} />
                        <Typography variant='h4' sx={{
                            fontFamily: 'Inter',
                            fontSize: '30px',
                            fontWeight: 700,
                            lineHeight: '60px',
                            textAlign: 'center',
                            color: '#FFFFFF',
                            marginTop: '10px'
                        }}>
                            Welcome to{' '}
                            <span style={{
                                background: '#FFFFFF',
                                fontFamily: 'Inter',
                                fontSize: '30px',
                                fontStyle: 'italic',
                                fontWeight: 900,
                                lineHeight: '32px',
                                textAlign: 'center',
                                borderRadius: '4px',
                                padding: '0px 5px',
                            }}>
                                <span style={{ color: '#0C5BA0', }}>Champ</span>
                                <span style={{ color: '#18A31A', }}>hunt</span>
                            </span>
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '20px',
                            width: mobileView ? '100%' : '90%',
                            background: '#FFFFFF',
                            borderRadius: '15px',
                            marginTop: '30px',
                            padding: '20px 15px'
                        }}>
                            <Typography variant='h4' sx={{
                                fontFamily: 'Inter',
                                fontSize: '22px',
                                fontWeight: 600,
                                lineHeight: '22px',
                                textAlign: 'center',
                                color: '#0C5BA0'
                            }}>
                                We have a gift{" "}<strong>GIFT</strong>{" "}for you !
                            </Typography>
                            <Typography variant='h4' sx={{
                                fontFamily: 'Inter',
                                fontSize: '16px',
                                fontWeight: 600,
                                lineHeight: '16px',
                                textAlign: 'center',
                                color: '#62666A'
                            }}>
                                Sign up to claim your <strong style={{ color: '#18A31A' }}>3000</strong> Runs Welcome Gift!
                            </Typography>
                            <Typography variant='h4' sx={{
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontWeight: 500,
                                lineHeight: '20px',
                                textAlign: 'center',
                                color: '#62666A',
                                padding: '0px 20px'
                            }}>
                                Use these runs to unlock exclusive deals, participate in contests, and more.
                            </Typography>
                            <Button
                                onClick={() => navigate("/signup")}
                                sx={{
                                    width: mobileView ? '60%' : '30%',
                                    background: 'linear-gradient(180deg, #043E81 0%, #1665A7 100%)',
                                    color: '#FFFFFF'
                                }}
                            >
                                SIGN UP
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal >
        </Box >
    );
}

export default LoginPopup;