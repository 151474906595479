import React from 'react';
import { Box } from '@mui/material';

import "react-tabs/style/react-tabs.css";

import Posts from "../../posts/index";
import Polls from "../../poll/poll";
// import EntryPage from "../commentary/entryPage";
import { useEffect, useState } from "react";
import axios from "axios";
import ActiveuserRunModel from "../ActiveuserRunModel";

import LiveMatch from './LiveMatch';
import PostSomething from './PostSomething';
import Story from "../../stories";

const Feeds = () => {
    const [openModel, setopenModel] = useState(false);
    const [userData, setuserData] = useState({});
    const [pitchCreated, setPitchcreated] = useState(false);
    const [pinpoll, setPinPoll] = useState([]);
    const accessToken = localStorage.getItem("token");
    const userId = localStorage.getItem("user_id");
    const [allMatch, setAllMatch] = useState([]);
    const handleClose = () => {
        setopenModel(false);
    };
    const getPerDayBonusRuns = () => {
        if (!userId) return;
        var rejectdetails = {
            method: "POST",
            url: global.config.ROOTURL.prod + `/auth/activeuser/createuserEntry`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: {
                userId,
            },
        };
        axios(rejectdetails)
            .then((res) => {
                if (res?.data?.message !== "") {
                    const newdata = res.data;
                    setuserData(newdata);
                    setopenModel(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchPInPoll = () => {
        if (!accessToken) return;
        const options = {
            method: "get",
            url: global.config.ROOTURL.prod + `/poll/getAllPinPoll`,
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        };

        axios(options)
            .then(({ data }) => {
                setPinPoll([...data]);
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    console.log(error);
                }
            });
    };

    useEffect(() => {
        fetchPInPoll();
        getPerDayBonusRuns();
        window.scrollTo(0, 0);
    }, [pitchCreated]);

    const getAllLiveMatch = async () => {

        const options = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/scorecard/getLiveScores",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(options)
            .then(({ data }) => {
                setAllMatch(data);
                console.log("MatchData...",data)
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    console.log(error);
                }
            });
    };
    useEffect(() => {
        getAllLiveMatch();
    }, []);

    return (
        <Box
            sx={{
                width: '100%',
                marginBottom: '16px'
            }}
        >
            {allMatch.length > 0 && (<LiveMatch allMatch={allMatch} />)}
            <Story />
            <PostSomething />
            {pinpoll.map((post, index) => (
                <Box key={index} style={{ width: "100%" }}>
                    <Polls key={index} post={post} postquestion={post?.question} />
                </Box>
            ))}
            <Posts
                filter={"all_pitches"}
                hprops={() => {
                    setPitchcreated(!pitchCreated);
                }}
            />
            {openModel && (
                <ActiveuserRunModel
                    data={userData}
                    Open={openModel}
                    handleCloseModel={handleClose}
                />
            )}
        </Box>
    )
}

export default Feeds;