import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getStorageItem } from "../../utils/sessionStorage";
import { Helmet } from "react-helmet";
import { Typography, Box, AppBar, Toolbar, Avatar, Card, CardContent, CardHeader, Button, Grid } from '@mui/material';
import bgEvent from "../../assets/images/Events/bg-event.png";
import { makeStyles } from '@mui/styles';
import { toast } from 'react-toastify';
import axios from "axios";
import RequestDialog from "./requestPopUp";
import RollNoModal from "./rollNoModal";
import PlayersList from "./playersList";
import backButton from "../../assets/images/icons/back-button.png"

const AllTeams = () => {
    const accessToken = getStorageItem("token");
    const [allTeam, setAllTeam] = useState([])
    const [teamMembers, setTeamMembers] = useState([])
    const [requestTeamName, setRequestTeamName] = useState('')
    const [requestTeamCaptain, setRequestTeamCaptain] = useState('')
    const [eventPageData, setEventPageData] = useState([])

    // back-button
    const navigate = useNavigate();

    // whatsapp sharing
    const champhuntURL = 'https://www.champhunt.com/all-events'
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let whatsappUrl = "";
    if (isMobile) {
        whatsappUrl = `https://api.whatsapp.com/send?text=${champhuntURL}`
    }
    else {
        whatsappUrl = `https://web.whatsapp.com/send?text=${champhuntURL}`
    }

    // user-Id
    const userId = localStorage.getItem('user_id');

    //tournamentID
    const { tournament_id } = useParams();

    // custom css
    const useStyles = makeStyles((theme) => ({
        avatar: {
            width: 30,
            height: 30,
        },

        teamInfo: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },

        players: {
            marginTop: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
        },

        inviteButton: {
            marginTop: theme.spacing(2),
        },

        smallAvatar: {
            width: 20,
            height: 20,
        },

        card: {
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
                transform: 'scale(1.05)',
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            },
        },
    }));

    const classes = useStyles();

    // Invite PopUp consisting Request
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [openInvite, setOpenInvite] = useState(false);

    const handleInviteOpen = (team) => {
        setSelectedTeam(team);
        setOpenInvite(true);
    };

    const handleInviteClose = () => {
        setOpenInvite(false);
        setSelectedTeam(null);
    };

    // Player list PopUp
    const [openList, setOpenList] = useState('');
    const teamMemberRef = useRef(null)

    const handlePlayerList = (team) => {
        teamMemberRef.current = team
        setOpenList(true);
    }

    const handleListClose = () => {
        setOpenList(false);
    };

    // roll no.
    const [rollNumber, setRollNumber] = useState(localStorage.getItem('rollNo'))
    const [isRollNoModalOpen, setIsRollNoModalOpen] = useState(false)
    const checkRollNoExist = (team) => {
        setRequestTeamName(team.teamName)
        setRequestTeamCaptain(team.teamCaptain)
        if (!rollNumber) {
            setIsRollNoModalOpen(true)
        }

        else { sendRequest(rollNumber) }

    }

    // send request button fn
    const sendRequest = async (rollNum) => {
        try {
            if (requestTeamCaptain && requestTeamName) {
                const teamData = {
                    userId: userId,
                    playerName: userName,
                    teamName: requestTeamName,
                    teamCaptain: requestTeamCaptain,
                    rollNo: rollNum,
                    tournamentId: tournament_id,
                }

                var loginOptions = {
                    method: "POST",
                    url: global.config.ROOTURL.prod + "/events/team/sendTeamRequest",
                    data: teamData,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    json: true,
                };

                await axios(loginOptions)
                    .then((response) => {
                        if (response.data) {
                            if (response.data === "Request Already Sent") {
                                toast.success(response.data)
                            }
                            else if (rollNum) {
                                toast.success(`Request Sent to Team Captain ${requestTeamCaptain}`)
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            }
        } catch (err) {
            console.log(err)
        }
    }

    const sendSecondRequest = async (rollNum) => {
        try {
            if (requestTeamCaptain && requestTeamName) {
                const teamData = {
                    userId: userId,
                    playerName: userName,
                    teamName: requestTeamName,
                    teamCaptain: requestTeamCaptain,
                    rollNo: rollNum,
                    tournamentId: tournament_id,
                }

                var loginOptions = {
                    method: "POST",
                    url: global.config.ROOTURL.prod + "/events/team/sendTeamRequest",
                    data: teamData,
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    json: true,
                };

                await axios(loginOptions)
                    .then((response) => {
                        if (response.data) {
                            if (rollNum) {
                                toast.success('Request Sent to Team Captain', requestTeamCaptain)
                            }
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            }
        } catch (err) {
            console.log(err)
        }
    }

    // roll no. popup submit
    const handleRollNoSubmit = (newRollNo) => {
        localStorage.setItem('rollNo', newRollNo);
        setRollNumber(newRollNo);
        sendSecondRequest(newRollNo);
        setIsRollNoModalOpen(false); // Close the modal after submission
    };

    const userName = localStorage.getItem('full_name')

    // team data
    useEffect(() => {
        const fetchTeamData = async () => {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                };

                const response = await axios.get(
                    `${global.config.ROOTURL.prod}/events/team/getAllTeams/${tournament_id}`,
                    config
                );

                if (response.data) {
                    const teamData = response.data;
                    setAllTeam([...teamData]);
                }
            } catch (error) {
                console.error("Error fetching team data:", error);
            }
        };
        fetchTeamData();
    }, []);

    const getEventData = async () => {
        try {
            const eventDataByTournament = {
                method: "GET",
                url: global.config.ROOTURL.prod + `/events/getEventById/${tournament_id}`,
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            };

            await axios(eventDataByTournament)
                .then((response) => {
                    setEventPageData(response.data || {});
                })
        } catch {
            console.log('error')
        }
    };

    useEffect(() => {
        getEventData()
    }, [])


    return (
        <div>
            <Helmet>
                <title>Champhunt | Tournament</title>
            </Helmet>

            <div style={{ paddingTop: "128px" }}>
                <AppBar position="static" sx={{ bgcolor: "#ffffff" }}>
                    <Toolbar>
                        <img onClick={() => navigate(-1)} style={{ width: '20px', height: '20px', marginRight: '20px', cursor: 'pointer' }} src={backButton} />
                        <Typography variant="h6" sx={{ color: '#313335', fontWeight: '600' }}>
                            {eventPageData.eventTitle}
                        </Typography>
                    </Toolbar>
                </AppBar>

                {/* BG */}
                <Box style={{ height: isMobile ? "200px" : "400px", overflow: "hidden", position: "relative" }}>
                    <img
                        src={eventPageData.pageBanner}
                        alt="Cricket"
                        className={classes.media}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            position: "absolute"
                        }}
                    />
                </Box>

                <Box sx={{ textAlign: 'center', padding: '16px 0', backgroundColor: '#FAFAFA !important' }}>
                    <Typography variant="h5" sx={{ paddingBottom: '5px', color: '#494D50' }}>
                        Organizing By: <span style={{ color: '#0B518E', fontWeight: '600' }}>{eventPageData.organizingBy}</span>
                    </Typography>
                    <Typography
                        variant="h6"
                        sx={{ color: '#840495', fontStyle: 'italic' }}
                        dangerouslySetInnerHTML={{ __html: eventPageData.eventdescription }}
                    />
                </Box>
            </div>

            <Box sx={{ width: '100%', maxWidth: '1600px', margin: '0 auto', padding: '0 20px', backgroundColor: '#FAFAFA !important', paddingBottom: '20px' }}>

                <Grid container spacing={3} sx={{ mt: 2 }}>

                    {allTeam.map((team, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index} data-value={team}>
                            <Card className={classes.card}>
                                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <CardHeader
                                        avatar={
                                            <Avatar style={{ width: '45px', height: '45px' }} aria-label="team-captain" className={classes.avatar} src="captain_image.jpg" />
                                        }
                                        title={
                                            <Typography style={{ fontsize: '16px', color: '#545454', fontWeight: '600' }}>{team.teamCaptain}</Typography>
                                        }
                                        subheader={
                                            <Typography variant="body2" color="#04B400" style={{ fontsize: '14px', fontWeight: 500 }}>
                                                Team Captain
                                            </Typography>
                                        }
                                    />
                                    <Button variant="outlined"
                                        color="primary"
                                        style={{ marginRight: '20px' }}
                                        className={classes.inviteButton}
                                        onClick={() => handlePlayerList(team)}>Players</Button>
                                </Box>
                                <CardContent>
                                    <div className={classes.teamInfo}>
                                        <Typography variant="h5" fontStyle="bold" color="#832990">{team.teamName}</Typography>
                                    </div>
                                    <div className={classes.players}>
                                        <Avatar className={classes.smallAvatar} src="path_to_player1_image.jpg" />
                                        <Avatar className={classes.smallAvatar} src="path_to_player2_image.jpg" />
                                        <Avatar className={classes.smallAvatar} src="path_to_player3_image.jpg" />
                                        <Avatar className={classes.smallAvatar} src="path_to_player4_image.jpg" />
                                        <Typography variant="body2">+3</Typography>
                                    </div>
                                    {team.teamCaptain === userName ? (
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            style={{ marginRight: '5px' }}
                                            className={classes.inviteButton}
                                            onClick={() => handleInviteOpen(team)}
                                        >
                                            Invite Friends
                                        </Button>
                                    ) : <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.inviteButton}
                                        onClick={() => checkRollNoExist(team)}
                                    >
                                        Send Request
                                    </Button>}

                                </CardContent>
                            </Card>
                        </Grid>

                    ))}

                </Grid>

            </Box>
            {selectedTeam && (
                <RequestDialog open={openInvite} onClose={handleInviteClose} team={selectedTeam} whatsAppUrl={whatsappUrl} />
            )}
            {teamMemberRef.current &&
                <PlayersList open={openList} onClose={handleListClose} teams={teamMemberRef.current} />

            }
            <RollNoModal
                open={isRollNoModalOpen}
                onClose={() => setIsRollNoModalOpen(false)}
                onSubmit={handleRollNoSubmit}
            />
        </div>
    )
}



export default AllTeams;