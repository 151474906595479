import React, { useState, useEffect, useCallback, useRef } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import axios from "axios";
import ReelItem from "./ReelItem";
import CommentModal from "./CommentModal";
import { getStorageItem } from "../../utils/sessionStorage";
import "./index.scss";
import VideoUploaderWithTrimmer from "./VideoUploaderWithTrimmer";

const theme = createTheme({
  palette: {
    mode: "dark",
  },
});

const Highlights = () => {
  const [reels, setReels] = useState([]);
  const [currentReelIndex, setCurrentReelIndex] = useState(0);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const accessToken = getStorageItem("token");
  const [ownFollowings, setOwnFollowings] = useState([]);


  const observer = useRef();
  const lastReelElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const fetchUserHighlights = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    const userId = getStorageItem("user_id");
    const currentDate = new Date().toISOString();
    try {
      const response = await axios.get(
        `${global.config.ROOTURL.prod}/highlight/highlights/getAllHighlights/${page}/5/${currentDate}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.length === 0) {
        setHasMore(false);
      } else {
        setReels((prevReels) => [...prevReels, ...response.data]);
      }
    } catch (error) {
      console.error("Error fetching user highlights:", error);
    }
    setLoading(false);
  }, [page]);

  const fetchOwnFollowing = async () => {
    if (!accessToken) return;
    const getOwnFollowing = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/auth/get-following-user-id/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getOwnFollowing)
      .then((response) => {
        const followingIds = response.data.map(item => item._id);
        setOwnFollowings(followingIds); 
      })
      // .then(() => checkFollower())
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error)
          // clearStorage();
          // navigate("/login");
        }
      });
  };

  useEffect(()=>{
    if(accessToken){
      fetchOwnFollowing()
    }
  },[])

  useEffect(() => {
    fetchUserHighlights();
  }, [fetchUserHighlights]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <CssBaseline />
      <div className="app">
        <div className="app__reels">
          {reels.map((reel, index) => (
            <div
              key={reel._id}
              ref={index === reels.length - 1 ? lastReelElementRef : null}
            >
              <ReelItem
                  reel={reel}
                  onComment={() => setIsCommentModalOpen(true)}
                  setCurrentReelIndex={() => setCurrentReelIndex(index)}
                  commentCount={reel.highlightCommentCount} 
                  checkFollow = {ownFollowings?.includes(reel.userId) ? "Following" : "NotFollowing"}
              />
            </div>
          ))}
        </div>
        {loading && <div>Loading...</div>}
        {!hasMore && <div>No more highlights to load</div>}
        <CommentModal
          isOpen={isCommentModalOpen}
          onClose={() => setIsCommentModalOpen(false)}
          highlightId={reels[currentReelIndex]?._id}
        />
      </div>
    </>
  );
};

export default Highlights;