import React from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// image
import referAfriendImage from "../../assets/images/home/refer.png";

const ReferAFriend = () => {
    const navigate = useNavigate();
    
    return (
        <Box className="invitefriend" sx={{cursor:"pointer"}}
            onClick={() => {
                navigate("/referearn")
            }} >
            <img src={referAfriendImage} alt="" />
        </Box >
    )
}

export default ReferAFriend;