import React from 'react';
import { Box } from '@mui/material';

import "react-tabs/style/react-tabs.css";
// import EntryPage from "../commentary/entryPage";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import ActiveuserRunModel from "../feeds/ActiveuserRunModel";

import LiveMatch from './liveMatch';
import PostSomething from './PostSometing';
import Story from "../guest-stories/index";
import Posts from "../guest-post/index";

const Feeds = (props) => {
    const [openModel, setopenModel] = useState(false);
    const [userData, setuserData] = useState({});
    const [pitchCreated, setPitchcreated] = useState(false);
    const [pinpoll, setPinPoll] = useState([]);
    const accessToken = localStorage.getItem("token");
    const userId = localStorage.getItem("user_id");
    const [allMatch, setAllMatch] = useState([]);
    const handleClose = () => {
        setopenModel(false);
    };
    const getPerDayBonusRuns = () => {
        if (!userId) return;
        var rejectdetails = {
            method: "POST",
            url: global.config.ROOTURL.prod + `/auth/activeuser/createuserEntry`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: {
                userId,
            },
        };
        axios(rejectdetails)
            .then((res) => {
                if (res?.data?.message !== "") {
                    const newdata = res.data;
                    setuserData(newdata);
                    setopenModel(true);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getPerDayBonusRuns();
        window.scrollTo(0, 0);
    }, [pitchCreated]);

    const getAllLiveMatch = async () => {

        const options = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/scorecard/getLiveScores",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(options)
            .then(({ data }) => {
                setAllMatch(data);
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    console.log(error);
                }
            });
    };
    useEffect(() => {
        getAllLiveMatch();
    }, []);

    return (
        <Box
            sx={{
                width: '100%',
                marginBottom: '16px'
            }}
        >
            {allMatch.length > 0 && (<LiveMatch allMatch={allMatch} />)}
            <Story />
            <PostSomething />
            <Posts
                filter={"all_pitches"}
                hprops={() => {
                    setPitchcreated(!pitchCreated);
                }}
            />
            {openModel && (
                <ActiveuserRunModel
                    data={userData}
                    Open={openModel}
                    handleCloseModel={handleClose}
                />
            )}
        </Box>
    )
}

export default Feeds;