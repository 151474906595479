import React from 'react';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Grid, Box, Typography, Avatar } from '@mui/material';

// images
import silverMedal from '../../assets/images/Silvermedal.svg';
import goldMedal from '../../assets/images/Goldmedal.svg';
import bronzeMedal from '../../assets/images/Bronzemedal.svg';
import silverStage from '../../assets/images/silverstages.svg';
import goldStage from '../../assets/images/goldStages.svg';
import bronzeStage from '../../assets/images/bronzeStages.svg';
import malePic from '../../assets/images/UserB.png';
import viewMore from '../../assets/images/Chevrons right.svg';

const NewLeaderBoard = () => {
    const [leaderboardFilter, setleaderboardFilter] = useState("Daily");
    const [allTimeRun, setAllTimeRun] = useState([]);

    function formatRunsNumber(runs) {
        if (runs >= 1000) {
            return (runs / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
        }
        return runs.toString();
    }


    const getleaderBoard = async () => {

        const options = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/leaderboard/thisYear/runsThisYear/1/10",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
        axios(options)
            .then(({ data }) => {
                setAllTimeRun(data);
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    console.log(error);
                }
            });
    };

    useEffect(() => {
        getleaderBoard();
    }, [])

    return (
        <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 1)', borderRadius: '8px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', padding: '25px 10px' }}>
                <Typography
                    sx={{
                        color: 'rgba(12, 91, 160, 1)',
                        fontSize: '18px',
                        fontWeight: '700',
                        fontFamily: 'Helvetica',
                    }}>
                    Leaderboard
                </Typography>
                <img src={viewMore} style={{ cursor: "pointer" }} />
            </Box>

            {/* leaderboard filter */}
            <Box sx={{
                display: "flex",
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                border: '1px solid #E2E2E2',
                borderRadius: '24px',
                margin: '0px 25px 20px 25px'
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        backgroundColor: leaderboardFilter === "Daily" ? 'rgba(44, 146, 7, 1)' : 'transparent',
                        borderRadius: '24px',
                    }}
                    onClick={() => setleaderboardFilter("Daily")}
                >
                    <Typography
                        sx={{
                            color: leaderboardFilter === "Daily" ? 'rgba(255, 255, 255, 1)' : 'rgba(107, 107, 107, 1)',
                            fontFamily: 'Helvetica',
                            padding: "7px 15px",
                            cursor: "pointer",
                            textAlign: 'center',
                            flexGrow: 1,
                        }}
                    >
                        Daily
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        backgroundColor: leaderboardFilter === "Weekly" ? 'rgba(44, 146, 7, 1)' : 'transparent',
                        borderRadius: '24px',
                    }}
                    onClick={() => setleaderboardFilter("Weekly")}
                >
                    <Typography
                        sx={{
                            color: leaderboardFilter === "Weekly" ? 'rgba(255, 255, 255, 1)' : 'rgba(107, 107, 107, 1)',
                            fontFamily: 'Helvetica',
                            padding: "7px 15px",
                            cursor: "pointer",
                            textAlign: 'center',
                            flexGrow: 1,
                        }}
                    >
                        Weekly
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        backgroundColor: leaderboardFilter === "All Time" ? 'rgba(44, 146, 7, 1)' : 'transparent',
                        borderRadius: '24px',
                    }}
                    onClick={() => setleaderboardFilter("All Time")}
                >
                    <Typography
                        sx={{
                            color: leaderboardFilter === "All Time" ? 'rgba(255, 255, 255, 1)' : 'rgba(107, 107, 107, 1)',
                            fontFamily: 'Helvetica',
                            padding: "7px 15px",
                            cursor: "pointer",
                            textAlign: 'center',
                            flexGrow: 1,
                        }}
                    >
                        All Time
                    </Typography>
                </Box>
            </Box>
            <Box>
                {allTimeRun && allTimeRun.length > 0 && (
                    <Grid container direction="row" justifyContent="center" alignItems="end">
                        <Grid item>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "6px",
                                }}
                            >
                                {/* <img
                                    style={{ width: "40px", zIndex: 1 }}
                                    src={silverMedal}
                                    alt="Silver Medal"
                                /> */}
                                <Link to={`/profile/${allTimeRun[1].userId}`} style={{ textDecoration: "none" }}>
                                    <Avatar
                                        style={{ width: "55px", height: '55px', marginTop: "-7px", zIndex: 0, borderRadius: '50%' }}
                                        src={allTimeRun[1].profilePhoto
                                            ? allTimeRun[1].profilePhoto
                                            : malePic}
                                        alt="Female"
                                        onError={(e) => e.target.src = malePic}
                                    />
                                </Link>
                            </Box>

                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: "5px",
                                }}>
                                <Link to={`/profile/${allTimeRun[1].userId}`} style={{ textDecoration: "none" }}>
                                    <Typography
                                        sx={{
                                            color: '#282828',
                                            fontSize: '12px',
                                            fontWeight: '700',
                                            fontFamily: 'Helvetica',
                                            marginBottom: "3px",
                                        }}>
                                        {allTimeRun[1].userName.length > 9
                                            ? allTimeRun[1].userName.substring(0, 9) + "..."
                                            : allTimeRun[1].userName}
                                    </Typography>
                                </Link>
                                <Typography
                                    sx={{
                                        color: 'rgba(12, 91, 160, 1)',
                                        backgroundColor: '#007BFF2B',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        fontFamily: 'Helvetica',
                                        padding: "8px 20px",
                                        borderRadius: "15px",
                                        marginBottom: "2px",
                                    }}>
                                    {formatRunsNumber(allTimeRun[1].runs)}
                                </Typography>
                            </Box>
                            <img src={silverStage} alt="Silver Stage" />
                        </Grid>

                        <Grid item>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '6px'
                                }}>
                                {/* <img
                                    style={{ width: "40px", zIndex: 1 }}
                                    src={goldMedal}
                                    alt="Gold Medal"
                                /> */}
                                <Link to={`/profile/${allTimeRun[0].userId}`} style={{ textDecoration: "none" }}>
                                    <Avatar
                                        style={{ width: "55px", height: '55px', marginTop: "-7px", zIndex: 0, borderRadius: '50%' }}
                                        src={allTimeRun[0].profilePhoto
                                            ? allTimeRun[0].profilePhoto
                                            : malePic}
                                        alt="Male"
                                        onError={(e) => e.target.src = malePic}
                                    />
                                </Link>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: "5px",
                                }}>
                                <Link to={`/profile/${allTimeRun[0].userId}`} style={{ textDecoration: "none" }}>
                                    <Typography
                                        sx={{
                                            color: '#282828',
                                            fontSize: '12px',
                                            fontWeight: '700',
                                            fontFamily: 'Helvetica',
                                            marginBottom: "3px",
                                        }}>
                                        {allTimeRun[0].userName.length > 9
                                            ? allTimeRun[0].userName.substring(0, 9) + "..."
                                            : allTimeRun[0].userName}
                                    </Typography>
                                </Link>
                                <Typography
                                    sx={{
                                        color: 'rgba(12, 91, 160, 1)',
                                        backgroundColor: '#007BFF2B',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        fontFamily: 'Helvetica',
                                        padding: "8px 20px",
                                        borderRadius: "15px",
                                        marginBottom: "2px",
                                    }}>
                                    {formatRunsNumber(allTimeRun[0].runs)}
                                </Typography>
                            </Box>
                            <img src={goldStage} alt="Gold Stage" style={{ marginLeft: '-1px', marginRight: '-1px' }} />
                        </Grid>

                        <Grid item>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '6px'
                                }}>
                                {/* <img
                                    style={{ width: "40px", zIndex: 1 }}
                                    src={bronzeMedal}
                                    alt="Bronze Medal"
                                /> */}
                                <Link to={`/profile/${allTimeRun[2].userId}`} style={{ textDecoration: "none" }}>
                                    <Avatar
                                        style={{ width: "55px", height: '55px', marginTop: "-7px", zIndex: 0, borderRadius: '50%' }}
                                        src={allTimeRun[2].profilePhoto
                                            ? allTimeRun[2].profilePhoto
                                            : malePic}
                                        alt="Also Male"
                                        onError={(e) => e.target.src = malePic}
                                    />
                                </Link>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: "5px",
                                }}>
                                <Link to={`/profile/${allTimeRun[2].userId}`} style={{ textDecoration: "none" }}>
                                    <Typography
                                        sx={{
                                            color: '#282828',
                                            fontSize: '12px',
                                            fontWeight: '700',
                                            fontFamily: 'Helvetica',
                                            marginBottom: "3px",
                                        }}>
                                        {allTimeRun[2].userName.length > 9
                                            ? allTimeRun[2].userName.substring(0, 9) + "..."
                                            : allTimeRun[2].userName}
                                    </Typography>
                                </Link>
                                <Typography
                                    sx={{
                                        color: 'rgba(12, 91, 160, 1)',
                                        backgroundColor: '#007BFF2B',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        fontFamily: 'Helvetica',
                                        padding: "8px 20px",
                                        borderRadius: "15px",
                                        marginBottom: "2px",
                                    }}>
                                    {formatRunsNumber(allTimeRun[2].runs)}
                                </Typography>
                            </Box>
                            <img src={bronzeStage} alt="Bronze Stage" />
                        </Grid>
                    </Grid>
                )}
            </Box>

        </Box>
    )
}

export default NewLeaderBoard;
