import React, { useContext, useEffect, useState } from "react";
import "./CartItem.scss"
import { CartContext } from "../../context/CartContext";
import { useNavigate } from "react-router-dom";


import minus from "../../assets/images/marketplace/minus.png";
import plus from "../../assets/images/marketplace/plus.png";
import ballIcon from "../../assets/images/marketplace/redball.png";
import deleteIcon from "../../assets/images/marketplace/deleteIcon.png";


export default function CartItem(props) {
  const { isCart, product, index } = props;
  const [cart, setCart] = useContext(CartContext);
  const [productValue, setPrdoductValue] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    setPrdoductValue(product?.productInfo?.quantity);
  }, [productValue, product?.productInfo?.quantity]);
  const handleDeleteProduct = (product) => {
    const productIndex = cart.findIndex(
      (cartProduct) =>
        cartProduct?.productInfo?._id === product?.productInfo?._id
    );
    if (productIndex !== -1) {
      if (product[productIndex]?.productInfo?.quantity > 1) {
        // Create a new array without the product to delete
        const updatedCart = [
          ...cart.slice(0, productIndex),
          ...cart.slice(productIndex + 1),
        ];

        // Update the cart state with the updated array
        setCart(updatedCart);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
      } else {
        const updatedCart = cart.filter((delProduct) => {
          return delProduct?.productInfo?._id !== product?.productInfo?._id;
        });
        setCart(updatedCart);
        localStorage.setItem("cart", JSON.stringify(updatedCart));
      }
    }
  };
  const handleProductValue = (operation, product) => {
    const updatedCart = [...cart]; // Create a copy of the cart array
    

    const productIndex = updatedCart.findIndex((cartProduct) => {
      return cartProduct?.productInfo?._id === product?.productInfo?._id;
    });

    if (productIndex !== -1) {
      if (operation === "add") {
        updatedCart[productIndex].productInfo.quantity += 1;
      } else if (operation === "remove") {
        if (updatedCart[productIndex]?.productInfo?.quantity === 1) {
          handleDeleteProduct(product);
        } else {
          updatedCart[productIndex].productInfo.quantity -= 1;
        }
      }
      setCart(updatedCart); // Update the cart state with the updated array
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    }
  };

  return (
    <div className="CartItemMainContainer">
      <div className="cart_inner_container">
        <div className="product_Left_container">
  
          <img className="product_image" src={ product?.productInfo?.images[0] ? product?.productInfo?.images[0]: ""} alt="" />
          
          <h4 className="product_name">{product?.productInfo?.title}</h4>
        </div>
        <div className="product_middle_container">
          <img
            className="minus_icon"
            src={minus}
            alt=""
            onClick={() => handleProductValue("remove", product)}
          />
          <div className="product_counter">{productValue}</div>
          <img
            className="plus_icon"
            src={plus}
            alt=""
            onClick={() => handleProductValue("add", product)}
          />
        </div>
        <div className="product_right_container">
          <p className="product_price">₹ {product?.productInfo?.price - product?.productInfo?.discount}</p>
         

          <img
            className="delete_btn"
            src={deleteIcon}
            alt=""
            onClick={() => handleDeleteProduct(product)}
          />
        </div>
      </div>
      <div className="runs_container">
        <div className="runs_inner_container">
          <img className="ball_icon" src={ballIcon} alt="" />
          <p className="runs_text">{product?.productInfo?.runs}</p>
        </div>
      </div>
      {index !== cart.length - 1 ? (
        <div className="horizontal_line"></div>
      ) : (
        null
      )}
    </div>
  );
}
