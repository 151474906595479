import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { Card, CardContent, Typography, Avatar, Button, Box } from '@mui/material';
import SportsBaseballIcon from "@mui/icons-material/SportsBaseball";

import pymkBackground from "../../assets/images/pymk-background.jpeg";
import BackgroundImageMayKnow from "../../assets/images/pymk-background.svg";
import pymkBall from '../../assets/images/bxs_cricket-ball.svg';
import suggestMore from '../../assets/images/Chevrons right.png';

const PeopleUMayKnow = () => {
    const navigate = useNavigate();
    const accessToken = getStorageItem("token");
    const [suggestions, setSuggestions] = useState([]);
    const [followings, setFollowings] = useState([]);
    const [isFollowing, setIsFollowing] = useState(false);
    const [changeTextToFollowing, setchangeTextToFollowing] = useState(false);
    const [suggestionFollowCheckArray, setSuggestionFollowCheckArray] = useState([false,false,false,false,false]);

    const url = global.config.ROOTURL.prod + "/auth/following-suggestion-users/";

    const getSuggestionsOptions = {
        method: "get",
        url: url,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
        },
    };

    const fetchFollowing = () => {

        if (!accessToken) return
        const getFollowing = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/auth/get-following-user-id/",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(getFollowing)
            .then((response) => {
                setFollowings(response.data ? response.data : []);
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    clearStorage();
                    navigate('/login');
                }
            });
    };

    useEffect(() => {
        fetchFollowing();
        axios(getSuggestionsOptions)
            .then((response) => {
                setSuggestions(response.data);
            })
            .catch((error) => {
                if (error?.response?.status == 400) {
                } else if (error?.response?.status == 401) {
                    navigate("/login");
                }
            });
    }, []);

    function formatRunsNumber(num) {
        if (num >= 1_00_00_000) {
            return (num / 1_00_00_000).toFixed(2) + 'Cr';
        } else if (num >= 1_00_000) {
            return (num / 1_00_000).toFixed(2) + 'L';
        } else if (num >= 1_000) {
            return (num / 1_000).toFixed(2) + 'K';
        }
        return num.toString();
    }

    const handleUnfollow = (followingId) => {
        const unfollow = {
            method: "POST",
            url: global.config.ROOTURL.prod + "/auth/remove-following-user/",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: {
                followingUserId: followingId,
            },
        };

        if (followingId != null) {
            axios(unfollow)
                .then((response) => {
                    console.log("unfollow response for the new pitch", response)
                    //   setIsFollowing(false);
                    //   localStorage.removeItem(following_${following._id});
                })
            // .then(() => {
            //   if (!loading) {
            //     timer.current = window.setTimeout(() => {
            //       setLoading(false);
            //     }, 1000);
            //   }
            //   onFolloUnFollowToggle();
            // })
            // .catch((error) => {
            //   if (error?.response?.status == 401) {
            //     // clearStorage();
            //     // navigate("/login");
            //   }
            // });
        }
    };

    const handleFollow = (followingId) => {
        const follow = {
            method: "POST",
            url: global.config.ROOTURL.prod + "/auth/add-following-user/",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: {
                followingUserId: followingId,
            },
        };

        if (followingId != null) {
            axios(follow)
                .then((response) => {
                    console.log("Following user for the pitch new redesign", response)
                })
            // .then(() => {
            //   if (!loading) {
            //     timer.current = window.setTimeout(() => {
            //       setLoading(false);
            //     }, 1000);
            //   }
            //   onFolloUnFollowToggle();
            // })
            // .catch((error) => {
            //   if (error?.response?.status == 401) {
            //     clearStorage();
            //     navigate("/login");
            //   }
            // });
        }
    };

    const handleToggleFollow = (index, followingId) => {
        setSuggestionFollowCheckArray(prevstate => {
            const newArray = [...prevstate];
            newArray[index] = !newArray[index];
            return newArray;
        });
        if (suggestionFollowCheckArray[index]) {
            handleUnfollow(followingId);
        } else {
            handleFollow(followingId);
        }
    };
    

    return (
        <Box
            sx={{
                backgroundColor: 'rgba(255, 255, 255, 1)',
                borderRadius: '8px',
                marginBottom: '12px',
                backgroundImage: `url(${BackgroundImageMayKnow})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                width: "100%",
            }}>
            <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center" }}>
                <Typography
                    sx={{
                        color: 'rgba(12, 91, 160, 1)',
                        fontSize: '24px',
                        fontWeight: '700',
                        fontFamily: 'Helvetica',
                        padding: "20px",
                        display: "flex", justifyContent: 'center', alignItems: 'center',
                    }}
                >People you may know
                </Typography>
                <img src={suggestMore} style={{ width: '25px', height: '25px', cursor:'pointer' }} onClick={()=>navigate('/suggestions')}/>
            </Box>

            {/* Card */}
            <Box sx={{
                padding: "0px 5px 15px 20px !important",
                overflowX: "scroll",
                gap: "15px",
                display: "flex",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                    display: "none",
                }
            }}>
                {suggestions.map((user, index) => (
                    <Card
                        sx={{
                            maxWidth: 180,
                            borderRadius: 3,
                            overflow: "visible !important",
                            border: '3px solid white',
                        }}
                        key={index}
                    >
                        {/* Background Image */}
                        <Box
                            component="img"
                            alt="background-img"
                            src={pymkBackground}
                            sx={{
                                width: '165px',
                                height: 80,
                                objectFit: "cover",
                                borderTopLeftRadius: '10px',
                                borderTopRightRadius: '10px',
                            }}
                        />

                        {/* Profile and Content */}
                        <CardContent
                            sx={{
                                textAlign: "start",
                                p: 2,
                                backgroundColor: "rgba(12, 91, 160, 1)",
                                padding: '0 10px 10px 10px !important',
                                borderBottomLeftRadius: "10px",
                                borderBottomRightRadius: "10px",
                            }}>
                            <Link to={`/profile/${user._id}`} style={{ textDecoration: "none" }}>
                                <Avatar
                                    src={user.profilePhoto}
                                    alt="Ravi Thakur"
                                    sx={{
                                        width: 40,
                                        height: 40,
                                        margin: "-30px auto 8px",
                                        border: "2px solid white",
                                    }}
                                />
                                <Typography sx={{ color: "#FFFFFF", fontSize: "16px", fontWeight: '600', paddingBottom: '8px' }}>
                                    {
                                        (user?.firstName?.length + user?.lastName?.length > 12)
                                            ? `${(user?.firstName + " " + user?.lastName).substring(0, 12)}...`
                                            : `${user?.firstName} ${user?.lastName}`
                                    }
                                </Typography>
                            </Link>
                            <Typography
                                color="text.secondary"
                                sx={{ display: "flex", alignItems: "center", gap: 0.5, fontSize: '10px', color: "white", fontWeight: "500", paddingBottom: '8px' }}
                            >
                                {user.followersCount} Followers &bull; <img src={pymkBall} style={{ width: "12px" }} />{formatRunsNumber(user.totalRun)} Runs
                            </Typography>

                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    textTransform: "none",
                                    fontWeight: "bold",
                                    width: "100%",
                                    backgroundColor: "white",
                                    color: "rgba(44, 146, 7, 1)",
                                    "&:hover": {
                                        color: "white",
                                        backgroundColor: "rgba(44, 146, 7, 1)"
                                    },
                                }}

                                onClick={()=> handleToggleFollow(index, user._id)}
                            >
                                {suggestionFollowCheckArray[index] ? "Following" : "Follow"}
                            </Button>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        </Box>
    )
}

export default PeopleUMayKnow;