import React, { useEffect, useState } from "react";
import "./index.css";
import Card from "./card";
import { Button, Stack } from "@mui/material";
import { getStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
const accessToken = getStorageItem("token");


const AllScorecards = () => {
  const [allCards, setAllCards] = useState([]);

  const getAllCards = async () => {
    if (!accessToken) return
    const options = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/scorecard/getAllRecentCards`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    await axios(options)
      .then(({ data }) => {
        console.log("Data=",data);
        setAllCards(data);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };
  useEffect(() => {
    getAllCards();
  }, []);
  return (
    <div className="contest_admin-wrapper">
      <div className="contest_admin_head">
        <h3 className="contest_admin_head-content--left">All Active Scorecards</h3>
      </div>
      <div className="contest_admin_prediction-list">
        {allCards?.map((card, index) => {
          return (
            <Card
              key={index}
              data={card}
              length={allCards?.length}
              getAllCards={getAllCards}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AllScorecards;