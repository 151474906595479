import "./index.scss";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { getStorageItem } from "../../utils/sessionStorage";
import { useState, useEffect } from "react";
import axios from "axios";

const NameCard = (props) => {
  const userId = getStorageItem("user_id");
  const accessToken = getStorageItem("token");
  const fullName = localStorage.getItem("full_name")
  const { newSharedDetail, postObj } = props;
  const [sharedPersonName, setSharedPersonName] = useState(newSharedDetail.firstName+ " " + newSharedDetail.lastName)


  const fetchUserInfo = () => {
      const getUserInfo = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/auth/profile/",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        data: {
          userId: newSharedDetail.userId,
        },
      };
      axios(getUserInfo)
        .then((response) =>{
          setSharedPersonName(response.data?.firstName+ " " + response.data?.lastName)
        })
        .catch((error) => {
          console.log(error)
        });
    
  };

  useEffect(()=>{
    if(newSharedDetail.userId !== userId){
      fetchUserInfo();
    }
  },[newSharedDetail])

  return (
    <div className="post" style={{background:"transparent", padding:"0px", margin: "0px 0px 0px -40px"}}>
      <div className="post-header">
        <div className="left">
          <div className="avatar">
            <img
              className="avatar-image"
              src={newSharedDetail.profilePhoto ? newSharedDetail.profilePhoto : defaultAvatar}
              alt={""}
            />
          </div>
          <div className="avatar-cnt">
            <p>
              <Link
                className="shared-link"
                to={`/profile/${newSharedDetail.userId}`}
                style={{ textDecoration: "none" }}
              >
                {newSharedDetail?.userId === userId ? fullName : sharedPersonName}
              </Link>
            </p>
            <p className="date-time">
              {newSharedDetail.createdDate && (
                <ReactTimeAgo date={Date.parse(newSharedDetail.createdDate)} locale="en-US" />
              )}
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NameCard;
