import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Box, Typography, Button, IconButton, Avatar } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { useMediaQuery } from "@mui/material";

import highlightBg from "../../../assets/images/Pitch/Feed/highlightBg.svg";
import { getStorageItem } from "../../../utils/sessionStorage";

const PostHilights = () => {
    const mobileView = useMediaQuery("(max-width:768px)");
    const accessToken = getStorageItem("token");
    const [reels, setReels] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [playingIndex, setPlayingIndex] = useState(null);

    const fetchUserHighlights = useCallback(async () => {
        if (loading) return;
        setLoading(true);
        const currentDate = new Date().toISOString();
        try {
            const response = await axios.get(
                `${global.config.ROOTURL.prod}/highlight/highlights/getAllHighlights/${page}/5/${currentDate}`,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            if (response.data.length === 0) {
                setHasMore(false);
            } else {
                setReels((prevReels) => [...prevReels, ...response.data]);
            }
        } catch (error) {
            console.error("Error fetching user highlights:", error);
        }
        setLoading(false);
    }, [page]);

    useEffect(() => {
        fetchUserHighlights();
    }, [fetchUserHighlights]);

    const handlePlayPause = (index) => {
        const video = document.getElementById(`video-${index}`);
        if (video.paused) {
            video.play();
            setPlayingIndex(index);  // Set the currently playing video
        } else {
            video.pause();
            setPlayingIndex(null);   // No video is playing
        }
    };

    return (
        <Box sx={{
            width: '100%',
            // height: '450px',
            backgroundImage: `url(${highlightBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            borderRadius: '8px',
            boxShadow: '0px -1px 0px 0px #F0F0F0 inset',
            marginBottom: '10px'
        }}>
            <Box sx={{ padding: '20px' }}>
                <Typography
                    variant='h5'
                    sx={{
                        color: 'var(--Primary-Color-2-500, #0C5BA0)',
                        fontFamily: 'Inter',
                        fontSize: '25px',
                        fontWeight: 700,
                        lineHeight: mobileView ? '25px' : '20px',
                        marginBottom: '20px',
                    }}
                >
                    Discover Your Next Favorite Clip
                </Typography>
                <Typography
                    variant='h6'
                    sx={{
                        color: 'var(--Primary-Color-1-600, #62666A)',
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontWeight: 400,
                        lineHeight: mobileView ? '25px' : '20px',
                        marginBottom: '25px'
                    }}
                >
                    Enjoy these clips made for you to relax and have a great time!
                </Typography>
                <Box
                    sx={{
                        width: '100%',
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                >
                    {reels.map((item, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: '224px',
                                height: '325px',
                                background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.32) 0%, rgba(0, 0, 0, 0.32) 100%)',
                                borderRadius: '4px',
                                position: 'relative',
                                display: 'inline-block',
                                marginRight: '10px',
                            }}
                            // onClick={() => handlePlayPause(index)}
                        >
                            <video
                                id={`video-${index}`}
                                src={item.HighlightURL || null}
                                controls={false}
                                style={{ width: '100%', height: '100%', borderRadius: '4px', objectFit: 'cover' }}
                                onPause={() => setPlayingIndex(null)} 
                                onPlay={() => setPlayingIndex(index)}
                            />
                            {playingIndex !== index && (
                                <IconButton
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                        color: '#FFF',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                        },
                                    }}
                                    onClick={() => handlePlayPause(index)}
                                >
                                    <PlayArrowIcon sx={{ fontSize: '30px' }} />
                                </IconButton>
                            )}
                            {playingIndex === index && (
                                <IconButton
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: 'rgba(0, 0, 0, 0.6)',
                                        color: '#FFF',
                                        '&:hover': {
                                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                                        },
                                    }}
                                    onClick={() => handlePlayPause(index)}
                                >
                                    <PauseIcon sx={{ fontSize: '30px' }} />
                                </IconButton>
                            )}
                            {/* <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <IconButton
                                    sx={{
                                        backgroundColor: 'transparent',
                                        border: 'none',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        const video = document.getElementById(`video-${index}`);
                                        if (video.paused) {
                                            video.play();
                                        } else {
                                            video.pause();
                                        }
                                    }}
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    >
                                        <polygon points="5,3 19,12 5,21 5,3" />
                                    </svg>
                                </IconButton>
                            </Box> */}
                            <Box sx={{
                                position: 'absolute',
                                bottom: '10px',
                            }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', paddingLeft: '10px' }}>
                                    <Avatar src={item.profilePhoto || null} alt='' />
                                    <Typography
                                        variant='h6'
                                        sx={{
                                            color: '#FFF',
                                            fontFamily: 'Inter',
                                            fontSize: '14px',
                                            fontWeight: 700,
                                            lineHeight: '18px',
                                            whiteSpace: 'normal',
                                            overflowWrap: 'break-word',
                                            maxWidth: '150px',
                                        }}
                                    >
                                        {item.highlightTitle || null}
                                    </Typography>
                                </Box>
                                <Typography
                                    variant='h6'
                                    sx={{
                                        color: '#EEEBEB',
                                        fontFamily: 'Inter',
                                        fontSize: '10px',
                                        fontWeight: 400,
                                        lineHeight: '20px',
                                        textAlign: 'center',
                                        marginLeft: '25px'
                                    }}
                                >
                                    {item.views || 0} views&nbsp;&nbsp;&nbsp;&nbsp;{item.highlightRunCount || 0} Runs
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box >
    )
}

export default PostHilights;