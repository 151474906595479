import React, { useEffect, useState } from 'react'
import moment from "moment";

import chLogo from "../../assets/images/chatWithChamhunt/logo.svg"


export default function Chat(props) {
    const { chatdata, type, number } = props;

    const formattedTime = moment(chatdata.createdDate).format('LT');
    const currentDate = moment(chatdata.createdDate).format('ll')
    return (
        <>
            {
                chatdata.sendertype !== type ?

                    <div className='leftchatContainer'>
                        <div className='chatparentcontainer'>
                            <img src={chLogo} className='chatimagecontainer' alt='Champhunt' />

                            <div className='chattextcontainer'>
                                {chatdata.mediatype !== "audio" && chatdata.mediatype !== "image" && chatdata.mediatype !== "video" && chatdata.message && (
                                    <p className='chatData'>{chatdata.message}</p>
                                )}
                                {chatdata.mediatype === "image" && chatdata.message && (
                                    <img src={chatdata.message} alt="Chat Image" className="chatImgLeft" />
                                )}
                                {chatdata.mediatype === "audio" && chatdata.message && (
                                    <audio controls controlsList="nodownload" key={number}>
                                        <source src={chatdata.message} type="audio/wav" key={number} />
                                        Your browser does not support the audio element.
                                    </audio>
                                )}
                                {chatdata.mediatype === "video" && chatdata.message && (
                                    <video controls controlsList="nodownload" key={number} className="chatImgLeft">
                                        <source src={chatdata.message} type="video/mp4" />
                                        Your browser does not support the video element.
                                    </video>
                                )}
                                <p style={{ color: '#AFB8CF', fontSize: '11px' }}>{formattedTime} {currentDate}</p>
                            </div>
                        </div>
                    </div >
                    :
                    <div className='rightchatContainer' style={{ flexDirection: 'row-reverse' }}>
                        <div className='chatparentcontainerright'>
                            <div className='chattextcontainerright'>
                                {chatdata.mediatype !== "audio" && chatdata.mediatype !== "image" && chatdata.mediatype !== "video" && chatdata.message && (
                                    <p className='chatData'>{chatdata.message}</p>
                                )}
                                {chatdata.mediatype === "image" && chatdata.message && (
                                    <img src={chatdata.message} alt="Chat Image" className="chatImage" />
                                )}
                                {chatdata.mediatype === "audio" && chatdata.message && (
                                    <audio controls controlsList="nodownload" key={number}>
                                        <source src={chatdata.message} type="audio/wav" key={number} />
                                        Your browser does not support the audio element.
                                    </audio>
                                )}
                                {chatdata.mediatype === "video" && chatdata.message && (
                                    <video controls controlsList="nodownload" key={number} className="chatImage">
                                        <source src={chatdata.message} type="video/mp4" />
                                        Your browser does not support the video element.
                                    </video>
                                )}
                                <p style={{ color: '#AFB8CF', fontSize: '11px', textAlign: 'end' }}>{formattedTime} {currentDate}</p>
                            </div>
                            {number === 0 && (
                                (chatdata.sendertype === 'user' && chatdata.adminseen) ||
                                (chatdata.sendertype !== 'user' && chatdata.userseen)
                            ) && <p style={{
                                margin: 'auto',
                                fontSize: '10px',
                                fontWeight: '600'
                            }}>Seen</p>}
                        </div>
                    </div>

            }
        </>
    )
}



