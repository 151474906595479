import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import lockedealimg from "../../assets/images/deals/deallockedimg.png";
import { useNavigate } from "react-router-dom";
import {useMediaQuery} from "@mui/material";
import "./UnlockModal.scss";

export default function UnlockModal(props) {
  const mobileView = useMediaQuery("(max-width:920px)");
  const navigate = useNavigate();
  const handleBackPitch = () => {
    navigate("/pitch");
  }


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: mobileView ? "95%" : "500",
    bgcolor: "background.paper",
    border: "1px solid #CBCBCB",
    borderRadius: "6px",
    boxShadow: 24,
    p: 4,
  };
  
  return (
    <div className="deal_unlock">
      <Modal
        open={props?.open}
        onClose={props?.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ textAlign: "center" }}>
            <img src={lockedealimg} alt="locked-deal-img" />
          </div>
          <Typography
            style={{
              textAlign: "center",
              color: "#313335",
              fontWeight: "700",
              fontSize: "25px",
            }}
            id="modal-modal-title"
            variant="h6"
          >
            Deal Locked!
          </Typography>
          <Typography
            style={{ textAlign: "center", color: "#84878a", fontWeight: "600" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            To unlock this deal, create
            {/* {props?.count.post > 0 && ` ${props?.count.post}  post, style={{color: "#0C5BA0"}}`} */}
            {props?.count.post > 0 && (
              <span
                style={{
                  color: "#0C5BA0",
                  fontWeight: "600",
                  paddingLeft: "3px",
                }}
              >{`${props.count.post} post,`}</span>
            )}
            {props?.count.highlight > 0 &&
              ` ${props?.count.highlight} highlight/s`}
            {props?.count.comment > 0 && (
              <span style={{ color: "#9b2daa", paddingLeft: "3px",  fontWeight: "600"}}>
                {props?.count.comment} comment
                {props?.count.comment > 1 ? "s" : ""}
              </span>
            )}
            &nbsp; to unlock the deal
            {/* {props?.count.comment > 0 && ` ${props?.count.comment} comment/s` style={{color: "red"}}}
            &nbsp; to unlock the deal */}
          </Typography>

          <div className="pitch_back__btn">
            <button
              // onClick={props?.handleClose}
              onClick={handleBackPitch}
            >
              Go to pitch
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
