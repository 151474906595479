import React from "react";
import { Dialog, DialogContent, DialogActions, Typography, IconButton, Button, Box, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import dealComingSoon from "../../assets/images/deals/dealComingSoon.json";
import dealLocked from "../../assets/images/deals/dealLocked.json";
import dealExpired from "../../assets/images/deals/Sad.json";
import Lottie from "react-lottie";

const DealNotification = ({ open, onClose, data }) => {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: (data.count === 0 || data.count < 0) ? dealExpired
      : data.comingSoon ? dealComingSoon
        : data.isLocked && !data.unlockedByUser ? dealLocked
          : null,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <Box>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          style: { borderRadius: 12, padding: "20px", textAlign: "center" },
        }}
      >
        <Box position="absolute" top="10px" right="10px">
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Lottie
            options={defaultOptions}
            style={{ width: "40%" }}
          />
          <Typography
            sx={{
              color: "rgba(49, 51, 53, 1)",
              fontSize: "32px",
              fontFamily: "Helvetica",
              fontWeight: "700",
              paddingBottom: "12px"
            }}
          >
            {(data.count === 0 || data.count < 0) ? "DEAL EXPIRED!"
              : data.comingSoon ? "COMING SOON"
                : data.isLocked && !data.unlockedByUser ? "DEAL LOCKED!"
                  : null}
          </Typography>

          {(data.count === 0 || data.count < 0) ?
            (
              <Typography
                sx={{
                  color: "rgba(243, 113, 33, 1)",
                  fontSize: "20px",
                  fontFamily: "Helvetica",
                  fontWeight: "600",
                }}
              >
                Oops! You missed a great offer.{" "}
                <span role="img" aria-label="sad face">
                  😢
                </span>{" "}
                But more amazing deals are on the way!
              </Typography>
            ) : data.comingSoon ? (
              <Typography
                sx={{
                  color: "rgba(243, 113, 33, 1)",
                  fontSize: "20px",
                  fontFamily: "Helvetica",
                  fontWeight: "600",
                }}
              >
                Something amazing is coming your way. Stay tuned and stay updated! 💥
              </Typography>
            ) :

              data.isLocked && !data.unlockedByUser ? (
                <Typography
                  sx={{
                    color: "rgba(98, 102, 106, 1)",
                    fontSize: "20px",
                    fontFamily: "Helvetica",
                    fontWeight: "600",
                  }}
                >
                  To unlock this deal, create {data.conditionForUnlock.post || 0} post, {data.conditionForUnlock.comment || 0} comments and {data.conditionForUnlock.highlight || 0} highlights.
                </Typography>
              ) : null
          }

        </DialogContent>
        <DialogActions sx={{ padding: "0px !important" }}>
          <Button
            variant="contained"
            onClick={onClose}
            style={{
              textTransform: "none",
              margin: "0 auto",
              backgroundColor: "rgba(12, 91, 160, 1)",
              color: "rgba(255, 255, 255, 1)",
              fontSize: "16px",
              fontFamily: "Helvetica",
              fontWeight: "600",
              width: "70%",
              boxShadow: "none"
            }}
          >
            Go to Deal page
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DealNotification;