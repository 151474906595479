import React, { useState, useEffect } from "react";
import FantasyPlayNowPage from "./FantasyPlayNowPage";
import { useMediaQuery } from "@material-ui/core";
import CskLogo from "../../assets/images/fantasyy/Chennai-Super-Kings-logo- 1.png";
import RrLogo from "../../assets/images/fantasyy/rrlogo.png";
import PbksLogo from "../../assets/images/fantasyy/pbkslogo.png";
import RcbLogo from "../../assets/images/fantasyy/rcb.png";
import LsgLogo from "../../assets/images/fantasyy/loknowlogo.png";
import MiLogo from "../../assets/images/fantasyy/milogo.png";
import GtLogo from "../../assets/images/fantasyy/gujarat_cricket_62e74247-b6a8-4079-959b-1d5d6620552e-removebg-preview 1.png";
import HyLogo from "../../assets/images/fantasyy/hyderabad-ipl-team-logo-removebg-preview 1.png";
import KkrLogo from "../../assets/images/fantasyy/kolkata_cricket_ced9e4c3-b5ce-4610-aed4-f2c297da6c21-removebg-preview 1.png";
import DcLogo from "../../assets/images/fantasyy/dclogo.png";


import AFGAvatar from "../../assets/images/fantasyy/WCavatar/AFG.png"
import AUSAvatar from "../../assets/images/fantasyy/WCavatar/AUS.png"
import BANAvatar from "../../assets/images/fantasyy/WCavatar/BAN.png"
import CANAvatar from "../../assets/images/fantasyy/WCavatar/CAN.png"
import ENGAvatar from "../../assets/images/fantasyy/WCavatar/ENG.png"
import INDAvatar from "../../assets/images/fantasyy/WCavatar/IND.png"
import IREAvatar from "../../assets/images/fantasyy/WCavatar/IRE.png"
import NEPAvatar from "../../assets/images/fantasyy/WCavatar/NEP.png"
import NZAvatar from "../../assets/images/fantasyy/WCavatar/NZ.png"
import PAKAvatar from "../../assets/images/fantasyy/WCavatar/PAK.png"
import SAAvatar from "../../assets/images/fantasyy/WCavatar/SA.png"
import SCOAvatar from "../../assets/images/fantasyy/WCavatar/SCO.png";
import SLAvata from "../../assets/images/fantasyy/WCavatar/SL.png";
import NAMAvatar from "../../assets/images/fantasyy/WCavatar/NAM.png";
import WIAvatar from "../../assets/images/fantasyy/WCavatar/WI.png";
import UGAAvatar from "../../assets/images/fantasyy/WCavatar/UGA.png";
import PNGAvatar from "../../assets/images/fantasyy/WCavatar/PNG.png";
import NETAvatar from "../../assets/images/fantasyy/WCavatar/NET.png";
import USAAvatar from "../../assets/images/fantasyy/WCavatar/USA.png";
import OMAAvatar from "../../assets/images/fantasyy/WCavatar/OMA.png";
import ZIMAvatar from "../../assets/images/fantasyy/WCavatar/ZIM.png";


import BattleLogo from "../../assets/images/fantasyy/battleipl.png";
import DcAvatar from "../../assets/images/fantasyy/dcavatar.png";
import SrhAvatar from "../../assets/images/fantasyy/srhavatar.png";
import LsgAvatar from "../../assets/images/fantasyy/lsgavatr.png";
import KkrAvatar from "../../assets/images/fantasyy/kkravatr.png";
import GtAvatar from "../../assets/images/fantasyy/gtavatar (2).png";
import RrAvatar from "../../assets/images/fantasyy/rravatr.png";
import PbkAvatar from "../../assets/images/fantasyy/pbk.png";
import RcbAvatar from "../../assets/images/fantasyy/rcbavatar.png";
import CskAvatar from "../../assets/images/fantasyy/cropcsk.png";
import MiAvatar from "../../assets/images/fantasyy/mumbaiimg.png";


import INDLogo from "../../assets/images/fantasyy/IND.png";
import PAKLogo from "../../assets/images/fantasyy/PAK.png";
import AFGLogo from "../../assets/images/fantasyy/AFG.png";
import AUSLogo from "../../assets/images/fantasyy/AUS.png";
import BANLogo from "../../assets/images/fantasyy/BAN.png";
import CANLogo from "../../assets/images/fantasyy/CAN.png";
import ENGLogo from "../../assets/images/fantasyy/ENG.png";
import IRELogo from "../../assets/images/fantasyy/IRE.png";
import NEPLogo from "../../assets/images/fantasyy/NEP.png";
import NetherlandsLogo from "../../assets/images/fantasyy/Netherlands.png";
import NewZealandLogo from "../../assets/images/fantasyy/New_Zealand.png";
import OmanLogo from "../../assets/images/fantasyy/Oman.png";
import PapuaLogo from "../../assets/images/fantasyy/Papua_New_Guinea.png";
import ScotlandLogo from "../../assets/images/fantasyy/Scotland.png";
import SouthAfricaLogo from "../../assets/images/fantasyy/South_Africa.jpg";
import SriLankaLogo from "../../assets/images/fantasyy/Sri_Lanka.png";
import UgandaLogo from "../../assets/images/fantasyy/Uganda.png";
import USAlogo from "../../assets/images/fantasyy/USA.png";
import WIlogo from "../../assets/images/fantasyy/WI.jpg";
import NAMLogo from "../../assets/images/fantasyy/NAM.png";
import ZIMLogo from "../../assets/images/fantasyy/ZIM.png";


import "./_index.scss";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import { BallTriangle } from 'react-loader-spinner';
import NoMatchesPage from "./NoMatchesPage";

import Leaderboard from "./leaderboard";
import { Button } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import pIcon from "../../assets/images/fantasyy/allPIcon.png";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const accessToken = getStorageItem("token");
  const [liveMatches, setLiveMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tId, setTId] = useState("");
  const [tournaments, setAllTournaments] = useState([]);
  const [tournamentId, setTournamentId] = useState("");
  const mobileView = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();


  const getTeamAvatars = (teamAvatarUrl) => {
    return teamAvatarUrl || null;
  };

  const getTeamLogo = (teamLogoUrl) => {
    return teamLogoUrl || null;
  };


  const teamNames = ["IND", "PAK", "IRE", "CAN", "USA", "ENG", "AUS", "NAM", "SCO", "OMA", "NZ", "WI", "AFG", "UGA", "PNG", "SA", "SL", "BAN", "NET", "NEP", "ZIM"];

  const teamGradients = {
    IND: "linear-gradient(180deg, #1C1C1C 0%, #87CEEB 100%)",
    PAK: "linear-gradient(180deg, #006400 0%, #228B22 100%)",
    AFG: "linear-gradient(180deg, #008080 0%, #4682B4 100%)",
    AUS: "linear-gradient(180deg, #FFD700 0%, #FF8C00 100%)",
    BAN: "linear-gradient(180deg, #006A4E 0%, #A52A2A 100%)",
    CAN: "linear-gradient(180deg, #FF0000 0%, #FFFFFF 100%)",
    ENG: "linear-gradient(180deg, #002147 0%, #C8102E 100%)",
    IRE: "linear-gradient(180deg, #169B62 0%, #FF883E 100%)",
    NEP: "linear-gradient(180deg, #003366 0%, #DC143C 100%)",
    NET: "linear-gradient(180deg, #21468B 0%, #FF4500 100%)",
    NZ: "linear-gradient(180deg, #000000 0%, #FFFFFF 100%)",
    OMA: "linear-gradient(180deg, #008000 0%, #FF0000 100%)",
    PNG: "linear-gradient(180deg, #FFD700 0%, #FF0000 100%)",
    SCO: "linear-gradient(180deg, #2E0854 0%, #800080 100%)",
    SA: "linear-gradient(180deg, #006341 0%, #FFD700 100%)",
    SL: "linear-gradient(180deg, #0033A0 0%, #FFD700 100%)",
    UGA: "linear-gradient(180deg, #FFCD00 0%, #D21034 100%)",
    USA: "linear-gradient(180deg, #B22234 0%, #3C3B6E 100%)",
    WI: "linear-gradient(180deg, #771900 0%, #FFD700 100%)",
    NAM: "linear-gradient(180deg, #002E63 0%, #A3C1AD 100%)",
    ZIM: "linear-gradient(180deg, #002E63 0%, #A3C1AD 100%)",
  };

  const getTeamGradient = (teamName) => {
    const formattedTeamName = teamName && teamName.trim();
    return (
      teamGradients[formattedTeamName] ||
      "linear-gradient(to right, #FFFFFF, #FFFFFF)"
    );
  };

  useEffect(() => {
    const fetchLiveMatches = async () => {
      try {
        const accessToken = getStorageItem("token");
        const response = await axios.get(
          global.config.ROOTURL.prod + "/contest/fantasy/getAllLiveMatches",
          {
            headers: {
              Authorization: "Bearer " + accessToken,
            },
          }
        );
        setTId(response?.data[0]?.tournamentId);
        setLiveMatches(
          response.data.map((match) => {
            const formattedMatch = {
              ...match,
              team1: match.team1.trim(),
              team2: match.team2.trim(),
            };
            return formattedMatch;
          })
        );
      } catch (error) {
        console.error("Error fetching live matches:", error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchLiveMatches();
  }, []);

  const getAllTournaments = async () => {
    if (!accessToken) return
    const options = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/contest/fantasy/getAllTournaments`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    await axios(options)
      .then(({ data }) => {
        setAllTournaments(data);
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    getAllTournaments();
  }, []);

  return (
    <>
      <div className="fantasy-main">
        <div style={{ width: "100%" }}> <Leaderboard tId={tournamentId} /></div>
        <h3 className="join-now-heading">Join the Action – <strong className="play-now-heading">Play Now!</strong></h3>
        <div className="zig-main-container">
          {loading ? (
            <div style={{ marginTop: "4rem" }}>
              <BallTriangle
                height="100"
                width="100"
                color="grey"
                ariaLabel="loading"
              />
            </div>
          ) : liveMatches.length === 0 ? (
            <NoMatchesPage /> // Show NoMatchesPage if no matches are available
          ) : (
            liveMatches.map((match, index) => (
              <div key={index} className="zigg">
                <div
                  className="zigzag-card1"
                  style={{ background: getTeamGradient(match.team1) }}
                >
                  <div className="player1-container">
                    <div className="csk-image-container">
                      <img
                        src={getTeamAvatars(match.team1Avatar)}
                        alt={`${match.team1} Avatar`}
                        className="csk-imgess"
                        style={{
                          width: window.innerWidth > 768 ? "27%" : "35%",
                          height: "100%",
                          objectFit: "cover",
                          marginRight: window.innerWidth > 768 ? "9rem" : "4rem",
                        }}
                      />
                    </div>
                    <div
                      className="csk-logo-container"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        borderRadius: "50%",
                        backgroundColor: "white",
                        width: window.innerWidth > 768 ? "100px" : "40px",
                        height: window.innerWidth > 768 ? "100px" : "40px",
                        left: window.innerWidth > 768 ? "15rem" : "2rem",
                        bottom: window.innerWidth > 768 ? "1rem" : "1rem",
                      }}
                    >
                      <img
                        src={getTeamLogo(match.team1Logo)}
                        alt={`${match.team1} Image`}
                        className="team1-logo-size"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                </div>


                <div
                  className="zigzag-card2"
                  style={{ background: getTeamGradient(match.team2) }}
                >
                  <div className="player2-container">
                    <div className="mi-image-container">
                      <img
                        src={getTeamAvatars(match.team2Avatar)}
                        alt={`${match.team2} Avatar`}
                        className="mi-imagess"
                        style={{
                          width: window.innerWidth > 768 ? "27%" : "35%",
                          height: "100%",
                          objectFit: "cover",
                          marginLeft: window.innerWidth > 768 ? "15rem" : "4rem"
                        }}
                      />
                      {/* <div className="team2-shadow-color"></div> */}
                    </div>
                    <div
                      className="mi-logo-container"
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        borderRadius: "50%",
                        backgroundColor: "white",
                        width: window.innerWidth > 768 ? "100px" : "40px",
                        height: window.innerWidth > 768 ? "100px" : "40px",
                        left: window.innerWidth > 768 ? "27rem" : "6rem",
                        bottom: window.innerWidth > 768 ? "1rem" : "1rem",
                      }}
                    >
                      <img
                        src={getTeamLogo(match.team2Logo)}
                        alt={`${match.team2} Image`}
                        className="team2-logo-size"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="battle-logo-container">
                  <img
                    src={BattleLogo}
                    alt="Battle Logo"
                    className="Battle-logo"
                  />
                </div>

                <div className="Playnow-container">
                  <FantasyPlayNowPage match={match} />
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};
export default Index;
