import React, { useState } from 'react';
import SearchBar from './searchBar';
import "./index.scss";

import { Box, Typography, Avatar } from '@mui/material';
import { useMediaQuery } from "@mui/material";
import AppBar from '@mui/material/AppBar';

import chLogo from "../../assets/images/Pitch/Header/Logo.svg";
import Bell1 from "../../assets/images/Pitch/Header/notification1.svg";
import BallIcon from "../../assets/images/Pitch/Header/ball.svg";

import LoginPopup from "../guest-pitch/LoginPopup";

const Header = () => {
    const mobileView = useMediaQuery("(max-width:768px)");
    const [open, setOpen] = useState(false);
    const handleClose = (data) => setOpen(data);
    const handleOpen = () => setOpen(true);

    return (
        <Box sx={{ flexGrow: 1 }} onClick={handleOpen}>
            <AppBar
                position="fixed"
                sx={{
                    width: '100%',
                    height: '70px',
                    backgroundColor: '#07365F !important',
                    zIndex: 100,
                    padding: mobileView ? "0px 5px" : "0px 30px",
                    top: '0px'
                }}
            >
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            cursor: 'pointer'
                        }}>
                        <img src={chLogo} alt='' />
                        {!mobileView && (
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: '25px !important', color: '#FFFFFF' }}>
                                CHAMPHUNT
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ width: mobileView ? '100%' : '22%', margin: mobileView ? '0px 5px' : null }}>
                        <SearchBar />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '60px' }}>
                        {!mobileView && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    background: "linear-gradient(176.47deg, #F82D00 6.96%, #FED40C 98.01%, #FFE70E 113.25%)",
                                    border: "0.8px solid #FFFFFF",
                                    borderRadius: '8px',
                                    padding: '4px 12px',
                                }}>
                                <img src={BallIcon} alt='' style={{ height: '25px', width: '25px' }} />
                                <Typography variant="h6" sx={{ fontSize: 16, fontWeight: 500, color: '#FFFFFF' }}>
                                    0
                                </Typography>
                            </Box>
                        )}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                            <img src={Bell1} alt='' />
                            {!mobileView && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                    }}>
                                    <Avatar alt=''
                                        sx={{ width: 48, height: 48, cursor: 'pointer', marginRight: '-20px' }}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </AppBar>
            {open && <LoginPopup open={open} handleClose={handleClose} />}
        </Box>
    );
}

export default Header;