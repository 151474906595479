import React, { useContext, useState, useEffect } from "react";
import { Box, Typography, } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import bg1 from "../../../assets/images/Pitch/Feed/liveMatchBg1.svg";
import chLogo from "../../../assets/images/Pitch/Header/Logo.svg";
import { SocketContext } from "../../../context/SocketContext";

const LiveMatch = (props) => {
  const [allMatch, setAllMatch] = useState(props.allMatch);

  const [socket, setSocket] = useContext(SocketContext);
  const [socketConnected, setSocketConnected] = useState(false);

  const mobileView = useMediaQuery("(max-width:768px)");

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on("connect", () => setSocketConnected(true));
    socket.on("liveScore", (data) => {
      setAllMatch((prevMatch) => {
        const updatedMatches = prevMatch.map((match) => {
          if (match._id === data._id) {
            return {
              ...match,
              team1: data.team1,
              team2: data.team2,
              ended: data.ended,
              information: data.information,
            };
          }
          return match;
        });

        return updatedMatches;
      });
    });
    return () => {
      socket?.off("connect");
      socket?.off("liveScore");
    };
  }, [socket]);

  return (
    <>
      {allMatch?.length === 1 ? (
        <Box
          sx={{
            width: "100%",
            height: mobileView ? "150px" : "186px",
            position: "relative",
            background: "#F6F9FE",
            border: "1px solid #F1F1F1",
            borderRadius: "8px",
            margin: "10px 0px",
            zIndex: 1,
          }}
        >
          <img
            src={bg1}
            alt=""
            style={{
              width: "100%",
              position: "absolute",
              bottom: "0px",
              zIndex: 0,
            }}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              padding: "20px 30px 10px 30px",
              zIndex: 2,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "#0C5BA0",
                fontWeight: 700,
                fontSize: 17,
                textAlign: "center",
                fontFamily: "Helvetica",
              }}
            >
              {allMatch[0].tournament || ""}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={allMatch[0].team1.image || chLogo}
                    alt=""
                    style={{ width: mobileView ? "35px" : "70px", height: mobileView ? "35px" : "70px" }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#333333",
                      fontWeight: 400,
                      fontSize: 16,
                      fontFamily: "Helvetica",
                    }}
                  >
                    {allMatch[0].team1.name || ""}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#5E5F60",
                      fontWeight: 700,
                      fontSize: 18,
                      fontFamily: "Helvetica",
                    }}
                  >
                    {allMatch[0].team1.runs || 0}/
                    {allMatch[0].team1.wickets || 0} (
                    {allMatch[0].team1.overs || 0})
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#4D4D4D",
                      fontWeight: 400,
                      fontSize: 14,
                      textAlign: "center",
                      fontFamily: "Helvetica",
                    }}
                  >
                    Overs {allMatch[0].team1.overs || 0}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Box>
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#5E5F60",
                      fontWeight: 700,
                      fontSize: 18,
                      fontFamily: "Helvetica",
                    }}
                  >
                    {allMatch[0].team2.runs || 0}/
                    {allMatch[0].team2.wickets || 0} (
                    {allMatch[0].team2.overs || 0})
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#4D4D4D",
                      fontWeight: 400,
                      fontSize: 14,
                      textAlign: "center",
                      fontFamily: "Helvetica",
                    }}
                  >
                    Overs {allMatch[0].team2.overs || 0}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={allMatch[0].team2.image || chLogo}
                    alt=""
                    style={{ width: mobileView ? "35px" : "70px", height: mobileView ? "35px" : "70px" }}
                  />
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#333333",
                      fontWeight: 400,
                      fontSize: 16,
                      fontFamily: "Helvetica",
                    }}
                  >
                    {allMatch[0].team2.name || ""}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Typography
              variant="h4"
              sx={{
                color: "#C8042C",
                fontWeight: 700,
                fontSize: 15,
                textAlign: "center",
                fontFamily: "Helvetica",
              }}
            >
              {allMatch[0].information || ""}
            </Typography>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "186px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            position: "relative",
            background: "#FFFFFF",
            border: "1px solid #F1F1F1",
            borderRadius: "8px",
            margin: "10px 0px",
            padding: "10px",
            zIndex: 1,
            overflowX: "scroll",
            scrollBehavior: "smooth",
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {allMatch.map((match, index) => (
            <Box
              key={index}
              sx={{
                minWidth: "300px",
                flex: 1,
                flexShrink: 0,
                height: "165px",
                background: "#F6F9FE",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <img
                src={bg1}
                alt=""
                style={{
                  width: "100%",
                  position: "absolute",
                  bottom: "0px",
                  zIndex: 0,
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  padding: "15px 10px 10px 10px",
                  zIndex: 2,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    color: "#0C5BA0",
                    fontWeight: 700,
                    fontSize: "16px",
                    textAlign: "center",
                    fontFamily: "Helvetica",
                  }}
                >
                  {match.tournament || ""}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={allMatch[0].team1.image || chLogo}
                        alt=""
                        style={{ width: "50px", height: "50px" }}
                      />
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#333333",
                          fontWeight: 400,
                          fontSize: "12px",
                          fontFamily: "Helvetica",
                          marginTop: "10px",
                        }}
                      >
                        {match.team1.name || ""}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#5E5F60",
                          fontWeight: 700,
                          fontSize: "12px",
                          fontFamily: "Helvetica",
                        }}
                      >
                        {match.team1.runs || 0}/{match.team1.wickets || 0} (
                        {match.team1.overs || 0})
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: 400,
                          fontSize: "10px",
                          textAlign: "center",
                          fontFamily: "Helvetica",
                        }}
                      >
                        Overs {match.team1.overs || 0}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <Box>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#5E5F60",
                          fontWeight: 700,
                          fontSize: 12,
                          fontFamily: "Helvetica",
                        }}
                      >
                        {match.team2.runs || 0}/{match.team2.wickets || 0} (
                        {match.team2.overs || 0})
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#4D4D4D",
                          fontWeight: 400,
                          fontSize: 10,
                          textAlign: "center",
                          fontFamily: "Helvetica",
                        }}
                      >
                        Overs {match.team2.overs || 0}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={allMatch[0].team2.image || chLogo}
                        alt=""
                        style={{ width: "50px", height: "50px" }}
                      />
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#333333",
                          fontWeight: 400,
                          fontSize: 12,
                          fontFamily: "Helvetica",
                          marginTop: "10px",
                        }}
                      >
                        {match.team2.name || ""}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#C8042C",
                    fontWeight: 700,
                    fontSize: 12,
                    textAlign: "center",
                    fontFamily: "Helvetica",
                    marginTop: "20px",
                  }}
                >
                  {match.information || ""}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default LiveMatch;
