import { Box, Typography } from "@mui/material";
import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../../utils/auth";
import Vouchers from "../vouchers/index";
import NoVoucher from "../../assets/images/deals/Frame 1686552851.png"
import { useMediaQuery } from "@mui/material";

import { getStorageItem } from "../../utils/sessionStorage";
const accessToken = getStorageItem("token");
export default function Deal() {
  const [voucherData, setVoucherData] = useState([]);
  const accessToken = getAuthToken();

  const mobileView = useMediaQuery("(max-width:769px)");

  const getAllVouchers = async () => {
    const getVouchers = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/voucher/getRandomUnredeemedVoucher`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    await axios(getVouchers)
      .then((response) => {
        setVoucherData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllVouchers();
  }, []);

  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "20px",
      }}
    >
      {/* <h2 style={{ textAlign: "center", color: "#00008B", marginTop: "5px" }}>
        Vouchers
      </h2> */}
      {voucherData.length > 0 && (
      <div
        style={{
          display: "flex",
          justifyContent: mobileView ? "center" : "start",
          flexWrap: "wrap",
          gap: "20px",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: `rgba(0, 0, 0, 0.1) 0px 1px 1px, 
                rgba(0, 0, 0, 0.1) 0px -1px 1px, 
                rgba(0, 0, 0, 0.1) 1px 0px 1px, 
                rgba(0, 0, 0, 0.1) -1px 0px 1px`,
          cursor: "pointer",
        }}
      >
        {
          voucherData.map((voucher) => (
            <Box
              id="voucherCard__Id"
              className="voucherCard__dp"
              key={voucher.voucherId}
              sx={{
                position: "relative",
                backgroundColor: "white",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                width: "100%",
                maxWidth: "250px",
                padding: "0",
                textAlign: "center",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
                },
              }}
              onClick={() =>
                navigate(`/voucher-description/${voucher.voucherId}`, {
                  state: { code: voucher.code },
                })
              }
            >
              <Box
                sx={{
                  height: "auto",
                  width: "100%",
                }}
              >
                <img
                  src={voucher.voucherImage}
                  alt="Vouchers-img"
                  style={{
                    height: "130px",
                    width: "100%",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                />
              </Box>
              <Box sx={{ height: "auto", padding: "20px 10px" }}>
                <Typography variant="h6" style={{ textAlign: "left" }}>
                   {voucher.discount}% Off
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontSize: "16px", textAlign: "left" }}
                >
                  {voucher.description}
                </Typography>
              </Box>

              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "14%",
                  transform: "translate(-50%, -50%)",
                  borderRadius: "50%",
                }}
              >
                <img
                  src={voucher.logoImage}
                  alt="voucher-logo"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                  }}
                />
              </Box>
            </Box>
          ))
        
        // : (
        //   <img
        //     src={NoVoucher}
        //     alt="No vouchers available"
        //     style={{
        //       width: "auto",
        //       height: "250px",
        //       margin: "auto",
        //       maxWidth: "60vw",
        //       objectFit: "contain",
        //     }}
        //   />
        // )
        }
      </div>
      )}
    </div>
  )
}
