import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";

const AuctionAceAdmin = () => {
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [originalFund, setOriginalFund] = useState("");
    const [fundRemaining, setFundRemaining] = useState("");
    const [image, setImage] = useState("");
    const accessToken = getStorageItem("token");

    const handleImageUpload = async (file) => {
        const formData = new FormData();
        formData.append("uploader", file);
        const uploadUrl = `${global.config.ROOTURL.prod}/upload-file`;
        try {
            const response = await axios.post(uploadUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            console.log("response:", response.data[0].location);
            return response.data[0].location;
        } catch (error) {
            console.error("Error uploading image:", error);
            throw new Error("Image upload failed. Please try again.");
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const teamInfo = {
            name,
            originalFund,
            fundRemaining,
        };

        try {
            if (image) teamInfo.image = await handleImageUpload(image);

            const response = await axios.post(
                `${global.config.ROOTURL.prod}/auction/team`,
                teamInfo,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.status !== 201) throw new Error("Failed to create team info");

            toast.success("Team info created successfully");

            setName("");
            setOriginalFund("");
            setFundRemaining("");
            setImage("");
        } catch (error) {
            console.error("Error creating team info:", error);
            toast.error(error.message || "Error creating team info. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="ad-creation-wrapper">
            <h1 className="ad-creation__title">Create Player Info</h1>
            <form className="ad-creation__form" onSubmit={handleFormSubmit}>
                <div>
                    <label htmlFor="title" className="ad-creation__form-label">
                        Team Name
                    </label>
                    <input
                        id="name"
                        type="text"
                        className="ad-creation__form-input"
                        placeholder="Team-name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="discount" className="ad-creation__form-label">
                        originalFund
                    </label>
                    <input
                        id="originalFund"
                        type="number"
                        className="ad-creation__form-input"
                        placeholder="Original Fund"
                        value={originalFund}
                        onChange={(e) => setOriginalFund(Number(e.target.value))}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="discount" className="ad-creation__form-label">
                        Fund Remaining
                    </label>
                    <input
                        id="discount"
                        type="number"
                        className="ad-creation__form-input"
                        placeholder="fund remaining"
                        value={fundRemaining}
                        onChange={(e) => setFundRemaining(Number(e.target.value))}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="logoImage" className="ad-creation__form-label">
                        Image
                    </label>
                    <input
                        id="logoImage"
                        type="file"
                        className="ad-creation__form-input"
                        onChange={(e) => setImage(e.target.files[0])}
                        accept="image/*"
                    />
                </div>

                <div>
                    <button
                        type="submit"
                        className={
                            loading
                                ? "ad-creation-form-submit--disabled"
                                : "ad-creation-form-submit--active"
                        }
                        disabled={loading}
                    >
                        {loading ? "Creating..." : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AuctionAceAdmin;
