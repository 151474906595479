import { React, useState, useEffect, useRef } from "react";
import { makeStyles, styled } from "@mui/styles";
import { InputAdornment, Button, Box } from "@mui/material";
import { CTextField } from "../../../commons/components/mui-c-components";
import validator from "validator";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LogoWithTitle from '../logo.component';
import { Alert } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import RECAPTCHA from 'react-google-recaptcha';
import VerificationSignUp from "../verfication/VerificationSignUp";
import GoogleSignup from "./GoogleSignup";
import Styled from "@emotion/styled";
import { Link } from '@mui/material';
import { toast } from "react-toastify";
const pageFontColor = "#FFFFFF";


const useStyles = makeStyles({
  root: {},
  // inputProps: {
  //   color: "black",
  //   borderRadius: 15,
  //   border: 10,
  //   fontSize: 18,
  // },
});


const SubmitButton = styled(Button)({
  background: "#0c5ba0",
  borderRadius: 8,
  color: pageFontColor,
  height: 45,
  fontWeight: 600
});
const HeaderLabel = Styled.h1(({ align }) => `
    font-size: 24px;
    font-weight:600;
    color: black;
    margin: 0;
    text-align:${align || 'left'};
    font-family:'Poppins';
`);

const SignupRegister = Styled.div(`
width: 87%;
max-width: 300px;
margin: 10px auto 5px auto;
padding: 10px 20px;
//margin: 20px auto 5px auto;
//padding: 10px 20px;
border-radius: 20px;
background: white;
font-size: 13px;
color: black;
text-align: center;
`);
export default function SignUpForm(props) {
  // initialize form error messages
  const [emailErrorMsg, setemailErrorMsg] = useState("");
  const [OpenOtp, setOpenOtp] = useState(false);
  const [email, setEmail] = useState('');
  const [PropsDataForVerfication, SetPropsData] = useState('');

  const [passwordErrorMsg, setpasswordErrorMsg] = useState("");
  const [confirmpasswordErrorMsg, setconfirmpasswordErrorMsg] = useState("");
  const [alert, setAlert] = useState(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertTitle, setAlertTitle] = useState("");

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [referralCode, setReferralCode] = useState(null);
  const [inviteKey, setInviteKey] = useState(props.props.inviteKey)
  const reRef = useRef();
  localStorage.setItem("uid", props.props.uid);
  localStorage.setItem("inviteKey", props.props.inviteKey)
  localStorage.setItem("referralCode", referralCode)
  const signUpSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage("");
    setError(false);
    setOpenOtp(false);
    const mail = email.toLowerCase();
    // const password = event.target.password.value;
    // const confirmPassword = event.target.password2.value;

    if (!validator.isEmail(mail)) {
      setErrorMessage("Please enter a valid email");
      setError(true);
      return
    }
    // else if (
    //   password === "" ||
    //   confirmPassword === ""
    // ) {
    //   setErrorMessage("Please enter the password");
    //   setError(true);
    //   return
    // } else if (password !== confirmPassword) {
    //   setErrorMessage("Password does not match");
    //   setError(true);
    //   return
    // }

    //...check email exist
    let validateReferral;
    if (referralCode) {
      validateReferral = await axios.get(`${global.config.ROOTURL.prod}/invite/emptyReferralUserId/${referralCode}`)
      if (validateReferral.data === "Absent") {
        toast.error("Wrong Referral Code")
        return;
      }
    }


    const isMailExistRes = await axios.post(
      `${global.config.ROOTURL.prod}/auth/check-email-exist`,
      { email: email.toLowerCase() }
    );
    if (isMailExistRes.data) {
      setErrorMessage("Email Already Exist");
      setError(true);
      return
    }

    //...send otp
    const captchaToken = await reRef.current.executeAsync();
    reRef.current.reset();
    const sendOTPRes = await axios.post(`
    ${global.config.ROOTURL.prod}/auth/sendOTP`,
      { email: email, captchaToken }
    );

    if (sendOTPRes.status === 200) {
      let data = { email: email, captchaToken };
      if (props.props.referral) {
        data.referredUserId = props.props.uid;
        data.secretCode = props.props.inviteKey;
      }
      SetPropsData(data);
      setOpenOtp(true);
    }
  };

  const handleOnChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };



  useEffect(() => {
    if (props.props.referral) {
      setAlertMessage(
        `Welcome to Champ Hunt! Your friend ${props.props.referredFromUserDetail?.firstName + " " + props.props.referredFromUserDetail?.lastName} just unlocked you the power to monetize your love for cricket. Sign up now and start earning!`
      );
      setAlert(true);
    }
  }, [props.props])


  return (
    <>
      {!OpenOtp && <>

        {!props.custClassName && <LogoWithTitle fontSize={"120"} />}
        <HeaderLabel align={props.breakPoint ? 'center' : 'left'}>Create An Account</HeaderLabel>

        {alert ? (
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert severity="success">
              <AlertTitle>{alertTitle}</AlertTitle>
              <div>
                {alertMessage}
              </div>
            </Alert>
          </Stack>
        ) : (
          <></>
        )}
        {error && (
          <Stack sx={{ width: "100%", height: 20 }} >

            <Alert severity="error" sx={{ height: 30, margin: 0, padding: 0 }}>
              <AlertTitle>
                {" "}

              </AlertTitle>
              {errorMessage}
            </Alert>
          </Stack>
        )}


        <Box component="form">

          <CTextField
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            type="email"
            placeholder="Email"
            size="small"
            autoFocus
            className={classes.root}
            value={email}
            onChange={handleOnChange}

            InputProps={{

            }}
          />

          {emailErrorMsg && <p style={{ color: "red" }}> {emailErrorMsg} </p>}
        </Box>
        
        {props.props.inviteKey ? 
        <Box>
          <CTextField
            margin="normal"
            required
            fullWidth
            id="inviteKey"
            name="inviteKey"
            type="text"
            placeholder="Enter  Code"
            size="small"
            autoFocus
            className={classes.root}
            value={props.props.inviteKey}
            disabled
          />
        </Box> : 
        <Box>
          <CTextField
            margin="normal"
            required
            fullWidth
            id="referralCode"
            name="referralCode"
            type="text"
            placeholder="Enter Referral Code"
            size="small"
            autoFocus
            className={classes.root}
            value={referralCode}
            onChange={(e) => {
              setReferralCode(e.target.value === "" ? null : e.target.value)
            }}
          />
        </Box>}
        

        <SubmitButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2, textTransform: "none", fontSize: 18, }}
          onClick={signUpSubmit}
        >
          Verify Me
        </SubmitButton>



        <Box sx={{ marginLeft: 6 }}>

          <RECAPTCHA
            sitekey={global.config.RECAPTCHA_PUBLIC_SITE_KEY}
            size="invisible"
            ref={reRef}
            badge="inline"
          />
          <p style={{ fontSize: 12, fontWeight: "400", color: '#929292', marginTop: 19, marginLeft: -25 }}>
            By Signing Up, you agree to our <a href="/privacy">terms and privacy policy.</a> </p>
        </Box>
        <GoogleSignup />
        <SignupRegister>

          <span>
            Already have an account? <Link href="/login" style={{ textDecoration: 'none' }}>&nbsp;Login here</Link>
          </span>
        </SignupRegister>
      </>}
      {OpenOtp && <VerificationSignUp state={PropsDataForVerfication} />}

    </>
  );
}
