import { useEffect, useState } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import "./indexmodal.scss";
import { successToast } from "../../commons/toast";
import { getAuthToken } from "../../utils/auth";
import ThisYearRunPopup from "./thisYearRunPopup";
import ThisYearPitchPopup from "./thisYearPitchPopup";
// import ThisYearFollowersPopup from "./thisYearFollowersPopup";
import ThisWeekRunPopup from "./thisWeekRunPopup"
import ThisWeekPitchPopup from "./thisWeekPitchPopup";
// import ThisWeekFollowersPopup from "./thisWeekFollowersPopup";
import TodayRunPopup from "./todayRunPopup"
import TodayPitchPopup from "./todayPitchPopup";
// import TodayFollowersPopup from "./todayFollowersPopup";
import SortIcon from '@mui/icons-material/Sort';
import { Select, MenuItem } from '@material-ui/core';
import {
  TextField,
  Input,
  Typography,
  Button,
  Fade,
  Modal,
  Box,
  Backdrop,
  Grid,
  useMediaQuery,
  InputAdornment,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { clearStorage } from "../../utils/sessionStorage";


// const style = {
//   position: "absolute",
//   top: "45%",
//   left: "50%",
//   bottom:"50%",
//   transform: "translate(-50%, -50%)",
//   // width:"100%",
//   bgcolor: "#FFFFFF",
//   border: "2px solid #F1F1F5",
//   borderRadius: "12px",
//   boxShadow: 24,
//   p: 0,
//   height: "650px",
// width: "850px",
// // left: "231px",
// // top: "110px",
// };
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "#FFFFFF",
  border: "2px solid #F1F1F5",
  borderRadius: "12px",
  boxShadow: 24,
  p: 0,
  // overflowY:'scroll',
  height:'90vh'
};
const styleMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 625,
  bgcolor: "#FFFFFF",
  border: "2px solid #F1F1F5",
  boxShadow: 24,
  height: "100%",
  width: "100%",
  p: 0,
};

const buttonStyle = {
  color: "#00298E",
  border: "1px solid #00298E",
  //   width: "87px",
  fontSize: "14px",
  fontWeight: "500",
  px: "11px !important",
  py: "7px !important",
};

const LeaderboardModal = (props) => {
  const [filter, setFilter] = useState("Runs");// <--------------(Like this).
  const handleChange = event => {
    setFilter(event.target.value);
  };
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  const [activeButtonAlltime, setActiveButtonAlltime] = useState(true);
  const [activeButtonThisWeek, setActiveButtonThisWeek] = useState(false);
  const [openDropDown, setOpenDropdown] = useState(false);
  const [activeButtonToday, setActiveButtonToday] = useState(false);
  const mobileView = useMediaQuery("(max-width:900px)");
  const { open, handleClose, handleOpen } = props;
  const accessToken = getAuthToken();
  const handleButtonClick1 = () => {
    setActiveButtonAlltime(true);
    setActiveButtonThisWeek(false);
    setActiveButtonToday(false);
  }
  const handleButtonClick2 = () => {
    setActiveButtonAlltime(false);
    setActiveButtonThisWeek(true);
    setActiveButtonToday(false);
  }  
  const handleButtonClick3 = () => {
    setActiveButtonAlltime(false);
    setActiveButtonThisWeek(false);
    setActiveButtonToday(true);
  }  

  return (
  <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
    
        <Box sx={mobileView ? styleMobile : style}>
        
          <Box className="leaderboard-modal">
          <Box className="pedestal">
            <Box className="title-icon">
              <Typography className="pedestal__title">Leaderboard</Typography>
              <IconButton
            aria-label="close"
            onClick={handleClose}
            className="close-icon"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
            </Box>
        <Box className="header__buttons">
          
        <Box className={activeButtonToday?"active-button":"header__buttons--top"} onClick={handleButtonClick3}>Today</Box>
          <Box className={activeButtonThisWeek?"active-button":"header__buttons--recent"} onClick={handleButtonClick2}>This Week</Box>
          
          <Box className={activeButtonAlltime?"active-button":"header__buttons--top"} onClick={handleButtonClick1}>  This Year</Box>
         <Box  className="header__buttons--top"  onClick={() =>setOpenDropdown(!open)}> <Select value={filter} style={{backgroundColor:"white", paddingRight: "53px", paddingLeft:"110px"}}   IconComponent={SortIcon }

          menuIsOpen={openDropDown} disableunderline='true'   

          onChange={handleChange}>
      <MenuItem value={"Runs"}  style={{background:"white"}}>Runs</MenuItem>
      {/* <MenuItem value={"Followers"}>Followers</MenuItem> */}
      <MenuItem value={"Pitch"} style={{background:"white"}}>Pitch</MenuItem>
    </Select>   </Box>
       </Box>
    </Box>
   { activeButtonAlltime&&filter==="Runs"&&<ThisYearRunPopup/>}
   { activeButtonAlltime&&filter==="Pitch"&&<ThisYearPitchPopup/>}
   {/* { activeButtonAlltime&&filter==="Followers"&&<ThisYearFollowersPopup/>} */}
   { activeButtonThisWeek&&filter==="Runs"&&<ThisWeekRunPopup/>}
   { activeButtonThisWeek&&filter==="Pitch"&&<ThisWeekPitchPopup/>}
   {/* { activeButtonThisWeek&&filter==="Followers"&&<ThisWeekFollowersPopup/>} */}
   { activeButtonToday&&filter==="Runs"&&<TodayRunPopup/>}
   { activeButtonToday&&filter==="Pitch"&&<TodayPitchPopup/>}
   {/* { activeButtonToday&&filter==="Followers"&&<TodayFollowersPopup/>} */}
    </Box>
    {/* <Box className="view-all">
      <h2 onClick={handleOpen}>View All</h2>
      </Box> */}
        </Box>
    
    </Modal>
  

  );
};

export default LeaderboardModal;
