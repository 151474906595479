import React, { useState } from 'react';
import { Box } from '@mui/material';

//pages
import NewLeaderBoard from './newLeaderboard';
import FirstCarouselPitch from './carousel-one';
import NewTournament from './newTournament';
import SecondCarouselPitch from './carousel-two';
import PeopleUMayKnow from './peopleuknow';
import ReferAFriend from './referafriend';

import LoginPopup from "../guest-pitch/LoginPopup";

const PitchRightSection = () => {
    const [open, setOpen] = useState(false);
    const handleClose = (data) => setOpen(data);
    const handleOpen = () => setOpen(true);
    return (
        <Box
            sx={{
                position: 'fixed',
                overflow: 'auto',
                height: '100vh',
                paddingBottom: '80px',
                width: '25vw',
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
            }}
        >
            <Box onClick={handleOpen}>
                <Box>
                    <NewLeaderBoard />
                </Box>

                <Box>
                    <FirstCarouselPitch />
                </Box>

                <Box>
                    <NewTournament />
                </Box>

                <Box>
                    <PeopleUMayKnow />
                </Box>

                <Box>
                    <ReferAFriend />
                </Box>

                <Box>
                    <SecondCarouselPitch />
                </Box>
                {open && <LoginPopup open={open} handleClose={handleClose} />}
            </Box>
        </Box>
    );
}

export default PitchRightSection;