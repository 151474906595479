import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { IconButton } from "@mui/material";
import Runs from "../../assets/images/Pitch/Feed/ballIcon.svg";
import animTwoRun from "../../assets/images/icons/animTwoRuns.png";
import animFourRun from "../../assets/images/icons/animFourRun.png";
import animSixRun from "../../assets/images/icons/animSixRun.png";
import new2ball from "../../assets/images/icons/2runs.svg";
import new4ball from "../../assets/images/icons/4runs.svg";
import new6ball from "../../assets/images/icons/6runs.svg";
import axios from "axios";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import "./dropdown.scss";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  outline: "none",
  p: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export default function ScoreRuns(props) {
  const navigate = useNavigate();
  const { postObj, handleupdaterun, postType, shareToOtherApps } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const accessToken = getStorageItem("token");
  const [openAnimation, setOpenAnimation] = useState(false);
  const [animationRunImg, setAnimationRunImg] = useState(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);

  };


  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const scorePost = (run) => {

    if (!postObj.feed_id && !run && !accessToken) return;
    let addRunOption = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/pitch/run/addRun",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        pitchId: postObj.feed_id || postObj._id,
        givenRun: run,
      },
    };

    if (postType === "shareJob") {
      addRunOption = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/job/sharejob/run/addRun",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
        data: {
          shareJobId: postObj.feed_id,
          shareJobUserId: postObj.userId,
          givenRun: run,
        },
      };
    }

    axios(addRunOption)
      .then(() => {
        handleupdaterun(run);
        setOpen(false);
        setOpenAnimation(true);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    if (shareToOtherApps) {
      setOpen((prevOpen) => !prevOpen);
    }
  }, [shareToOtherApps])

  return (
    <div>
      <div>
        <IconButton
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={() => handleToggle()}
          disableRipple={true}
          style={{
            padding: "0px",
            paddingRight: "3px",
          }}
          className="post_icon_image"
        >
          {open ? (
            <img className="post_icon_image" src={Runs} alt="" />
          ) : (
            <img className="post_icon_image" src={Runs} alt="" />
          )}
        </IconButton>
        {open && (
          <div className={`runcard_post `}>
            <img
              src={new4ball}
              className="post_icon_image"
              onClick={() => {
                scorePost(4);
                setOpenAnimation(true);
                setAnimationRunImg(animFourRun);
              }}
              alt=""
            />
            <img
              src={new6ball}
              className="middlerunimage post_icon_image"
              onClick={() => {
                scorePost(6);
                setOpenAnimation(true);
                setAnimationRunImg(animSixRun);
              }}
              alt=""
            />
            <img
              src={new2ball}
              className="post_icon_image"
              onClick={() => {
                scorePost(2);
                setOpenAnimation(true);
                setAnimationRunImg(animTwoRun);
              }}
              alt=""
            />
          </div>
        )}
        <Modal
          open={openAnimation}
          onClose={() => {
            setOpenAnimation(false);
            setAnimationRunImg(null);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            {animationRunImg && <img src={animationRunImg} alt="Run Animation" />}
          </Box>
        </Modal>
      </div>
    </div>
  );
}
