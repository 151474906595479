import React, { useEffect, useMemo, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, TextField, Avatar, Autocomplete, InputAdornment, IconButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";

import searchIcon from "../../assets/images/Pitch/Header/search.svg";

const accessToken = getStorageItem("token");

const SearchBar = (props) => {
  let timeoutID;
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const { onSearchTextChange } = props;
  const [suggestedProfileData, setSuggestedProfileData] = useState([]);
  const autocompleteRef = useRef(null);
  const [open, setOpen] = useState(false);

  const getSearchResults = (method, url, data) => {
    axios({
      method: method,
      url: url,
      headers: {
        Authorization: "Bearer " + getStorageItem("token"),
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        // console.log(response)
      })
      .catch((error) => {
        // console.log('error', error);
      });
  };

  const getAllSuggestedProfiles = async () => {
    if (!accessToken) return;

    const options = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/auth/name-suggestions?searchText=${searchText}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then(({ data }) => {
        setSuggestedProfileData(data);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    getAllSuggestedProfiles();
  }, [searchText])


  const onSearchTextUpdate = () => {
    if (searchText.length >= 1) {
      // clearTimeout(timeoutID)
      onSearchTextChange(searchText);
      const path = window.location.pathname;
      if (!path.includes("/search")) {
        navigate("/search");
      }
      setTimeout(() => {
        setSearchText("");
      }, 10000);
    }
  };
  const onsearchupdate = (e) => {
    setSearchText(e);
  };

  useEffect(() => {
    if (searchText) {
      getSearchResults(
        "POST",
        global.config.ROOTURL.prod + "/auth/name-search",
        {
          searchText: searchText,
          pageNumber: 1,
          pagePerSize: global.config.pagePerSize,
        }
      );
    }
  }, [searchText]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSearchTextUpdate();
    }
  };

  const handleInputChange = (e, value) => {
    setSearchText(value);
    setOpen(value.length > 0); // Open Dropdown only when there is some input
  };

  const handleInputClick = (e) => {
    setOpen(false); // Hide options when input field is clicked without typing
    onSearchTextUpdate();
  };

  const handleAutocompleteChange = (e, value) => {
    setSearchText(value);
    if (value && value.length >= 1) {
      onSearchTextChange(value);
      const path = window.location.pathname;
      if (!path.includes("/search")) {
        navigate("/search");
      }
      setOpen(false);
      setSearchText("");
    }
  };

  return (
    <Box sx={{ flexGrow: 1, display: "flex", overflow: "hidden", alignItems: "center" }}>
      <Autocomplete
        sx={{
          flexGrow: 1,
          width: 340,
          background: '#2B63955C',
          borderRadius: "68px",
          height: 44,
          border: "1px solid #638EB4",
          '@media (max-width:600px)': {
            width: 270,
          }
        }}
        style={{ overflow: "hidden !important", color: "#FFFFFF !important" }}
        className="autocomplete"
        autoFocus={true}
        open={open}
        inputValue={searchText}
        onChange={handleAutocompleteChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        freeSolo
        disableClearable
        options={suggestedProfileData}
        getOptionLabel={(option) => option && option?.name ? option?.name : ''}
        PaperComponent={(props) => (
          <Box
            {...props}
            sx={{
              width: '100%',
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: '10px',
              border: "1px solid var(--black-10, rgba(0, 0, 0, 0.10))",
              background: "#FFF",
              boxShadow: "0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)",
              position: 'absolute',
              marginTop: '12px',
              color: '#5E5F60',
              zIndex: 5000,
            }}
          />
        )}
        ListboxComponent={(props) => (
          <Box
            {...props}
            sx={{
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              overflowY: 'auto',
              maxHeight: '200px',
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box component="li" sx={{ display: 'flex', alignItems: 'center' }} {...props} onClick={handleInputClick}>
            <Avatar src={option.profilePhoto} sx={{ mr: 2 }} />
            <Typography>{option.name}</Typography>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            onClick={handleInputClick}
            variant="outlined"
            placeholder="search....."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton >
                    {/* <SearchIcon onClick={onSearchTextUpdate} sx={{ color: '#FFFFFF' }} /> */}
                    <img onClick={() => onSearchTextUpdate} src={searchIcon} alt="" style={{ width: '20px', height: '20px' }} />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                overflow: "hidden !important", border: "none",
                outline: "none",
                color: "#FFFFFF",
                fontSize: '17px',
                fontWeight: 300,
                position: "relative",
                top: "-8px",
              }
            }}
            sx={{
              '& fieldset': { border: 'none' },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputBase-input::placeholder': {
                color: '#FFFFFF',
                fontWeight: 300,
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default SearchBar;
