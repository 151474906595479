import React, { useState, useRef, useEffect } from "react";
import "./sell.scss";
import { getStorageItem } from "../../utils/sessionStorage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import BackegroundImage from "../../assets/images/Image-Background.png";

// User Information
const userId = getStorageItem("user_id");
const userName = getStorageItem("full_name");
const userAvatar =
  getStorageItem("avatar") ||
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";
const userEmail = getStorageItem("user_email");

// Access Token
const accessToken = getStorageItem("token");

const Index = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [imageIsUploading, setImageIsUploading] = useState(false);

  const fetchUserDetails = async () => {
    const options = {
      method: "post",
      url: global.config.ROOTURL.prod + "/auth/profile",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userId,
      }
    };
    const res = await axios(options);
    if (res.data && res?.data?.role === "Admin") {
      setIsAdmin(true);
    }
  }

  useEffect(() => {
    if (userId) {
      fetchUserDetails();
    }
  }, []);

  const handleFilesChange = (event) => {
    setFiles([]);
    const selectedFiles = event.target.files;
    const tempImages = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const reader = new FileReader();
      reader.onload = (event) => {
        tempImages.push(event.target.result);
        if (tempImages.length === selectedFiles.length) {
          setImages([...images, ...tempImages]);
          setFiles([...files, ...selectedFiles]);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("uploader", file);
    const response = await axios.post(
      `${global.config.ROOTURL.prod}/upload-file`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );
    if (response.data) {
      console.log("Image saved successfully");
      return response.data[0].location;
    } else {
      console.log("error occured:");
      throw new Error(`Failed to upload image ${file}`);
    }
  };

  const uploadImages = async () => {
    const promises = [];
    if (files.length === 0) {
      toast.error("At least one product photo is needed");
      setLoading(false);
      return;
    }
    for (let i = 0; i < files.length; i++) {
      if (files[i].size / 1024 > 5120) {
        toast.error(`File ${files[i]} size exceeded 5 mb`);
        return;
      }
      promises.push(uploadImage(files[i]));
    }

    try {
      const urls = await Promise.all(promises);
      setImageUrls(urls);

      // Code added here
      const title = titleRef.current.value;
      const category = selectedOption;
      const price = priceRef.current.value;
      const description = descriptionRef.current.value;
      const city = cityRef.current.value;
      const warranty = warrantyRef.current.value;
      const stock = stockRef.current.value;
      const discount = discountRef.current.value;
      const email = userEmail;
      const name = userName;
      const profile = userAvatar;
      const createdBy = userId;
      const contactWhatsapp = phoneRef.current.value;
      const contactNumber = whatsappRef.current.value;
      const contactEmail = emailRef.current.value;

      if (!contactNumber && !contactEmail && !contactWhatsapp) {
        toast.error("Phone/ Whatsapp/ Email is required");
        setLoading(false);
        return;
      }
      if (!userId) {
        toast.error("userId is required");
        setLoading(false);
        return;
      }

      if (
        discountRef.current.value &&
        Number(discountRef.current.value) > Number(priceRef.current.value)
      ) {
        toast.error("Discount price cannot be greater than original price");
        setLoading(false);
        return;
      }

      const sellerInformationData = {
        sellerId: userId,
        sellerName: name,
        sellerEmail: email,
        sellerProfile: profile,
      };
      const response = await fetch(
        global.config.ROOTURL.prod + "/marketplace/seller/createSeller",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(sellerInformationData),
        }
      );
      if (response.ok) {
        const result = await response.json();
        const productInformationData = {
          title: title,
          category: category,
          price: price,
          stock: stock,
          discount: discount,
          images: urls,
          description: description,
          createdBy: createdBy,
          soldBy: result?.sellerId,
          checkSellerRuns: -50, // Hard-Coded for now
          city: city,
          warranty: warranty,
          contactPhone: contactNumber,
          contactWhatsapp: contactWhatsapp,
          contactEmail: contactEmail,
        };

        console.log(productInformationData);
        const res = await fetch(
          global.config.ROOTURL.prod + "/marketplace/product/createProduct",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(productInformationData),
          }
        );
        if (res.ok) {
          setLoading(false);
          toast.success("New Product created successfully");
          setImages([]);
          setFiles([]);
          navigate("/marketplace");
        } else {
          setLoading(false);
          toast.error("Some error occured");
        }
      } else {
        setLoading(false);
        toast.error("Some error occured");
      }

      // Code ending here

      console.log("Images uploaded successfully");
    } catch (error) {
      toast.error(error);
      setLoading(false);
    } finally {
      setImageIsUploading(false);
    }
  };

  // Product Form
  const titleRef = useRef();
  const priceRef = useRef();
  const priceRunsRef = useRef();
  const warrantyRef = useRef();
  const cityRef = useRef();
  const stockRef = useRef();
  const discountRef = useRef();
  const discountRunsRef = useRef();
  const emailRef = useRef();
  const whatsappRef = useRef();
  const phoneRef = useRef();
  const [imageUrls, setImageUrls] = useState([]);
  const descriptionRef = useRef();
  const [files, setFiles] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [selectedOption, setSelectedOption] = useState("Sporting Goods");
  const [loading, setLoading] = useState(false);
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmitForm = async () => {
    try {
      setLoading(true);
      const res = await uploadImages();
      // If seller don't exists create a seller or continue
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
      // handle error
      console.log(error);
    }
  };

  return (
    <>
      <div className="sell_background">
        <Helmet>
          <title>Champhunt | MarketPlace-Sell</title>
        </Helmet>
        {/* Banner */}
        <div className="main__banner">
          <div className="product__banner">
            {images?.length > 0 && (
              <div className="symbols__wrapper">
                {images?.map((image, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        imageIndex === index ? "symbols-elongated" : "symbols"
                      }
                      onClick={() => {
                        setImageIndex(index);
                      }}
                    ></div>
                  );
                })}
              </div>
            )}
            <div className="part-1">
              {images.length > 0
                ? images.map((image, index) => (
                  <div
                    key={index}
                    className="box-1"
                    onClick={() => {
                      setImageIndex(index);
                    }}
                  >
                    <img src={image} alt="" />
                  </div>
                ))
                : [...Array(2)].map((image, index) => (
                  <div
                    key={index}
                    className="box-1"
                    onClick={() => {
                      setImageIndex(index);
                    }}
                  >
                    <img
                      src={BackegroundImage}
                      alt=""
                    />
                  </div>
                ))}
            </div>

            <div className="part-2">
              <img
                src={
                  images[imageIndex]
                    ? images[imageIndex]
                    : `${BackegroundImage}`
                }
                alt=""
              />
            </div>
          </div>

          <div className="add-remove__files">
            <label htmlFor="files" style={{
              display: "flex",
              justifyContent: "center",
              background: "#0C5BA0",
              borderRadius: "8px",
              width: "100%",
              height: "100%",
              alignItems: "center",
              cursor: "pointer",
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: 500,
              color: "#FFFFFF",
            }}>
              Add Images
              <input
                type="file"
                name="files"
                id="files"
                multiple
                accept="image/*"
                className="add__images"
                onChange={handleFilesChange}
              />
            </label>
            {/* <img
              src="/marketplace/remove.svg"
              alt="remove"
              className="add-remove__icons"
              onClick={() => {
                setImages([]);
                setFiles([]);
              }}
            /> */}
          </div>

          <div className="product-form">
            <input
              type="text"
              id="name"
              className="form__input"
              ref={titleRef}
              placeholder="Name"
              required
            />
            <div className="form__container">
              <select
                name="category"
                id="category"
                defaultValue="Sports"
                onChange={handleSelectChange}
                required
                className="select_category"
              >
                <option value="Sports">Sporting Goods</option>
                <option value="Clothes">Clothes</option>
                <option value="Electronics">Consumer Electronics</option>
                <option value="Health">Health Supplements</option>
              </select>
            </div>
            <input
              type="text"
              id="description"
              placeholder="Description"
              className="description-textarea"
              ref={descriptionRef}
              required
            />
            <div className="row">
              <input
                placeholder="Price (₹)"
                type="text"
                id="price"
                ref={priceRef}
                onChange={(e) => {
                  priceRunsRef.current.value = e.target.value * 120
                }}
                required
              />
              <input
                placeholder="Runs"
                type="text"
                id="priceRuns"
                className="form__input"
                ref={priceRunsRef}
                disabled={!isAdmin}
              />
            </div>
            <div className="row">
              <input
                placeholder="Discount (₹)"
                type="text"
                id="discount"
                className="form__input"
                ref={discountRef}
                onChange={(e) => {
                  discountRunsRef.current.value = e.target.value * 120;
                }}
              />
              <input
                placeholder="Runs"
                type="text"
                id="discountRuns"
                className="form__input"
                ref={discountRunsRef}
                disabled={!isAdmin}
              />
            </div>
            <input
              placeholder="Stock"
              type="text"
              id="stock"
              className="form__input"
              ref={stockRef}
              required
            />
            <div className="row">
              <input
                type="text"
                ref={phoneRef}
                id="phone"
                name="phoneNumber"
                placeholder="Phone Number"
                pattern="^\d{10}$"
              />
              <input
                ref={whatsappRef}
                type="text"
                id="whatsapp"
                name="whatsapp"
                placeholder="Whatsapp Number"
                pattern="^\d{10}$"
              />
            </div>
            <div className="row">
              <input
                type="text"
                id="email"
                placeholder="Email"
                ref={emailRef}
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                required
              />
              <input
                type="text"
                id="city"
                ref={cityRef}
                name="city"
                placeholder="City"
              />
            </div>
            <input
              type="text"
              id="warranty"
              ref={warrantyRef}
              name="warranty"
              placeholder="Product warranty"
            />
            {loading ? (
              <button className="sell__button--loading">Submitting</button>
            ) : (
              <button className="sell_publish_button" type="button" onClick={handleSubmitForm}>
                Publish
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
