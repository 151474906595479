import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import defaultAvatar from "../../../assets/images/profile/default_avatar.png";
import Notification from "../notification";
import axios from "axios";
import SearchBar from '../searchBar';
import Runcard from "../../posts/run";
import ProfileMenue from '../profileMenue';
import "../index.scss";
import { resetLogin } from "../../../redux/reducers/login";
import { useDispatch } from "react-redux";
import { clearStorage, getStorageItem } from "../../../utils/sessionStorage";
import { PathAction } from "../../../redux/reducers/pitchselection";
import fireBaseFuntion from "../../../firebase";

import { Box, Typography, Avatar } from '@mui/material';
import { useMediaQuery } from "@mui/material";
import AppBar from '@mui/material/AppBar';

import chLogo from "../../../assets/images/Pitch/Header/Logo.svg";
import profileLogo from "../../../assets/images/Pitch/Header/profileLogo.svg";
import giftIcon from "../../../assets/images/Pitch/Header/gift.svg";


const Header = (props) => {
    const mobileView = useMediaQuery("(max-width:768px)");
    const [checked, setChecked] = useState(false);
    const [userRuns, setUserRuns] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        avatar: "",
    });
    const [pendigMessage, setPendingMessage] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [activeNotifications, setActiveNotifications] = useState(0);
    const dropdownRef = useRef(null);
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const userAvatar = getStorageItem("avatar");
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [openProfileDropDown, setOpenProfileDropDown] = useState(false);

    const handleAction = () => {
        setIsOpen(!isOpen);
    };

    const handleDropdown = () => {
        if (isOpen) {
            // setIsOpen(!isOpen);
            setIsOpen(false);

        }
    };

    const handleProfileClick = () => {
        setOpenProfileDropDown(!openProfileDropDown);
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);  // Close dropdown if clicked outside
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const togglechanged = () => {
        setChecked(!checked);
        let checkmaindata;
        if (!checked) {
            checkmaindata = "ALL";
        } else {
            checkmaindata = "Following";
        }
        dispatch(PathAction.handlePitchSelction({ payload: checkmaindata }));
        const shared = {
            method: "POST",
            url: global.config.ROOTURL.prod + "/auth/updatePitchSelectionType",
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
            data: {
                pitchSelectionType: checkmaindata,
            },
        };
        if (checkmaindata) {
            axios(shared)
                .then((response) => {
                })
                .catch((error) => {
                    if (error?.response?.status === 401) {
                    }
                });
        }
    };

    const fetchUserInfo = () => {
        if (!userId) return;
        const getUserInfo = {
            method: "POST",
            url: global.config.ROOTURL.prod + "/auth/profile/",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: {
                userId: userId,
            },
        };
        axios(getUserInfo)
            .then((response) => {
                setUserInfo({
                    firstName: response.data.firstName ? response.data.firstName : "",
                    lastName: response.data.lastName ? response.data.lastName : "",
                    avatar: response.data.profilePhoto
                        ? response.data.profilePhoto
                        : defaultAvatar,
                });
                localStorage.setItem(
                    "locationpermission",
                    response.data.locationpermission
                );
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    clearStorage();
                    navigate("/login");
                }
            });
    };
    const setpitchselection = () => {
        if (!accessToken) return;
        const shared = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/auth/getPitchSelectionType",
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
        };
        axios(shared)
            .then((response) => {
                const checkdata = response.data.pitchSelectionType === "ALL";

                setChecked(checkdata);
                dispatch(
                    PathAction.handlePitchSelction({
                        payload: response.data.pitchSelectionType,
                    })
                );
            })

            .catch((error) => {
                if (error?.response?.status == 401) {
                }
            });
    };
    const fetchNotification = () => {
        if (!accessToken) return;
        const options = {
            method: "GET",
            url: `${global.config.ROOTURL.prod}/notification/get-top-10/${userId}`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(options)
            .then((response) => {
                setActiveNotifications(response.data.activeNotificationsCount);
                console.log(
                    "response.data.activeNotificationsCount",
                    response.data.activeNotificationsCount
                );
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    clearStorage();
                    navigate("/login");
                }
            });
    };
    const realTimeNotificationUpdate = () => {
        if (userId) {
            const { onValue, starCountRef } = fireBaseFuntion(
                "user/notification/" + userId
            );

            onValue(starCountRef, (snapShot) => {
                const data = snapShot.val();
                if (data?.createdUserId === userId) {
                    if (activeNotifications > 0) {
                        setActiveNotifications(activeNotifications);
                    }
                }
            });
        }
    };
    const fetchPendingMessage = () => {
        const shared = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/chat/getNotSeenMessageByUser",
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
        };
        axios(shared)
            .then((response) => {
                setPendingMessage(response.data);
            })

            .catch((error) => {
                if (error?.response?.status == 401) {
                    console.log(error.response.status);
                }
            });
    };

    useEffect(() => { }, [checked]);

    useEffect(() => {
        fetchUserInfo();
        setpitchselection();
        fetchPendingMessage();
        fetchNotification();
    }, []);

    useEffect(() => {
        realTimeNotificationUpdate();
    }, [activeNotifications]);

    return (
        <Box sx={{ flexGrow: 1 }} onClick={handleDropdown}>
            <AppBar
                position="fixed"
                sx={{
                    width: '100%',
                    height: '70px',
                    backgroundColor: '#07365F !important',
                    zIndex: 100,
                    padding: mobileView ? "0px 5px" : "0px 30px",
                    top: '0px'
                }}
            >
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                    }}
                >
                    <Box
                        onClick={() => navigate("/pitch")}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            cursor: 'pointer'
                        }}>
                        <img src={chLogo} alt='' />
                        {!mobileView && (
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontSize: '25px !important', color: '#FFFFFF' }}>
                                CHAMPHUNT
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ width: mobileView ? '100%' : '22%', margin: mobileView ? '0px 5px' : null }}>
                        <SearchBar
                            onSearchTextChange={(searchText) =>
                                props.onSearchTextChange(searchText)
                            }
                        />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '60px' }}>
                        {!mobileView && (<Runcard />)}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                            <Notification />
                            {!mobileView && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '5px',
                                    }}>
                                    <Avatar src={userAvatar || profileLogo} alt=''
                                        sx={{ width: 48, height: 48, cursor: 'pointer', marginRight: '-20px' }}
                                        onClick={() => handleProfileClick()}
                                    />
                                </Box>
                            )}
                            {!mobileView &&
                                (<ProfileMenue
                                    isOpen={isOpen}
                                    handleAction={handleAction}
                                    dropdownRef={dropdownRef}
                                    togglechanged={togglechanged}
                                    pendigMessage={pendigMessage}
                                    checked={checked}
                                />)}
                        </Box>
                    </Box>
                </Box>
            </AppBar>
        </Box>
    );
}

export default Header;