import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./card.css";
import axios from "axios";
import { getAuthToken } from "../../utils/auth";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import CardDelete from './cardDeleteModal'
import CardEdit from './editCard'

const Card = (props) => {
  const [data, setData] = useState(props.data)
  const [openDeleteCardModal, setOpenDeleteCardModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const accessToken = getAuthToken();
  const navigate = useNavigate();

  const handleOpenDeleteCardModal = () => setOpenDeleteCardModal(true);
  const handleCloseDeleteCardModal = () => setOpenDeleteCardModal(false);
  const handleOpenEditModal = () => setOpenEditModal(true)
  const handleCloseEditModal = () => setOpenEditModal(false)

  const deleteCard = (id) => {
    if (!accessToken && !id) return
    var deleteDetails = {
      method: 'DELETE',
      url: global.config.ROOTURL.prod + `/scorecard/delete/${id}`,
      headers: {
        'Authorization': 'Bearer ' + accessToken,
      },
    };
    axios(deleteDetails)
      .then(response => {
        if (response) {
          toast.success("Card deleted ")
          handleCloseDeleteCardModal()
          props.getAllCards()
        }
      })
      .catch(error => {
        toast.error("error occured ")
        console.log(error)
      })
  }

  // Placeholder image in case team image is not available
  const placeholderImage = "/svg/default-team-logo.svg";

  return (
    <div className="contest_admin_predction-wrapper">
      <div className="contest_admin_prediction-item">
        {/* Header with team names and actions */}
        <div className="contest_admin_prediction-header">
          <div className="contest_admin_prediction-item-name">
            {data?.team1?.name+" vs "+data?.team2?.name}
          </div>
          <div> 
            <FontAwesomeIcon 
              icon={faPenToSquare} 
              style={{ margin: "5px" }} 
              onClick={handleOpenEditModal} 
            />
            <img
              src="/svg/admin/delete.svg"
              alt="delete"
              className="contest_admin_prediction-item-delete" 
              onClick={handleOpenDeleteCardModal}
            />
          </div>
        </div>

        {/* Team Images and Scores Container */}
        <div className="team-info-container" style={{
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          padding: '10px 0'
        }}>
          {/* Team 1 */}
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            width: '40%' 
          }}>
            <img 
              src={data?.team1?.image || placeholderImage} 
              alt={`${data?.team1?.name} logo`}
              style={{ 
                width: '80px', 
                height: '80px', 
                objectFit: 'contain',
                marginBottom: '10px'
              }} 
            />
            <p style={{fontSize:'14px', fontWeight: 'bold'}}>
              {data?.team1?.name}
            </p>
            <p style={{fontSize:'14px'}}>
              {data?.team1?.runs || 0}/{data?.team1?.wickets || 0} 
              ({data?.team1?.overs || 0} overs)
            </p>
          </div>

          {/* VS */}
          <div style={{ 
            fontSize: '16px', 
            fontWeight: 'bold', 
            color: '#888' 
          }}>
            VS
          </div>

          {/* Team 2 */}
          <div style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            width: '40%' 
          }}>
            <img 
              src={data?.team2?.image || placeholderImage} 
              alt={`${data?.team2?.name} logo`}
              style={{ 
                width: '80px', 
                height: '80px', 
                objectFit: 'contain',
                marginBottom: '10px'
              }} 
            />
            <p style={{fontSize:'14px', fontWeight: 'bold'}}>
              {data?.team2?.name}
            </p>
            <p style={{fontSize:'14px'}}>
              {data?.team2?.runs || 0}/{data?.team2?.wickets || 0} 
              ({data?.team2?.overs || 0} overs)
            </p>
          </div>
        </div>

        {/* Additional Match Details */}
        <div style={{ 
          borderTop: '1px solid #eee', 
          padding: '10px', 
          textAlign: 'center' 
        }}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <p style={{fontSize:'14px'}}>
              <strong>Type:</strong> {data?.type || 'Not Specified'}
            </p>
            <p style={{fontSize:'14px'}}>
              <strong>Ended:</strong> {data?.ended ? "Yes" : "No"}
            </p>
          </div>
          
          {data?.winner && (
            <p style={{fontSize:'14px', color: 'green'}}>
              <strong>Winner:</strong> {data?.winner}
            </p>
          )}
          
          <p style={{fontSize:'14px', color: '#666'}}>
            <strong>Info:</strong> {data?.information || 'No additional information'}
          </p>
        </div>
      </div>

      {/* Modals */}
      {openDeleteCardModal && (
        <CardDelete 
          handleOpen={handleOpenDeleteCardModal} 
          team1={data?.team1?.name} 
          team2={data?.team2?.name}
          handleClose={handleCloseDeleteCardModal} 
          open={openDeleteCardModal} 
          cardId={data?._id}
          handleDelete={deleteCard} 
        />
      )}

      {openEditModal && (
        <CardEdit 
          handleOpen={handleOpenDeleteCardModal}
          handleClose={handleCloseEditModal} 
          open={openEditModal} 
          cardData={data} 
          setData={setData}
        />
      )}
    </div>
  );
};

export default Card;