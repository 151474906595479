import React from "react";
import { Box, TextField, Autocomplete, InputAdornment, IconButton } from '@mui/material';

import searchIcon from "../../assets/images/Pitch/Header/search.svg";

const SearchBar = () => {
  const options = [];

  return (
    <Box sx={{ flexGrow: 1, display: "flex", overflow: "hidden", alignItems: "center" }}>
      <Autocomplete
        sx={{
          flexGrow: 1,
          width: 340,
          background: '#2B63955C',
          borderRadius: "68px",
          height: 44,
          border: "1px solid #638EB4",
          '@media (max-width:600px)': {
            width: 270,
          }
        }}
        options={options}
        style={{ overflow: "hidden !important", color: "#FFFFFF !important" }}
        className="autocomplete"
        autoFocus={true}
        freeSolo
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="search....."
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton >
                    <img src={searchIcon} alt="" style={{ width: '20px', height: '20px' }} />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                overflow: "hidden !important", border: "none",
                outline: "none",
                color: "#FFFFFF",
                fontSize: '17px',
                fontWeight: 300,
                position: "relative",
                top: "-8px",
              }
            }}
            sx={{
              '& fieldset': { border: 'none' },
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputBase-input::placeholder': {
                color: '#FFFFFF',
                fontWeight: 300,
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default SearchBar;
