import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import NewCard from "../card/NewCard";
import "./single.scss";
import { getStorageItem } from "../../utils/sessionStorage";
import { toast } from "react-toastify";
import axios from "axios";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ChevronRight } from "lucide-react";
import { AiOutlineCopy } from "react-icons/ai";
import { CartContext } from "../../context/CartContext";
import ball from "../../assets/images/marketplace/redball.png";
import star from "../../assets/images/marketplace/Stars2.png";
import cartIcon from "../../assets/images/marketplace/cartIcon.png";
import vanIcon from "../../assets/images/marketplace/van.png";
import vanIcon2 from "../../assets/images/marketplace/Delivery.png";
import stock from "../../assets/images/marketplace/Stock.png";
import badge from "../../assets/images/marketplace/Guaranteed.png";
import badge2 from "../../assets/images/marketplace/badge.png";
import locationIcon from "../../assets/images/marketplace/location.png";
import phoneIcon from "../../assets/images/marketplace/telephone.png";
import shareIcon from "../../assets/images/marketplace/shareIcon.png";
import wishistIcon from "../../assets/images/marketplace/Union.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { scrollToTop } from "react-scroll/modules/mixins/animate-scroll";

const Index = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [sellerDetail, setSellerDetail] = useState(null);
  const [imageIndex, setImageIndex] = useState(0);
  const userId = getStorageItem("user_id");
  const userName = getStorageItem("full_name");
  const userAvatar =
    getStorageItem("avatar") ||
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";
  const userEmail = getStorageItem("user_email");
  const accessToken = getStorageItem("token");
  const [modal, setModal] = useState(false);
  const [cart, setCart] = useContext(CartContext);
  const [isProductPresent, setIsProductPresent] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [swapProductImg, setSwapProductImg] = useState(0);
  const [productImgBorder, setProductImgBorder] = useState(false);
  const [wishListed, setWishListed] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const navigate = useNavigate();

  const closePopup = () => {
    setModal(false);
    document.body.style.overflow = "auto";
  };

  const openPopup = () => {
    setModal(true);
    document.body.style.overflow = "hidden";
    window.scrollTo(0 , 0); // scroll to top left corner of viewport
  };

  // Function  for show message(error) once
  let errorTimeout;

  function showErrorOnce(message) {
    if (!errorTimeout) {
        toast.error(message);
        errorTimeout = setTimeout(() => {
            clearTimeout(errorTimeout);
            errorTimeout = null; 
        }, 5000); 
    }
  }

  // Function  for show message(error) once
  let successTimeout;

  function showSuccessOnce(message) {
    if (!successTimeout) {
        toast.success(message);
        successTimeout = setTimeout(() => {
            clearTimeout(successTimeout);
            successTimeout = null; 
        }, 5000); 
    }
  }
  // Function to get products using categories
  async function fetchProductsByCategories() {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/product/getProductsByCategory/${product?.category}?limit=5`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const { products, count } = await response.json();
      const suggested = products?.filter((item) => {
        return item._id !== id;
      });
      setProducts(suggested);
    } catch (err) {
      console.log(err);
    }
  }

  // Function to calculate final product price after discount
  const calculateDiscount = (price, discountPrice) => {
    return Math.round(price - discountPrice);
  };

  // Function to calculate time
  const convertDate = (date) => {
    // Format the createdAt date using the formatDate function
    const formattedDate = moment(date).format("DD/MM/YYYY");
    return formattedDate;
  };
  // Function for Scroll to top 
  const ScrollToTop = () =>{
    window.scrollTo({top: 0 });
  }

  // Function to fetch product
  const fetchProduct = async () => {
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/product/getSingleProductByProductId/${id}`,
        {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        }
      );
      const product = await response.json();
      handleCheckSeller(product?.sellerId);
      setProduct(product);
      if (cart?.length > 0) {
        const productFound = cart?.find((item) => {
          return item?.productInfo?._id === product?._id;
        });
        if (productFound) {
          setIsProductPresent(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Handle notify

  const handleNotifyMe = async () => {
    if (!product._id) {
      console.log("No product id found");
      return;
    }
    const options = {
      method: "POST",
      url:
        global.config.ROOTURL.prod +
        "/marketplace/product/addUserToNotification",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userId,
        userEmail: userEmail,
        userName: userName,
        productId: product._id,
      },
    };
    axios(options)
      .then((data) => {
        console.log(data);
        showSuccessOnce(
          "You will be notified on your registered email once this product gets back in stock."
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Function to fetch seller details
  const handleCheckSeller = async (sellerId) => {
    if (!sellerId && !accessToken) return;
    const opt = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/marketplace/seller/findInformationOfSingleSellerById/${sellerId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    axios(opt)
      .then((data) => {
        setSellerDetail(data?.data);
        closePopup();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddToCart = async (product) => {
    if (!product) {
      return;
    }
    const productSellerInfo = {
      productInfo: product,
      sellerInfo: sellerDetail,
    };
    productSellerInfo.productInfo.quantity = 1;
    setCart([...cart, productSellerInfo]);
    localStorage.setItem("cart", JSON.stringify([...cart, productSellerInfo]));
    showSuccessOnce(`${product?.title} added to cart`);
  };

  // Function to create Buyer
  const handleCreateBuyer = async () => {
    // Check seller check eligibility
    if (!userId && !accessToken) return;
    try {
      const options = {
        method: "GET",
        url: global.config.ROOTURL.prod + "/pitch/getTotalRun/" + userId,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      axios(options)
        .then((data) => {
          if (data.data >= product?.runs) {
            // Create a new buyer
            if (!localStorage.getItem("buyer-address")) {
              showErrorOnce("Buyer Address does not exists");
              navigate("/marketplace/address");
              return;
            }
            const address = JSON.parse(localStorage.getItem("buyer-address"));
            const buyerInformationData = {
              buyerId: userId,
              buyerName: userName,
              buyerEmail: userEmail,
              buyerProfile: userAvatar,
              productId: product?._id,
              buyerAddress: address,
              sellerId: product?.sellerId,
            };
            const opt = {
              method: "POST",
              url:
                global.config.ROOTURL.prod + `/marketplace/buyer/createBuyer`,
              headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
              },
              data: buyerInformationData,
            };
            axios(opt)
              .then((data) => {
                navigate(`/marketplace/order_status/${userId}`);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            showErrorOnce("Not enough Runs to redeem this deal. Keep engaging to earn more!");
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleCheckIfProductIsWishLitedOrNot = async () => {
    if (!accessToken && !userId) return;
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/product/checkIfProductIsWishListedByUserId`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            productId: product?._id,
          }),
        }
      );
      const res = await response.json();
      if (res === true) {
        setWishListed(true);
      } else {
        setWishListed(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddToWishlist = async (_id) => {
    // handle the star icon click here
    if (!_id && !userId) return;
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        "/marketplace/product/addProductToWishList",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            userId: userId,
            productId: product?._id,
          }),
        }
      );
      const res = await response.json();
      console.log(res);
      setWishListed(true);
      showSuccessOnce("Added this item to wishlist");
    } catch (err) {
      console.log(err);
    }
  };

  const handleARemoveFromWishlist = async (_id) => {
    // handle the star icon click here
    if (!accessToken && !userId) return;
    try {
      const response = await fetch(
        global.config.ROOTURL.prod +
        `/marketplace/product/removeProductFromWishList`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userId,
            productId: product?._id,
          }),
        }
      );
      const res = await response.json();
      setWishListed(false);
      showSuccessOnce("Removed this item from wishlist");
    } catch (err) {
      console.log(err);
    }
  };

  const handleShare = () => {
    const productUrl = window.location.href; // Get the current URL
    navigator.clipboard
      .writeText(productUrl)
      .then(() => {
        showSuccessOnce("Product link copied to clipboard!");
      })
      .catch((err) => {
        showErrorOnce("Failed to copy link");
        console.error("Error copying link: ", err);
      });
  };

  //marketplace homepage redirection for the mobile view
  const handleBackMobileView = () => {
    navigate("/marketplace");
  };

  useEffect(() => {
    handleCheckIfProductIsWishLitedOrNot();
  }, []);

  useEffect(() => {
    fetchProduct();
  }, [id]);
  useEffect(() => {
    fetchProductsByCategories();
  }, [product]);
  // useEffect(() => {
  //   checkIfBuyerAlreadyCheckedTheSeller();
  // }, [product, sellerDetail]);

  useEffect(() => {
    // Login to check if the product exists in cart or not
    if (cart.length > 0) {
      const productFound = cart.find((item) => {
        return item?.productInfo?._id === product?._id;
      });
      if (productFound) {
        setIsProductPresent(true);
      }
    }
  }, [cart]);

  return (
    <div className="product_detail_main">
      {/* seller contact modal */}
      {modal && (
        <div className="confirm__modal">
          <div className="seller__details-box">
            <img
              src="/marketplace/cancel.svg"
              alt="cancel"
              className="cancel"
              onClick={closePopup}
            />
            <h3 className="confirm__modal-heading">Contact Seller</h3>
            {product?.contactPhone && (
              <div className="seller__details">
                <img
                  src="/marketplace/phone.svg"
                  alt="phone"
                  className="details"
                />
                <h4 className="seller-contact-details">
                  {product?.contactPhone}
                </h4>
                <AiOutlineCopy
                  className="icon--copy"
                  onClick={() => {
                    navigator.clipboard.writeText(product?.contactPhone);
                    showSuccessOnce("Phone number copied.");
                  }}
                />
              </div>
            )}
            {product?.contactWhatsapp && (
              <div className="seller__details">
                <img
                  src="/marketplace/whatsapp.svg"
                  alt="whatsapp"
                  className="details"
                />
                <h4 className="seller-contact-details">
                  {product?.contactWhatsapp}
                </h4>
                <AiOutlineCopy
                  className="icon--copy"
                  onClick={() => {
                    navigator.clipboard.writeText(product?.contactWhatsapp);
                    showSuccessOnce("Whatsapp number copied.");
                  }}
                />
              </div>
            )}
            {product?.contactEmail && (
              <div className="seller__details">
                <img
                  src="/marketplace/email.svg"
                  alt="email"
                  className="details"
                />
                <h4 className="seller-contact-details">
                  {product?.contactEmail}
                </h4>
                <AiOutlineCopy
                  className="icon--copy"
                  onClick={() => {
                    navigator.clipboard.writeText(product?.contactEmail);
                    showSuccessOnce("Email copied.");
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="navigation_share_box">
        <div className="navigation">
          {!isMobile ? (
            <>
              <div className="nav_text_box">
                <p
                  className="nav_text"
                  onClick={() => navigate("/marketplace")}
                >
                  Home
                </p>
                <ChevronRight className="arrowRight" strokeWidth={1.5} />
              </div>
              <div className="nav_text_box">
                {/* // <p className="nav_text">Popular</p>// */}
                {/* <ChevronRight className="arrowRight" strokeWidth={1.5} /> */}
              </div>
            </>
          ) : (
            <KeyboardBackspaceIcon onClick={handleBackMobileView} />
          )}
          <p className="product_text">{product?.title}</p>
        </div>
        <div className="share_wishlist">
          {!wishListed ? (
            <img
              src={wishistIcon}
              alt=""
              className="wishlistIcon"
              onClick={(e) => {
                e.stopPropagation();
                handleAddToWishlist(product?._id);
              }}
            />
          ) : (
            <svg
              className="star"
              width="45px"
              height="39px"
              viewBox="0 0 22 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={(e) => {
                e.stopPropagation();
                handleARemoveFromWishlist(product?._id);
              }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.1274 1.64942C12.9476 1.52448 12.8359 1.32177 12.8359 1.10287C12.8359 0.746184 12.5468 0.457031 12.1901 0.457031H9.79584C9.43563 0.457031 9.14363 0.749033 9.14363 1.10924C9.14363 1.32901 9.03222 1.53283 8.85202 1.65864C8.44456 1.94313 7.99238 2.29879 7.70773 2.64453C7.38397 3.03778 7.14173 3.59473 6.9742 4.08316C6.8253 4.51732 6.43138 4.83203 5.9724 4.83203H4.52825C2.48904 4.83203 0.835938 6.51096 0.835938 8.58203V11.707C0.835938 13.7781 2.48904 15.457 4.52825 15.457H17.1436C19.1828 15.457 20.8359 13.7781 20.8359 11.707V8.58203C20.8359 6.51096 19.1828 4.83203 17.1436 4.83203H15.9816C15.5308 4.83203 15.141 4.52859 14.9904 4.10372C14.8082 3.58961 14.5468 2.99387 14.2115 2.58666C13.935 2.25082 13.5123 1.917 13.1274 1.64942ZM12.8017 3.04416C12.6312 2.78515 12.3338 2.64453 12.0237 2.64453H9.91973C9.62069 2.64453 9.33364 2.77566 9.15796 3.01765C8.98833 3.25131 8.82511 3.50874 8.73337 3.73828C8.72667 3.75505 8.72015 3.77185 8.71381 3.78867C8.50981 4.32995 8.99015 4.83203 9.56859 4.83203H12.2584C12.8773 4.83203 13.3661 4.2663 13.1445 3.68836C13.1383 3.67213 13.132 3.65597 13.1255 3.6399C13.0469 3.44323 12.9279 3.23588 12.8017 3.04416Z"
                fill="#0C5BA0"
                stroke="#0C5BA0"
                strokeWidth="0.6"
              />
            </svg>
          )}
          <img
            src={shareIcon}
            alt=""
            className="shareIcon"
            onClick={(e) => {
              e.stopPropagation();
              setIsShareModalOpen(true);
            }}
          />
          {isShareModalOpen && (
            <div>
              <Modal
                open={isShareModalOpen}
                onClose={() => setIsShareModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={shareModalstyle}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h6"
                    >
                      {window.location.href.substring(0, 25)}...
                    </Typography>
                    <ContentCopyIcon onClick={handleShare} />
                  </Box>
                </Box>
              </Modal>
            </div>
          )}
        </div>
      </div>
      <div className="box_container">
        <div className="images_box">
          <div className="images_left_box">
            <div
              className="image_inner_box"
              style={{
                border:
                  productImgBorder && swapProductImg === 0
                    ? "2px solid var(--Neutral-Foreground-2-Brand-Hover, #0F6CBD)"
                    : "",
              }}
              onClick={() => {
                setSwapProductImg(0);
                setProductImgBorder(true);
              }}
            >
              <img
                className="images_left"
                src={product?.images ? product?.images[0] : null}
                alt={product?.title}
              />
            </div>
            <div
              className="image_inner_box"
              style={{
                border:
                  productImgBorder && swapProductImg === 1
                    ? "2px solid var(--Neutral-Foreground-2-Brand-Hover, #0F6CBD)"
                    : "",
              }}
              onClick={() => {
                setSwapProductImg(1);
                setProductImgBorder(true);
              }}
            >
              <img
                className="images_left"
                src={product?.images ? product?.images[1] : null}
                alt={product?.title}
              />
            </div>
            <div
              className="image_inner_box"
              style={{
                border:
                  productImgBorder && swapProductImg === 2
                    ? "2px solid var(--Neutral-Foreground-2-Brand-Hover, #0F6CBD)"
                    : "",
              }}
              onClick={() => {
                setSwapProductImg(2);
                setProductImgBorder(true);
              }}
            >
              <img
                className="images_left"
                src={product?.images ? product?.images[2] : null}
                alt={product?.title}
              />
            </div>
          </div>
          <div className="image_right_box">
            <img
              className="image_right"
              src={product?.images ? product?.images[swapProductImg] : null}
              alt={product?.title}
            />
          </div>
        </div>

        <div className="details_box">
          <h1 className="product_name">{product?.title}</h1>

          <div className="price_runs_box">
            <div className="price_box">
              <p className="original_price">&#8377;{product?.price-product?.discount}</p>
              <del className="del_price">
                <small>&#8377;{product?.price}</small>
              </del>
            </div>
            <div className="runs_box">
              <img className="ball" src={ball} alt="" />
              <p className="runs_text">{product?.runs}</p>
            </div>
          </div>

          <div className="rating_box">
            <img className="rating_star" src={star} alt="rating" />
            <p className="rating_text">{product?.averageRating}</p>
          </div>

          <p className="production-discp-container">Description</p>
          <p className="product_description">{product?.description}</p>

          <div className="button_box">
            {product?.stock > 0 ? (
              <Button
                className="cart_button"
                onClick={() => {
                  if (isProductPresent) {
                    showSuccessOnce("Item alreay added in the cart");
                    return;
                  }
                  handleAddToCart(product);
                }}
              >
                <img className="cartIcon" src={cartIcon} alt="" />
                <p className="cart_text">Add to cart</p>
              </Button>
            ) : (
              <Button
                className="cart_button"
                onClick={() => {
                  showSuccessOnce("Item is out of stock");
                }}
              >
                <img className="cartIcon" src={cartIcon} alt="" />
                <p className="cart_text">Out of Stock</p>
              </Button>
            )}
            {product?.stock > 0 ? (
              <Button className="buy_button" onClick={handleCreateBuyer}>
                <img className="vanIcon" src={vanIcon} alt="" />
                <div className="buy_text">
                  <p style={{ fontWeight: 400 }}>Buy for</p>{" "}
                  <storng style={{ color: "#FFFFFF" }}>{product?.runs}</storng>
                </div>
              </Button>
            ) : (
              <Button className="buy_button" onClick={() => handleNotifyMe()}>
                <img className="vanIcon" src={vanIcon} alt="" />
                <div className="buy_text">
                  <p style={{ fontWeight: 400 }}>Notify Me</p>{" "}
                </div>
              </Button>
            )}
          </div>

          <div className="shiping_box">
            <div className="icon_box">
              <img className="icon" src={vanIcon2} alt="" />
              <div>
                <p className="head_name">Delivery by</p>
                <p className="date_text">10 Jul</p>
              </div>
            </div>
            <div className="icon_box">
              <img className="icon" src={stock} alt="" />
              <div>
                {product?.stock > 0 ? (
                  <p className="head_name">In Stock</p>
                ) : (
                  <p className="head_name">Out of Stock</p>
                )}
                <p className="date_text">Today</p>
              </div>
            </div>
            <div className="icon_box">
              <img className="icon" src={badge} alt="" />
              <div>
                <p className="head_name">Guaranteed</p>
                {product?.warrantyDuration ? (
                  <p className="location_text">{product?.warrantyDuration}</p>
                ) : (
                  null
                )}
              </div>
            </div>
          </div>

          <div className="seller_box">
            <h1 className="about-seller">About Seller</h1>
            <div className="seller_inner_box">
              <div className="seller_profile">
                <img
                  className="avatar"
                  src={sellerDetail?.sellerProfile}
                  alt={sellerDetail?.sellerName}
                />
                <div className="name_box">
                  <div className="name_badge_box">
                    <h2 className="seller_name">{sellerDetail?.sellerName}</h2>
                    {sellerDetail?.isSellerVerified && (
                      <img className="seller_badge" src={badge2} alt="" />
                    )}
                  </div>
                  <div className="location_box">
                    <img className="location_icon" src={locationIcon} alt="" />
                    {product?.city ? (
                      <p className="location_text">{product?.city}</p>
                    ) : (
                      null
                    )}
                  </div>
                </div>
              </div>
              <Button className="seller_button">
                <img className="phone_icon" src={phoneIcon} alt="" />
                <p className="seller_text" onClick={openPopup}>
                  Contact Seller
                </p>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="suggestions_list">
        <h2 className="suggestion_heading">Suggestions</h2>
        <div className="cards_box">
          {products?.map((product, index) => {
            return (
              <NewCard
                onClick={ScrollToTop()}
                key={index}
                product={product}
                index={index}
                type="suggested"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Index;

const shareModalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  outline: "none",
  boxShadow: 24,
  p: 4,
};
