import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { clearStorage, deleteStorageItem, getStorageItem, setStorageItem } from "../../utils/sessionStorage";
import axios from "axios";
import { makeStyles } from "@mui/styles";
// Custom CSS
import "./index.scss";
// MUI Components
import { IconButton, Dialog, Typography, DialogContent, DialogContentText, DialogTitle, Box, AppBar, Toolbar, Button } from '@mui/material';
// React-Toastify
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

// new page
import bgEvent from "../../assets/images/Events/bg-event.png";
import eventCard from "../../assets/images/Events/eventBg.png";
import TeamDetail from "./detailPopUp.js";
import Lottie from 'react-lottie';
import animationData from '../../assets/images/Events/otp-verification.json';
import closeIcon from "../../assets/images/Events/Dismiss-circle.png";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import backButton from "../../assets/images/icons/back-button.png"

const SingleEvent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { tournament_id } = useParams()
  const accessToken = getStorageItem("token");

  const [page, setPage] = useState(1);
  const [eventPageData, setEventPageData] = useState([])

  const handleVerificationExit = () => setVerificationOpen(!verificationOpen);

    // lottie working
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    }  

  // Material-UI styles
  const useStyles = makeStyles((theme) => ({
    content: {
      maxWidth: 800,
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    section: {
      padding: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
      },
      flex: 1,
    },
    textContainer: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  }));

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  // Passcode Verification
  const [verificationText1, setVerificationText1] = useState('');
  const [verificationText2, setVerificationText2] = useState('');
  const [verificationText3, setVerificationText3] = useState('');
  const [verificationText4, setVerificationText4] = useState('');

  const inputReference1 = useRef(null);
  const inputReference2 = useRef(null);
  const inputReference3 = useRef(null);
  const inputReference4 = useRef(null);

  const [verificationOpen, setVerificationOpen] = useState(false);
  const handleVerification = () => {
    setVerificationOpen(true);
  };

  // Create Team PopUp
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // Verify button
  const [isVerified, setIsVerified] = useState(false);

  const getEventData = async () => {
    try {
      const eventDataByTournament = {
        method: "GET",
        url: global.config.ROOTURL.prod + `/events/getEventById/${tournament_id}`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };

      await axios(eventDataByTournament)
        .then((response) => {
          setEventPageData(response.data || {});
        })
    } catch {
      console.log('error')
    }
  };

  useEffect(() => {
    getEventData()
  }, [])


  // Verification Using Passcode
  useEffect(() => {
    const verifyOTP = async (isAllTextField) => {
      if (!isAllTextField) {
        let eventCode = `${verificationText1}${verificationText2}${verificationText3}${verificationText4}`;
        const url = `${global.config.ROOTURL.prod}/events/checkEventAccessCode/${tournament_id}/${eventCode.toLowerCase()}`;

        try {
          const config = {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };
  
          const sendOTPRes = await axios.post(url, {}, config);
  
          if (sendOTPRes.data === "success") {
            setVerificationOpen(!verificationOpen);
            toast.success("Successfully Verified");
            setIsVerified(true);
          } else {
            toast.error("WRONG ACCESS KEY");
          }
        } catch (error) {
          console.error("Error during POST request:", error);
          toast.error(error.response?.data?.error || "Failed to verify. Please try again.");
        }
      }
    };
  
    verifyOTP(!(
      verificationText1.trim().length === 1
      && verificationText2.trim().length === 1
      && verificationText3.trim().length === 1
      && verificationText4.trim().length === 1
    ));
  }, [verificationText1, verificationText2, verificationText3, verificationText4]);
  

  return (
    <>
      <Helmet>
        <title>Champhunt | Tournament</title>
      </Helmet>

      <div style={{ paddingTop: isMobile ? "80px" : "128px" }}>
        {/* Header */}
        <AppBar position="static" sx={{ bgcolor: "#ffffff" }}>
          <Toolbar>
            <img onClick={() => navigate(-1)} style={{ width: '20px', height: '20px', marginRight: '20px', cursor: 'pointer' }} src={backButton} />
            <Typography variant="h6" sx={{ color: '#313335', fontWeight: '600' }}>
              {eventPageData.eventTitle}
            </Typography>
          </Toolbar>
        </AppBar>

        {/* BG */}
        <Box style={{ height: isMobile ? "200px" : "400px", overflow: "hidden", position: "relative" }}>
          <img
            src={eventPageData.pageBanner}
            alt="Cricket"
            className={classes.media}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "absolute"
            }}
          />
        </Box>

        {/* Sub-heading */}
        <Box sx={{ textAlign: 'center', padding: '16px 0', backgroundColor: 'white !important', }}>
          <Typography variant="h5" sx={{ paddingBottom: '5px', color: '#494D50' }}>
            Organizing By: <span style={{ color: '#0B518E', fontWeight: '600' }}>{eventPageData.organizingBy}</span>
          </Typography>
          <Typography
            variant="h6"
            sx={{ color: '#840495', fontStyle: 'italic' }}
            dangerouslySetInnerHTML={{ __html: eventPageData.eventdescription }}
          />
        </Box>

        {/* Event Ticket Bg */}
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: 'auto',
          padding: '40px 0',
          backgroundColor: 'white !important',
          '& img': {
            width: isMobile ? '90%' : '60%',
            height: isMobile ? 'auto' : 'auto'
          }
        }}>
          <img src={eventPageData.detailBanner} alt="Event" />
        </Box>

        {/* Verify button */}
        <Box sx={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
          height: "auto",
          paddingBottom: '20px',
          backgroundColor: 'white !important',
        }}>
          {!isVerified ? (<Button variant="contained" sx={{ width: '30%', height: '40px' }} onClick={handleVerification}>
            VERIFY
          </Button>) : (
            <>
              <Button variant="contained" sx={{ width: '30%', height: '40px' }} onClick={handleClickOpen}>
                CREATE YOUR TEAM
              </Button>

              <Button variant="contained" sx={{ width: '30%', height: '40px' }} onClick={() => navigate(`/${tournament_id}/AllTeams`)}>
                JOIN TEAM
              </Button>
              <TeamDetail open={open} onClose={handleClose} />
            </>
          )}


          {/* Passcode Verification Passcode */}
          <Dialog open={verificationOpen} sx={{ textAlign: 'center' }}>
            <IconButton onClick={handleVerificationExit} aria-label="close" sx={{ justifyContent: "flex-end !important", borderRadius: "0 !important" }}>
              <img src={closeIcon} alt="Close" style={{ width: 24, height: 24 }} />
            </IconButton>
            <Box>
              <Lottie
                options={defaultOptions}
                height={200}
                width={200}
              />
            </Box>

            <DialogTitle sx={{ color: '#0C5BA0', fontSize: '22px' }}>Verify Your Access</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ color: '#62666A', fontSize: '18px' }}>
                Enter Your College Passcode to Join the Tournament
              </DialogContentText>

              <DialogContentText sx={{ color: '#95999D', fontSize: '12px', fontStyle: 'italic' }}>
                Welcome to the tournament! To ensure a fair and secure experience for all participants, please enter your college passcode below. Let's get you into the action!
              </DialogContentText>
            </DialogContent>

            <div className="userinput">
              <input
                ref={inputReference1}
                className={`inputFiled ${verificationText1.length === 1 ? '' : 'required'}`}
                type="text"
                maxLength="1"
                onChange={(e) => {
                  setVerificationText1(e.target.value);
                  inputReference2.current.focus();
                }}
                style={{ border: '1px solid #D0D5DD', borderRadius: '8px', textTransform: 'uppercase' }}
                autoFocus={true}
              />
              <input
                ref={inputReference2}
                className={`inputFiled ${verificationText2.length === 1 ? '' : 'required'}`}
                type="text"
                maxLength="1"
                onChange={(e) => {
                  setVerificationText2(e.target.value);
                  inputReference3.current.focus();
                }}
                style={{ border: '1px solid #D0D5DD', borderRadius: '8px', textTransform: 'uppercase' }}
              />
              <input
                ref={inputReference3}
                className={`inputFiled ${verificationText3.length === 1 ? '' : 'required'}`}
                type="text"
                maxLength="1"
                onChange={(e) => {
                  setVerificationText3(e.target.value);
                  inputReference4.current.focus();
                }}
                style={{ border: '1px solid #D0D5DD', borderRadius: '8px', textTransform: 'uppercase' }}
              />
              <input
                ref={inputReference4}
                className={`inputFiled ${verificationText4.length === 1 ? '' : 'required'}`}
                type="text"
                maxLength="1"
                onChange={(e) => {
                  setVerificationText4(e.target.value);
                }}
                style={{ border: '1px solid #D0D5DD', borderRadius: '8px', textTransform: 'uppercase' }}
              />
            </div>
          </Dialog>
        </Box>

      </div >
    </>
  );
};

export default SingleEvent;
