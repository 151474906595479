import React, { useState, useEffect } from 'react';
import { getStorageItem } from "../../utils/sessionStorage";
import { useParams } from 'react-router-dom';
import { Typography, Box, IconButton, Dialog, DialogTitle, DialogContent, List, ListItem, ListItemAvatar, ListItemText, Avatar } from '@mui/material';
import axios from 'axios';
import closeIcon from "../../assets/images/Events/Dismiss-circle.png";

const PlayersList = ({ open, onClose, teams }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const accessToken = getStorageItem("token");
  const { tournament_id } = useParams()
  
  useEffect(() => {
    if (teams && teams.teamName) {
      const fetchData = async () => {
        try {
          const token = localStorage.getItem("token");
          const response = await axios.get(
            global.config.ROOTURL.prod + `/events/team/showTeamMembers/${teams.teamName}/${tournament_id}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          if (response.data) {
            setTeamMembers(response.data);
          }
        } catch (error) {
          console.log("Error fetching team members:", error.response || error.message);
        }
      };
      fetchData();
    }
  }, [teams]);  

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <IconButton onClick={onClose} aria-label="close" sx={{ justifyContent: "flex-end !important", borderRadius: "0 !important" }}>
        <img src={closeIcon} alt="Close" style={{ width: 24, height: 24 }} />
      </IconButton>
      <Box style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
        <DialogTitle style={{ display: "flex", alignItems: 'center', marginLeft: '20px' }}>
          Team Members <Typography color='primary' sx={{ paddingLeft: '5px' }}>{teamMembers.length} PLAYERS</Typography>
        </DialogTitle>
      </Box>
      <DialogContent>
        <List>
          {teamMembers.map((member, index) => (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar alt={member.playerName} src="path_to_member_image.jpg" />
              </ListItemAvatar>
              <ListItemText
                primary={member.playerName}
                secondary={`Roll no: ${member.rollNo}`}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default PlayersList;
