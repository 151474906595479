import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Avatar } from '@mui/material';

import bg1 from "../../assets/images/Pitch/NavMenu/bg1.svg";
import batman from "../../assets/images/Pitch/NavMenu/cricketer.svg";
import bgBall from "../../assets/images/Pitch/NavMenu/ballBg.svg";
import pitchLogo from "../../assets/images/Pitch/NavMenu/pitch.svg";
import arenaLogo from "../../assets/images/Pitch/NavMenu/arena.svg";
import highlightsLogo from "../../assets/images/Pitch/NavMenu/highlights.svg";
import dealsLogo from "../../assets/images/Pitch/NavMenu/deal.svg";
import dogoutLogo from "../../assets/images/Pitch/NavMenu/dogout.svg";
import marketplaceLogo from "../../assets/images/Pitch/NavMenu/marketplace.svg";
import jobLogo from "../../assets/images/Pitch/NavMenu/job.svg";
import avtarLogo from "../../assets/images/Pitch/NavMenu/avatar.svg";
import trendingLogo from "../../assets/images/Pitch/NavMenu/trending.svg";
import ruleLogo from "../../assets/images/Pitch/NavMenu/rules.svg";
import analyticsLogo from "../../assets/images/Pitch/NavMenu/analytics.svg";
import chatwithchamphunt from "../../assets/images/Pitch/NavMenu/chatwithchamphunt.svg";
import settingsLogo from "../../assets/images/Pitch/NavMenu/setting.svg";
import contactLogo from "../../assets/images/Pitch/NavMenu/contact.svg";
import aboutLogo from "../../assets/images/Pitch/NavMenu/about.svg";
import privacyLogo from "../../assets/images/Pitch/NavMenu/privacy.svg";

import LoginPopup from "../guest-pitch/LoginPopup";

const menuItems = [
    { logo: pitchLogo, name: "Pitch" },
    { logo: arenaLogo, name: "Arena" },
    { logo: highlightsLogo, name: "Highlights" },
    { logo: dealsLogo, name: "Deals" },
    { logo: dogoutLogo, name: "Dugout" },
    { logo: marketplaceLogo, name: "Marketplace" },
    { logo: jobLogo, name: "Job" },
    { logo: trendingLogo, name: "Trending" },
    { logo: ruleLogo, name: "Rules" },
    { logo: analyticsLogo, name: "Analytics" },
    { logo: chatwithchamphunt, name: "Chat With Champhunt" },
    { logo: settingsLogo, name: "Settings" },
    { logo: contactLogo, name: "Contact us" },
    { logo: aboutLogo, name: "About us" },
    { logo: privacyLogo, name: "Privacy Policy" },
]

const NavigationMenu = () => {
    const navigate = useNavigate();
    const [selectedMenuItem, setSelectedMenuItem] = useState(0);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = (data) => setOpen(data);

    const handleMenuItemClick = (index) => {
        setSelectedMenuItem(index);
        const itemName = menuItems[index].name;
    };

    const handleMouseEnter = (index) => {
        setHoveredItem(index);
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                height: '100vh',
                overflow: 'auto',
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
            }}>
            <Box
                onClick={handleOpen}
                sx={{
                    width: '19.6vw',
                    backgroundColor: '#FFFFFF',
                    height: "auto",
                    position: 'relative',
                    paddingBottom: '80px'
                }}
            >
                <Box sx={{ position: 'relative', borderBottom: "1px solid #0000001A", padding: '10px 20px' }}>
                    <img src={bg1} alt='' style={{ position: 'absolute', zIndex: 0, width: '80%', top: '40px', }} />
                    {menuItems.slice(0, 7).map((item, index) => (
                        <Box
                            onClick={() => handleMenuItemClick(index)}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px 10px',
                                position: 'relative',
                                backgroundColor: hoveredItem === index
                                    ? '#A0CFF852'
                                    : (hoveredItem === null && selectedMenuItem === index)
                                        ? '#A0CFF852'
                                        : 'transparent',
                                borderRadius: '10px',
                                zIndex: 1,
                                '&:hover': {
                                    backgroundColor: '#A0CFF852',
                                    borderRadius: '10px'
                                }
                            }}
                        >
                            <img src={item.logo} alt={item.name}
                                style={{ width: '25px', height: '25px', marginRight: '15px' }}
                            />
                            <Typography variant="body1"
                                sx={{
                                    color: selectedMenuItem === index ? '#0F6CBD' : '#0F0F0F',
                                    fontWeight: selectedMenuItem === index ? 'bold' : 400,
                                    fontFamily: 'Helvetica',
                                    fontSize: '15px'
                                }}>
                                {item.name}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box sx={{ padding: '25px 30px', borderBottom: "1px solid #0000001A", }}>
                    <Typography variant="h6"
                        sx={{
                            fontFamily: 'Helvetica',
                            fontStyle: 'italic',
                            color: '#424242',
                            fontSize: '12px',
                            fontWeight: 700,
                            lineHeight: '20px'
                        }}>
                        Sign in to play, earn rewards, and connect with cricket fans!
                    </Typography>
                    <Button
                        onClick={() => navigate("/signup")}
                        sx={{
                            background: '#EDFEE7',
                            borderRadius: '18px',
                            padding: '7px 12px',
                            border: "1px solid #43AD4526",
                            marginTop: '20px',
                            '&:hover': {
                                background: '#EDFEE7',
                            }
                        }}
                    >
                        <Avatar src={avtarLogo} alt='' sx={{ width: '24px', height: '24px' }} />
                        <Typography variant="h6"
                            sx={{
                                color: '#3AC309',
                                fontSize: '14px',
                                fontWeight: 500,
                                marginLeft: '10px',
                                textTransform: 'none'
                            }}>
                            Sign in
                        </Typography>
                    </Button>
                </Box>
                <Box sx={{ position: 'relative', padding: '25px 30px', borderBottom: "1px solid #0000001A", }}>
                    <img src={batman} alt='' style={{ position: 'absolute', width: '30%', zIndex: 0, top: '-90px', right: '10px' }} />
                    <Typography variant="h5"
                        sx={{
                            color: '#0F548C',
                            fontWeight: 700,
                            fontSize: '16px',
                            fontFamily: 'Helvetica'
                        }}>
                        Explore
                    </Typography>
                    {menuItems.slice(7, 11).map((item, index) => (
                        <Box
                            onClick={() => handleMenuItemClick(index + 7)}
                            onMouseEnter={() => handleMouseEnter(index + 7)}
                            onMouseLeave={handleMouseLeave}
                            key={index + 7}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                marginTop: '10px',
                                cursor: 'pointer',
                                borderRadius: "10px",
                                backgroundColor: hoveredItem === index + 7
                                    ? '#A0CFF852'
                                    : (hoveredItem === null && selectedMenuItem === index + 7)
                                        ? '#A0CFF852'
                                        : 'transparent',
                                '&:hover': {
                                    backgroundColor: '#A0CFF852',
                                    borderRadius: '10px'
                                }
                            }}
                        >
                            <img src={item.logo} alt={item.name}
                                style={{ width: '25px', height: '25px', marginRight: '15px' }}
                            />
                            <Typography variant="body1"
                                sx={{
                                    color: selectedMenuItem === index + 7 ? '#0F6CBD' : '#0F0F0F',
                                    fontWeight: selectedMenuItem === index + 7 ? 'bold' : 400,
                                    fontFamily: 'Helvetica',
                                    fontSize: '15px'
                                }}>
                                {item.name}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box sx={{ padding: '25px 30px', borderBottom: "1px solid #0000001A", }}>
                    <Typography variant="h5"
                        sx={{
                            color: '#0F548C',
                            fontWeight: 700,
                            fontSize: '16px',
                            fontFamily: 'Helvetica'
                        }}>
                        More Options
                    </Typography>
                    {menuItems.slice(11).map((item, index) => (
                        <Box
                            onClick={() => handleMenuItemClick(index + 11)}
                            onMouseEnter={() => handleMouseEnter(index + 11)}
                            onMouseLeave={handleMouseLeave}
                            key={index + 11}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                marginTop: '10px',
                                cursor: 'pointer',
                                borderRadius: "10px",
                                position: 'relative',
                                zIndex: 2,
                                backgroundColor: hoveredItem === index + 11
                                    ? '#A0CFF852'
                                    : (hoveredItem === null && selectedMenuItem === index + 11)
                                        ? '#A0CFF852'
                                        : 'transparent',
                                '&:hover': {
                                    backgroundColor: '#A0CFF852',
                                    borderRadius: '10px'
                                }
                            }}
                        >
                            <img src={item.logo} alt={item.name}
                                style={{ width: '25px', height: '25px', marginRight: '15px' }}
                            />
                            <Typography variant="body1"
                                sx={{
                                    color: selectedMenuItem === index + 11 ? '#0F6CBD' : '#0F0F0F',
                                    fontWeight: selectedMenuItem === index + 11 ? 'bold' : 400,
                                    fontFamily: 'Helvetica',
                                    fontSize: '15px'
                                }}>
                                {item.name}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <img src={bgBall} alt='' style={{ position: 'absolute', zIndex: 0, width: '47%', bottom: '50px', left: '0px' }} />
                {open && <LoginPopup open={open} handleClose={handleClose} />}
            </Box >
        </Box>
    )
}

export default NavigationMenu;
