import React from 'react';
import axios from 'axios';
import { Dialog } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStorageItem } from '../../utils/sessionStorage';
import { toast } from "react-toastify";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DealRedeemPopUp from './dealRedeemPopUp';
import "./style.scss"

const DealRedeemAds = ({ open, onClose, dealId }) => {
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [adModal, setAdModal] = useState(false);
    const [videoModal, setVideoModal] = useState(false);
    const [adWatched, setAdWatched] = useState(false);
    const [ad, setAd] = useState(null);
    const [redeemSuccessfullPopup, setRedeemSuccessfullPopup] = useState(false);

    const handleNavigateDealPage = () => {
        navigate("/deals");
    };

    const dealSuccessOpenPopup = () => {
        setRedeemSuccessfullPopup(true);
    };

    const dealSuccessClosePopup = () => {
        setRedeemSuccessfullPopup(false);
        onClose();
    };

    const fetchRandomAd = async () => {
        try {
            const response = await axios.get(
                `${global.config.ROOTURL.prod}/ads/fetch-ad-by-deal-id/${dealId}`,
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Headers": "*",
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Methods": "*",
                    },
                }
            );
            // console.log(
            //   `${global.config.ROOTURL.prod}/ads/fetch-ad-by-deal-id/${dealId}`
            // );
            if (response?.status === 200) {
                setAd(response?.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchRandomAd();
    }, []);

    const handleWatchFullAd = async () => {
        try {
            // Give 1000 runs
            const response = await axios.post(
                `${global.config.ROOTURL.prod}/auth/given-runs/`,
                {
                    userId: userId,
                    givenRun: 1000,
                },
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                }
            );

            const viewIncreaseResponse = await axios.post(
                `${global.config.ROOTURL.prod}/ads/increaseViewCount`,
                {
                    _id: ad?._id,
                    userId: userId,
                },
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    },
                }
            );

            if (response.status === 200 && viewIncreaseResponse.status === 200) {
                setVideoModal(false);
            } else {
                setVideoModal(false);
                toast.error("Some error occured!");
            }
        } catch (error) {
            console.error("Error watching ad:", error);
            setVideoModal(false);
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 3,
                    p: 2,
                },
            }}
        >
            <div className="modal-backdrop-ad">
                <div className="modal-content-ad">
                    <div className="modal-header-ad">
                        <h2>{ad?.title}</h2>
                        <button
                            className="close-ad"
                            onClick={onClose}
                        >
                            <AiOutlineCloseCircle />
                        </button>
                    </div>
                    <div className="modal-body-ad" onClick={handleNavigateDealPage}>
                        <video
                            src={ad?.url}
                            autoPlay
                            // controls
                            onEnded={() => {
                                setAdWatched(true);
                                dealSuccessOpenPopup();
                            }}
                        ></video>
                        <p>{ad?.description}</p>
                    </div>
                </div>
            </div >
            {redeemSuccessfullPopup && (
                <DealRedeemPopUp
                    open={redeemSuccessfullPopup}
                    onClose={dealSuccessClosePopup}
                />
            )}
        </Dialog>
    )
}

export default DealRedeemAds;