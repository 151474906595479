import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton'

import cricketGif from "../../assets/images/Pitch/Feed/cricket.gif";

const WorkPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedItemName } = location.state || {};

    return (
        <Box
            sx={{ position: 'relative', top: '45px', background: '#FFFFFF' }}
        >
            <Box sx={{
                height: '60px',
                border: '0px 0px 1px 0px',
                borderBottom: '1px solid #AFB8CF',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
            }}>
                <IconButton sx={{ position: 'absolute', left: '20px' }} onClick={() => navigate(-1)}>
                    <ArrowBackIcon fontSize="large" style={{ color: '#212121' }} />
                </IconButton>
                <Typography
                    sx={{
                        fontFamily: 'Inter',
                        fontSize: '25px',
                        fontWeight: 700,
                        textAlign: 'center',
                        color: '#0B518E',
                    }}>
                    {selectedItemName || ""}
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '15px'
            }}>
                <img src={cricketGif} alt='' style={{ margin: '50px 0px' }} />
                <Typography
                    variant='h4'
                    sx={{
                        fontFamily: 'Helvetica',
                        fontSize: '25px',
                        fontWeight: 700,
                        color: '#0F6CBD',
                        textAlign: 'center'
                    }}>
                    Setting the Field Right!
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Helvetica',
                        fontSize: '20px',
                        fontWeight: 400,
                        lineHeight: '30px',
                        color: '#95999D',
                        textAlign: 'center'
                    }}>
                    We’re making some updates to improve your experience.
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Helvetica',
                        fontSize: '20px',
                        fontWeight: 400,
                        lineHeight: '30px',
                        color: '#3AC309',
                        textAlign: 'center'
                    }}>
                    Stay tuned—we’ll be back in action soon!
                </Typography>
                <Button
                    onClick={() => navigate("/pitch")}
                    sx={{
                        background: 'linear-gradient(237.51deg, #0C5BA0 7.13%, #880499 37.63%, #F46710 75.99%, #EE9B04 99.56%)',
                        padding: '7px 10px',
                        textTransform: 'none',
                        fontFamily: 'Helvetica',
                        fontWeight: 700,
                        color: '#FFFFFF',
                        marginTop: '20px'
                    }}
                >Go To Pitch
                </Button>
            </Box>
        </Box >
    )
}

export default WorkPage;