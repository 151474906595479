import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss";

const SecondCarouselPitch = () => {
    const [carousel, setCarousel] = useState([]);
    const [sliderRef, setSliderRef] = useState(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        ref: setSliderRef,
    };

    const handleVideoPlay = () => {
        if (sliderRef) {
            sliderRef.slickPause();
        }
    };

    const handleVideoEnd = () => {
        if (sliderRef) {
            sliderRef.slickPlay();
        }
    };

    const getCarousels = async () => {
        try {
            const response = await axios({
                method: "GET",
                url: `${global.config.ROOTURL.prod}/carousel/getAllGuest/PitchCarousel/1/10`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });
            const sortedCarousel = response.data.sort((a, b) => a.priority - b.priority);
            setCarousel(sortedCarousel);
        } catch (error) {
            console.error("Error fetching carousel data:", error);
        }
    };

    useEffect(() => {
        getCarousels();
    }, []);

    return (
        <div className="carousel-container2" style={{ maxWidth: "600px", margin: "12px auto" }}>
            <Slider {...settings}>
                {carousel.map((data, index) => (
                    <div
                        key={index}
                    >
                        {(
                            data.name.endsWith('.gif') || data.name.endsWith('.png') ? (
                                <img src={data.name} alt="Pitch Carousel" className="carousel-image2" />
                            ) : (
                                <video
                                    key={index}
                                    autoPlay
                                    loop={false}
                                    muted
                                    className="carousel-image2"
                                    onPlay={handleVideoPlay}
                                    onEnded={handleVideoEnd}
                                >
                                    <source src={data.name} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            )
                        )}
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SecondCarouselPitch;
