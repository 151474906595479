import React from 'react';
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import { Box, Typography } from '@mui/material';
import './style.scss';

// images
import bhrisGayle from "../../assets/images/news/EAusSemiFinal.jpg";
import ForwardCircle from "../../assets/images/clarity_circle-arrow-solids.svg"

const NewTournament = () => {
    const navigate = useNavigate();
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const [eventOnPitch, setEventOnPitch] = useState([]);

    useEffect(() => {
        if (accessToken) {
            const options = {
                method: "GET",
                url: global.config.ROOTURL.prod + "/events/getAll/1/5?ongoing=true",
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            };

            axios(options)
                .then(({ data }) => {
                    setEventOnPitch([...data].slice(0, 1));
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [])

    return (
        <Box className="tournament-small-wrapper" margin="0" sx={{marginBottom:"12px"}}>
            <Typography
                variant="p"
                fontWeight={600}
                fontSize={20}
                color="#1A1919"
                fontFamily="Helvetica"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '15px' }}
            >
                Tournaments
            </Typography>

            {eventOnPitch.length > 0 ? (
                eventOnPitch.map((event) => (
                    <Box key={event._id} height="150px" width="100%" className="tournament-small"
                        onClick={() => {
                            if (event.eventNameCode !== "") {
                                navigate(`/tournament/${event._id}`)
                            } else {
                                navigate(`/events/${event._id}`)
                            }
                        }
                        }>
                        <img
                            src={event.eventBanner}
                            alt="tournament-banner"
                            height="150px"
                            width="100%"
                            className="tournament-banner-small"
                        />
                        <Box className="tournament-small-overlay"></Box>
                        <Typography
                            variant="h5"
                            fontFamily="Helvetica"
                            fontWeight={600}
                            fontSize="22px"
                            color="#fff"
                            textAlign="center"
                            className="tournament-small-heading"
                        >
                            {event.eventLocation}
                        </Typography>
                        <Box
                            component="img"
                            alt="arrow-img"
                            src={ForwardCircle}
                            className="tournament-small-subheading"
                        />
                    </Box>
                ))
            ) : (
                <Typography fontFamily="Helvetica" fontWeight={400} fontSize="14px" color="#1A1919" textAlign="center">
                    No tournaments available.
                </Typography>
            )}

            < Typography
                fontFamily="Helvetica"
                fontWeight={600}
                fontSize="16px"
                color=" #0c5ba0"
                className="tournament-small-read-more"
                onClick={() => {
                    navigate('/all-events');
                }}
            >
                Show More
            </Typography>
        </Box >
    )
}

export default NewTournament;