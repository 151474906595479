import React, { useState, useEffect } from "react";
import "./index.scss";
import champLogo from "../../assets/images/icons/storyIcon.png";
import axios from "axios";
import LoginPopup from "../guest-pitch/LoginPopup";
import storyBgCircle from "../../assets/images/Pitch/Feed/storybg.svg";

const Story = () => {
    const [stories, setStories] = useState([]);
    const [open, setOpen] = useState(false);
    const handleClose = (data) => setOpen(data);
    const handleOpen = () => setOpen(true);

    const getStoriesGuestAndUserDetails = () => {

        const options = {
            method: "get",
            url: global.config.ROOTURL.prod + "/story/getStoriesGuest",
            headers: {
            },
        };

        axios(options)
            .then(({ data }) => {
                setStories([...data]);
            })
            .catch((error) => {
                console.log("Error in guest story", error)
            });
    };
    useEffect(() => {
        getStoriesGuestAndUserDetails();
    }, []);

    return (
        <>
            <div className="story-main-container" onClick={handleOpen}>
                <div className="stories-slider">
                    {stories.map((story, index) => (
                        <div
                            key={story.id}
                            className="following-stories"
                        >
                            <div className="following-profile-container">
                                <img className="storybg-cricle" src={storyBgCircle} alt="" />
                                <img
                                    className="following-profile"
                                    src={story.user.profilePhoto || champLogo}
                                    alt=""
                                />
                            </div>
                            <p className="following-name">{`${story.user?.firstName} `}</p>
                        </div>
                    ))}
                </div>
            </div>
            {/* open popup */}
            {open && <LoginPopup open={open} handleClose={handleClose} />}
        </>
    );
};

export default Story;