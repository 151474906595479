import React, { useState } from 'react';

import "../pitch/index.scss"
import { Grid, Box } from '@mui/material';
import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";

import NavigationMenu from '../../components/guest-pitch/NavigationMenue.js';
import PitchRightSection from '../../components/guest-pitch/pitchRightSection';
import Feeds from '../../components/guest-feed/Feed.js';
import Footer from "../../components/footer/Footer.js";
import Header from '../../components/header-guest/NewHeader.js';
import GuestLoginPopup from "../../components/preview/Popup.js";

const PitchMain = () => {
    const mobileView = useMediaQuery("(max-width:900px)");
    const [Open, setOpen] = useState(false);
    const handleClose = (data) => setOpen(data);

    return (
        <>
            <Helmet>
                <title>Champhunt | Pitch</title>
            </Helmet>
            <Header />
            <Box
                sx={{
                    boxSizing: 'border-box',
                    background: '#FCFCFC',
                    overflowX: 'auto !important',
                    marginTop: '70px',
                    padding: mobileView ? '0px 10px' : '0px 120px',
                    alignItems: 'center'
                }}
            >
                <Grid container spacing={2}>
                    {!mobileView && (
                        <Grid
                            item
                            xs={12}
                            sm={3}
                            md={3}
                        >
                            <NavigationMenu />
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={5.36}
                        sx={{
                            maxWidth: mobileView ? '100% !important' : null
                        }}
                    >
                        <Feeds />
                    </Grid>
                    {!mobileView && (
                        <Grid
                            item
                            xs={12}
                            sm={3}
                            md={3.5}
                        >
                            <PitchRightSection />
                        </Grid>
                    )}
                </Grid>
                <Footer />
            </Box>
            {Open && <GuestLoginPopup Open={Open} handleClose={handleClose} />}
        </>
    );
}

export default PitchMain;