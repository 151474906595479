import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import "./index.scss";
import { getStorageItem } from "../../../utils/sessionStorage";

const AdminCreateVoucher = () => {
  const [codeInput, setCodeInput] = useState("");
  const [codes, setCodes] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [discount, setDiscount] = useState("");
  const [expiry, setExpiry] = useState("");
  const [redirectUrl, setRedirectUrl] = useState("");
  const [logoImage, setLogoImage] = useState(null);
  const [voucherImage, setVoucherImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [detailInput, setDetailInput] = useState("");
  const [details, setDetails] = useState([]);
  const accessToken = getStorageItem("token");
  const [redeemedRun, setRedeemedRun] = useState(0);
  const handleAddCode = () => {
    if (codeInput.trim() === "") {
      toast.error("Code cannot be empty");
      return;
    }
    setCodes([...codes, codeInput.trim()]);
    setCodeInput("");
  };

  const handleRemoveCode = (index) => {
    setCodes(codes.filter((_, i) => i !== index));
  };

  const handleAddDetail = () => {
    if (detailInput.trim() === "") {
      toast.error("Detail cannot be empty");
      return;
    }
    setDetails([...details, detailInput.trim()]);
    setDetailInput("");
  };

  const handleRemoveDetail = (index) => {
    setDetails(details.filter((_, i) => i !== index));
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("uploader", file);
    const uploadUrl = `${global.config.ROOTURL.prod}/upload-file`;
    try {
      const response = await axios.post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("response:", response.data[0].location);
      return response.data[0].location;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw new Error("Image upload failed. Please try again.");
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const voucherData = {
      codes,
      title,
      description,
      discount: Number(discount),
      expiry: expiry ? new Date(expiry) : undefined,
      redirectUrl,
      details,
      redeemedRun,
    };

    try {
      if (logoImage) voucherData.logoImage = await handleImageUpload(logoImage);
      if (voucherImage)
        voucherData.voucherImage = await handleImageUpload(voucherImage);

      const response = await axios.post(
        `${global.config.ROOTURL.prod}/voucher/create`,
        voucherData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status !== 200) throw new Error("Failed to create voucher");

      toast.success("Voucher created successfully");
      setCodes([]);
      setTitle("");
      setDescription("");
      setDiscount("");
      setExpiry("");
      setRedirectUrl("");
      setLogoImage(null);
      setVoucherImage(null);
      setDetails([]); // Reset details
      setRedeemedRun(0);
    } catch (error) {
      console.error("Error creating voucher:", error);
      toast.error(error.message || "Error creating voucher. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="ad-creation-wrapper">
      <h1 className="ad-creation__title">Create Voucher</h1>
      <form className="ad-creation__form" onSubmit={handleFormSubmit}>
        <div>
          <label htmlFor="title" className="ad-creation__form-label">
            Title
          </label>
          <input
            id="title"
            type="text"
            className="ad-creation__form-input"
            placeholder="Enter Voucher Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="description" className="ad-creation__form-label">
            Description
          </label>
          <textarea
            id="description"
            className="ad-creation__form-input"
            placeholder="Enter Voucher Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="discount" className="ad-creation__form-label">
            Discount Percentage
          </label>
          <input
            id="discount"
            type="number"
            className="ad-creation__form-input"
            placeholder="Enter Discount Percentage"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="discount" className="ad-creation__form-label">
            redeemed Run
          </label>
          <input
            id="discount"
            type="number"
            className="ad-creation__form-input"
            placeholder="Enter Redeemed Run"
            value={redeemedRun}
            onChange={(e) => setRedeemedRun(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="expiry" className="ad-creation__form-label">
            Expiry Date (Optional)
          </label>
          <input
            id="expiry"
            type="date"
            className="ad-creation__form-input"
            value={expiry}
            onChange={(e) => setExpiry(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="redirectUrl" className="ad-creation__form-label">
            Redirect URL
          </label>
          <input
            id="redirectUrl"
            type="url"
            className="ad-creation__form-input"
            placeholder="Enter Redirect URL"
            value={redirectUrl}
            onChange={(e) => setRedirectUrl(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="logoImage" className="ad-creation__form-label">
            Logo Image
          </label>
          <input
            id="logoImage"
            type="file"
            className="ad-creation__form-input"
            onChange={(e) => setLogoImage(e.target.files[0])}
            accept="image/*"
          />
        </div>
        <div>
          <label htmlFor="voucherImage" className="ad-creation__form-label">
            Voucher Image
          </label>
          <input
            id="voucherImage"
            type="file"
            className="ad-creation__form-input"
            onChange={(e) => setVoucherImage(e.target.files[0])}
            accept="image/*"
          />
        </div>
        <div>
          <label htmlFor="code" className="ad-creation__form-label">
            Voucher Codes
          </label>
          <div className="ad-creation__code-input-wrapper">
            <input
              id="code"
              type="text"
              className="ad-creation__form-input"
              placeholder="Enter a Code"
              value={codeInput}
              onChange={(e) => setCodeInput(e.target.value)}
            />
            <button
              type="button"
              className="ad-creation-form-submit--active"
              onClick={handleAddCode}
            >
              Add Code
            </button>
          </div>
          <ul className="ad-creation__code-list">
            {codes.map((code, index) => (
              <li key={index} className="ad-creation__code-item">
                {code}
                <button
                  type="button"
                  className="ad-creation__remove-code-button"
                  onClick={() => handleRemoveCode(index)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
        {/* New details field */}
        <div>
          <label htmlFor="detail" className="ad-creation__form-label">
            Voucher Details
          </label>
          <div className="ad-creation__detail-input-wrapper">
            <input
              id="detail"
              type="text"
              className="ad-creation__form-input"
              placeholder="Enter a Detail"
              value={detailInput}
              onChange={(e) => setDetailInput(e.target.value)}
            />
            <button
              type="button"
              className="ad-creation-form-submit--active"
              onClick={handleAddDetail}
            >
              Add Detail
            </button>
          </div>
          <ul className="ad-creation__code-list">
            {details.map((detail, index) => (
              <li key={index} className="ad-creation__code-item">
                {detail}
                <button
                  type="button"
                  className="ad-creation__remove-detail-button"
                  onClick={() => handleRemoveDetail(index)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <button
            type="submit"
            className={
              loading
                ? "ad-creation-form-submit--disabled"
                : "ad-creation-form-submit--active"
            }
            disabled={loading}
          >
            {loading ? "Creating..." : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AdminCreateVoucher;
