import React from "react";
import { Dialog, DialogContent, DialogActions, Typography, IconButton, Button, Box, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import animationData from "../../assets/images/deals/dealRedeemed.json"
import Lottie from "react-lottie";


const DealRedeemPopUp = ({open, onClose}) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    return (
        <Box>
            <Dialog
                open={open}
                onClose={onClose}
                PaperProps={{
                    style: { borderRadius: 12, padding: "20px", textAlign: "center" },
                }}
            >
                <Box position="absolute" top="10px" right="10px">
                    <IconButton onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <Lottie
                        options={defaultOptions}
                        style={{ width: "40%" }}
                    />
                    <Typography
                        sx={{
                            color: "rgba(49, 51, 53, 1)",
                            fontSize: "32px",
                            fontFamily: "Helvetica",
                            fontWeight: "700",
                            paddingBottom: "12px"
                        }}
                    >
                        WAY TO GO
                    </Typography>

                    <Typography
                        sx={{
                            color: "rgba(243, 113, 33, 1)",
                            fontSize: "20px",
                            fontFamily: "Helvetica",
                            fontWeight: "600",
                        }}
                    >
                        You've successfully claimed the deal.
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ padding: "0px !important" }}>
                    <Button
                        variant="contained"
                        onClick={onClose}
                        style={{
                            textTransform: "none",
                            margin: "0 auto",
                            backgroundColor: "rgba(12, 91, 160, 1)",
                            color: "rgba(255, 255, 255, 1)",
                            fontSize: "16px",
                            fontFamily: "Helvetica",
                            fontWeight: "600",
                        }}
                    >
                        Go to Deal page
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DealRedeemPopUp;