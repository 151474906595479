import React from 'react';
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { useDispatch } from "react-redux";
import { PathAction } from "../../redux/reducers/globalPath";
import { getStorageItem, setStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import "../pitch/index.scss"
import { Grid, Box } from '@mui/material';
import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";

import PostContext from "../../context/post";
import NavigationMenu from '../../components/pitch/NavigationMenu';
import PitchRightSection from '../../components/pitch/pitchRightSection';
import Feeds from '../../components/feeds/feed-redesign/Feeds';
import Footer from "../../components/footer/Footer.js";

const PitchMain = () => {
    const mobileView = useMediaQuery("(max-width:900px)");
    const accessToken = getStorageItem("token");
    const [showForm, setShowForm] = useState(false);
    const [isPitch, setIsPitch] = useState(true);
    const [pitchCreated, setPitchcreated] = useState(false);
    const [url1, setURL] = useState("");
    const pitchURL = "/pitch";
    const path = window.location.pathname;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(PathAction.handlePath({ payload: path }));
        const eventRedirect = getStorageItem("eventRedirect");
        if (eventRedirect && eventRedirect !== "") {
            navigate(eventRedirect);
        }
    }, []);

    const appContext = useContext(AppContext);
    const { setOnlyLogo } = appContext;
    setOnlyLogo(false);

    const handlePostClick = () => {
        if (pitchURL === url1) {
            setShowForm(!showForm);
        }
    };

    const handleCancelPost = () => {
        setShowForm(false);
    };
    const setpitchselection = () => {
        if (!accessToken) return
        const shared = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/auth/getPitchSelectionType",
            headers: {
                Authorization: "Bearer " + accessToken,
                'Content-Type': 'application/json'
            },

        }
        axios(shared)
            .then((response) => {
                // console.log("setpitchselection", response)
                setStorageItem('pitchSelectionType', response.data.pitchSelectionType)
                setPitchcreated(true);
                // localStorage.setItem('hey', 'rsigi')
            })

            .catch((error) => {
                if (error?.response?.status == 401) {
                    console.log(error.response.status)
                }
            });
    }
    useEffect(() => {
        setpitchselection();
        return () => {
            setOnlyLogo(false);
        }
    }, [])
    // const mobileView = useMediaQuery("(max-width:959px)");
    const handlehprops = () => {
        setPitchcreated(!pitchCreated);
    }
    const handlesetUrl = (data) => {
        setURL(data)
    }

    return (
        <>
            <Helmet>
                <title>Champhunt | Pitch</title>
            </Helmet>

            <PostContext.Provider
                value={{
                    showForm,
                    toggleShowForm: handleCancelPost,
                }}
            >
                <Box
                    sx={{
                        boxSizing: 'border-box',
                        background: '#FCFCFC',
                        overflowX: 'auto !important',
                        marginTop: mobileView ? '45px' : "0px",
                        padding: mobileView ? '0px 10px' : '60px 110px 0px 110px',
                        alignItems: 'center'
                    }}
                >
                    <Grid container spacing={2}>
                        {!mobileView && (
                            <Grid
                                item
                                xs={12}
                                sm={3}
                                md={3}
                            >
                                <NavigationMenu />
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={5.36}
                            sx={{
                                maxWidth: mobileView ? '100% !important' : null
                            }}
                        >
                            <Feeds />
                        </Grid>
                        {!mobileView && (
                            <Grid
                                item
                                xs={12}
                                sm={3}
                                md={3.5}
                            >
                                <PitchRightSection />
                            </Grid>
                        )}
                    </Grid>
                    <Footer />
                </Box>
            </PostContext.Provider>
        </>
    );
}

export default PitchMain;