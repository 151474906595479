import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import BackArrow from "../../assets/images/marketplace/leftArrow.png"
import { useNavigate } from "react-router-dom";
import animationData from "../../assets/images/referafriend.json";
import LottieBgImage from "../../assets/images/referbgimage.png"
import Lottie from "react-lottie";
import WhatsApp from "../../assets/images/whatsapp.png";
import Instagram from "../../assets/images/instagram.png";
import FaceBook from "../../assets/images/facebook.png";
import { getStorageItem } from '../../utils/sessionStorage';
import axios from "axios";
import { getAuthToken } from '../../utils/auth';
import { CircularProgress } from '@mui/material';
import InviteLink from '../../assets/images/InviteLink.png';
import { useMediaQuery } from "@mui/material";

function ReferEarn() {
    const navigate = useNavigate();
    const userId = getStorageItem('user_id');
    const [referralCode, setReferralCode] = useState("KS123CH")
    const [copyMsg, setCopyMsg] = useState("");
    const [copyStatus, setCopyStatus] = useState(false)
    const accessToken = getAuthToken();
    const [referralRuns, setReferralRuns] = useState(1000)

    const firstName = localStorage.getItem("first_name");
    const lastName = localStorage.getItem("last_name")
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${referralCode}`
    let whatsappUrl = ""
    if (isMobile) {
        whatsappUrl = `https://api.whatsapp.com/send?text=Hey! 🏏 Join me on Champhunt – the ultimate app for cricket lovers. Sign up with my referral code and grab ${referralRuns} Runs as a welcome bonus!%0A
%0A
🎉 Collect Runs, redeem them for awesome rewards, and be part of a fun cricket community.%0A
%0A
USE MY CODE : ${referralCode}%0A 
Or %0A 
LINK : ${global.config.share_url}/invite/${userId}/${referralCode}%0A
%0A
✨ Let’s score big together!!`
    }
    else {
        whatsappUrl = `https://web.whatsapp.com/send?text=Hey! 🏏 Join me on Champhunt – the ultimate app for cricket lovers. Sign up with my referral code and grab ${referralRuns} Runs as a welcome bonus!%0A
%0A
🎉 Collect Runs, redeem them for awesome rewards, and be part of a fun cricket community.%0A
%0A
USE MY CODE : ${referralCode}%0A 
Or %0A 
LINK : ${global.config.share_url}/invite/${userId}/${referralCode}%0A
%0A
✨ Let’s score big together!!`
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    const forMobile = useMediaQuery('(max-width:769px)');

    const createReferral = async (secretCode) => {
        var getReferral = {
            method: 'GET',
            url: global.config.ROOTURL.prod + `/invite/inviteLink?referralCode=${secretCode}`,
            headers: {
                'Authorization': 'Bearer ' + accessToken,
            },
        };

        await axios(getReferral)
            .then((response) => {
                console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const generateReferralCode = async () => {
        let uniqueCode = false
        while (!uniqueCode) {
            const random3DigitNumber = Math.floor(100 + (Math.random() * 900))
            const generatedCode = (firstName.slice(0, 3).toUpperCase() + lastName.slice(0, 3).toUpperCase() + `${random3DigitNumber}`)

            await axios({
                method: "GET",
                url: global.config.ROOTURL.prod + `/invite/validateReferral/${generatedCode}`,
            }).then((response) => {
                if (response.data === 'Absent') {
                    setReferralCode(generatedCode)
                    uniqueCode = true
                    createReferral(generatedCode)
                }
            })
        }
    }


    const validateReferral = () => {
        axios({
            method: "GET",
            url: global.config.ROOTURL.prod + `/invite/checkUserSecretCode/${userId}`,
        })
            .then((response) => {
                if (response.data !== "Absent" && response.data?.secretCode && response.data.secretCode.length > 4) {
                    setReferralCode(response.data.secretCode)
                }
                else {
                    generateReferralCode()
                }
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    //clearStorage();
                    navigate('/login');
                }
            });
    }

    useEffect(() => {
        validateReferral()
    }, [])

    useEffect(async () => {
        await axios.get(global.config.ROOTURL.prod + `/defaultRun/getRunsByName/REFERRAL_RUN`, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        }).then((response) => {
            if (response.data) {
                setReferralRuns(response.data.run)
            }
        }).catch((err) => {
            console.log(err)
        })
    }, [])

    useEffect(()=>{
        return ()=>{
            console.log("REFERRAL AND EARN PAGE IS GETTING CLOSED")
        }
    },[])

    return (
        <Box sx={{ marginTop: forMobile ? 0 : "3em" }}>
            <Box sx={{ height: "9vh", display: "flex", alignItems: "center", margin: "0px 20px" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img src={BackArrow} alt="Back-Arrow" onClick={() => navigate(-1)} />
                    <Typography variant="h6" style={{ margin: "0px 12px" }}>Refer & Earn</Typography>
                </Box>
            </Box>
            <Box sx={{ width: "100%", height: forMobile ? "auto" : "58vh", backgroundImage: `url(${LottieBgImage})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                <Lottie
                    options={defaultOptions}
                    style={{ width: "100%", height: "100%" }}
                />
            </Box>
            <Box sx={{ width: "80%", display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", margin: "0 auto", paddingBottom: "8%" }}>
                <Typography style={{ display: 'flex', whiteSpace: "nowrap", flexDirection: 'row', margin: "20px 0px", fontSize: forMobile ? "16px" : "2.5rem", fontWeight: "600" }}>
                    <span style={{ color: 'rgba(136, 4, 153, 1)', marginRight: '10px' }}>Score Big</span>
                    <span style={{ color: 'rgba(12, 91, 160, 1)', marginRight: '10px' }}>with Champhunt's</span>
                    <span style={{ color: 'rgba(44, 146, 7, 1)' }}>Refer & Earn!</span>
                </Typography>
                <Typography variant="body1" style={{ width: forMobile ? "100%" : "80%", fontSize: forMobile ? "12px" : "18px", fontWeight: '500', color: 'rgba(149, 153, 157, 1)' }}>
                    Invite your friends to <span style={{ color: 'rgba(136, 4, 153, 1)' }}>Champhunt</span> and unlock exclusive rewards. Share your unique code and climb the
                    <span style={{ color: 'rgba(243, 113, 33, 1)' }}> leaderboard</span> with every successful referral.
                    <span style={{ color: 'rgba(44, 146, 7, 1)' }}>The more you refer, the more you earn!</span>
                </Typography>
                <Typography style={{ margin: forMobile ? "25px 0px" : "35px 0px", color: 'rgba(73, 77, 80, 1)', fontSize: forMobile ? "12px" : "20px", fontWeight: '500' }}>Tell your friend to enter your code during <span style={{ color: 'rgba(152, 122, 1, 1)' }}>SIGN UP</span></Typography>
                <Box sx={{
                    display: "flex", border: "1px solid rgba(202, 204, 206, 1)",
                    justifyContent: "space-between", width: forMobile ? "100%" : "25%",
                    alignItems: "center", borderRadius: "30px", padding: "3px 6px 3px 20px", marginBottom: '20px'
                }}>
                    {referralCode !== 'KS123CH' ?
                        <Typography sx={{ color: 'rgba(243, 113, 33, 1)', fontSize: forMobile ? '14px' : '18px', fontWeight: 500 }}>{referralCode}</Typography>
                        : <CircularProgress />
                    }
                    <Button style={{ background: "linear-gradient(180deg, rgba(22,101,167,1) 44%, rgba(4,62,129,1) 100%)", color: "white", borderRadius: "30px", margin: "4px  0px", fontSize: forMobile ? '12px' : '16px' }}
                        onClick={() => {
                            navigator.clipboard.writeText(referralCode)
                            setCopyMsg("Code Copied")
                            setCopyStatus(true)
                            setTimeout(() => {
                                setCopyStatus(false)
                            }, 1000)
                        }}
                    >Copy Code</Button>
                </Box>
                <Typography sx={{ marginBottom: '5px' }}>or use:</Typography>

                <Box sx={{ display: 'flex',whiteSpace:"nowrap", width: forMobile ? '100%' : '30%', justifyContent: "center" }}>
                    <a
                        style={{
                            display: 'flex',
                            textDecoration: "none",
                            alignItems: 'center',
                            color: 'gray',
                            margin: "4px 8px",
                            cursor: 'pointer',
                            fontSize: forMobile ? "12px" : "18px",
                        }}
                        onClick={() => {
                            navigator.clipboard.writeText(`${global.config.share_url}/invite/${userId}/${referralCode}`)
                            setCopyMsg("Link Copied")
                            setCopyStatus(true)
                            setTimeout(() => {
                                setCopyStatus(false)
                            }, 1000)
                        }}
                    ><img src={InviteLink} style={{ width: forMobile ? '20px' : '40px', height: 'auto', marginRight: "5px" }} alt="Invite Link" /> Copy link</a>
                    <a href={whatsappUrl} data-action="share/whatsapp/share" target="_blank"
                        style={{
                            display: 'flex',
                            textDecoration: "none",
                            alignItems: 'center',
                            color: 'gray',
                            margin: "4px 8px",
                            cursor: 'pointer',
                            fontSize: forMobile ? "12px" : "18px",
                        }}
                    >
                        <img src={WhatsApp} style={{ width: forMobile ? '20px' : '40px', height: 'auto' }} alt="WhatsApp img" />
                        Share with whatsapp
                    </a>
                </Box>

                <Box sx={{position:"relative"}}>
                {copyStatus && 
                    <Typography
                        style={{ position: 'absolute', left: "-60px",fontSize:"14px",width:"118px", border: "1px solid rgba(12, 91, 160, 1)", padding: "8px", margin: "10px", borderRadius: "10px", backgroundColor:"rgba(12, 91, 160, 1)",color:'white' }}
                    >
                        {copyMsg}
                    </Typography>
                }
            </Box>
            </Box>
        </Box>
    )
}

export default ReferEarn;