import React from 'react';
import { Box } from '@mui/material';

import DealsCarousel from './dealsCarousel';
import AllDeals from './alldeals';

const NewDealsPage = () => {
    return (
        <div style={{backgroundColor:"rgba(255, 255, 255, 1)"}}>
            <Box>
                < DealsCarousel />
            </Box>

            <Box>
                < AllDeals />
            </Box>
        </div>
    )
}

export default NewDealsPage;