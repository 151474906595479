
import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogActions, Typography, IconButton, Button, Box, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getStorageItem } from '../../utils/sessionStorage';
import dealExpired from "../../assets/images/deals/loan modal.json";
import axios from "axios";
import Lottie from "react-lottie";


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    boxShadow: 24,
    p: 4,
};
const LoanModelPopUp = ({ openModel, handleCloseModel }) => {
    // const accessToken = getStorageItem("token");
    // const userId = getStorageItem("user_id");
    // const email = getStorageItem("user_email");
    // const name = getStorageItem("full_name");

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: dealExpired || null,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    // const handleConfirm = () => {
    //     if (!userId || !accessToken) return;
    //     const getUserInfo = {
    //         method: "POST",
    //         url: global.config.ROOTURL.prod + "/auth/profile/",
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //             Authorization: "Bearer " + accessToken,
    //         },
    //         data: {
    //             userId: userId,
    //         },
    //     };
    //     axios(getUserInfo)
    //         .then((response) => {
    //             handleReddemLoanDeal(name, email, response?.data?.mobileNo, LoanAmount)
    //             handleClose();

    //         }
    //         )
    //         .catch((error) => {
    //             console.log(error);
    //         })

    // }

    // useEffect(() => {
    // }, [props])

    return (
        <div>
            <Dialog
                open={openModel}
                onClose={handleCloseModel}
                PaperProps={{
                    style: { borderRadius: 12, padding: "20px", textAlign: "center" },
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box position="absolute" top="10px" right="10px">
                    <IconButton onClick={handleCloseModel} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <Lottie
                        options={defaultOptions}
                        style={{ width: "30%" }}
                    />
                    <Typography
                        sx={{
                            color: "rgba(49, 51, 53, 1)",
                            fontSize: "32px",
                            fontFamily: "Helvetica",
                            fontWeight: "700",
                            paddingBottom: "12px"
                        }}
                    >
                        Borrowing Runs
                    </Typography>

                    <Typography
                        sx={{
                            color: "rgba(98, 102, 106, 1)",
                            fontSize: "20px",
                            fontFamily: "Helvetica",
                            fontWeight: "400",
                        }}
                    >
                        You also have the option to borrow runs in order to redeem a deal. This deal will be reserved, and once you've earned enough runs to cover the borrowed amount, we will deliver the deal to you
                    </Typography>
                </DialogContent>

                <DialogActions sx={{ padding: "0px !important" }}>
                    <Button
                        variant="contained"
                        onClick={handleCloseModel}
                        style={{
                            textTransform: "none",
                            margin: "0 auto",
                            backgroundColor: "rgba(12, 91, 160, 1)",
                            color: "rgba(255, 255, 255, 1)",
                            boxShadow:"none",
                            fontSize: "16px",
                            fontFamily: "Helvetica",
                            fontWeight: "600",
                            width:"70%"
                        }}
                    >
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
};

export default LoanModelPopUp;
