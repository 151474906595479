import "./postSomething.scss";

import shareImgIcon from "../../../assets/images/Pitch/Feed/shareImg.svg";
import sharePollIcon from "../../../assets/images/Pitch/Feed/sharePoll.svg";
import shareEmojiIcon from "../../../assets/images/Pitch/Feed/shareEmoji.svg";
import shareVideoIcon from "../../../assets/images/Pitch/Feed/shareVideo.svg";

import { Typography, Button, Modal, Box, Grid, Avatar } from "@mui/material";
import { HiOutlineArrowNarrowLeft } from "react-icons/hi";
import { Select, MenuItem } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { GrAddCircle } from "react-icons/gr";
import Gallery from "../../../commons/components/gallery";
import "../../../commons/components/post-modal/index.scss";
import defaultAvatar from "../../../assets/images/profile/default_avatar.png";
import { useMediaQuery } from "@mui/material";
import { clearStorage, getStorageItem } from "../../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import { MentionsInput, Mention } from "react-mentions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmojiPicker from "emoji-picker-react";
import { PathAction } from "../../../redux/reducers/globalPath";
import { useDispatch, useSelector } from "react-redux";

const _ = require("lodash");

const stylePollMobile = {
  color: "white",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#FFFFFF",
  border: "2px solid #F1F1F5",
  boxShadow: 24,
  minHeight: "100%",
  width: "100%",
  p: 0,
};
const stylePoll = {
  color: "white",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "625px",
  minHeight: "500px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 0,
};

const MentionStyle = {
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
  },

  "&multiLine": {
    input: {
      minHeight: "50px",
      padding: 9,
      border: "1px solid silver",
      "&focused": {
        border: "1px solid black",
      },
    },
  },

  suggestions: {
    marginTop: 50,
    list: {
      fontSize: 14,
      backgroundColor: "#FFFFFF",
      boxShadow: "0px 5px 15px rgba(68, 68, 79, 0.1)",
      borderRadius: 8,
      minWidth: 400,
    },
    item: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: 14,
      lineHeight: 2,
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#d2effa",
      },
    },
  },
};

const PostSomething = () => {
  const [count, setCount] = useState(0);
  const [showEmojis, setShowEmojis] = useState(false);
  const reload = useSelector((state) => state.path.shouldLoad);
  const dispatch = useDispatch();
  const handleEmojiPicker = () => {
    setShowEmojis(!showEmojis);
  };

  const [description, setDescription] = useState({
    hashtag: [],
    mentions: [],
    text: [],
  });
  const [pollDescription, setPollDescription] = useState({
    hashtag: [],
    text: [],
  });
  const [PollContent, setPollContent] = useState({
    message: "",
    messageValue: "",
    hashtags: [],
  });
  const [postMessageWithoutHtml, setPostMessageWithoutHtml] = useState("");
  const [pollQuestionWithoutHtml, setPollQuestionWithoutHtml] = useState("");
  const [question, setQuestion] = useState("");
  const [choice1, setChoice1] = useState("");
  const [choice2, setChoice2] = useState("");
  const [choice3, setChoice3] = useState("");
  const [choice4, setChoice4] = useState("");
  const [choice5, setChoice5] = useState("");
  const [noOfActiveDays, setNoOfActiveDays] = useState("");
  const [choice, setChoice] = useState([]);
  const emojiRef = useRef();

  const [images, setImages] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isPosting, setIsPosting] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    if (!formContent.messageValue) return; // Prevent posting if messageValue is empty
    setIsPosting(true);
    handlePostSubmit(); // Call the submit handler
    setTimeout(() => setIsPosting(false), 2000); // Reset to default after an operation
  };

  const resizeImage = (image, aspectRatio) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const width = image.width;
      const height = image.height;
      let newWidth, newHeight;

      if (aspectRatio === "16:9") {
        newWidth = width;
        newHeight = (width / 16) * 9;
      } else if (aspectRatio === "5:4") {
        newWidth = width;
        newHeight = (width / 5) * 4;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;
      ctx.drawImage(image, 0, 0, newWidth, newHeight);
      resolve(canvas.toDataURL());
    });
  };

  const handleFilesChange = (event) => {
    setFiles([]);
    const selectedFiles = event.target.files;
    const tempImages = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const reader = new FileReader();
      reader.onload = async (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = async () => {
          const resizedImage = await resizeImage(img, "16:9"); // Change '16:9' to '5:4' if needed
          tempImages.push(resizedImage);
          if (tempImages.length === selectedFiles.length) {
            setImages(tempImages);
            setFiles(selectedFiles);
          }
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const [imageIsUploading, setImageIsUploading] = useState(false);

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("uploader", file);
    const response = await axios.post(
      `${global.config.ROOTURL.prod}/upload-file`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
        },
      }
    );
    if (response.data) {
      return response.data[0].location;
    } else {
      console.log("error occured:");
      throw new Error(`Failed to upload image ${file}`);
    }
  };

  const uploadImages = async () => {
    const promises = [];
    if (files.length === 0) {
      toast.error("At least one product photo is needed");
      setLoading(false);
      return;
    }
    for (let i = 0; i < files.length; i++) {
      if (files[i].size / 1024 > 5120) {
        toast.error(`File ${files[i]} size exceeded 5 mb`);
        setLoading(false);
        return;
      }
      promises.push(uploadImage(files[i]));
    }

    try {
      const urls = await Promise.all(promises);
      let optionData = {};
      optionData.postImageURLs = urls;
      if (
        formContent.message.trim() !== "" ||
        optionData.postImageURLs ||
        formContent.video
      ) {
        optionData.postMessage = formContent.message.trim();
        if (formContent.video) {
          optionData.video = formContent.video;
        }
        optionData.mentionedIds = formContent.mentions;
        savePostText(optionData);
      }
    } catch (error) {
      toast.error(error);
      setLoading(false);
    } finally {
      setImageIsUploading(false);
    }
  };

  useEffect(() => {
    let handler = (e) => {
      if (!emojiRef.current || !emojiRef.current.contains(e.target)) {
        setShowEmojis(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const addRow = () => {
    if (count <= 2 && choice.length <= 3) {
      setChoice([...choice, { choice: "" }]);
      setCount(count + 1);
      console.log(count);
    }
  };
  const removeRow = (i) => {
    const newForm = [...choice];
    newForm.splice(i, 1);
    setChoice(newForm);
    setCount(count - 1);
  };
  const resetChoice = (choiceNo) => {
    if (choiceNo === 3) {
      setChoice3("");
    } else if (choiceNo === 4) {
      setChoice4("");
    } else if (choiceNo === 5) {
      setChoice5("");
    } else {
      console.log("nothing is reset");
    }
  };
  const navigate = useNavigate();
  const mobileView = useMediaQuery("(max-width:900px)");
  // const { open, handleClose, handleOpen } = props;
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const userName = getStorageItem("user_name");
  const avatar = getStorageItem("avatar");
  const [openPoll, setOpenPoll] = useState(false);
  const [maxLength, setMaxLength] = useState(20);
  const handleOpenPoll = () => setOpenPoll(true);
  const handleClosePoll = () => setOpenPoll(false);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    avatar: "",
  });
  const [status, setStatus] = useState(true);

  const [formContent, setFormContent] = useState({
    message: "",
    images: [],
    video: "",
    messageValue: "",
    hashtags: [],
    mentions: [],
    postImageURLs: [],
  });

  const clearMedia = () => {
    setFormContent((prevState) => ({
      ...prevState,
      images: [],
      video: "",
    }));
    setImages([]);
  };

  // Create PostHashTag
  const createPostHashTag = async (_id, hashTagText, pitchId) => {
    if (_id && hashTagText && pitchId) {
      const data = {
        hashTagId: _id,
        hashTagText: hashTagText,
        pitchId: pitchId,
      };
      const options = {
        method: "POST",
        url:
          global.config.ROOTURL.prod +
          "/hash-profile-tag/postHashTag/createPostHashTag",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        data: data,
      };
      axios(options)
        .then(({ data }) => {
          console.log(data);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            console.log(error);
          }
        });
    }
  };
  // Create PollHasTag
  const createPollHashTag = async (_id, hashTagText, pollId) => {
    if (_id && hashTagText && pollId) {
      const data = {
        hashTagId: _id,
        hashTagText: hashTagText,
        pollId: pollId,
      };
      const options = {
        method: "POST",
        url:
          global.config.ROOTURL.prod +
          "/hash-profile-tag/pollHashTag/createPollHashTag",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        data: data,
      };
      axios(options)
        .then(({ data }) => {
          console.log(data);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            console.log(error);
          }
        });
    }
  };

  // Create Hashtags
  const createHashTag = async (hashtags, pitchId, isPoll) => {
    if (hashtags && hashtags.length) {
      for (let index = 0; index < hashtags.length; index += 1) {
        const element = hashtags[index];
        const hashTagText = element.substring(1);
        const createdUserId = userId;
        const createdUserName = `${userInfo.firstName} ${userInfo.lastName}`;
        const data = {
          hashTagText: hashTagText,
          createdUserId: createdUserId,
          createdUserName: createdUserName,
        };
        const options = {
          method: "POST",
          url: global.config.ROOTURL.prod + "/hash-profile-tag/createHashTag",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
          data: data,
        };
        axios(options)
          .then(({ data }) => {
            console.log(data);
            console.log(data, pitchId, isPoll);
            if (isPoll) {
              // pitchId ===pollid
              createPollHashTag(data._id, data.hashTagText, pitchId);
            } else {
              createPostHashTag(data._id, data.hashTagText, pitchId);
            }
          })
          .catch((error) => {
            if (error?.response?.status == 401) {
              console.log(error);
            }
          });
      }
    }
  };

  const [mentionedUsers, setMentionedUsers] = useState([]);

  // Create PostTags
  const createProfileTags = async (mentions, pitchDescription, pitchId) => {
    if (mentions && mentions.length) {
      const users = [];
      const mentionedUserDetails = [];
      setMentionedUsers([]);

      mentions.forEach((id) => {
        const data = {
          userId: id,
        };
        const options = {
          method: "POST",
          url: global.config.ROOTURL.prod + "/auth/profile",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
          data: data,
        };
        axios(options)
          .then(({ data }) => {
            let creationOptions = {
              method: "POST",
              url:
                global.config.ROOTURL.prod +
                `/hash-profile-tag/profileTag/createProfileTag`,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
              },
              data: {
                createdUserId: userId,
                createdUserName: userName,
                createdUserProfilePhoto: avatar,
                profileTagedUserId: data._id,
                profileTagedUserName: data.firstName + " " + data.lastName,
                profileTagedUserProfilePhoto: data.profilePhoto,
                pitchId: pitchId,
                postMessage: pitchDescription,
              },
            };
            axios(creationOptions)
              .then((response) => {
                console.log("hhf");
                console.log(response);
              })
              .catch((error) => {
                console.log("Creation error" + error);
                if (error?.response?.status == 401) {
                  console.log(error);
                }
              });
          })
          .catch((error) => {
            if (error?.response?.status == 401) {
              console.log(error);
            }
          });
      });
    }
  };

  //poll
  const handleResetPoll = () => {
    setPollContent({
      message: "",
      messageValue: "",
      hashtags: [],
    });
    setPollDescription({
      hashtag: [],
      text: [],
    });
    setChoice1("");
    setChoice2("");
    setChoice3("");
    setChoice4("");
    setChoice5("");
    setQuestion("");
    setNoOfActiveDays("");
    setChoice([]);
    setCount(0);
  };
  const handlePollSubmit = () => {
    // console.log(choice1Ref,choice2Ref,choice3Ref,choice4Ref)
    if (PollContent.message === "") {
      toast.error("Question incomplete");
      return;
    } else if (choice1 === "") {
      toast.error("Choice one incomplete");
      return;
    } else if (choice2 === "") {
      toast.error("Choice two incomplete");
      return;
    } else if (noOfActiveDays === 0 || noOfActiveDays === "") {
      toast.error("Duration incomplete");
      return;
    }
    const options = [];
    options.push(choice1);
    options.push(choice2);
    if (choice3) {
      options.push(choice3);
    }
    if (choice4) {
      options.push(choice4);
    }
    if (choice5) {
      options.push(choice5);
    }
    const questionData = {
      description: pollDescription,
      PollMessageWithoutHtml: pollQuestionWithoutHtml.trim(),
    };
    const pollData = {
      question: PollContent.message.trim(),
      questionHastag: questionData,
      options,
      noOfActiveDay: noOfActiveDays,
    };
    // Axios Post request
    const createNewPoll = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/poll/createPoll",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: pollData,
    };
    axios(createNewPoll)
      .then((response) => {
        if (response?.status === 200 && response) {
          dispatch(PathAction.handleCreatedObject({ payload: response.data }));
          toast.success("New Poll created successfuly");
          console.log(response);
          createHashTag(PollContent.hashtags, response.data._id, true);
          handleResetPoll();
          handleClosePoll();
          handleModalClose();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          error.message
            ? toast.error("Error: " + error.message)
            : toast.error("Error: " + error);
        }
        error.message
          ? toast.error("Error: " + error.message)
          : toast.error("Error: " + error);
      });
  };
  //poll end

  function hasYoutubeLink(description) {
    const youtubeRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/[a-zA-Z0-9_\-]+/g;
    return youtubeRegex.test(description);
  }

  const [addMedia, setAddMedia] = useState(true);

  const handleEmojiClick = (emoji) => {
    const messageValue = formContent.messageValue || "";
    const newMessageValue = messageValue + emoji + " ";
    setFormContent({
      ...formContent,
      message: newMessageValue,
      messageValue: newMessageValue,
    });
    setPostMessageWithoutHtml(newMessageValue);
    setShowEmojis(false);
  };

  const handleInputChange = (event, newValue, newPlainTextValue, mentions) => {
    setPostMessageWithoutHtml(newPlainTextValue);
    let hashtagRegex = /#[^\s#]*/;
    let mentionRegex = /@\w*\s\w*/;
    const hTag = [];
    const mUser = [];
    const plainTxt = [];
    let splitted = "";
    var one_or_two = new RegExp(
      "(" + hashtagRegex.source + "|" + mentionRegex.source + ")"
    );
    console.log("oneTwo", one_or_two);
    splitted = newPlainTextValue?.split(one_or_two);
    splitted?.map((txt, i) => {
      if (txt.match(hashtagRegex)) {
        hTag.push({ index: i, text: txt });
      } else if (txt.match(mentionRegex)) {
        const uId = mentions
          .filter((data) => data.display.includes(txt))
          .map((data) => data.id);
        mUser.push({ index: i, text: txt, userId: uId.toString() });
      } else {
        plainTxt.push({ index: i, text: txt });
      }
      return true;
    });
    setDescription({ hashtag: hTag, mentions: mUser, text: plainTxt });
    if (hasYoutubeLink(newPlainTextValue)) {
      setFormContent({
        ...formContent,
        images: [],
        video: "",
      });
      setAddMedia(false);
    } else {
      setAddMedia(true);
      setFormContent({ ...formContent });
    }
    const mentionedIds = mentions
      ? mentions
        .filter((data) => data.display.includes("@"))
        .map((data) => data.id)
      : [];
    const hashtags = newPlainTextValue
      ? newPlainTextValue
        .match(/#[^\s#]*/gim)
        ?.filter((x, i, a) => a.indexOf(x) === i)
      : [];
    const { name, value } = event.target || {};
    if (name === "image") {
      if (event.target.files[0].type === "video/mp4") {
        const videoObj = event.target.files[0];
        var video = document.createElement("video");
        video.preload = "metadata";
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          if (video.duration > 0 && video.duration <= 90) {
            setFormContent({ ...formContent, video: videoObj });
            setStatus(false);
          } else {
            setStatus(true);
          }
        };
        video.src = URL.createObjectURL(videoObj);
      }
    } else {
      setFormContent({
        ...formContent,
        [name]: value,
        message: newValue,
        messageValue: value,
        mentions: mentionedIds,
        hashtags: hashtags,
      });
    }
    setStatus(
      formContent.message === "" &&
      formContent.images === [] &&
      formContent.video === ""
    );
  };

  const handlePollInputChange = (event, newValue, newPlainTextValue) => {
    console.log(event, newValue, newPlainTextValue);
    setPollQuestionWithoutHtml(newPlainTextValue);
    let hashtagRegex = /#[^\s#]*/;
    const hTag = [];
    const plainTxt = [];
    let splitted = "";
    var one_or_two = new RegExp("(" + hashtagRegex.source + ")");
    splitted = newPlainTextValue?.split(one_or_two);
    splitted?.map((txt, i) => {
      if (txt.match(hashtagRegex)) {
        hTag.push({ index: i, text: txt });
      } else {
        plainTxt.push({ index: i, text: txt });
      }
      return true;
    });
    setPollDescription({ hashtag: hTag, text: plainTxt });

    const hashtags = newPlainTextValue
      ? newPlainTextValue
        .match(/#[^\s#]*/gim)
        ?.filter((x, i, a) => a.indexOf(x) === i)
      : [];

    const { value } = event.target || {};

    setPollContent({
      message: newValue,
      messageValue: value,
      hashtags: hashtags,
    });
  };
  const formData = new FormData();

  const savePostText = (optionData) => {
    optionData.description = description;
    optionData.postMessageWithoutHtml = postMessageWithoutHtml;
    let submitPostOptions = {
      method: "post",
      url: global.config.ROOTURL.prod + "/pitch/create",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      data: optionData,
    };
    if (
      (optionData.postMessage && optionData.postMessage.trim() !== "") ||
      optionData.postImageURLs ||
      optionData.video
    ) {
      axios(submitPostOptions)
        .then(({ data }) => {
          dispatch(PathAction.handleCreatedObject({ payload: data }));

          createHashTag(formContent.hashtags, data._id);
          createProfileTags(
            formContent.mentions,
            optionData.postMessage,
            data._id
          );
          handleModalClose();
          toast.success("Posted successfully");
          return data;
        })
        .catch((error) => {
          handleModalClose();
          if (error?.response?.status === 401) {
            console.log(error);
          }
        });
    }
  };

  const handlePostSubmit = _.throttle(async (e) => {
    e.preventDefault();
    setButtonDisabled(true);
    if (images.length > 0) {
      await uploadImages();
    } else {
      if (
        formContent.message.trim() !== "" ||
        formContent.postImageURLs ||
        formContent.video
      ) {
        let optionData = {};
        optionData.postMessage = formContent.message.trim();
        if (formContent.video) {
          // Upload Video
          const formData = new FormData();
          formData.append("uploader", formContent.video);
          await axios
            .post(`${global.config.ROOTURL.prod}/upload-file`, formData, {
              headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Headers": "*",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "*",
              },
            })
            .then((response) => {
              if (response.data) {
                optionData.video = response.data[0].location;
              } else {
                console.log("error occured:");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
        optionData.mentionedIds = formContent.mentions;
        savePostText(optionData);
      }
    }
    setButtonDisabled(false);
  }, 10000);

  const fetchUserInfo = () => {
    if (userId) {
      const getUserInfo = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/auth/profile/",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        data: {
          userId: userId,
        },
      };
      axios(getUserInfo)
        .then((response) =>
          setUserInfo({
            firstName: response.data.firstName ? response.data.firstName : "",
            lastName: response.data.lastName ? response.data.lastName : "",
            avatar: response.data.profilePhoto
              ? response.data.profilePhoto
              : defaultAvatar,
          })
        )
        .catch((error) => {
          if (error?.response?.status === 401) {
            clearStorage();
            navigate("/login");
          }
        });
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const handleModalClose = () => {
    setButtonDisabled(false);
    dispatch(PathAction.handleReload({ payload: !reload }));
    // handleClose();
    setImages([]);
    setFormContent({
      message: "",
      images: [],
      postImageURLs: [],
      video: "",
    });
    setAddMedia(true);
  };

  const getUserMentions = async (str, callback) => {
    if (!str) return;
    const nameSearch = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/mention-name-search",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        searchText: str,
        pageNumber: 1,
        pagePerSize: 5,
      },
    };
    axios(nameSearch)
      .then(({ data }) => {
        return data.map((data) => {
          return {
            id: data._id,
            display: "@" + data.firstName + " " + data.lastName,
            photo: data.profilePhoto,
            email: data.email,
            followers: 450,
            runs: 13450,
            followersCount: data.followersCount,
            totalRun: data.totalRun,
            following: data.youFollow,
          };
        });
      })
      .then(callback);
  };

  const renderSuggestions = (entry) => {
    return (
      <div className="suggestions1">
        <div className="suggestions1__image">
          <Avatar
            sx={{ width: "30px", height: "30px", marginLeft: "5px" }}
            src={
              entry.photo
                ? entry.photo
                : "https://www.umass.edu/philosophy/sites/default/files/store/img/cfm/user_23.png"
            }
            alt=""
          />
          {entry.following && (
            <div className="following__mark">
              <svg
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.4362 1.11473C4.18418 1.11473 3.93462 1.16437 3.70178 1.26081C3.46894 1.35726 3.25738 1.49862 3.07917 1.67683C2.90096 1.85503 2.7596 2.0666 2.66316 2.29944C2.56671 2.53228 2.51707 2.78183 2.51707 3.03385C2.51707 3.28588 2.56671 3.53543 2.66316 3.76827C2.7596 4.00111 2.90096 4.21267 3.07917 4.39088C3.25738 4.56909 3.46894 4.71045 3.70178 4.8069C3.93462 4.90334 4.18418 4.95298 4.4362 4.95298C4.94518 4.95298 5.43332 4.75079 5.79323 4.39088C6.15313 4.03098 6.35533 3.54284 6.35533 3.03385C6.35533 2.52487 6.15313 2.03673 5.79323 1.67683C5.43332 1.31692 4.94518 1.11473 4.4362 1.11473ZM1.96875 3.03385C1.96875 2.37945 2.22871 1.75184 2.69145 1.28911C3.15419 0.826369 3.78179 0.566406 4.4362 0.566406C5.09061 0.566406 5.71821 0.826369 6.18095 1.28911C6.64368 1.75184 6.90365 2.37945 6.90365 3.03385C6.90365 3.68826 6.64368 4.31587 6.18095 4.7786C5.71821 5.24134 5.09061 5.5013 4.4362 5.5013C3.78179 5.5013 3.15419 5.24134 2.69145 4.7786C2.22871 4.31587 1.96875 3.68826 1.96875 3.03385Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.87207 8.51673C0.87207 7.57147 1.24757 6.66493 1.91597 5.99653C2.58437 5.32814 3.49091 4.95264 4.43616 4.95264C5.38142 4.95264 6.28796 5.32814 6.95636 5.99653C7.62475 6.66493 8.00026 7.57147 8.00026 8.51673V8.67026C8.00026 8.94607 7.80725 9.18404 7.53747 9.24051L6.57736 9.44284C5.61396 9.64572 4.62862 9.70988 3.65151 9.63585C3.57979 9.62933 3.51352 9.59484 3.46702 9.53986C3.42052 9.48487 3.39752 9.41379 3.40299 9.34198C3.40846 9.27017 3.44197 9.2034 3.49627 9.1561C3.55057 9.1088 3.62131 9.08476 3.69319 9.08918C4.61875 9.15936 5.552 9.0985 6.46441 8.90604L7.42452 8.70425C7.43226 8.70273 7.43923 8.69855 7.44423 8.69245C7.44923 8.68635 7.45196 8.6787 7.45193 8.67081V8.51673C7.45193 7.7169 7.1342 6.94982 6.56863 6.38426C6.00307 5.81869 5.23599 5.50096 4.43616 5.50096C3.63633 5.50096 2.86926 5.81869 2.30369 6.38426C1.73812 6.94982 1.42039 7.7169 1.42039 8.51673V8.67026C1.42039 8.68671 1.43136 8.70042 1.44781 8.70425L2.40792 8.90604C2.44317 8.91345 2.47661 8.92774 2.50633 8.94808C2.53606 8.96842 2.56149 8.99442 2.58117 9.02459C2.60085 9.05475 2.61439 9.0885 2.62103 9.12391C2.62766 9.15931 2.62726 9.19567 2.61985 9.23092C2.61243 9.26616 2.59814 9.29961 2.5778 9.32933C2.55746 9.35906 2.53147 9.38449 2.5013 9.40417C2.47113 9.42385 2.43738 9.43739 2.40198 9.44403C2.36658 9.45066 2.33021 9.45026 2.29497 9.44284L1.33485 9.24051C1.20396 9.21306 1.08651 9.14139 1.00223 9.03754C0.917954 8.93369 0.871993 8.804 0.87207 8.67026V8.51673Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.5"
                />
              </svg>
            </div>
          )}
        </div>
        <div className="suggestions1__fullname">
          <h2>{entry.display}</h2>
        </div>
        {/* <div className="suggestions1__email">
            <p>{entry.email}</p>
          </div> */}
        <div className="suggestions1__followers">
          <svg
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.78301 0.735851C4.44479 0.735851 4.10989 0.802467 3.79741 0.931897C3.48494 1.06133 3.20103 1.25104 2.96187 1.49019C2.72271 1.72935 2.53301 2.01326 2.40358 2.32574C2.27415 2.63821 2.20753 2.97311 2.20753 3.31133C2.20753 3.64954 2.27415 3.98445 2.40358 4.29692C2.53301 4.60939 2.72271 4.89331 2.96187 5.13246C3.20103 5.37162 3.48494 5.56133 3.79741 5.69076C4.10989 5.82019 4.44479 5.8868 4.78301 5.8868C5.46607 5.8868 6.12115 5.61546 6.60414 5.13246C7.08714 4.64947 7.35848 3.99439 7.35848 3.31133C7.35848 2.62827 7.08714 1.97319 6.60414 1.49019C6.12115 1.00719 5.46607 0.735851 4.78301 0.735851ZM1.47168 3.31133C1.47168 2.43311 1.82055 1.59086 2.44154 0.969865C3.06254 0.348871 3.90479 0 4.78301 0C5.66123 0 6.50347 0.348871 7.12447 0.969865C7.74546 1.59086 8.09433 2.43311 8.09433 3.31133C8.09433 4.18955 7.74546 5.03179 7.12447 5.65279C6.50347 6.27378 5.66123 6.62265 4.78301 6.62265C3.90479 6.62265 3.06254 6.27378 2.44154 5.65279C1.82055 5.03179 1.47168 4.18955 1.47168 3.31133Z"
              fill="#718193"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.31369e-07 10.6697C1.31369e-07 9.40121 0.503925 8.18463 1.40092 7.28763C2.29791 6.39064 3.51449 5.88672 4.78303 5.88672C6.05157 5.88672 7.26815 6.39064 8.16514 7.28763C9.06213 8.18463 9.56606 9.40121 9.56606 10.6697V10.8758C9.56606 11.2459 9.30704 11.5653 8.945 11.6411L7.65652 11.9126C6.36363 12.1849 5.04131 12.271 3.73003 12.1716C3.63378 12.1629 3.54484 12.1166 3.48243 12.0428C3.42003 11.969 3.38916 11.8736 3.39651 11.7772C3.40385 11.6809 3.44882 11.5913 3.52169 11.5278C3.59456 11.4643 3.68949 11.432 3.78595 11.438C5.02807 11.5322 6.28048 11.4505 7.50494 11.1922L8.79341 10.9214C8.8038 10.9194 8.81316 10.9138 8.81987 10.9056C8.82658 10.8974 8.83024 10.8871 8.83021 10.8765V10.6697C8.83021 9.59637 8.40381 8.56695 7.64482 7.80796C6.88582 7.04897 5.85641 6.62257 4.78303 6.62257C3.70965 6.62257 2.68023 7.04897 1.92124 7.80796C1.16225 8.56695 0.735851 9.59637 0.735851 10.6697V10.8758C0.735851 10.8979 0.750568 10.9163 0.772643 10.9214L2.06112 11.1922C2.10842 11.2022 2.1533 11.2213 2.19319 11.2486C2.23308 11.2759 2.26721 11.3108 2.29362 11.3513C2.32003 11.3918 2.3382 11.4371 2.34711 11.4846C2.35602 11.5321 2.35548 11.5809 2.34552 11.6282C2.33557 11.6755 2.3164 11.7204 2.2891 11.7603C2.2618 11.8002 2.22692 11.8343 2.18643 11.8607C2.14594 11.8871 2.10065 11.9053 2.05314 11.9142C2.00563 11.9231 1.95683 11.9226 1.90953 11.9126L0.621058 11.6411C0.445399 11.6042 0.287775 11.508 0.174675 11.3687C0.0615764 11.2293 -0.000103895 11.0553 1.31369e-07 10.8758V10.6697Z"
              fill="#718193"
            />
          </svg>
          <p>{entry.followersCount}</p>
        </div>
      </div>
    );
  };

  const getHashtags = async (str, callback) => {
    if (!str) return;
    const nameSearch = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/hash-profile-tag/searchHashTag",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        searchText: str,
        pageNumber: 1,
        pagePerSize: 5,
      },
    };
    axios(nameSearch)
      .then(({ data }) => {
        return data.map((data) => {
          return {
            id: data._id,
            display: "#" + data.hashTagText,
          };
        });
      })
      .then(callback);
  };
  const maxPostLength = 3000;
  const [postContent, setPostContent] = useState("");
  const [charCount, setCharCount] = useState(0);

  // Handler for updating post content and character count
  const handlePostContentChange = (event, newValue, newPlainTextValue) => {
    setPostContent(newPlainTextValue);
    setCharCount(newPlainTextValue.length);
  };

  // useEffect(() => {
  //     if (images.length > 0 || formContent.video) {
  //         setOpenPreview(true)
  //     }
  // }, [images, formContent.video])

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          height: "auto",
          background: "#FFFFFF",
          marginTop: "1px",
          padding: "10px 0px 0px 10px",
          margin: "10px 0px",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "10px",
          }}
        >
          <Avatar src={avatar || null} alt="" />
          <Box sx={{ width: mobileView ? "95%" : "100%", flexGrow: 1 }}>
            <MentionsInput
              value={formContent.messageValue || ""}
              name="message"
              placeholder="What's happening?"
              maxLength={maxPostLength}
              className="mentions-input"
              onChange={(event, newValue, newPlainTextValue, mentions) => {
                handleInputChange(event, newValue, newPlainTextValue, mentions);
                setCharCount(newPlainTextValue.length);
              }}
              allowSpaceInQuery={true}
            >
              <Mention
                trigger="@"
                renderSuggestion={renderSuggestions}
                appendSpaceOnAdd={true}
                data={getUserMentions}
                markup="<a class='mentioned' href='/profile/__id__'>__display__</a>"
                sx={{
                  color: "#1DA1F2",
                  fontWeight: 500,
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              />
              <Mention
                trigger="#"
                appendSpaceOnAdd={true}
                allowSpaceInQuery={false}
                data={getHashtags}
                markup="<a class='hashtagged' href='/hashtags?id=__id__'>__display__</a>"
                sx={{
                  color: "#1DA1F2",
                  fontWeight: 500,
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              />
            </MentionsInput>
          </Box>
        </Box>
        <Box sx={{ margin: "10px 15px 0px 7px" }}>
          {images.length > 0 ? <Gallery images={images} /> : <div></div>}
          {formContent.video && (
            <video
              style={{ widows: "100%" }}
              className="attch responsive"
              controls
            >
              <source src={URL.createObjectURL(formContent.video)} />
              Your browser does not support HTML5 video.
            </video>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              position: "relative",
            }}
          >
            <Box mt={0.9}>
              <label htmlFor="post--gallery">
                <img src={shareImgIcon} alt="" style={{ cursor: "pointer" }} />
                <input
                  type="file"
                  id="post--gallery"
                  hidden
                  name="image"
                  accept="image/*"
                  onChange={handleFilesChange}
                  multiple
                />
              </label>
            </Box>
            <img
              src={sharePollIcon}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => handleOpenPoll()}
            />
            <img
              src={shareEmojiIcon}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={handleEmojiPicker}
            />
            <Box mt={0.9}>
              <label htmlFor="post--video">
                <img
                  src={shareVideoIcon}
                  alt=""
                  style={{ cursor: "pointer" }}
                />
                <input
                  type="file"
                  hidden
                  name="image"
                  id="post--video"
                  accept="video/*"
                  onChange={handleInputChange}
                />
              </label>
            </Box>
            {images.length > 0 || formContent.video ? (
              <IconButton
                onClick={clearMedia}
                sx={{ position: "absolute", right: "-130px" }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </Box>
          <Modal
            open={openPoll}
            onClose={handleClosePoll}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={mobileView ? stylePollMobile : stylePoll}>
              <Box className="post__polls--header">
                <div
                  className="post__polls--back_button"
                  onClick={handleClosePoll}
                >
                  <IconButton
                    className="close__icon"
                    aria-label="close"
                    onClick={handleResetPoll}
                    sx={{
                      color: "black",
                    }}
                  >
                    <HiOutlineArrowNarrowLeft style={{ color: "white" }} />
                  </IconButton>
                </div>
                <h2 className="post__polls__heading">Create Poll</h2>
              </Box>
              <Grid
                container
                columnSpacing={1}
                style={{ padding: "0px 25px 25px 25px" }}
              >
                {/* Title */}

                <Grid
                  item
                  xs={12}
                  style={{ padding: "10px", paddingLeft: "10px" }}
                >
                  {" "}
                  <Box className="title-poll-icon">
                    <Typography className="pedestal__title">
                      <h4 style={{ color: "black" }}>Ask Question</h4>
                    </Typography>
                    <div
                      style={{
                        border: "2px solid #fff8dc",
                        backgroundColor: "#fff8dc",
                      }}
                    >
                      {" "}
                      <svg
                        style={{ marginRight: "5px", marginLeft: "5px" }}
                        width="12"
                        height="13"
                        viewBox="0 0 12 13"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.9446 1.66667C9.2277 1.66667 11.8892 4.20372 11.8892 7.33333C11.8892 10.4629 9.2277 13 5.9446 13C2.66149 13 0 10.4629 0 7.33333C0 4.20372 2.66149 1.66667 5.9446 1.66667ZM5.9446 2.66667C3.24086 2.66667 1.04905 4.75601 1.04905 7.33333C1.04905 9.91067 3.24086 12 5.9446 12C8.64834 12 10.8402 9.91067 10.8402 7.33333C10.8402 4.75601 8.64834 2.66667 5.9446 2.66667ZM5.9446 3.66667C6.21015 3.66667 6.42961 3.85477 6.46437 4.09882L6.46912 4.16667V7.16667C6.46912 7.4428 6.23428 7.66667 5.9446 7.66667C5.67905 7.66667 5.45959 7.47853 5.42483 7.23453L5.42008 7.16667V4.16667C5.42008 3.89053 5.65492 3.66667 5.9446 3.66667ZM10.9431 1.74247L11.0009 1.78237L11.811 2.42515C12.0337 2.60181 12.0639 2.91708 11.8786 3.12932C11.7087 3.32387 11.4166 3.36433 11.1977 3.23365L11.1399 3.19375L10.3298 2.55097C10.1071 2.37431 10.0769 2.05904 10.2621 1.8468C10.4321 1.65225 10.7241 1.61179 10.9431 1.74247ZM7.51817 0C7.80785 0 8.0427 0.22386 8.0427 0.5C8.0427 0.753133 7.84533 0.962327 7.58937 0.995433L7.51817 1H4.37103C4.08135 1 3.84651 0.77614 3.84651 0.5C3.84651 0.246867 4.04383 0.0376733 4.29986 0.00456667L4.37103 0H7.51817Z"
                          fill="#212121"
                        />
                      </svg>
                      <Select
                        displayEmpty
                        disableunderline="true"
                        value={noOfActiveDays}
                        style={{
                          backgroundColor: "#fff8dc",
                          height: "1px",
                          width: "90px",
                        }}
                        onChange={(e) => setNoOfActiveDays(e.target.value)}
                        required
                      >
                        <MenuItem value="" disabled selected>
                          Duration
                        </MenuItem>{" "}
                        <MenuItem
                          value={6}
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          {" "}
                          6 hours
                        </MenuItem>
                        <MenuItem value={12}>12 hours</MenuItem>
                        <MenuItem
                          value={24}
                          style={{
                            textAlign: "right",
                            justifyContent: "center",
                          }}
                        >
                          1 day
                        </MenuItem>
                      </Select>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <div className="POllMention">
                    <MentionsInput
                      value={
                        PollContent.messageValue
                          ? PollContent.messageValue
                          : " "
                      }
                      name="message"
                      placeholder="Start typing here"
                      id="postContent"
                      className="pollContent"
                      onChange={(
                        event,
                        newValue,
                        newPlainTextValue,
                        mentions
                      ) => {
                        handlePollInputChange(
                          event,
                          newValue,
                          newPlainTextValue,
                          mentions
                        );
                      }}
                      allowSpaceInQuery={true}
                      style={{
                        ...MentionStyle,
                        suggestions: {
                          ...MentionStyle.suggestions,
                          bottom: "30px", // Position suggestions above the bottom padding
                        },
                        input: {
                          ...MentionStyle.input,
                          minHeight: "50px",
                          maxHeight: "200px",
                          height: "auto",
                          overflowY: "auto",
                          textAlign: "left",
                          padding: "10px",
                          paddingBottom: "30px",
                          boxSizing: "border-box",
                          marginBottom: "10px",
                        },
                        highlighter: {
                          ...MentionStyle.highlighter,
                          padding: "10px",
                          paddingBottom: "30px",
                        },
                      }}
                    >
                      <Mention
                        trigger="#"
                        appendSpaceOnAdd={true}
                        allowSpaceInQuery={false}
                        data={getHashtags}
                        markup="<a class='hashtagged' href='/hashtags?id=__id__'>__display__</a>"
                      />
                    </MentionsInput>
                  </div>
                </Grid>
                <Typography className="pedestal__title">
                  <h4 style={{ color: "black", paddingLeft: "10px" }}>
                    Options
                  </h4>
                </Typography>
                <Grid item xs={12}>
                  <div className="form_controls">
                    <input
                      maxLength={maxLength}
                      type="text"
                      id="outlined-adornment-amount"
                      placeholder="Choice 1"
                      name="choice 1"
                      autoFocus
                      margin="normal"
                      sx={{ input: { paddingLeft: "5px" } }}
                      required
                      onChange={(e) => setChoice1(e.target.value.trim())}
                    />
                    {choice1.length >= maxLength ? (
                      <div style={{ fontSize: "10px" }}>
                        max. {maxLength} characters allowed
                      </div>
                    ) : null}
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="form_controls">
                    <input
                      type="text"
                      id="outlined-adornment-amount"
                      placeholder="Choice 2"
                      maxLength={maxLength}
                      name="choice 2"
                      margin="normal"
                      sx={{ input: { paddingLeft: "5px" } }}
                      required
                      onChange={(e) => setChoice2(e.target.value.trim())}
                    />
                    {choice2.length >= maxLength ? (
                      <div style={{ fontSize: "10px" }}>
                        max. {maxLength} characters allowed
                      </div>
                    ) : null}
                  </div>
                </Grid>
                {choice.length > 0 &&
                  choice.map((item, i) => (
                    <Grid item xs={12} key={i}>
                      <div className="form_controls_outlined">
                        {i === 0 && (
                          <>
                            <div className="extra__input__wrapper">
                              <input
                                maxLength={maxLength}
                                type="text"
                                id="outlined-adornment-amount"
                                name="choice 3"
                                placeholder="Choice 3"
                                margin="normal"
                                sx={{ input: { paddingLeft: "5px" } }}
                                required
                                onChange={(e) =>
                                  setChoice3(e.target.value.trim())
                                }
                              />
                              <CloseIcon
                                onClick={() => {
                                  removeRow();
                                  resetChoice(3);
                                }}
                                style={{
                                  color: "black",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                            <div>
                              {choice3.length >= maxLength ? (
                                <div style={{ fontSize: "10px" }}>
                                  max. {maxLength} characters allowed
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                        {i === 1 && (
                          <>
                            {" "}
                            <div className="extra__input__wrapper">
                              <input
                                maxLength={maxLength}
                                type="text"
                                id="outlined-adornment-amount"
                                name="choice 4"
                                placeholder="Choice 4"
                                margin="normal"
                                sx={{ input: { paddingLeft: "5px" } }}
                                required
                                onChange={(e) =>
                                  setChoice4(e.target.value.trim())
                                }
                              />
                              <CloseIcon
                                onClick={() => {
                                  removeRow();
                                  resetChoice(4);
                                }}
                                style={{
                                  color: "black",
                                  cursor: "pointer",
                                }}
                              />
                            </div>{" "}
                            <div>
                              {choice4.length >= maxLength ? (
                                <div style={{ fontSize: "10px" }}>
                                  max. {maxLength} characters allowed
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                        {i === 2 && (
                          <>
                            <div className="extra__input__wrapper">
                              <input
                                maxLength={maxLength}
                                type="text"
                                id="outlined-adornment-amount"
                                name="choice 5"
                                placeholder="Choice 5"
                                margin="normal"
                                sx={{ input: { paddingLeft: "5px" } }}
                                required
                                onChange={(e) =>
                                  setChoice5(e.target.value.trim())
                                }
                              />
                              <CloseIcon
                                onClick={() => {
                                  removeRow();
                                  resetChoice(5);
                                }}
                                style={{
                                  color: "black",
                                  cursor: "pointer",
                                }}
                              />
                            </div>{" "}
                            <div>
                              {choice5.length >= maxLength ? (
                                <div style={{ fontSize: "10px" }}>
                                  max. {maxLength} characters allowed
                                </div>
                              ) : null}
                            </div>
                          </>
                        )}
                      </div>
                    </Grid>
                  ))}
                <Grid item xs={12}>
                  {count <= 2 && choice.length <= 3 && (
                    <div className="form_controls_add add_polls" fullWidth>
                      <div style={{ marginLeft: "2px" }}>
                        Add another option
                      </div>

                      <GrAddCircle
                        onClick={addRow}
                        className="gr_add_circle--polls"
                      />
                    </div>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    style={{
                      backgroundColor: "#FECC08",
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                      width: "25px",
                    }}
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    margin="normal"
                    onClick={handlePollSubmit}
                    fullWidth
                  >
                    Post
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          <Button
            label="Submit"
            onClick={handlePostSubmit}
            sx={{
              background: "#D0E7FB",
              borderRadius: "50px",
              textTransform: "none",
              fontWeight: 400,
              fontFamily: "Inter",
              color: "#0C5BA0",
              "&:hover": {
                background: "#D0E7FB",
              },
            }}
          >
            {buttonDisabled ? "Posting..." : "Post"}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "20%",
          left: "45%",
          transform: "translate(-50%, -50%)",
          zIndex: 1000,
        }}
      >
        {showEmojis ? (
          <div ref={emojiRef} style={{ height: "250px" }}>
            {!mobileView ? (
              <EmojiPicker
                onEmojiClick={(EmojiClickData, MouseEvent) => {
                  handleEmojiClick(EmojiClickData.emoji);
                }}
              />
            ) : (
              <EmojiPicker
                onEmojiClick={(EmojiClickData, MouseEvent) => {
                  handleEmojiClick(EmojiClickData?.emoji);
                }}
                width={340}
                height={365}
              />
            )}
          </div>
        ) : null}
      </Box>
    </Box>
  );
};

export default PostSomething;
