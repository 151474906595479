import React, { useContext, useState } from "react";
import "./index.css";
import { Modal, Box } from "@mui/material";
import { toast } from "react-toastify";
import { getStorageItem } from "../../utils/sessionStorage";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { SocketContext } from "../../context/SocketContext";

const Index = (props) => {
  const [socket] = useContext(SocketContext);

  const [team1, setTeam1] = useState({
    name: props?.cardData?.team1?.name,
    image: props?.cardData?.team1?.image,
    runs: props?.cardData?.team1?.runs || 0,
    wickets: props?.cardData?.team1?.wickets || 0,
    overs: props?.cardData?.team1?.overs || 0,
  });
  const [team2, setTeam2] = useState({
    name: props?.cardData?.team2?.name,
    image: props?.cardData?.team2?.image,
    runs: props?.cardData?.team2?.runs || 0,
    wickets: props?.cardData?.team2?.wickets || 0,
    overs: props?.cardData?.team2?.overs || 0,
  });

  const [info, setInfo] = useState(props?.cardData?.information || "");
  const [ended, setEnded] = useState(props?.cardData?.ended || "");
  const [winner, setWinner] = useState(props?.cardData?.winner || "");
  const [live, setLive] = useState(props?.cardData?.live || "");

  const accessToken = getStorageItem("token");

  const handleEditCard = async () => {
    if (!accessToken) return;

    const data1 = {
      team1: {
        name: team1.name,
        image: team1.image,
        runs: team1.runs,
        wickets: team1.wickets,
        overs: team1.overs,
      },
      team2: {
        name: team2.name,
        image: team2.image,
        runs: team2.runs,
        wickets: team2.wickets,
        overs: team2.overs,
      },
      information: info,
      winner,
      live,
    };

    if (data1.ended) {
      data1.ended = ended;
    }

    socket?.emit("updatedScore", { id: props?.cardData?._id, data1 });

    // const options = {
    //   method: "PUT",
    //   url: global.config.ROOTURL.prod + `/scorecard/update/${props?.cardData?._id}`,
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + accessToken,
    //     role: "Admin",
    //   },
    //   data: data1,
    // };

    // axios(options)
    //   .then(({ data }) => {
    //     toast.success("card Edited successfully");
    //     console.log("data",data)
    //     props.setData(data);
    //     props.handleClose();
    //   })
    //   .catch((error) => {
    //     if (error?.response?.status === 401) {
    //       console.log(error);
    //     }
    //   });
  };

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="contest-admin-create">
          <div className="contest-admin-create-wrapper">
            <div className="contest-admin-create-wrapper-main">
              <Box style={{ float: "right" }}>
                <IconButton onClick={props.handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <div className="contest-admin-create-wrapper-header">
                <p className="contest-admin-create-wrapper-header--text">
                  Edit card
                </p>
              </div>

              <div className="contest-admin-create-wrapper-form">
                <div className="contest-create-name-wrapper">
                  {team1.name} Runs-
                  <input
                    type="number"
                    style={{ paddingLeft: "4px" }}
                    value={team1.runs}
                    onChange={(event) => {
                      setTeam1({ ...team1, runs: event.target.value });
                    }}
                    required
                  />
                </div>
                <div className="contest-create-name-wrapper">
                  {team1.name} Wickets-
                  <input
                    type="number"
                    style={{ paddingLeft: "4px" }}
                    value={team1.wickets}
                    onChange={(event) => {
                      setTeam1({ ...team1, wickets: event.target.value });
                    }}
                    required
                  />
                </div>
                <div className="contest-create-name-wrapper">
                  {team1.name} Overs-
                  <input
                    type="number"
                    style={{ paddingLeft: "4px" }}
                    value={team1.overs}
                    onChange={(event) => {
                      setTeam1({ ...team1, overs: event.target.value });
                    }}
                    required
                  />
                </div>
                <div className="contest-create-name-wrapper">
                  {team2.name} Runs-
                  <input
                    type="number"
                    style={{ paddingLeft: "4px" }}
                    value={team2.runs}
                    onChange={(event) => {
                      setTeam2({ ...team2, runs: event.target.value });
                    }}
                    required
                  />
                </div>
                <div className="contest-create-name-wrapper">
                  {team2.name} Wickets-
                  <input
                    type="number"
                    style={{ paddingLeft: "4px" }}
                    value={team2.wickets}
                    onChange={(event) => {
                      setTeam2({ ...team2, wickets: event.target.value });
                    }}
                    required
                  />
                </div>
                <div className="contest-create-name-wrapper">
                  {team2.name} Overs-
                  <input
                    type="number"
                    style={{ paddingLeft: "4px" }}
                    value={team2.overs}
                    onChange={(event) => {
                      setTeam2({ ...team2, overs: event.target.value });
                    }}
                    required
                  />
                </div>
                <div className="contest-create-name-wrapper">
                  Information-
                  <input
                    type="text"
                    style={{ paddingLeft: "4px" }}
                    className="contest-create-name"
                    value={info}
                    maxLength={30}
                    onChange={(event) => {
                      setInfo(event.target.value);
                    }}
                    required
                  />
                </div>
                <div className="contest-create-name-wrapper">
                  Winner-
                  <input
                    type="text"
                    style={{ paddingLeft: "4px" }}
                    className="contest-create-name"
                    value={winner}
                    onChange={(event) => {
                      setWinner(event.target.value);
                    }}
                  />
                </div>
                <div className="contest-create-name-wrapper">
                  Ended-
                  <input
                    type="checkbox"
                    style={{ width: "10px" }}
                    checked={ended}
                    onChange={(event) => {
                      setEnded(event.target.checked);
                    }}
                  />
                </div>
                <div className="contest-create-name-wrapper">
                  Live
                  <input
                    type="checkbox"
                    style={{ width: "10px" }}
                    checked={live}
                    onChange={(event) => {
                      setLive(event.target.checked);
                    }}
                  />
                </div>
                <button
                  onClick={handleEditCard}
                  className="contest-preediction-admin-creation-submit-button"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
