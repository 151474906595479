import { useEffect, useRef, useState } from "react";
import React from "react";
import Input from "../../commons/form/input";
import ChampButton from "../../commons/form/button";
import Upload from "../../assets/images/onboarding/upload.svg";
import Avatar from "../../assets/images/onboarding/avatar.svg";

import Batsmen from "../../assets/images/onboarding/batsmen.svg";
import Keeper from "../../assets/images/onboarding/keeper.svg";
import Umpire from "../../assets/images/onboarding/umpire.svg";
import Bowler from "../../assets/images/onboarding/bowler.svg";

import BatsmenSelected from "../../assets/images/onboarding/batsmen-selected.svg";
import KeeperSelected from "../../assets/images/onboarding/keeper-selected.svg";
import UmpireSelected from "../../assets/images/onboarding/umpire-selected.svg";
import BowlerSelected from "../../assets/images/onboarding/bowler-selected.svg";
import LogoWithTitle from "../signin-signup/logo.component";
import "./index.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingButton from "../loading-button/LoadingButton";
import { getStorageItem } from "../../utils/sessionStorage";
import { width } from "@mui/system";

const OnboardingComponent = () => {
  const loginOptionPassword = getStorageItem('loginOptionPassword');
  const profileEmail = getStorageItem("user_email");
  const userName = getStorageItem("user_name");
  const userID = getStorageItem("user_id");
  const accessToken = getStorageItem("token");
  const uid = getStorageItem("uid");
  const inviteKey = getStorageItem("inviteKey");
  const email = getStorageItem("user_email");
  const referralCode = getStorageItem("referralCode");
  const navigate = useNavigate();
  // if (firstName && lastName){navigate('/pitch');}
  const [options, setOptions] = useState([]);
  const [image, setImage]
    = useState("https://chamhunt-file-save-app.s3.us-west-1.amazonaws.com/avatar_17_11_2023_8_42_AM.png");
  const [disabled, setDisabled] = useState(true);
  const [userInfo, setUserInfo] = useState({
    first_name: "",
    last_name: "",
    profilePhoto: "",
    state: "",
    user: "",
    interests: {
      batsmen: false,
      bowler: false,
      keeper: false,
      umpire: false,
    },
  });
  const [step, setStep] = useState(1);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [style, setStyle] = useState({});
  const [imageUrl, setImageUrl] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  useEffect(() => {
    const stateOptions = [
      { id: "Andhra Pradesh", label: "Andhra Pradesh" },
      { id: "Arunachal Pradesh", label: "Arunachal Pradesh" },
      { id: "Assam", label: "Assam" },
      { id: "Bihar", label: "Bihar" },
      { id: "Chhattisgarh", label: "Chhattisgarh" },
      { id: "Goa", label: "Goa" },
      { id: "Gujarat", label: "Gujarat" },
      { id: "Haryana", label: "Haryana" },
      { id: "Himachal Pradesh", label: "Himachal Pradesh" },
      { id: "Jammu & Kashmir", label: "Jammu & Kashmir" },
      { id: "Karnataka", label: "Karnataka" },
      { id: "Kerala", label: "Kerala" },
      { id: "Madhya Pradesh", label: "Madhya Pradesh" },
      { id: "Maharashtra", label: "Maharashtra" },
      { id: "Manipur", label: "Manipur" },
      { id: "Meghalaya", label: "Meghalaya" },
      { id: "Mizoram", label: "Mizoram" },
      { id: "Nagaland", label: "Nagaland" },
      { id: "Delhi", label: "Delhi" },
      { id: "Odisha", label: "Odisha" },
      { id: "Puducherry", label: "Puducherry" },
      { id: "Punjab", label: "Punjab" },
      { id: "Rajasthan", label: "Rajasthan" },
      { id: "Sikkim", label: "Sikkim" },
      { id: "Tamil Nadu", label: "Tamil Nadu" },
      { id: "Telangana", label: "Telangana" },
      { id: "Tripura", label: "Tripura" },
      { id: "Uttar Pradesh", label: "Uttar Pradesh" },
      { id: "Uttarakhand", label: "Uttarakhand" },
      { id: "West Bengal", label: "West Bengal" },
    ];

    setOptions(stateOptions);

  }, []);





  const handleLater = () => {
    window.location.href = "/pitch";
  };

  const updateData = async (profileData) => {
    if (!accessToken && !profileData) return
    var saveProfileOptions = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/updateWithOtp/",
      data: profileData, // Send profileData directly as JSON payload
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json", // Use "application/json" content type
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(saveProfileOptions)
      .then((res) => {
        localStorage.setItem("first_name", res.data.firstName);
        localStorage.setItem("last_name", res.data.lastName);
        localStorage.setItem(
          "full_name",
          res.data.firstName + " " + res.data.lastName
        );
        localStorage.setItem("avatar", res.data.profilePhoto);
        localStorage.setItem("token", res.data.token);
        navigate("/pitch");
      })
      .catch((error) => {
        console.log(error);
      });

  };


  const handleNext = () => {
    setDisabled(true);

    if (step === 1) {
      userInfo.user = userID;
    }

    let profileData = {};

    profileData.firstName = firstName;
    profileData.lastName = lastName;
    profileData.profilePhoto = image;
    profileData.email = email;

    if (inviteKey != null) {
      profileData.referralCode = inviteKey;
      profileData.shareMode = "inviteLink"
      //profileData.secretCode = inviteKey;
    }
    if(referralCode !== null){
      profileData.referralCode = referralCode;
      profileData.shareMode = "referral"
    }

    updateData(profileData);

    if (step === 2) {
      handleLater();
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("uploader", file);
    const response = await axios.post(`${global.config.ROOTURL.prod}/upload-file`, formData, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "*",
      },
    });
    if (response.data) {
      return response.data[0].location;
    } else {
      console.log("error occured:");
      throw new Error(`Failed to upload image ${file}`);
    }
  };
  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const eventimage = event.target.files[0];
      const imageforuplaod = await uploadImage(eventimage);
      setImage(imageforuplaod);
    }
  };
  const handleOnChange = (event) => {
    const { name, value } = event.target;

    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const updateInterests = (key) => {
    const interests = {
      ...userInfo.interests,
      [key]: !userInfo.interests[key],
    };

    setUserInfo({
      ...userInfo,
      interests,
    });
  };

  useEffect(() => {
    setStyle({
      background: `${!disabled && !loading
        ? "#0c5ba0"
        : "#747452"
        }`,
      color: "white",
      width: "95%",
      padding: "6px",
      borderRadius: "8px",
      border: "none",
      cursor: "pointer",
      "&.MuiButtonBase-root:hover": {
        bgcolor: "rgb(21, 101, 192)",
        border: "none",
      },
      "&.MuiButton-root.Mui-disabled": {
        color: "darkgrey",
      },
    });
  }, [disabled]);

  const getGtkmMarkup = () => {
    let markup;

    switch (step) {
      case 1:
        markup = (
          <>
            <LogoWithTitle fontSize={"100"} />

            <p className="center greeting">
              <span>Hello, </span>
              <span>{profileEmail}</span>
            </p>
            <p className="center others-know">Let others know about you</p>
            <div className="onboarding gtkm form">
              <Input
                classes=" gtkm-name"
                placeholder="First Name"
                id="first_name"
                name="first_name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
              <Input
                classes=" gtkm-name"
                placeholder="Last Name"
                id="last_name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
              />

              <div className="gtkm-upload-container">
                <p className="add-pic">
                  Add a photo to better connect with friends and the like minded
                  ones. People love your smile
                </p>
                <div className="gtkm-upload">
                  <div className="avatar">
                    <div className="image-upload">
                      <label for="file-input">
                        <img
                          className="profile-avatar image-user"
                          src={image}
                          alt="Upload your avatar"
                        />
                      </label>
                      <input
                        id="file-input"
                        type="file"
                        onChange={onImageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
        break;
      default:
        const { batsmen, bowler, keeper, umpire } = userInfo.interests;

        markup = (
          <>
            <p className="center ">Hello , {userName}</p>
            <span className="center limit ">
              Meet new people {"&"} discover activities , news and much more of
              your interest. What are you interested in ?
            </span>
            <form className="onboarding gtkm form">
              <div className="interests">
                <div className="interest">
                  <img
                    src={batsmen ? BatsmenSelected : Batsmen}
                    onClick={() => {
                      updateInterests("batsmen");
                    }}
                    alt=""
                  />
                </div>
                <div className="interest">
                  <img
                    src={keeper ? KeeperSelected : Keeper}
                    onClick={() => {
                      updateInterests("keeper");
                    }}
                    alt=""
                  />
                </div>
                <div className="interest">
                  <img
                    src={bowler ? BowlerSelected : Bowler}
                    onClick={() => {
                      updateInterests("bowler");
                    }}
                    alt=""
                  />
                </div>
                <div className="interest">
                  <img
                    src={umpire ? UmpireSelected : Umpire}
                    onClick={() => {
                      updateInterests("umpire");
                    }}
                    alt=""
                  />
                </div>
              </div>
            </form>
          </>
        );
    }
    return markup;
  };

  useEffect(() => {
    if ((firstName !== "" && lastName !== "") || step === 2) {
      setDisabled(false);
    }
  }, [firstName, lastName]);

  return (
    <div className="component on-boarding">
      {getGtkmMarkup()}
      <div className="gtkm-footer btn-block">
        <LoadingButton
          disabled={disabled}
          style={style}
          buttonValue={"Next"}
          setSuccess={setSuccess}
          setLoading={setLoading}
          handleClick={handleNext}
          loading={loading}
          success={success}
        ></LoadingButton>
      </div>
    </div>
  );
};

export default OnboardingComponent;