import { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import Post from "./post";
import Polls from "../poll/poll";
import { BallTriangle } from "react-loader-spinner";
import "./index.scss";
import { getStorageItem } from "../../utils/sessionStorage";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { SecurityUpdateGoodRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import JobMain from "../pitch-job/JobMain";
import { PathAction } from "../../redux/reducers/globalPath";
import Challenge from "../challenge/FeedChallenge";
import Expirationmodel from "./expirationmodel";
import chLogo from "../../assets/images/Pitch/Feed/champhuntAdLogo.svg";
import PostHilights from "../feeds/feed-redesign/PostHilights";

const Posts = (props) => {
  const { filter, uid, pitchCreatedProps, hprops } = props;
  const [showNewFeedBaner, setShowNewFeedBaner] = useState(false);
  const accessToken = localStorage.getItem("token");
  const userId = localStorage.getItem("user_id");
  const [startDate, setStartDate] = useState(return1SecondPlusDate());
  const reduxState = useSelector((state) => state.pitchS.PitchSelction);
  const reload = useSelector((state) => state.path.shouldLoad);
  let newCreatedData = useSelector((state) => state.path.createdObject);
  let newDeletedId = useSelector((state) => state.path.deltedId);
  const [shouldLoadPage, setShouldLoadPage] = useState(false);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [visitorLocation, setVisitorLocation] = useState({});
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [postPin, setPostPin] = useState([]);
  const [ads, setAds] = useState([]);
  const [adIndex, setAdIndex] = useState(null);
  const [showStatus, setShowStatus] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [ownFollowings, setOwnFollowings] = useState([]);
  const [userSavedPosts, setUserSavedPosts] = useState([]);
  const [locationpermission, setLocationPermission] = useState(
    localStorage.getItem("locationpermission") === "true"
  );

  const updateLocationPermission = () => {
    if (!accessToken) return;
    const update = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/updateLocationPermission",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        locationpermission: false,
      },
    };
    axios(update)
      .then((response) => {
        localStorage.setItem("locationpermission", false);
        if (response.data.modifiedCount === 1) {
          setLocationPermission(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
        }
      });
  };
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          setVisitorLocation({ latitude, longitude });
          try {
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`
            );
            const data = await response.json();
            const { address } = data;
            const { state, country, district } = address;
            setState(state);
            setCountry(country);
          } catch (error) {
            if (error) updateLocationPermission();

            console.error("Error fetching location:", error);
          }
        },
        (error) => {
          if (error.message) updateLocationPermission();
          console.error(error.message);
        }
      );
    }
  };
  const onEnterViewport = async (postId) => {
    if (!postId && !accessToken) return;
    if (locationpermission) getLocation();
    try {
      const req = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/feeds/feedimpression/create",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        data: {
          feedId: postId,
          visitorLocation,
          state,
          country,
        },
      };

      axios(req)
        .then((response) => { })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchMoreData = (No) => {
    if (!accessToken) return;
    const req = {
      method: "GET",
      url:
        reduxState === "ALL"
          ? global.config.ROOTURL.prod +
          `/feeds/getAllFeeds/${No ? No : page}/${global.config.pichPagePerSize
          }/${startDate}`
          : global.config.ROOTURL.prod +
          `/feeds/getAlFollowerFeeds/${No ? No : page}/${global.config.pichPagePerSize
          }/${startDate}`,

      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(req)
      .then((response) => {
        if (response.data.length === 0) {
          setHasMore(false);
        } else {
          // Append new data to existing items
          // this is for deleted pitch poll job

          if (newDeletedId) {
            const dataexist = response.data.filter(
              (i) => newDeletedId === i.feed_id
            );
            if (dataexist.length > 0) {
              const isAnyData = response.data.filter(
                (i) => newDeletedId !== i.feed_id
              );
              response.data = isAnyData;
              // and here i remove that data from redux
              dispatch(PathAction.handleDeleteobject({ payload: null }));
            }
          }
          if (No === 1) {
            // if there is created pitch poll repost then this wil trigger otherwise it will null
            if (newCreatedData) {
              const isAnyData = response.data.filter(
                (i) => newCreatedData._id === i.feed_id
              );
              // if coming response dont have that new created object then this will trigger
              if (isAnyData.length === 0) {
                const updatedData = {
                  ...newCreatedData,
                  feed_id: newCreatedData._id,
                };
                // here i set new object to response data
                response.data.unshift(updatedData);
              }
              // and here i remove that data from redux
              dispatch(PathAction.handleCreatedObject({ payload: null }));
            }
            setItems([]);
            setItems((prevItems) => prevItems.concat(response.data));
            if (!shouldLoadPage) {
              setShouldLoadPage(true);
            }
            setPage(2);
          } else {
            setItems((prevItems) => prevItems.concat(response.data));
            setPage((prevPage) => prevPage + 1);
            if (!shouldLoadPage) {
              setShouldLoadPage(true);
            }
          }
        }
      })
      .catch((error) => console.log(error));
  };
  const fetchDatas = async () => {
    if (!accessToken) return;
    const options = {
      method: "GET",
      url: global.config.ROOTURL.prod + `/pitch/getAllPinPost`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };

    await axios(options)
      .then(({ data }) => {
        setPostPin([...data]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchAds = () => {
    if (!accessToken) return;
    const options = {
      method: "get",
      url: global.config.ROOTURL.prod + `/ads/fetch-pitch-ads`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then(({ data }) => {
        setAds([...data]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateSubscriberStatus = () => {
    if (!accessToken) return;
    const options = {
      method: "get",
      url:
        global.config.ROOTURL.prod + `/pay/inActiveUserSubscription/${userId}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then(({ data }) => {
        if (data.status == 201 && !localStorage.getItem("modalShown")) {
          setShowStatus(true);
          setIsSuccessModalOpen(true);
          localStorage.setItem("modalShown", "true");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAdUrl = (data) => {
    if (data) {
      window.open(data.adUrl);
    } else {
      console.log("URL not found");
    }
  };

  const fetchUserSavedPosts = async () => {
    try {
      await axios.get(`${global.config.ROOTURL.prod}/feeds/getUserSavedPosts/${userId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      })
        .then((response) => {
          console.log(response.data)
          setUserSavedPosts(response.data.userSavedPosts)
        })
    } catch (err) {
      console.log(err)
    }
  };

  const fetchOwnFollowing = async () => {
    if (!accessToken) return;
    const getOwnFollowing = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/auth/get-following-user-id/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(getOwnFollowing)
      .then((response) => {
        const followingIds = response.data.map(item => item._id);
        setOwnFollowings(followingIds);
      })
      // .then(() => checkFollower())
      .catch((error) => {
        if (error?.response?.status === 401) {
          console.log(error)
          // clearStorage();
          // navigate("/login");
        }
      });
  };

  useEffect(() => {
    if (accessToken) {
      fetchOwnFollowing();
    }
  }, [])
  useEffect(() => {
    if (accessToken) {
      fetchUserSavedPosts();
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
    setStartDate(return1SecondPlusDate());
    fetchMoreData(1);
    fetchDatas();
    fetchAds();
    // updateSubscriberStatus();
    if (!localStorage.getItem("modalShown")) {
      updateSubscriberStatus();
    }
  }, [reload, reduxState]);

  return (
    <>
      {items.length === 0 && (
        <div className="component posts">
          {postPin.map((post, index) => (
            <div key={index} style={{ width: "100%" }}>
              <Post key={index} post={post} onEnterViewport={onEnterViewport} />
            </div>
          ))}
          {hasMore && (
            <div style={{ textAlign: "center", margin: "20px 0" }}>
              <BallTriangle
                height="100"
                width="100"
                color="grey"
                ariaLabel="loading"
              />
            </div>
          )}
        </div>
      )}
      <Expirationmodel
        open={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
      />
      {shouldLoadPage && (
        <div className="component posts">
          <label
            hidden={!showNewFeedBaner}
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => {
              setShowNewFeedBaner(false);
              window.location.reload();
            }}
          >
            new feed is available click here
          </label>
          {postPin.map((post, index) => (
            <div key={index} style={{ width: "100%" }}>
              <Post key={index} post={post} onEnterViewport={onEnterViewport} />
            </div>
          ))}
          <InfiniteScroll
            dataLength={items.length}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <BallTriangle
                height="100"
                width="100"
                color="grey"
                ariaLabel="loading"
              />
            }
          >
            {items.map((post, index) => {
              // Adjust the condition to check for every 20 posts instead of 10
              const shouldDisplayAd = (index + 1) % 5 === 0;
              const adIndex = Math.floor(index / 5) % ads.length; // Calculate ad index accordingly

              return (
                <div key={index} style={{ width: "100%" }}>
                  {/* Render the post based on its type */}
                  {!post.options && !post.jobTitle && !post.challengeId && (
                    <Post
                      key={index}
                      post={post}
                      onEnterViewport={onEnterViewport}
                      checkFollow={ownFollowings?.includes(post.userId) ? "Following" : "NotFollowing"}
                      checkSaved={userSavedPosts.includes(post.feed_id) ? "Saved" : "notSaved"}
                    />
                  )}
                  {post.options &&
                    !post.jobTitle &&
                    !post.question.PollMessage && (
                      <Polls
                        key={index}
                        post={post}
                        postquestion={post.question}
                        onEnterViewport={onEnterViewport}
                      />
                    )}
                  {!post.options && post.jobTitle && (
                    <JobMain
                      key={index}
                      post={post}
                      onEnterViewport={onEnterViewport}
                    />
                  )}
                  {!post.options && post.challengeId && (
                    <Challenge key={index} postObj={post} />
                  )}

                  {shouldDisplayAd && adIndex >= 0 && adIndex < ads.length && (
                    <>
                      <div
                        key={`ad-${adIndex}`}
                        style={{
                          width: '100%',
                          padding: "20px",
                          margin: "20px 0px",
                          borderRadius: "8px",
                          backgroundColor: "#F2F8FF",
                          border: "1px solid var(--Neutral-Stroke-1-Rest, #D1D1D1)",
                          boxShadow: '0px -1px 0px 0px #F0F0F0 inset',
                          fontFamily: "Inter",
                          fontSize: "16px",
                          color: "#000000",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px'
                          }}>
                            <img src={chLogo} alt="" />
                            <h3
                              style={{
                                fontSize: "20px",
                                color: "#242424",
                                fontFamily: 'Inter',
                                fontWeight: 600
                              }}
                            >
                              {ads[adIndex].title}
                            </h3>
                          </div>
                          {/* Advertisment Tag */}
                          <span
                            style={{
                              borderRadius: 'var(--Medium, 4px)',
                              border: '1px solid var(--Neutral-Stroke-1-Rest, #D1D1D1)',
                              background: 'var(--Neutral-Background-Transparent-Rest, rgba(255, 255, 255, 0.00))',
                              padding: '5px 12px',
                              marginRight: '20px',
                              color: 'var(--Primary-Color-1-700, #494D50)'
                            }}
                          >
                            Sponsored
                          </span>
                        </div>
                        <video
                          src={ads[adIndex].url}
                          style={{
                            width: "100%",
                            margin: "10px 0px",
                            boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.2)",
                          }}
                          controls
                          autoPlay
                          muted
                          onError={(e) => console.error("Video playback error:", e)}
                        />

                        {/* Display the ad description */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              fontFamily: 'Inter',
                              fontSize: 16,
                              fontWeight: 600,
                              color: '#898A8D',
                              lineHeight: '23px',
                            }}
                          >
                            {ads[adIndex].description}
                          </p>
                          <Button
                            variant="contained"
                            onClick={() => handleAdUrl(ads[adIndex])}
                          >
                            {ads[adIndex].urlTitle || "Open"}
                          </Button>
                        </div>
                      </div>
                      <PostHilights />
                    </>
                  )}
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};
const return1SecondPlusDate = () => {
  let t = new Date();
  t.setSeconds(t.getSeconds() + 100);
  return t;
};

export default Posts;