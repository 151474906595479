import React, { useEffect, useState } from "react";
import "../voucher/get_vocher/index.scss";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const AdminTeam = () => {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleDelete = async (_id) => {
    try {
      const response = await fetch(
        `${global.config.ROOTURL.prod}/auction/team/${_id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete team");
      }

      setTeams(teams.filter((team) => team._id !== _id));
      console.log("Deleted");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        const response = await fetch(
          `${global.config.ROOTURL.prod}/auction/teams`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch Teams List");
        }
        const data = await response.json();
        setTeams(data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeams();
  }, []);

  const handleUpdateTeam = async (id, updatedTeam) => {
    try {
      const response = await fetch(
        `${global.config.ROOTURL.prod}/auction/team/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(updatedTeam),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update team");
      }

      const updatedTeams = teams.map((team) =>
        team._id === id ? { ...team, ...updatedTeam } : team
      );

      setTeams(updatedTeams);
    } catch (error) {
      console.error(error);
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="voucher-list">
      {teams.map((team) => (
        <TeamCard
          key={team._id}
          team={team}
          onUpdate={handleUpdateTeam}
          handleDelete={handleDelete}
        />
      ))}
    </div>
  );
};

const TeamCard = ({
  team,
  onUpdate,
  handleDelete,
}) => {
  const {
    _id,
    name,
    originalFund,
    fundRemaining,
    image,
    maxPlayer,
  } = team;
  const [isEditing, setIsEditing] = useState(false);
  const [editedOriginalFund, setEditedOriginalFund] = useState(originalFund);
  const [editedFundRemaining, setEditedFundRemaining] = useState(fundRemaining);
  const [editedMaxPlayer, setEditedMaxPlayer]= useState(maxPlayer);
  
  const handleUpdate = async () => {
    const updatedTeam = {
      fundRemaining: editedFundRemaining,
    };

    try {
      await onUpdate(_id, updatedTeam);
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to update team:", error);
    }
  };

  return (
    <div className="voucher-card">
      {image && (
        <img
          src={image}
          alt={`${name} player`}
          className="voucher-image"
          style={{ objectFit: "contain" }}
        />
      )}
      <div style={{ marginTop: "160px" }}>
        {isEditing ? (
          <>
            <label>Fund Remaining</label>
            <input
              type="number"
              value={editedFundRemaining}
              onChange={(e) => setEditedFundRemaining(Number(e.target.value))}
              className="voucher-input"
              placeholder="Fund Remaining"
            />

            <div className="voucher-actions">
              <Button
                onClick={handleUpdate}
                variant="contained"
                color="primary"
              >
                Save
              </Button>
              <Button
                onClick={() => setIsEditing(false)}
                variant="outlined"
                color="secondary"
              >
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <>
            <p>Name: {name}</p>
            <p>Original Fund: {originalFund}</p>
            <p>Fund Remaining: {fundRemaining}</p>
            <p>Max Player: {maxPlayer}</p>

            <div
              className="d_u_buttons"
              style={{ flexWrap: "wrap", gap: "4px" }}
            >
              <Button
                onClick={() => setIsEditing(true)}
                variant="outlined"
                color="primary"
                style={{ marginTop: "10px" }}
              >
                Update
              </Button>
              <Button
                variant="outlined"
                startIcon={<DeleteIcon />}
                onClick={() => handleDelete(_id)}
                color="secondary"
                style={{ marginTop: "10px" }}
              >
                Delete
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminTeam;
