import React from 'react';
import { Box } from '@mui/material';

//pages
import NewLeaderBoard from '../pitch-right-side/newLeaderboard';
import FirstCarouselPitch from '../pitch-right-side/carousel-one';
import NewTournament from '../pitch-right-side/newTournament';
import SecondCarouselPitch from '../pitch-right-side/carousel-two';
import PeopleUMayKnow from '../pitch-right-side/peopleuknow';
import ReferAFriend from '../pitch-right-side/referafriend';

const PitchRightSection = () => {
    return (
        <Box
            sx={{
                position: 'fixed',
                overflow: 'auto',
                height: '100vh',
                paddingBottom: '80px',
                width: '25vw',
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
            }}>
            <Box>
                <Box>
                    <NewLeaderBoard />
                </Box>

                <Box>
                    <FirstCarouselPitch />
                </Box>

                <Box>
                    <NewTournament />
                </Box>

                <Box>
                    <PeopleUMayKnow />
                </Box>

                <Box>
                    <ReferAFriend />
                </Box>

                <Box>
                    <SecondCarouselPitch />
                </Box>
            </Box>
        </Box>
    );
}

export default PitchRightSection;