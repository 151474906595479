import React, { useState, useEffect } from 'react';
import { Grid, Card, CardMedia, CardContent, Typography, Box, Button } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useMediaQuery } from "@mui/material";
import { toast } from 'react-toastify';
import '../new-deals/style.scss';
import SuccessModal from "./successModal";

import dealBallImg from "../../assets/images/deals/bxs_cricket-ball.svg";
import couponImg from '../../assets/images/subscription/plus.png';
import defaultImg from "../../assets/images/deals/Default Deal.png";
import cardBackground from "../../assets/images/deals/dealbackgroundImg.jpeg";
import { getStorageItem } from "../../utils/sessionStorage";

const Coupon = ({ sortDeal }) => {
    const mobileView = useMediaQuery("(max-width:769px)");
    const [coupons, setCoupons] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState(true);
    const userEmail = getStorageItem('user_email');
    const userName = getStorageItem('full_name');
    const [originalCouponsList, setOriginalCouponsList] = useState([]);
    let filteredDeal=[...coupons];
    const handleClaim = async (coupon) => {
        try {
            const response = await fetch(global.config.ROOTURL.prod + `/couponRedeem/createRedeemDetails`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    couponId: coupon._id,
                    type: coupon.type,
                    discount: coupon.discount,
                    userId: getStorageItem('user_id')
                })
            });

            if (!response.ok) {
                throw new Error("Failed to claim coupon");
            }

            const updatedCoupons = coupons.map((c) =>
                c._id === coupon._id ? { ...c, redeemed: true } : c
            );

            setCoupons(updatedCoupons);
            toast.success('Coupon claimed');
            closeModal();
            setIsSuccessModalOpen(true);
            await sendEmail(coupon);
        } catch (error) {
            toast.error('Failed to claim coupon');
        }
    };

    const sendEmail = async (coupon) => {
        try {
            const response = await fetch(global.config.ROOTURL.prod + `/couponRedeem/sendRedeemEmail`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({
                    userEmail,
                    userName,
                    discountCode: coupon.description
                })
            });

            if (!response.ok) {
                throw new Error('Failed to send email');
            }

            toast.success('Confirmation email sent');

        } catch (error) {
            toast.error('Failed to send confirmation email');
        }
    };

    const openModal = (coupon) => {
        setSelectedCoupon(coupon);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedCoupon(null);
    };

    useEffect(() => {
        const fetchCoupons = async () => {
            try {
                const response = await fetch(global.config.ROOTURL.prod + `/coupons/getCouponsWithStatus/${getStorageItem("user_id")}`, {
                    headers: {
                        "Authorization": `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                if (!response.ok) {
                    throw new Error("Failed to fetch coupons");
                }
                const data = await response.json();
                setCoupons(data);
                setOriginalCouponsList(data);
            } catch (error) {
                console.error(error);
            }
        };

        fetchCoupons();
    }, []);

    useEffect(() => {        
        // SORT FUNCTIONALITY
    
    
        if (sortDeal === 'Price Low to High') {
          filteredDeal = [...filteredDeal].sort((a, b) => a.discount - b.discount)
        }
        else if (sortDeal === 'Price High to Low') {
          filteredDeal = [...filteredDeal].sort((a, b) => b.discount - a.discount)
        }
        else if (sortDeal === 'A to Z') {
          filteredDeal = [...filteredDeal].sort((a, b) => a.description.localeCompare(b.description))
        }
        else if (sortDeal === 'Z to A') {
          filteredDeal = [...filteredDeal].sort((a, b) => b.description.localeCompare(a.description))
        }
        else if (sortDeal === 'New') {
          filteredDeal = [...filteredDeal].sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
        }
        else {
          filteredDeal = [...originalCouponsList]
        }
    
        setCoupons(filteredDeal !== undefined ? filteredDeal : coupons)
    }, [sortDeal])

    return (
        <>
            {coupons.map((coupon) => (
                <Grid item xs={6} sm={6} md={3} key={coupon.id}>
                    <Card
                        sx={{
                            height: "293px",
                            width: mobileView ? "100%" : "100%",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            borderRadius: 2,
                            backgroundImage: `url(${cardBackground})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            boxShadow: "none",
                            transition: "transform 0.3s ease",
                            "&:hover": {
                                transform: "scale(1.05)",
                            },
                        }}
                    >
                        <Box sx={{ height: mobileView ? "120px" : "160px", position: "relative", padding: "10px 10px 0 10px" }}>
                            {/* Image Section */}
                            <CardMedia
                                component="img"
                                image={couponImg || defaultImg}
                                alt=''
                                sx={{
                                    height: "100%",
                                    borderRadius: "10px"
                                }}
                            />

                            {/* Offer Time (Overlay on Bottom of Image) */}
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    color: "white",
                                    padding: "5px",
                                    margin: "0 10px",
                                    borderBottomLeftRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                    textAlign: "center",
                                    fontSize: mobileView ? "12px" : "16px",
                                }}
                            >
                                Discount {coupon.discount || null}%
                            </Box>
                        </Box>
                        {/* Content */}
                        <CardContent sx={{ textAlign: "center" }}>
                            <Typography
                                sx={{
                                    color: "rgba(66, 66, 66, 1)",
                                    fontSize: "18px",
                                    fontFamily: "Helvetica",
                                    fontWeight: "700"
                                }}>
                                {coupon.description.length > 18
                                    ? coupon.description.substring(0, 18) + "..."
                                    : coupon.description || ""}
                            </Typography>
                            <Button
                                onClick={() => openModal(coupon)}
                                variant="contained"
                                disabled={coupon.redeemed}
                                sx={{
                                    backgroundImage: coupon.redeemed
                                        ? "none" // No gradient for disabled
                                        : "linear-gradient(90deg, #FECD08, #F74E01, #EB128D)",
                                    "&:hover": {
                                        backgroundImage: coupon.redeemed
                                            ? "none"
                                            : "linear-gradient(90deg, #EB128D, #F74E01, #FECD08)", 
                                    },
                                    "&.Mui-disabled": {
                                        backgroundColor: "#D3D3D3",
                                        color: "black",
                                    },
                                    marginTop: "10px",
                                }}
                            >
                                <img src={dealBallImg} style={{ width: "16px", marginRight: "5px" }} />
                                {coupon.redeemed ? 'Redeemed' : 'Claim'}
                            </Button>


                        </CardContent>
                    </Card>
                </Grid>
            ))}
            {selectedCoupon && (
                <Dialog
                    open={isModalOpen}
                    onClose={closeModal}
                >
                    <DialogTitle>Confirm Claim</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to claim the following coupon?
                        </DialogContentText>
                        <p>{selectedCoupon.description}</p>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClaim(selectedCoupon)} color="primary">
                            Claim
                        </Button>
                        <Button onClick={closeModal} color="primary" autoFocus>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <SuccessModal
                open={isSuccessModalOpen}
                onClose={() => setIsSuccessModalOpen(false)}
            />
        </>
    )
}

export default Coupon;