import React, { useRef } from "react";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import { Outlet } from "react-router-dom";

// Material UI
import Box from "@material-ui/core/Box";
import {
  CalendarToday,
  EventAvailable,
  Person,
  PersonOutlined,
} from "@material-ui/icons";
import Stack from "@mui/material/Stack";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import DensitySmallIcon from "@mui/icons-material/DensitySmall";
import AddIcon from "@mui/icons-material/Add";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import HistoryIcon from "@mui/icons-material/History";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import SearchIcon from "@mui/icons-material/Search";
// import DehazeIcon from "@mui/icons-material/Dehaze";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import {
  AssignmentInd,
  CalendarViewMonth,
  PersonOffOutlined,
  ShoppingBasket,
  StoreMallDirectory,
} from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSuitcase } from "@fortawesome/free-solid-svg-icons";
// Components
import AdminSidebar from "../../components/admin-sidebar";
import { Helmet } from "react-helmet";

const AdminDashboard = () => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const userName = getStorageItem("user_name");
  const avatar = getStorageItem("avatar");
  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    avatar: "",
    role: "",
  });

  const fetchUserInfo = async () => {
    if (!accessToken && !userId) return;
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: userId,
      },
    };
    await axios(getUserInfo)
      .then((response) => {
        if (
          response.data.role !== "SuperAdmin" &&
          response.data.role !== "Admin"
        ) {
          console.log("NOT ADMIN");
          navigate("/pitch");
        }
        setUserInfo({
          firstName: response.data.firstName
            ? response.data.PollsIconfirstName
            : "",
          lastName: response.data.lastName ? response.data.lastName : "",
          avatar: response.data.profilePhoto
            ? response.data.profilePhoto
            : defaultAvatar,
          role: response.data.role ? response.data.role : null,
        });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };

  const SideBarData = [
    {
      section: 1,
      name: "AdminChats",
      icon: <EmojiEventsIcon />,
      items: [
        {
          _id: 1,
          name: "All Chats",
          icon: <EventAvailable />,
          path: "/admin/all-chats",
        },
        {
          _id: 1,
          name: "Search user ",
          icon: <EventAvailable />,
          path: "/admin/searchChat",
        },
      ],
    },
    {
      section: 2,
      name: "Deals",
      icon: <LocalOfferIcon />,
      items: [
        {
          _id: 1,
          name: "All Deals",
          icon: <DensitySmallIcon />,
          path: "/admin/all-deals",
        },

        {
          _id: 3,
          name: "Create",
          icon: <AddIcon />,
          path: "/admin/create-deal",
        },
        {
          _id: 4,
          name: "Update",
          icon: <UpgradeIcon />,
          path: "/admin/update-deal",
        },
        // {
        //   _id: 5,
        //   name: "Delete",
        //   icon: <DeleteOutlineIcon />,
        //   path: "/admin/delete-event",
        // },/Hide delete menu
        {
          _id: 6,
          name: "Redeem History",
          icon: <HistoryIcon />,
          path: "/admin/redeem-history",
        },
        {
          _id: 6,
          name: "Inactive Deals",
          icon: <DeleteOutlineIcon />,
          path: "/admin/inactive-deals",
        },
      ],
    },
    {
      section: 3,
      name: "Carousel",
      icon: <ViewCarouselIcon />,
      items: [
        {
          _id: 1,
          name: "Pitch1 Carousels",
          icon: <DensitySmallIcon />,
          path: "/admin/pitch1-carousel",
        },
        {
          _id: 1,
          name: "Pitch2 Carousels",
          icon: <DensitySmallIcon />,
          path: "/admin/pitch2-carousel",
        },
        {
          _id: 2,
          name: "LandingPage Carousels",
          icon: <DensitySmallIcon />,
          path: "/admin/landingpage-carousel",
        },
        {
          _id: 3,
          name: "ArenaPage Carousels",
          icon: <DensitySmallIcon />,
          path: "/admin/arenacarousel",
        },
        {
          _id: 4,
          name: "Deal Carousels",
          icon: <DensitySmallIcon />,
          path: "/admin/dealcarousel",
        },
        {
          _id: 5,
          name: "Create",
          icon: <AddIcon />,
          path: "/admin/create-carousel",
        },
      ],
    },

    {
      section: 4,
      name: "Default users",
      icon: <PersonIcon />,
      items: [
        {
          _id: 1,
          name: "All",
          icon: <DensitySmallIcon />,
          path: "/admin/default-users",
        },
      ],
    },
    {
      section: 5,
      name: "Jobs",
      icon: <FontAwesomeIcon style={{ marginLeft: "4px" }} icon={faSuitcase} />,
      items: [
        {
          _id: 1,
          name: "All jobs",
          icon: <DensitySmallIcon />,
          path: "/admin/all-jobs",
        },
      ],
    },
    {
      section: 6,
      name: "Reffered users",
      icon: <AssignmentInd />,
      items: [
        {
          _id: 1,
          name: "Today",
          icon: <CalendarToday />,
          path: "/admin/reffered-users/today",
        },
        {
          _id: 2,
          name: "All",
          icon: <CalendarViewMonth />,
          path: "/admin/reffered-users",
        },
      ],
    },
    {
      section: 7,
      name: "Give runs",
      icon: <AssignmentInd />,
      items: [
        {
          _id: 1,
          name: "Search users",
          icon: <CalendarToday />,
          path: "/admin/give-runs/search",
        },
      ],
    },
    {
      section: 8,
      name: "Default run",
      icon: <PersonIcon />,
      items: [
        {
          _id: 1,
          name: "All",
          icon: <DensitySmallIcon />,
          path: "/admin/default-run",
        },
      ],
    },
    {
      section: 9,
      name: "Challenge",
      icon: <PersonIcon />,
      items: [
        {
          _id: 1,
          name: "All",
          icon: <DensitySmallIcon />,
          path: "/admin/challenge",
        },
        {
          _id: 2,
          name: "Create Challenge",
          icon: <DensitySmallIcon />,
          path: "/admin/challenge/create",
        },
      ],
    },
    {
      section: 9,
      name: "Contest",
      icon: <PersonIcon />,
      items: [
        {
          _id: 1,
          name: "All",
          icon: <DensitySmallIcon />,
          path: "/admin/contest",
        },
        {
          _id: 2,
          name: "Create Contest",
          icon: <DensitySmallIcon />,
          path: "/admin/contest/create",
        },
        {
          _id: 2,
          name: "Update",
          icon: <DensitySmallIcon />,
          path: "/admin/contest/update",
        },
        {
          _id: 2,
          name: "Answers",
          icon: <DensitySmallIcon />,
          path: "/admin/contest/answers/:id",
        },
        {
          _id: 3,
          name: "Results",
          icon: <DensitySmallIcon />,
          path: "/admin/contest/results/:id",
        },
      ],
    },
    {
      section: 10,
      name: "Marketplace",
      icon: <ShoppingBasket />,
      items: [
        {
          _id: 1,
          name: "All Sellers",
          icon: <StoreMallDirectory />,
          path: "/admin/marketplace/all-sellers",
        },
        {
          _id: 2,
          name: "All Products",
          icon: <StoreMallDirectory />,
          path: "/admin/marketplace/all-products",
        },
      ],
    },
    {
      section: 11,
      name: "pin Poll & Post",
      icon: <ViewCarouselIcon />,
      items: [
        {
          _id: 1,
          name: "pin-poll-post",
          icon: <DensitySmallIcon />,
          path: "/admin/getallpinpoll",
        },
      ],
    },
    {
      section: 12,
      name: "CoverPhoto",
      icon: <ViewCarouselIcon />,
      items: [
        {
          _id: 1,
          name: "All CoverPhoto",
          icon: <DensitySmallIcon />,
          path: "/admin/getAll-coverphoto",
        },
        {
          _id: 2,
          name: "Create",
          icon: <AddIcon />,
          path: "/admin/create-coverphoto",
        },
      ],
    },
    {
      section: 13,
      name: "App Version",
      icon: <ViewCarouselIcon />,
      items: [
        {
          _id: 2,
          name: "update Version",
          icon: <AddIcon />,
          path: "/admin/app_version",
        },
      ],
    },
    {
      section: 14,
      name: "Events",
      icon: <EmojiEventsIcon />,
      items: [
        {
          _id: 1,
          name: "Find All",
          icon: <EventAvailable />,
          path: "/admin/find-all-events",
        },
        {
          _id: 2,
          name: "Find One",
          icon: <SearchIcon />,
          path: "/admin/find-event",
        },
        {
          _id: 3,
          name: "Create",
          icon: <AddIcon />,
          path: "/admin/create-event",
        },
        {
          _id: 4,
          name: "Update",
          icon: <UpgradeIcon />,
          path: "/admin/update-event",
        },
      ],
    },
    {
      section: 15,
      name: "Fantasy",
      icon: <PersonIcon />,
      items: [
        {
          _id: 1,
          name: "All Matches",
          icon: <DensitySmallIcon />,
          path: "/admin/fantasy",
        },
        {
          _id: 2,
          name: "All Tournaments",
          icon: <DensitySmallIcon />,
          path: "/admin/tournament",
        },
        {
          _id: 3,
          name: "Create Match",
          icon: <DensitySmallIcon />,
          path: "/admin/fantasy/create",
        },
        {
          _id: 4,
          name: "Create Tournament",
          icon: <DensitySmallIcon />,
          path: "/admin/fantasy/createTournament",
        },
      ],
    },
    {
      section: 16,
      name: "Fantasy-Players",
      icon: <PersonIcon />,
      items: [
        {
          _id: 1,
          name: "All Players",
          icon: <DensitySmallIcon />,
          path: "/admin/fantasy-players",
        },
        {
          _id: 2,
          name: "Create Player",
          icon: <DensitySmallIcon />,
          path: "/admin/fantasy-players/create",
        },
      ],
    },
    {
      section: 17,
      name: "Advertisments",
      icon: <PersonIcon />,
      items: [
        {
          _id: 1,
          name: "All",
          icon: <DensitySmallIcon />,
          path: "/admin/ads",
        },
        {
          _id: 2,
          name: "Create",
          icon: <DensitySmallIcon />,
          path: "/admin/ads/create",
        },
      ],
    },
    {
      section: 18,
      name: "Subscription",
      icon: <PersonIcon />,
      items: [
        {
          _id: 1,
          name: "All Subscriptions",
          icon: <DensitySmallIcon />,
          path: "/admin/subscriptions",
        },
        {
          _id: 2,
          name: "Create Subscription",
          icon: <DensitySmallIcon />,
          path: "/admin/subscriptions/create",
        },
        {
          _id: 3,
          name: "Redeem Subscription History",
          icon: <DensitySmallIcon />,
          path: "/admin/subscriptions/getHistory",
        },
      ],
    },
    {
      section: 18,
      name: "Voucher",
      icon: <PersonIcon />,
      items: [
        {
          _id: 1,
          name: "All Vouchers",
          icon: <DensitySmallIcon />,
          path: "/admin/voucher/getAllVouchers",
        },
        {
          _id: 2,
          name: "Create Voucher",
          icon: <DensitySmallIcon />,
          path: "/admin/voucher/create",
        },
      ],
    },
    {
      section: 19,
      name: "Coupons",
      icon: <LocalOfferIcon />,
      items: [
        {
          _id: 1,
          name: "Create Coupon",
          icon: <DensitySmallIcon />,
          path: "/admin/coupons/create",
        },
        {
          _id: 2,
          name: "Active Coupons",
          icon: <DensitySmallIcon />,
          path: "/admin/active-coupons",
        },
      ],
    },
    {
      section: 20,
      name: "Group Chats",
      icon: <LocalOfferIcon />,
      items: [
        {
          _id: 1,
          name: "Add Stickers",
          icon: <DensitySmallIcon />,
          path: "/admin/groupchats/stickers",
        },
        {
          _id: 2,
          name: "Add Ads",
          icon: <DensitySmallIcon />,
          path: "/admin/groupchats/adsSection",
        },
        // {
        //   _id: 2,
        //   name: "Active Coupons",
        //   icon: <DensitySmallIcon />,
        //   path: "/admin/active-coupons",
        // },
      ],
    },
    {
      section: 21,
      name: "Commentary",
      icon: <PersonIcon />,
      items: [
        {
          _id: 1,
          name: "Create",
          icon: <DensitySmallIcon />,
          path: "/admin/commentary/create",
        },
        {
          _id: 2,
          name: "User Videos",
          icon: <DensitySmallIcon />,
          path: "/admin/videosByUser",
        },
        {
          _id: 3,
          name: "Admin Videos",
          icon: <DensitySmallIcon />,
          path: "/admin/videosByAdmin",
        },
      ],
    },
    {
      section: 22,
      name: "Guest Pitch-Selection",
      icon: <PersonIcon />,
      items: [
        {
          _id: 1,
          name: "Guest Pitch",
          icon: <DensitySmallIcon />,
          path: "/admin/guestPitchSelection",
        },
      ],
    },
    {
      section: 23,
      name: "Push Notification",
      icon: <PersonIcon />,
      items:[
        {
          _id:1,
          name: "All Devices",
          icon: <DensitySmallIcon />,
          path: "/admin/pushNotificationToAll",
        },
        {
          _id:2,
          name: "Groups",
          icon: <DensitySmallIcon />,
          path: "/admin/pushNotificationToGroup",
        }
      ]
    },
    {
      section: 24,
      name: "Auction Ace",
      icon: <EmojiEventsIcon />,
      items: [
        {
          _id: 1,
          name: "Create",
          icon: <AddIcon />,
          path: "/admin/create",
        },
        {
          _id:2,
          name:"Players List",
          icon : <DensitySmallIcon />,
          path:"/admin/playersList",
        },
        {
          _id: 3,
          name: "Create Team",
          icon: <AddIcon />,
          path: "/admin/createTeam",
        },
        {
          _id:4,
          name:"Teams List",
          icon : <DensitySmallIcon />,
          path:"/admin/teamsList",
        }
      ],
    },
    {
      section: 25,
      name: "Pitch Scorecard",
      icon: <EmojiEventsIcon />,
      items: [
        {
          _id: 1,
          name: "Create",
          icon: <AddIcon />,
          path: "/admin/create-score",
        },
        {
          _id:2,
          name:"All Scorecards",
          icon : <DensitySmallIcon />,
          path:"/admin/all-scores",
        },
      ],
    },
  ];

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const [showSidebar, setShowSidebar] = useState(true);
  const openSideBar = () => {
    setShowSidebar(true);
  };
  const closeSideBar = () => {
    setShowSidebar(false);
  };
  const menuRef = useRef();

  return (
    <div>
      <Helmet>
        <title>Champhunt | Admin</title>
      </Helmet>

      <Stack direction="row" spacing="2" justifyContent="space-between">
        {showSidebar ? (
          <Box ref={menuRef}>
            <AdminSidebar SideBarData={SideBarData} />
          </Box>
        ) : null}
        <Box p={2} flex={5} sx={{ position: "relative" }}>
          <Box>
            {!showSidebar ? (
              <NavigateNextIcon
                sx={{
                  position: "absolute",
                  left: "10px",
                  top: "10px",
                  cursor: "pointer",
                }}
                onClick={openSideBar}
              />
            ) : (
              <CloseIcon
                sx={{
                  position: "absolute",
                  left: "-49px",
                  top: "7px",
                  cursor: "pointer",
                }}
                onClick={closeSideBar}
              />
            )}
          </Box>
          <Outlet />
        </Box>
      </Stack>
    </div>
  );
};

export default AdminDashboard;
