import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Box, InputLabel, Input, MenuItem } from "@mui/material";
import coverimage from "../../../assets/images/profile/camera.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useEffect } from "react";
import ProfileCrop from "./ProfileCrop";
import addicon from "../../../assets/images/profile/Addicon.png";
import removeicon from "../../../assets/images/profile/removeicon.png";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getStorageItem } from "../../../utils/sessionStorage";
import defaultAvatar from "../../../assets/images/profile/default_avatar.png";
import { isValidNumber } from "libphonenumber-js";
import axios from "axios";

const genders = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
];
export default function ProfileEditForm() {
  const [validator, setValidator] = useState(true);
  const [OpenCrop, setOpenCrop] = useState(false);
  const [Players, setPlayers] = useState([1, 2]);
  const [croppedImage, setcropedImage] = useState(null);
  const [tempimage, settempImage] = useState(null);
  const [changeCountry, setchangecountry] = useState(false);
  const [PlayersList, setPlayersList] = useState([]);
  const [userInfo, setUserInfo] = useState([]);
  const [countries, setCountries] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const accessToken = getStorageItem("token");
  const localStorageUserId = getStorageItem("user_id");
  const navigate = useNavigate();

  const trigger = () => {
    axios("https://restcountries.com/v2/all")
      .then((data) => {
        setCountries(data.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("uploader", file);
    const response = await axios.post(
      `${global.config.ROOTURL.prod}/upload-file`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
        },
      }
    );
    if (response.data) {
      return response.data[0].location;
    } else {
      console.log("error occured:");
      throw new Error(`Failed to upload image ${file}`);
    }
  };
  const handlesetCropImage = async (data, crop) => {
    settempImage(crop);
    const imageforuplaod = await uploadImage(data);
    setcropedImage(imageforuplaod);
  };
  const handleCloseCrop = () => {
    setOpenCrop(false);
  };
  const handleOPenCrop = () => {
    setOpenCrop(true);
  };
  const handleRemoveOption = (i) => {
    if (i > 0) {
      const newList = Array.from(Players);
      newList.pop();
      setPlayers(newList);
    }
  };

  const fetchUserInfo = () => {
    if (!localStorageUserId) return;
    const getUserInfo = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/profile/",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: {
        userId: localStorageUserId,
      },
    };
    axios(getUserInfo)
      .then((response) => {
        setIsChecked(response.data.showDob);
        setUserInfo({
          firstName: response.data.firstName ? response.data.firstName : "",
          lastName: response.data.lastName ? response.data.lastName : "",
          avatar: response.data.profilePhoto
            ? response.data.profilePhoto
            : defaultAvatar,
          gender: response.data.Gender ? response.data.Gender : "",
          country: response.data.Country ? response.data.Country : "",
          DOB: response.data.DOB ? response.data.DOB : "",
          aboutMe: response.data.aboutMe ? response.data.aboutMe : "",
          countryFlag: response.data.countryFlag
            ? response.data.countryFlag
            : "",
          MobileNumber: response?.data?.mobileNo
            ? response?.data?.mobileNo
            : "",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlecountry = (value) => {
    setUserInfo({
      ...userInfo,
      country: value ? value.name : "",
    });
    setchangecountry(true);
  };

  const handleSubmit = async () => {
    if (!userInfo) return;
    const { firstName, lastName, gender, country, DOB, MobileNumber } =
      userInfo;
    let errors = {};
    setValidator(true);
    if (!firstName) {
      setValidator(false);
      errors.firstName = "First name is required";
    }
    if (!lastName) {
      setValidator(false);
      errors.lastName = "Last name is required";
    }
    if (!gender) {
      setValidator(false);
      errors.gender = "gender is required";
    }
    if (!country) {
      setValidator(false);
      errors.country = "country is required";
    }
    if (!DOB) {
      setValidator(false);
      errors.DOB = "DOB is required";
    }
    if (!MobileNumber) {
      setValidator(false);
      errors.MobileNumber = "MobileNumber is required";
    }
    if (!isValidNumber(MobileNumber, "IN")) {
      setValidator(false);
      errors.MobileNumber = "fill correct MobileNumber";
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      if (changeCountry && countries) {
        let FlagImage = countries.filter((i) => country === i.name);
        let imagePass = FlagImage[0].flags.png;
        // covert image to fil
        const convertPngUrlToFile = async (pngUrl, filename) => {
          try {
            const response = await fetch(pngUrl);
            const blob = await response.blob();
            const file = new File([blob], filename, { type: "image/png" });

            return file;
          } catch (error) {
            console.error("Error converting PNG URL to File:", error);
            return null;
          }
        };

        await convertPngUrlToFile(imagePass, "image.png").then((file) => {
          if (file) {
            setUserInfo({
              ...userInfo,
              countryFlag: file,
            });
            saveProfile(file);
          } else {
            console.log("File conversion failed.");
          }
        });
      } else {
        saveProfile();
      }
    }
  };

  const saveProfile = async (file) => {
    const { firstName, aboutMe, lastName, gender, country, DOB, MobileNumber } =
      userInfo;

    if (!accessToken) return;
    let profileData = new FormData();
    profileData.append("aboutMe", aboutMe);
    profileData.append("firstName", firstName);
    profileData.append("lastName", lastName);
    profileData.append("gender", gender);
    profileData.append("country", country);
    profileData.append("DOB", DOB);
    profileData.append("showDob", isChecked);
    if (croppedImage) {
      profileData.append("profilePhoto", croppedImage);
    }
    profileData.append("mobileNo", MobileNumber);
    if (file) {
      profileData.append("uploader", file);
    }

    const saveProfileOptions = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/update/",
      data: profileData,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(saveProfileOptions)
      .then(() => {
        localStorage.setItem('first_name', firstName)
        localStorage.setItem('last_name', lastName)
        localStorage.setItem('full_name', `${firstName} ${lastName}`)
        navigate(`/profile/${localStorageUserId}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchUserInfo();
    trigger();
  }, []);

  const countryOptions = Object.keys(countries).map((countryCode) => ({
    code: countryCode,

    name: countries[countryCode].name,
  }));

  return (
    <div>
      <div className="ProfileimageContainer">
        {tempimage ? (
          <img src={tempimage} className="ProfileSetImage" alt="profile" />
        ) : (
          <img
            src={userInfo?.avatar}
            className="ProfileSetImage"
            alt="profile"
          />
        )}
        <img
          src={coverimage}
          onClick={handleOPenCrop}
          className="childImageprofile"
          alt="cover"
        />
      </div>

      {userInfo && (
        <div className="profile__editformbox">
          <div className="editform_div1">
            <InputLabel htmlFor="first-name" sx={{ mt: 2, color: "#242424" }}>
              First Name
            </InputLabel>
            <TextField
              onChange={(e) => {
                setUserInfo({
                  ...userInfo,
                  firstName: e.target.value,
                });
              }}
              value={userInfo?.firstName}
              id="firstName"
              label={!userInfo?.firstName ? "First Name*" : ""}
              size="medium"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={formErrors.firstName}
              helperText={formErrors.firstName}
            />
          </div>

          <div className="editform_div2">
            <InputLabel htmlFor="first-name" sx={{ mt: 2, color: "#242424" }}>
              Last Name
            </InputLabel>
            <TextField
              onChange={(e) => {
                setUserInfo({
                  ...userInfo,
                  lastName: e.target.value,
                });
              }}
              value={userInfo?.lastName}
              id="lastName"
              label={userInfo?.lastName ? "" : "Last Name*"}
              size="medium"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={formErrors.lastName}
              helperText={formErrors.lastName}
            />
          </div>

          <div className="editform_div1">
            <InputLabel htmlFor="gender" sx={{ mt: 2, color: "#242424" }}>
              Gender
            </InputLabel>
            <TextField
              onChange={(e) => {
                setUserInfo({
                  ...userInfo,
                  gender: e.target.value,
                });
              }}
              value={userInfo?.gender}
              id="gender"
              select
              label={userInfo?.gender ? userInfo?.gender : "Gender"}
              size="medium"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={formErrors.gender}
              helperText={formErrors.gender}
            >
              {genders.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="editform_div2">
            <InputLabel htmlFor="countrys" sx={{ mt: 2, color: "#242424" }}>
              Country
            </InputLabel>
            <Autocomplete
              options={countries}
              getOptionLabel={(country) => country.name}
              onChange={(event, value) => handlecountry(value)}
              value={userInfo?.country}
              id="country"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={userInfo?.country ? userInfo?.country : "Country*"}
                  size="medium"
                  variant="outlined"
                  fullWidth
                  sx={{ marginBottom: 2 }}
                  error={formErrors.country}
                  helperText={formErrors.country}
                />
              )}
            />
          </div>

          <div className="editform_div1">
            <InputLabel
              htmlFor="Mobile-Number"
              sx={{ mt: 2, color: "#242424" }}
            >
              Mobile Number
            </InputLabel>
            <TextField
              onChange={(e) => {
                setUserInfo({
                  ...userInfo,
                  MobileNumber: e.target.value,
                });
              }}
              type="tel"
              value={userInfo?.MobileNumber}
              id="Mobile Number"
              label={userInfo?.MobileNumber ? "" : "Mobile Number*"}
              size="medium"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={formErrors.MobileNumber}
              helperText={formErrors.MobileNumber}
            />
          </div>

          <div className="editform_div2">
            <InputLabel htmlFor="About-Me" sx={{ mt: 2, color: "#242424" }}>
              Date of Birth
            </InputLabel>
            <TextField
              type="date"
              onChange={(e) => {
                setUserInfo({
                  ...userInfo,
                  DOB: e.target.value,
                });
              }}
              value={userInfo?.DOB}
              id="DOB"
              label={userInfo?.DOB ? "" : "Date of Birth*"}
              size="medium"
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
              error={formErrors.DOB}
              helperText={formErrors.DOB}
            />
            <FormControlLabel
              style={{ marginTop: "-15px" }}
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
              }
              label="Show Date of Birth On Profile"
            />
          </div>

          <div className="editform_div1">
            <Box>
              <InputLabel htmlFor="About-Me" sx={{ color: "#242424" }}>
                About Me
              </InputLabel>
              <TextField
                onChange={(e) => {
                  setUserInfo({
                    ...userInfo,
                    aboutMe: e.target.value,
                  });
                }}
                value={userInfo?.aboutMe}
                id="aboutMe"
                label={userInfo?.aboutMe ? "" : "About Me"}
                size="medium"
                variant="outlined"
                multiline // Enable multi-line input
                rows={4} // Specify the number of rows to display
                fullWidth
                sx={{ marginBottom: 2 }}
              />
            </Box>
          </div>

          {OpenCrop && (
            <ProfileCrop
              open={OpenCrop}
              handleclose={handleCloseCrop}
              handlecropImage={handlesetCropImage}
              imageforcrop={userInfo?.avatar}
            />
          )}

          <div className="editform_div3">
            <Button
              className="Btn"
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleSubmit} className="Btn SaveBtn">
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
