import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { Grid, Card, CardMedia, CardContent, Typography, Box, Button, IconButton, ButtonBase } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import DealNotification from './dealNotification';
import DealPopUpCard from './dealPopUp';
import SortByPopup from "./sortPopUp";
import './style.scss';
import LoanModel from './loadModal';

// images
import sortIcon from "../../assets/images/deals/Filter.svg";
import defaultImg from "../../assets/images/deals/Default Deal.png";
import cardBackground from "../../assets/images/deals/dealbackgroundImg.jpeg";
import dealBallImg from "../../assets/images/deals/bxs_cricket-ball.svg";
import Coupon from '../userCoupons/Coupon';
import Vouchers from '../vouchers/Vouchers';
import greyDealBallImg from "../../assets/images/deals/locked-bxs_cricket-ball.svg";
import subscriberImg from "../../assets/images/deals/deakcornerimage.png";


const LimitedOfferBox = ({ deal, mobileView }) => {
    const [remainingTime, setRemainingTime] = useState(0);
    const [dealText, setDealText] = useState("");

    useEffect(() => {
        const calculateRemainingTime = () => {
            const startTime = new Date(deal?.startDateAndTime).getTime();
            const endTime = new Date(deal?.endDateAndTime).getTime();
            const currentDate = new Date();
            currentDate.setHours(currentDate.getHours() + 5);

            currentDate.setMinutes(currentDate.getMinutes() + 30);
            const currentTime = currentDate.getTime();
            if (currentTime < startTime) {
                setRemainingTime(startTime - currentTime);
                setDealText("Offer Live in")
            } else if (currentTime > endTime) {
                setRemainingTime(0);
            } else {
                setRemainingTime(endTime - currentTime);
                setDealText("Offer Ends in")
            }
        };
        const interval = setInterval(calculateRemainingTime, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [deal?.startDateAndTime, deal?.endDateAndTime]);

    const formatTime = (time) => {
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((time / (1000 * 60)) % 60);
        const seconds = Math.floor((time / 1000) % 60);

        const formattedDays = days.toString().padStart(2, "0");
        const formattedHours = hours.toString().padStart(2, "0");
        const formattedMinutes = minutes.toString().padStart(2, "0");
        const formattedSeconds = seconds.toString().padStart(2, "0");

        // return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;

        if (days > 0) {
            return `${formattedDays}:${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        } else {
            return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        }
    };

    return (
        <>
            {deal.categories === "TimeLimit" && (
                <Box
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        color: "white",
                        padding: "5px",
                        margin: "0 10px",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        textAlign: "center",
                        fontSize: mobileView ? "10px" : "12px",
                    }}
                >
                    {dealText} : {formatTime(remainingTime)}
                </Box>
            )}
        </>
    );
};

const AllDeals = () => {
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const navigate = useNavigate();
    const mobileView = useMediaQuery("(max-width:768px)");

    const [sortOpen, setSortOpen] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [dealId, setDealId] = useState("");
    const [deals, setDeals] = useState([]);
    const [filterModal, setFilterModal] = useState(false);
    const [filterName, setFilterName] = useState("");
    const [dealsPerPage] = useState(8);
    const [currentPage, setCurrentPage] = useState(1);
    const [indexOfLastDeal, setIndexOfLastDeal] = useState(currentPage * dealsPerPage)
    const [indexOfFirstDeal, setIndexOfFirstDeal] = useState(indexOfLastDeal - dealsPerPage)
    const [isSubscriber, setIsSubscriber] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [originalDealsList, setOriginalDealsList] = useState([]);
    const [profileData, setProfileData] = useState({});
    const [loanModelPopUp, setLoanModelPopUp] = useState(false);

    // Number convertion as per US standards 
    function formatRunsNumber(runs) {
        if (runs >= 1000) {
            return (runs / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
        }
        return runs.toString();
    }
    const [sortDeal, SetSortDeal] = useState("Popular")
    let filteredDeal = [...deals];


    // locked , etc. popup
    const handleOpenNotification = (deal) => {
        setDealId(deal);
        setIsNotificationOpen(true);
    };

    const handleCloseNotification = () => {
        setIsNotificationOpen(false);
    };

    // Redeem popup
    const handleDealOpenPopup = (deal) => {
        setDealId(deal);
        setPopupOpen(true);
    };

    const handleDealClosePopup = () => {
        setPopupOpen(false);
    };

    const handleLoanModalOpenPopup = () => {
        console.log('hey mate')
        setLoanModelPopUp(true);
    };

    const handleLoanModalClosePopup = () => {
        setLoanModelPopUp(false);
    };

    const currentDateTime = new Date();
    currentDateTime.setHours(currentDateTime.getHours() + 5);

    currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
    const calculateOffPrice = (redeemrun, discount) => {
        return Math.round((redeemrun * discount) / 100);
    };

    const calculatePriceAfterDiscount = (redeemrun, discount) => {
        return Math.round(redeemrun - (redeemrun * discount) / 100);
    };

    const getUserProfile = () => {
        const getUserInfo = {
            method: "POST",
            url: global.config.ROOTURL.prod + "/auth/profile/",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            data: {
                userId: userId,
            },
        };
        axios(getUserInfo)
            .then((response) => {
                setProfileData(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
    };

    const checkUserIsSubscriber = () => {
        if (!accessToken) return;
        if (!userId) return console.error("userId required");
        const getTotalRun = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/pay/isSubscriber/" + userId,
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(getTotalRun)
            .then((response) => {
                if (response.data?.status === "active") {
                    setIsSubscriber(true);
                } else {
                    setIsSubscriber(false);
                }
            })
            .catch((error) => {
                console.log("1");
                console.log(error);
            });
    };

    let getDealsOptions;
    if (accessToken) {
        getDealsOptions = {
            method: 'get',
            url: global.config.ROOTURL.prod + '/deal/getAllWeb',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            json: true
        };
    }
    else {
        console.log(2);
        getDealsOptions = {
            method: 'get',
            url: global.config.ROOTURL.prod + '/deal/getAllWebWithoutLogin',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            json: true
        };
    }

    useEffect(() => {
        axios(getDealsOptions)
            .then(response => {
                console.log(response.data)
                setDeals(response.data);
                setOriginalDealsList(response.data);
            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    clearStorage();
                    navigate('/login');
                }
            });
    }, []);

    useEffect(() => {
        setCurrentPage(1);
    }, [filterName]);

    useEffect(() => {
        checkUserIsSubscriber();
        getUserProfile();
    }, []);

    const ApplySorting = () => {        
        // SORT FUNCTIONALITY
    
    
        if (sortDeal === 'Price Low to High') {
          filteredDeal = [...filteredDeal].sort((a, b) => a.redeemrun - b.redeemrun)
        }
        else if (sortDeal === 'Price High to Low') {
          filteredDeal = [...filteredDeal].sort((a, b) => b.redeemrun - a.redeemrun)
        }
        else if (sortDeal === 'A to Z') {
          filteredDeal = [...filteredDeal].sort((a, b) => a.name.localeCompare(b.name))
        }
        else if (sortDeal === 'Z to A') {
          filteredDeal = [...filteredDeal].sort((a, b) => b.name.localeCompare(a.name))
        }
        else if (sortDeal === 'New') {
          filteredDeal = [...filteredDeal].sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
        }
        else {
          filteredDeal = [...originalDealsList]
        }
    
        setDeals(filteredDeal !== undefined ? filteredDeal : deals)
        setSortOpen(false)
    }

    return (
        <Box sx={{ marginTop: "20px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
                <ButtonBase onClick={handleLoanModalOpenPopup}>
                    <Typography
                        sx={{
                            color: "rgba(66, 66, 66, 1)",
                            fontSize: "24px",
                            fontFamily: "Helvetica",
                            fontWeight: "700"
                        }}
                    >
                        Borrowing Runs
                    </Typography>
                    </ButtonBase>

                <IconButton onClick={() => setSortOpen(true)} sx={{ position: "relative" }}>
                    <img alt="Sort Icon" src={sortIcon} />
                </IconButton>
                <SortByPopup open={sortOpen} onClose={() => setSortOpen(false)} sortDeal={sortDeal} changeSort={(newSortDeal) => SetSortDeal(newSortDeal) } ApplySorting={() => ApplySorting()} />
            </Box>

            <Box sx={{ padding: mobileView ? 1 : 2 }}>
                <Grid container spacing={2} columns={{ xs: 12, sm: 12, md: 12 }}>
                    {deals.map((deal, index) => (
                        <Grid item xs={6} sm={6} md={3} key={deal._id}>
                            <Card
                                sx={{
                                    width: mobileView ? "100%" : "100%",
                                    position: "relative",
                                    border: "1px solid rgba(0, 0, 0, 0.1)",
                                    borderRadius: 2,
                                    backgroundImage: `url(${cardBackground})`,
                                    backgroundSize: "cover",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    boxShadow: "none",
                                    transition: "transform 0.3s ease",
                                    "&:hover": {
                                        transform: "scale(1.05)",
                                    },
                                }}
                            >
                                <Box sx={{ height: mobileView ? "120px" : "160px", position: "relative", padding: "10px 10px 0 10px" }}>

                                    <Typography
                                        sx={{
                                            backgroundColor: deal.comingSoon ? "rgb(44 143 63)" : deal.isLocked && !deal.unlockedByUser ? "grey" : (deal.count === 0 || deal.count < 0) ? "grey" : "none",
                                            color: "white",
                                            padding: "3px 5px",
                                            position: "absolute",
                                            top: "0px",
                                            left: "0px",
                                            borderBottomRightRadius: "4px",
                                            width: "120px",
                                            textAlign: "center",
                                        }}>
                                        {deal.comingSoon ? "Coming Soon" : deal.isLocked && !deal.unlockedByUser ? "Locked" : (deal.count === 0 || deal.count < 0) ? "Out of Stock" : ""}
                                    </Typography>

                                    {deal.SubscribedUser &&
                                        < Box sx={{
                                            position: "absolute",
                                            top: "0px",
                                            right: "0px",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            padding: "8px 6px 1px 0px",
                                            borderBottomLeftRadius: "12px",
                                            borderLeft: "2px solid rgba(235, 169, 40, 1)",
                                        }}>
                                            <Box
                                                component="img"
                                                src={subscriberImg}
                                                alt="for subscriber"
                                                sx={{
                                                    width: "35px",
                                                }}
                                            />
                                        </Box>
                                    }

                                    {/* Image Section */}
                                    <CardMedia
                                        component="img"
                                        image={deal.dealImage ? deal.dealImage : defaultImg}
                                        alt="deal image"
                                        onError={(e) => e.target.src = defaultImg}
                                        sx={{
                                            height: "100%",
                                            borderRadius: "10px"
                                        }}
                                    />

                                    {/* Offer Time (Overlay on Bottom of Image) */}
                                    {(deal.categories === "TimeLimit") &&
                                        <LimitedOfferBox deal={deal} mobileView={mobileView} />
                                    }
                                </Box>

                                <Box sx={{ marginTop: "10px" }}>
                                    <div className="deal-reamininggiftcontainer">
                                        <div
                                            className="deal-reamaning"
                                            style={{
                                                width: deal.count > 0
                                                    ? `${(deal.count * 100) / deal.totalDeal}%`
                                                    : "0%",
                                            }}
                                        >
                                        </div>
                                    </div>
                                </Box>

                                {/* Content */}
                                <CardContent sx={{ textAlign: "center" }}>
                                    <Box display="flex" justifyContent={deal.discount ? "space-evenly" : "center"}>
                                        <Typography
                                            sx={{
                                                color: "rgba(66, 66, 66, 1)",
                                                fontSize: "18px",
                                                fontFamily: "Helvetica",
                                                fontWeight: "700"
                                            }}>
                                            {deal.name.length > 20
                                                ? deal.name.substring(0, 20) + "..."
                                                : deal.name}
                                        </Typography>


                                        {deal?.discount > 0 &&
                                            new Date(deal?.discountStartDateAndTime) <= currentDateTime &&
                                            new Date(deal?.discountEndDateAndTime) >= currentDateTime &&
                                            <Typography sx={{
                                                color: "rgba(74, 97, 121, 1)",
                                                fontSize: "18px",
                                                fontFamily: "Helvetica",
                                                fontWeight: "400",
                                                textDecoration: "line-through",
                                            }}>
                                                {formatRunsNumber(deal.redeemrun)}
                                            </Typography>
                                        }
                                    </Box>

                                    {deal.count ? (
                                        deal.comingSoon ? (
                                            <Button alt="coming-soon" onClick={() => handleOpenNotification(deal)} variant="contained"
                                                sx={{
                                                    marginTop: "10px",
                                                    backgroundColor: "rgba(202, 204, 206, 1)",
                                                    color: "rgba(255, 255, 255, 1)",
                                                    "&:hover": {
                                                        backgroundColor: "rgba(202, 204, 206, 1)",
                                                    },
                                                    boxShadow: "none",
                                                    width: "100px"
                                                }}>
                                                <img src={greyDealBallImg} style={{ width: "16px", marginRight: "5px" }} /> {formatRunsNumber(deal.redeemrun)}
                                            </Button>
                                        ) : (deal.SubscribedUser && !isSubscriber) ? (
                                            <Button
                                                variant="contained"
                                                onClick={() => navigate('/champhunt-plus')}
                                                sx={{
                                                    backgroundImage: "linear-gradient(90deg, #FECD08, #F74E01, #EB128D)",
                                                    "&:hover": {
                                                        backgroundImage: "linear-gradient(90deg, #EB128D, #F74E01, #FECD08)", // Reverse the gradient
                                                    },
                                                    marginTop: "10px",
                                                    color: "white",
                                                    width: "100px",
                                                    boxShadow: "none",
                                                }}>
                                                <img src={dealBallImg} style={{ width: "16px", marginRight: "5px" }} /> {formatRunsNumber(deal.redeemrun)}
                                            </Button>
                                        ) : deal.isLocked && !deal.unlockedByUser ? (
                                            <Button onClick={() => handleOpenNotification(deal)} variant="contained"
                                                sx={{
                                                    marginTop: "10px",
                                                    backgroundColor: "rgba(202, 204, 206, 1)",
                                                    color: "rgba(255, 255, 255, 1)",
                                                    "&:hover": {
                                                        backgroundColor: "rgba(202, 204, 206, 1)",
                                                    },
                                                    boxShadow: "none",
                                                    width: "100px"
                                                }}>
                                                <img src={greyDealBallImg} style={{ width: "16px", marginRight: "5px" }} /> {formatRunsNumber(deal.redeemrun)}
                                            </Button>
                                        ) : (
                                            deal?.discount > 0 &&
                                            new Date(deal?.discountStartDateAndTime) <= currentDateTime &&
                                            new Date(deal?.discountEndDateAndTime) >= currentDateTime) ? (
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundImage: "linear-gradient(90deg, #FECD08, #F74E01, #EB128D)",
                                                    "&:hover": {
                                                        backgroundImage: "linear-gradient(90deg, #EB128D, #F74E01, #FECD08)",
                                                    },
                                                    marginTop: "10px",
                                                    color: "white",
                                                    boxShadow: "none",
                                                    width: "100px"
                                                }}
                                                onClick={() => handleDealOpenPopup(deal)}
                                            >
                                                <img src={dealBallImg} style={{ width: "16px", marginRight: "5px" }} /> {calculatePriceAfterDiscount(deal?.redeemrun, deal?.discount)}{" "}
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundImage: "linear-gradient(90deg, #FECD08, #F74E01, #EB128D)",
                                                    "&:hover": {
                                                        backgroundImage: "linear-gradient(90deg, #EB128D, #F74E01, #FECD08)",
                                                    },
                                                    marginTop: "10px",
                                                    color: "white",
                                                    boxShadow: "none",
                                                    width: "100px"
                                                }}
                                                onClick={() => handleDealOpenPopup(deal)}
                                            >
                                                <img src={dealBallImg} style={{ width: "16px", marginRight: "5px" }} /> {formatRunsNumber(deal.redeemrun)}
                                            </Button>
                                        )
                                    ) : (
                                        <Button alt="out-of-stock" onClick={() => handleOpenNotification(deal)} variant="contained"
                                            sx={{
                                                marginTop: "10px",
                                                backgroundColor: "rgba(202, 204, 206, 1)",
                                                color: "rgba(255, 255, 255, 1)",
                                                "&:hover": {
                                                    backgroundColor: "rgba(202, 204, 206, 1)",
                                                },
                                                boxShadow: "none",
                                                width: "100px"
                                            }}>
                                            <img src={greyDealBallImg} style={{ width: "16px", marginRight: "5px" }} /> {formatRunsNumber(deal.redeemrun)}
                                        </Button>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                    <Coupon sortDeal={sortDeal}/>
                    <Vouchers sortDeal={sortDeal} />
                </Grid>
            </Box>
            {
                dealId && (
                    <DealPopUpCard open={popupOpen} handleClose={handleDealClosePopup} deal={dealId} profileData={profileData} />
                )
            }

            {
                isNotificationOpen && (
                    <DealNotification open={isNotificationOpen} onClose={handleCloseNotification} data={dealId} />
                )
            }

            {
                loanModelPopUp && (
                    <LoanModel openModel={loanModelPopUp} handleCloseModel={handleLoanModalClosePopup} />
                )
            }
        </Box >
    )
}

export default AllDeals;