import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { getAuthToken } from "../../utils/auth";
import { clearStorage } from "../../utils/sessionStorage";
import SortIcon from '@mui/icons-material/Sort';
import { Box, Button, useMediaQuery } from "@mui/material";
import SingleGift from "./SingleGift";
import FilterModal from './FilterModal'
import CustomPagination from './custompagination'
import './index.scss';
import LoanModel from "../deal-description/LoanModel";
import LoanInformaion from "./LoanInformaion";
import Coupons from "../userCoupons/coupons";
import FilterListIcon from '@mui/icons-material/FilterList';
import "./index.scss";
import Vouchers from "../vouchers/index";
import { BallTriangle } from "react-loader-spinner";


export default function Gift({ userRuns }) {
  const navigate = useNavigate();
  const [deals, setDeals] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const mobileView = useMediaQuery("(max-width:769px)");
  const [filterName, setFilterName] = useState("");
  const handleClose = () => setFilterModal(!filterModal);
  const [dealsPerPage] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);
  const [indexOfLastDeal, setIndexOfLastDeal] = useState(currentPage * dealsPerPage)
  const [indexOfFirstDeal, setIndexOfFirstDeal] = useState(indexOfLastDeal - dealsPerPage)
  const [paginatedDeals, setPaginatedDeals] = useState([]);
  const [LoanModel, setLaonModel] = useState(false);
  const [activeFilter, setActiveFilter] = useState("All");

  const filterDeals = [
    // {
    //   id: 1,
    //   name: "All",
    //   bgColor: "rgba(12, 91, 160, 1)"
    // },
    // {
    //   id: 2,
    //   name: "Deals",
    //   bgColor: "rgba(243, 113, 33, 1)"
    // },
    // {
    //   id: 3,
    //   name: "Coupons",
    //   bgColor: "rgba(136, 4, 153, 1)"
    // },
    // {
    //   id: 4,
    //   name: "Voucher",
    //   bgColor: "rgba(212, 136, 6, 1)"
    // }
  ]

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCloseModel = () => {
    setLaonModel(false);
  };

  const accessToken = getAuthToken();


  let getDealsOptions;
  if (accessToken) {
    getDealsOptions = {
      method: 'get',
      url: global.config.ROOTURL.prod + '/deal/getAllWeb',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + accessToken
      },
      json: true
    };
  }
  else {
    console.log(2);
    getDealsOptions = {
      method: 'get',
      url: global.config.ROOTURL.prod + '/deal/getAllWebWithoutLogin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      json: true
    };
  }

  useEffect(() => {
    setLoading(true);  // Start loading
    axios(getDealsOptions)
      .then(response => {
        setDeals(response.data);
        setLoading(false);  // End loading
      })
      .catch(error => {
        setLoading(false);  // End loading on error
        if (error?.response?.status === 401) {
          clearStorage();
          navigate('/login');
        }
      });
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [filterName]);

  const paginatedDeals1 = useMemo(() => {
    const startIndex = (currentPage - 1) * dealsPerPage;
    return deals.slice(startIndex, startIndex + dealsPerPage);
  }, [currentPage, deals]);

  return (
    <div className="MainDealContainer">
      {loading ? (
        <div className="loader-container">
          <BallTriangle color="#808080" height={100} width={100} />
        </div>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '0px 10px',
              alignItems: mobileView ? 'start' : 'end',
              justifyContent: 'space-between',
              flexDirection: mobileView ? 'column' : 'row',
            }}
          >
            <Box display="flex" sx={{ padding: mobileView ? '0 0 10px 0' : '0px 10px', gap: mobileView ? '5px' : 2 }}>
              {filterDeals.map((filter, index) => (
                <Button
                  key={index}
                  variant="contained"
                  style={{
                    backgroundColor: activeFilter === filter.name ? filter.bgColor : "white",
                    color: activeFilter === filter.name ? "white" : filter.bgColor,
                    boxShadow: "none",
                    borderRadius: "12px",
                    border: "2px solid",
                  }}
                  onClick={() => setActiveFilter(filter.name)}
                  className="filterdealsbuttonsnew"
                >
                  {filter.name}
                </Button>
              ))}
            </Box>
            <FilterListIcon className="sort-icon" onClick={handleClose} />
          </div>

          <div className="AllgiftContainer">
            {(activeFilter === "All" || activeFilter === "Deals") && (
              <div className="giftContainer__inner">
                {!mobileView && paginatedDeals1.map((deal, index) => (
                  <div key={index} className="giftcardContainer">
                    <SingleGift deal={deal} userRuns={userRuns} />
                  </div>
                ))}
                {mobileView && deals.map((deal, index) => (
                  <div key={index} className="giftcardContainer">
                    <SingleGift deal={deal} userRuns={userRuns} />
                  </div>
                ))}
              </div>
            )}
            {(activeFilter === "All" || activeFilter === "Coupons") && <Coupons />}
            {(activeFilter === "All" || activeFilter === "Voucher") && <Vouchers />}
            <div style={{ marginTop: "20px" }}>
              {!mobileView && deals?.length > 0 && (
                <div className="custom-pagination">
                  <CustomPagination
                    totalDeals={deals.length}
                    dealsPerPage={dealsPerPage}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                  />
                </div>
              )}
            </div>
          </div>

          {filterModal && (
            <FilterModal
              setFilter={setFilterName}
              open={filterModal}
              handleClose={handleClose}
              setDeal={setDeals}
            />
          )}

          {LoanModel && <LoanInformaion openModel={LoanModel} handleCloseModel={handleCloseModel} />}
        </>
      )}
    </div>

  );
}
