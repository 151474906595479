import { useEffect, useState } from "react";
import axios from "axios";
import Bell from "../../assets/images/header/bell.svg";
import Bell2 from "../../assets/images/Pitch/Header/notification.svg";
import Bell1 from "../../assets/images/Pitch/Header/notification1.svg";
import { useNavigate } from "react-router-dom";
import NotifictionList from "../../containers/notification/list";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { ClickAwayListener } from "@mui/material";
import fireBaseFuntion from "../../firebase";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

const Notification = (props) => {
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [custNotifications, setcustNotifications] = useState([]);
  const [activeNotificationsCount, setActiveNotificationsCount] = useState(0);
  const [bellIconClickCss, setBellIconClickCss] = useState("");
  const { list, isViewAllButtonShow } = props;
  const [isView, setisView] = useState(false);
  const [isViewMoreVisible, setIsViewMoreVisible] = useState(true);
  const navigate = useNavigate();

  const fetchNotification = () => {
    if (!userId && !accessToken) return;
    const options = {
      method: "GET",
      url: `${global.config.ROOTURL.prod}/notification/get-top-10/${userId}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then((response) => {
        setcustNotifications(response.data.top10Notifications);
        setActiveNotificationsCount(response.data.activeNotificationsCount);
        console.log(
          "response.data.activeNotificationsCount",
          response.data.activeNotificationsCount
        );
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };

  const updateAllNotificationTillDate = () => {
    if (!userId) return;
    const options = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/notification/updateAfterSeenByUserId",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: { userId },
    };
    axios(options).then((response) => {
      setActiveNotificationsCount(0);
      fetchNotification();
    });
  };
  //firbase start
  const realTimeNotificationUpdate = () => {
    if (userId) {
      const { onValue, starCountRef } = fireBaseFuntion(
        "user/notification/" + userId
      );

      onValue(starCountRef, (snapShot) => {
        const data = snapShot.val();
        if (data?.createdUserId === userId) {
          if (activeNotificationsCount > 0) {
            setActiveNotificationsCount(activeNotificationsCount);
          }
        }
      });
    }
  };
  useEffect(() => {
    fetchNotification();
  }, []);
  useEffect(() => {
    realTimeNotificationUpdate();
  }, [activeNotificationsCount]);
  const handleViewAllClick = () => {
    setIsViewMoreVisible(false);
    navigate("/notification");
  };

  return (
    <div className="notification">
      {/* <a href="javascript:void(0)"> */}
      <ClickAwayListener
        onClickAway={() => {
          setBellIconClickCss("");
          setisView(false);
        }}
      >
        <div
          className={`notBtn ${bellIconClickCss}`}
          onClick={() => {
            const clickcss = bellIconClickCss === "" ? "bellIconCick" : "";
            setBellIconClickCss(clickcss);
            setisView(!isView);
            setIsViewMoreVisible(true);
          }}
        >
          {activeNotificationsCount > 0 ? (
            <>
              {/* <div className="dotforNotification"></div> */}
              <img
                src={Bell2}
                alt=""
                className="fas fa-bell new-notification"
                onClick={() => {
                  updateAllNotificationTillDate();
                  setisView(true);
                }}
                style={{ height: "30px", width: "30px", cursor: "pointer" }}
              />
            </>
          ) : (
            <img
              src={Bell1}
              alt=""
              style={{ height: "30px", width: "30px", cursor: "pointer" }}
              onClick={() => {
                updateAllNotificationTillDate();
                setisView(true);
              }}
            />
          )}
          {isView && (
            <div className="box">
              <div className="display">
                <NotifictionList
                  list={custNotifications}
                  isViewAllButtonShow={true}
                />
              </div>
              {isViewMoreVisible && (
                <div className="view-all">
                  <Grid item xs={12} sm={12} lg={12}>
                    <div className="view-more">
                      <Link
                        to="/notification"
                        className=" view-more-text primary"
                        onClick={handleViewAllClick}
                      >
                        View All
                      </Link>
                    </div>
                  </Grid>
                </div>
              )}
            </div>
          )}
        </div>
      </ClickAwayListener>
      {/* </a> */}
    </div>
  );
};

export default Notification;
