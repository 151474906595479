import { useEffect, useState, useRef } from 'react';
import { Dialog, DialogContent, DialogActions, DialogTitle, Typography, Button, Box, IconButton, Divider, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getStorageItem } from "../../utils/sessionStorage";
import axios from 'axios';
import { useMediaQuery } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import UpdateMobileNumber from "../deal-description/UpdateMobileNumber";
import DealRedeemAds from './dealsAd';

import dealPopupImg from "../../assets/images/deals/bxs_cricket-ball.svg";

const DealPopUpCard = ({ open, handleClose, deal, profileData }) => {
    const navigate = useNavigate();
    const mobileView = useMediaQuery("(max-width:768px)");
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const userName = getStorageItem("full_name");
    const [userRuns, setUserRuns] = useState(profileData.totalRun);
    const [openRedeemedModal, setOpenRedeemedModal] = useState(false);
    const [showModalForRedeemed, setShowModalForRedeemed] = useState(false);
    const [hasRedeemed, setHasRedeemed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [redeemedToday, setRedeemedToday] = useState(false);
    const [isSubscriber, setIsSubscriber] = useState(false);
    const [openRunsModal, setOpenRunsModal] = useState(false);
    const handleUpdateMobileNoModal = () => setUpdateMobileNo(!updateMobileNo);
    const [updateMobileNo, setUpdateMobileNo] = useState(false);
    const [redeemDealsToday, setRedeemDealsToday] = useState("false");
    const [redeemSuccessfullPopup, setRedeemSuccessfullPopup] = useState(false);
    const [dealSuccessAd, setDealSuccessAd] = useState(false)

    const calculatePriceAfterDiscount = (redeemrun, discount) => {
        return Math.round(redeemrun - (redeemrun * discount) / 100);
    };

    const redeemDeal = () => {
        if (profileData.mobileNo === null || profileData.mobileNo === undefined || !profileData.mobileNo) {
            setUpdateMobileNo(true);
        } else {
            const dealRedeemed = {
                method: "post",
                url: global.config.ROOTURL.prod + "/auth/redeemDeal",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                },
                data: {
                    dealId: deal._id,
                    name: userName,
                    email: profileData.email,
                    mobile: profileData.mobileNo,
                    comingSoon: deal?.comingSoon,
                },
                json: true,
            };

            if (userRuns >= deal.redeemrun) {
                axios(dealRedeemed)
                    .then((response) => {
                        if (response.data.message === "redeemedtoday") {
                            setShowModalForRedeemed(true);
                            setHasRedeemed(false);
                            setShowModal(!showModal);
                            setRedeemedToday(true);
                            setOpenRedeemedModal(false);
                            // handleCloseRedeemedModal()
                        } else if (response.data.message === "redeemed Data required") {
                            setUpdateMobileNo(true);
                        } else {
                            setHasRedeemed(true);
                            setRedeemedToday(false);

                            if (isSubscriber) {
                                setOpenRunsModal(true);
                            } else {
                                setDealSuccessAd(true)
                            }
                        }
                    })
                    .catch((error) => {
                        if (error?.response?.status === 401) {

                            navigate("/login");
                        }
                    });
            } else {
                toast.error("You have insufficient runs to redeem this deal");
            }
        }
    };


    const checkRedeemDealToday = () => {
        const getRedeemDeal = {
            method: "GET",
            url: global.config.ROOTURL.prod + `/dealredeemdetails/hasRedeemedTodayByUserIdAndDealId/${userId}/${deal._id}`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        }
        axios(getRedeemDeal)
            .then((response) => {
                setRedeemDealsToday(response.data);
            })
            .catch((error) => {
                console.log(error);
            })
    };


    useEffect(() => {
        checkRedeemDealToday();
    }, [userId, deal._id]);


    return (
        <Box>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: 3,
                        p: mobileView ? 4 : 2,
                    },
                }}
            >
                {/* Close Button */}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        top: mobileView ? 2 : 8,
                        right: mobileView ? 2 : 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4} sx={{ borderRadius: "8px" }}>
                        <Box
                            component="img"
                            src={deal.dealImage}
                            alt="Deal Logo"
                            sx={{ width: mobileView ? "100%" : "90%", height: mobileView ? "100%" : "100px", display: "block", margin: "auto", borderRadius: "8px", objectFit: "cover" }}
                        />
                    </Grid>

                    {/* Content Column */}
                    <Grid item xs={12} sm={8}>
                        <Box>
                            <DialogTitle
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2,
                                    padding: "0px"
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "rgba(12, 91, 160, 1)",
                                        fontSize: "24px",
                                        fontFamily: "Helvetica",
                                        fontWeight: "700"
                                    }}>
                                    {deal.name}
                                </Typography>
                            </DialogTitle>

                            {/* Dialog Content */}
                            <DialogContent sx={{ padding: "0px" }}>
                                <Typography
                                    sx={{
                                        color: "rgba(49, 51, 53, 1)",
                                        fontSize: "18px",
                                        fontFamily: "Helvetica",
                                        fontWeight: "600",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        padding: "12px 0px"
                                    }}
                                >
                                    <img src={dealPopupImg} style={{ width: "16px", marginRight: "5px" }} /> {(deal?.discount) > 0 ? (calculatePriceAfterDiscount(deal?.redeemrun, deal?.discount)) : (deal.redeemrun)}  Runs
                                </Typography>

                                {/* About The Offer */}
                                <Box mb={2}>
                                    <Typography sx={{
                                        color: "rgba(25, 23, 26, 1)",
                                        fontSize: "16px",
                                        fontFamily: "Helvetica",
                                        fontWeight: "500",
                                    }}
                                        dangerouslySetInnerHTML={{ __html: deal.desc || "" }}
                                    />
                                </Box>
                            </DialogContent>

                            {/* Dialog Actions */}
                            <DialogActions sx={{ justifyContent: "flex-start" }}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => redeemDeal()}
                                    disabled={redeemDealsToday === "false" ? false : true}
                                    sx={{width : mobileView ? "100%" : "60%" }}
                                >
                                    {redeemDealsToday === "false" ? "REDEEM" : "Already Redeemed"}
                                </Button>
                            </DialogActions>
                        </Box>
                    </Grid>
                </Grid>

            </Dialog>
            {updateMobileNo && (
                <UpdateMobileNumber
                    open={updateMobileNo}
                    handleClose={handleUpdateMobileNoModal}
                    redeemDeal={redeemDeal}
                    dealId={deal._id}
                />
            )}

            {dealSuccessAd && (
                <DealRedeemAds
                    open={dealSuccessAd}
                    onClose={() => {
                        setDealSuccessAd(false);
                        handleClose();
                    }}
                    dealId={deal._id}
                />
            )}
        </Box>
    )
}

export default DealPopUpCard;