import React, { useState, useEffect } from "react";
import axios from "axios";
import IplBanner from "../../assets/images/fantasyy/fantassyBanner.png";
import VsLogo from "../../assets/images/fantasyy/VS.png";
import CskLogo from "../../assets/images/fantasyy/Chennai-Super-Kings-logo- 1.png";
import RrLogo from "../../assets/images/fantasyy/rrlogo.png";
import PbksLogo from "../../assets/images/fantasyy/pbkslogo.png";
import RcbLogo from "../../assets/images/fantasyy/rcb.png";
import LsgLogo from "../../assets/images/fantasyy/loknowlogo.png";
import MiLogo from "../../assets/images/fantasyy/milogo.png";
import GtLogo from "../../assets/images/fantasyy/gujarat_cricket_62e74247-b6a8-4079-959b-1d5d6620552e-removebg-preview 1.png";
import HyLogo from "../../assets/images/fantasyy/hyderabad-ipl-team-logo-removebg-preview 1.png";
import KkrLogo from "../../assets/images/fantasyy/kolkata_cricket_ced9e4c3-b5ce-4610-aed4-f2c297da6c21-removebg-preview 1.png";
import DcLogo from "../../assets/images/fantasyy/dclogo.png";


import INDLogo from "../../assets/images/fantasyy/IND.png";
import PAKLogo from "../../assets/images/fantasyy/PAK.png";
import AFGLogo from "../../assets/images/fantasyy/AFG.png";
import AUSLogo from "../../assets/images/fantasyy/AUS.png";
import BANLogo from "../../assets/images/fantasyy/BAN.png";
import CANLogo from "../../assets/images/fantasyy/CAN.png";
import ENGLogo from "../../assets/images/fantasyy/ENG.png";
import IRELogo from "../../assets/images/fantasyy/IRE.png";
import NEPLogo from "../../assets/images/fantasyy/NEP.png";
import NetherlandsLogo from "../../assets/images/fantasyy/Netherlands.png";
import NewZealandLogo from "../../assets/images/fantasyy/New_Zealand.png";
import OmanLogo from "../../assets/images/fantasyy/Oman.png";
import PapuaLogo from "../../assets/images/fantasyy/Papua_New_Guinea.png";
import ScotlandLogo from "../../assets/images/fantasyy/Scotland.png";
import SouthAfricaLogo from "../../assets/images/fantasyy/South_Africa.jpg";
import SriLankaLogo from "../../assets/images/fantasyy/Sri_Lanka.png";
import UgandaLogo from "../../assets/images/fantasyy/Uganda.png";
import USAlogo from "../../assets/images/fantasyy/USA.png";
import WIlogo from "../../assets/images/fantasyy/WI.jpg";
import NAMLogo from "../../assets/images/fantasyy/NAM.png";
import ZIMLogo from "../../assets/images/fantasyy/ZIM.png";

import BattleLogo from "../../assets/images/fantasyy/battleipl.png";
import "./_playerSelectPage.scss";
import PlayerCard from "./PlayerCard";
import PlayerScorePage from "./PlayerScorePage";
import FantassyModal from "./FantassyModal";
import PlayerCard2 from "./PlayerCard2";
import FantasyRunIcon from "../../assets/images/fantasyy/runsipl.png";
import { getStorageItem } from "../../utils/sessionStorage";
import { useLocation } from "react-router-dom";
import Run from "../../components/posts/run";
import { Tab, Tabs, AppBar } from "@mui/material";

const user_Id = getStorageItem("user_id");
const PlayerSelectPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { state } = useLocation();
  const { _id, team1, team2 } = state;
  const [selectedTeam, setSelectedTeam] = useState(team1);
  const [isPlayerScorePageOpen, setIsPlayerScorePageOpen] = useState(false);
  const [isFantassyModalOpen, setIsFantassyModalOpen] = useState(false);
  const [playersData, setPlayersData] = useState([]);
  const [selectedPlayersCount, setSelectedPlayersCount] = useState(0);


  // const teamLogos = {
  //   Chennai: CskLogo,
  //   Mumbai: MiLogo,
  //   Gujarat: GtLogo,
  //   Kolkata: KkrLogo,
  //   Lucknow: LsgLogo,
  //   Punjab: PbksLogo,
  //   Rajasthan: RrLogo,
  //   Bangalore: RcbLogo,
  //   Delhi: DcLogo,
  //   Hyderabad: HyLogo,
  // };


  const teamLogos = {
    Chennai: CskLogo,
    Mumbai: MiLogo,
    Gujarat: GtLogo,
    Kolkata: KkrLogo,
    Lucknow: LsgLogo,
    Punjab: PbksLogo,
    Rajasthan: RrLogo,
    Bangalore: RcbLogo,
    Delhi: DcLogo,
    Hyderabad: HyLogo,
    IND: INDLogo,
    PAK: PAKLogo,
    AFG: AFGLogo,
    AUS: AUSLogo,
    BAN: BANLogo,
    CAN: CANLogo,
    ENG: ENGLogo,
    IRE: IRELogo,
    NEP: NEPLogo,
    NET: NetherlandsLogo,
    NZ: NewZealandLogo,
    OMA: OmanLogo,
    PNG: PapuaLogo,
    SCO: ScotlandLogo,
    SA: SouthAfricaLogo,
    SL: SriLankaLogo,
    UGA: UgandaLogo,
    USA: USAlogo,
    WI: WIlogo,
    NAM: NAMLogo,
    ZIM: ZIMLogo,
  };
  // const teamColorsObj1 = {
  //   Chennai: "#F7BA07",
  //   Mumbai: "#0276B9",
  //   Gujarat: "#1D1E22",
  //   Kolkata: "#3D2360",
  //   Lucknow: "#A02A4C",
  //   Punjab: "#C72127",
  //   Rajasthan: "#AD2770",
  //   Bangalore: "#FF0000",
  //   Delhi: "#1665A7",
  //   Hyderabad: "#F06925",
  // };
  
  const teamColorsObj1 = {
    Chennai: "#F7BA07",
    Mumbai: "#0276B9",
    Gujarat: "#1D1E22",
    Kolkata: "#3D2360",
    Lucknow: "#A02A4C",
    Punjab: "#C72127",
    Rajasthan: "#AD2770",
    Bangalore: "#FF0000",
    Delhi: "#1665A7",
    Hyderabad: "#F06925",
    IND: "#1C448E",
    PAK: "#016D38",
    AFG: "#0072BC",
    AUS: "#FFCC00",
    BAN: "#006A4E",
    CAN: "#FF0000",
    ENG: "#0A559F",
    IRE: "#009A44",
    NEP: "#DC143C",
    NET: "#FF6600",
    NZ: "#000000",
    OMA: "#C8102E",
    PNG: "#FFCC00",
    SCO: "#4B0082",
    SA: "#006837",
    SL: "#0033A0",
    UGA: "#FFD100",
    USA: "#B31942",
    WI: "#7A0019",
    NAM: "#005BAC",
    ZIM: "#FFCD00",
  };
  const getTeamColors = (teamName) => {
    const formattedTeamName = teamName && teamName.trim();
    return (
      teamColorsObj1[formattedTeamName] ||
      "#FFFFFF"
    ); 
  };
  const fetchPlayersData = async (teamName) => {
    try {
      const accessToken = getStorageItem("token");
      const options = {
        method: "GET",
        url:
          global.config.ROOTURL.prod +
          `/contest/fantasyMatch/findPlayersByMatchIdAndTeam/${_id}/${teamName}`,
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      };
      const response = await axios(options);
      const data = response.data;
      setPlayersData(data);
      return data;
    } catch (error) {
      console.error("Error fetching players data:", error);
      throw error;
    }
  };

  const fetchPlayerCount = () => {
    const accessToken = getStorageItem("token");
    const options = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/contest/userFantasy/findPlayersCountByMatchIdandUserid/${_id}`,
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(options)
      .then(({ data }) => {
        if (data) {
          setSelectedPlayersCount(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClosePlayerScorePage = () => {
    setIsPlayerScorePageOpen(false);
  };

  const handleToggleTeam = (team) => {
    setSelectedTeam(team);
    fetchPlayersData(team);
  };

  const handlePlayerSelection = () => {
    if (selectedPlayersCount < 11){
    setSelectedPlayersCount((prevCount) => prevCount + 1);
    }
    
  };



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    fetchPlayersData(team1);
    fetchPlayerCount();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [team1]);

  return (
    <div className={`PlayerSelecte-container ${isMobile ? "mobile" : ""}`}>
      {!isMobile && (
        <div className="top-banner-container">
          <img
            src={teamLogos[team1]}
            alt="CSK Logo"
            className="logo csk-logo"
          />
          <img src={VsLogo} alt="VS Logo" className="logos vs-logo" />
          <img src={teamLogos[team2]} alt="MI Logo" className="logo mi-logo" />
        </div>
      )}

      <div className="scores-containers">
        {isPlayerScorePageOpen && (
          <PlayerScorePage onClose={handleClosePlayerScorePage} />
        )}
      </div>

      <div className="player-selector-section">
        {isMobile ? (
          <>
            <div className="mobile-header">
              <div className="mobile-header-item">
                <p>Player Selected</p>
                <span>{selectedPlayersCount}/11</span>
              </div>
              <div className="mobile-header-item">
                <p>Runs Left</p>
                <span>
                  <img src={FantasyRunIcon} alt="Run Icon" />
                  <Run isRunValueNeeded={true} userId={user_Id} />
                </span>
              </div>
            </div>

            <div className="mobile-toggle-buttons">
              <button
                className={`toggle-button1 ${
                  selectedTeam === team1 ? "active" : ""
                }`}
                onClick={() => handleToggleTeam(team1)}
                style={{ backgroundColor: selectedTeam===team1?getTeamColors(team1):"white", color:selectedTeam===team1?"white":"black", flex: 1 }}
              >
                {team1}
              </button>
              <button
                className={`toggle-button2 ${
                  selectedTeam === team2 ? "active" : ""
                }`}
                onClick={() => handleToggleTeam(team2)}
                style={{ backgroundColor: selectedTeam===team2?getTeamColors(team2):"white", color:selectedTeam===team2?"white":"black", flex: 1 }}
              >
                {team2}
              </button>
            </div>

            {selectedTeam === team1 && (
              <div className="player-cards-column">
                <div className="player-cards-container">
                  {playersData?.map((player) => (
                    <PlayerCard
                      key={player._id}
                      playerData={player}
                      onBuyClick={handlePlayerSelection}
                    />
                  ))}
                </div>
              </div>
            )}

            {selectedTeam === team2 && (
              <div className="player-cards-column">
                <div className="player-cards-container">
                  {playersData?.map((player) => (
                    <PlayerCard2
                      key={player._id}
                      playerData={player}
                      onBuyClick={handlePlayerSelection}
                    />
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div style={{width: "25%"}}>
              <AppBar
                position="static"
                style={{
                  color: "white",
                  backgroundColor: "white",
                  marginBottom: "15px",
                }}
              >
                <Tabs
                  value={selectedTeam}
                  onChange={(event, newValue) => handleToggleTeam(newValue)}
                  TabIndicatorProps={{ style: { display: "none" } }}
                >
                  <Tab
                    label={team1}
                    value={team1}
                    style={{ backgroundColor: selectedTeam===team1?getTeamColors(team1):"white", color:selectedTeam===team1?"white":"black", flex: 1 }}
                    // style={{color: "white", flex: 1}}
                  />
                  <Tab
                    label={team2}
                    value={team2}
                    style={{ backgroundColor: selectedTeam===team2?getTeamColors(team2):"white", color:selectedTeam===team2?"white":"black", flex: 1 }}
                    // style={{color: "white", flex: 1}}
                  />
                </Tabs>
              </AppBar>
              {selectedTeam === team1 && (
                <div className="player-cards-column">
                  <div
                    className={`player-cards-container1 ${
                      selectedTeam === team1 ? "active" : ""
                    }`}
                  >
                    {playersData?.map((player) => (
                      <PlayerCard
                        key={player._id}
                        playerData={player}
                        onBuyClick={handlePlayerSelection}
                      />
                    ))}
                  </div>
                </div>
              )}
              {selectedTeam === team2 && (
                <div className="player-cards-column">
                  <div
                    className={`player-cards-container2 ${
                      selectedTeam === team2 ? "active" : ""
                    }`}
                  >
                    {playersData?.map((player) => (
                      <PlayerCard2
                        key={player._id}
                        playerData={player}
                        onBuyClick={handlePlayerSelection}
                      />
                    ))}
                  </div>
                </div>
              )}
              </div>
            <div className="desktop-header">
              <span>{selectedPlayersCount}/11</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PlayerSelectPage;
