import React from 'react';
import axios from 'axios';
import Switch from "react-switch";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import "./index.scss";

import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { resetLogin } from "../../redux/reducers/login";

const ProfileMenue = (props) => {
    const { isOpen, handleAction, dropdownRef, pendigMessage, togglechanged, checked } = props;
    const navigate = useNavigate();
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const dispatch = useDispatch();

    const handleMyProfile = () => {
        navigate(`/profile/${userId}`);
    };
    const handleMyMessage = () => {
        navigate(`/profile/chat/${userId}`);
    };
    const handleAboutUs = () => {
        navigate("/about-us");
    };
    const handlePrivacy = () => {
        navigate("/privacy");
    };
    const handleRules = () => {
        navigate("/rules");
    };
    const handleContactUs = () => {
        navigate("/contact-us");
    };

    const handleLogout = () => {
        if (!userId) return;
        var logOutOptions = {
            method: "post",
            url: global.config.ROOTURL.prod + "/auth/logout/",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
            data: {
                id: userId,
            },
        };
        axios(logOutOptions)
            .then(() => {
                dispatch(resetLogin());
                localStorage.clear();
                navigate("/login");
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    clearStorage();
                    navigate("/login");
                }
            });
    };


    return (
        <div>
            <div className="primary" onClick={handleAction} ref={dropdownRef}>
                {/* {userInfo.firstName + " " + userInfo.lastName} */}
                <i className="arrow down"></i>
                <div
                    direction="bottom"
                    className="header-options"
                    style={{ visibility: isOpen ? "visible" : "hidden" }}
                >
                    <div className="caret"></div>
                    <div className="options" style={{ width: "149px" }}>
                        <ul>
                            <li onClick={handleMyProfile}>My Profile</li>
                        </ul>
                        {/* <ul>
                            <li onClick={handleMyMessage}>
                                <div>
                                    Message
                                    {pendigMessage > 0 && (
                                        <p className="MessageContainerdiv1">
                                            {pendigMessage}
                                        </p>
                                    )}
                                </div>
                            </li>
                        </ul>
                        <ul>
                            <li onClick={handleAboutUs}>About us</li>
                        </ul>
                        <ul>
                            <li onClick={handlePrivacy}>Privacy</li>
                        </ul>
                        <ul>
                            <li onClick={handleRules}>Rules</li>
                        </ul> */}
                        <ul style={{ paddingLeft: "28px" }}>
                            <li>
                                <label
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <span style={{ paddingRight: "7px" }}>
                                        GlobalPitch
                                    </span>
                                    <Switch
                                        onChange={togglechanged}
                                        checked={checked}
                                        onColor="#86d3ff"
                                        onHandleColor="#2693e6"
                                        handleDiameter={20}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                        height={19}
                                        width={41}
                                        className="react-switch"
                                        id="material-switch"
                                    />
                                </label>
                            </li>
                        </ul>
                        <ul>
                            <li onClick={handleLogout}>Sign Out</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileMenue;