import CommentsIconGray from "../../assets/images/icons/comments.svg";
import ShareIconGray from "../../assets/images/icons/share.svg";
import Logo from "../../assets/images/header/logo.png";
import CommentsIcon from "../../assets/images/comment_blue_icon.png";
// import RunsIcon from "../../assets/images/icons/post-action.png";
import ShareIcon from "../../assets/images/share_pink_icon.png";
import { Box, height, margin } from "@mui/system";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import React from "react";
import fireBaseFuntion from "../../firebase";
import ChampButton from "../../commons/form/button";
import ReactTimeAgo from "react-time-ago";
import Runs from "../../assets/images/icons/post-action.png";
import axios from "axios";
import ScoreRuns from "../runs-dropdown/dropdownMenu";
import RunCard from "../posts/run";
import NameCard from "../posts/name-card";
import ShareModal from "../../commons/components/share-modal/PostModal";
import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import config from "../../config";
import Header from "../header/index";
import GuestHeader from "../header/header-without-signin"
import runs from "../../assets/images/icons/newBall3.png";
import new2ball from "../../assets/images/icons/2runs.svg";
import new4ball from "../../assets/images/icons/4runs.svg";
import new6ball from "../../assets/images/icons/6runs.svg";
import emptyreshare from "../../assets/images/icons/mdi_repost.png";
import newshare from "../../assets/images/icons/majesticons_share.png";
import newcomment from "../../assets/images/icons/emptycomment.png";
import newreshare from "../../assets/images/icons/newreshare.png";
import Slider from "../../commons/components/slider";
import { useDispatch } from "react-redux";
import { PathAction } from "../../redux/reducers/globalPath";
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import PostPopup from "./PostPopup";
import "./postPreview.scss";
import Popup from "./Popup";
import GifBoxOutlinedIcon from '@mui/icons-material/GifBoxOutlined';
import { BsEmojiSmile } from "react-icons/bs";
import EmojiPicker from "emoji-picker-react";
import { MentionsInput, Mention } from "react-mentions";
import GifComponent from "../../components/posts/GifComponent";
import SendIcon from "@mui/icons-material/Send";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { FaUserCircle } from "react-icons/fa";
import DropdownMenu from "../dropdown-menu/dropdownMenu";

const baseURL = config.ROOTURL.prod;
const mobileStyle = {
  background: "white",
  minWidth: "370px",
  minHeight: "400px",
};
const style = {
  minWidth: "650px",
  minHeight: "271px",
  marginTop: "140px",
  marginBottom: "62px",
  marginLeft: "82px",
  marginRight: "82px",
};
const Posts = (props) => {
  console.log("propsss", props)
  var regex = /(http[s]?:\/\/.*\.(?:png|jpg|gif|svg|jpeg|webp))/i;
  const mobileView = useMediaQuery("(max-width:768px)");
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  console.log("posts", posts)
  const userId = getStorageItem("user_id");
  const dispatch = useDispatch();
  const [postsOfUserId, setPostsOfUserId] = useState([]);
  const [comments, setComments] = useState([]);
  console.log("comments", comments);
  const [commentsLimit, setCommentLimit] = useState([]);
  const [startDate, setStartDate] = useState(return1SecondPlusDate());
  const [pageNumber, setPageNumber] = useState(1);
  const [isShareopen, setShareOpen] = useState(false);
  const [newSharedDetail, setNewSharedDetail] = useState([]);
  const [IsOpen, setIsOpen] = useState(false);
  const [runsGivenValue, setRunsGivenValue] = useState(0);
  const [runsImage, setRunsImage] = useState(runs);
  const [postObj, setPostObj] = useState(props.post);
  const [sharing, setSharing] = useState(false);
  const [sharedBody, setSharedBody] = useState("");
  const [shareOriginalPost, setoriginalPost] = useState(null);
  const [runsGiven, setRunsGiven] = useState(false);
  const [runsScored, setRunsScored] = useState();
  const [showComments, setShowComments] = useState(false);
  const [open, setOpen] = useState(false);
  const [postOpen, setPostOpen] = useState(false);
  const [comment, setComment] = useState("");
  const params = useParams();
  const pitchId = params.id;
  const avatar = getStorageItem("avatar");
  const [ownPost, setOwnPost] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showRuns, setShowRuns] = useState(false);
  const [sharedisable, setSharedisable] = useState(true);
  const [sampleImage2, setSampleImage2] = useState("");
  const [sampleImage1, setSampleImage1] = useState("");
  const accessToken = getStorageItem("token");
  const [commentText, setCommentText] = useState("")
  const loginDetails = localStorage.getItem("user_id");
  const ActiveUser = localStorage.getItem("user_id");
  const inputRef = useRef(null);
  const emojiRef = useRef();
  const clipRef = useRef();
  const [giphyUrl, setGiphyUrl] = useState("");
  const [textWithoutHtml, setTextWithoutHtml] = useState("");
  const [isFilterButtonActive, setIsFilterButtonActive] = useState(false);

  const [descriptionForApp, setDescriptionForApp] = useState({
    hashtag: [],
    mentions: [],
    text: [],
  });
  const [mentionedIds, setMentionedIds] = useState("");
  const [updateRun, setUpdateRun] = useState(
    props?.post?.postRunCount ? props?.post?.postRunCount : 0
  );
  const [updateCommentCount, setUpdateCommentedCount] = useState(
    props?.post?.postCommentCount ? props?.post?.postCommentCount : 0
  );
  const [updateRepostCount, setUpdateRepostCount] = useState(
    props?.post?.sharedPost ? props?.post?.sharedPost.length : 0
  );
  useEffect(() => {
    console.log("props", props);
  }, [])
  const updateRuninPitch = (data) => {
    setUpdateRun(updateRun + data);
    setRunsGivenValue(data);
  };
  const realTimePitchUpdate = () => {
    if (userId) {
      const { onValue, starCountRef } = fireBaseFuntion(
        "user/pitchDetail/"
        // + postObj.feed_id
      );

      onValue(starCountRef, (snapShot) => {
        const data = snapShot.val();

        if (data) {
          // setPostObj(data);
          setUpdateRun(data.postRunCount);
          setUpdateCommentedCount(data.postCommentCount);
          setUpdateRepostCount(data?.sharedPost?.length);
        }
      });
    }
  };
  const handleclose = () => {
    setpopupData(false);
  };

  const getComments = async () => {
    let req = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/pitch/comment/getByPitchId/${params.id}/1/10`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    };
    await axios(req).then((res) => {
      console.log("getcommetns", res);

    }
    )
  }

  const handleEmojiClick = (emoji) => {
    const messageValue = comment || "";
    const newMessageValue = messageValue + emoji + " ";
    setComment(newMessageValue);
  };

  const hasOnlyEmptySpaces = (value) => {
    return value.replace(/\s/g, '') === '';
  };

  const handleInputChange = (event, newValue, newPlainTextValue, mentions) => {
    if (hasOnlyEmptySpaces(event.target.value)) {
      setComment('');
    } else {
      setComment(event.target.value)
    }
    // if (replyUser !== "") {
    //   mentions.push(replyUser, ...mentions);
    // }

    let hashtagRegex = /#[^\s#]*/;
    let mentionRegex = /@\w*\s\w*/;
    const hTag = [];
    const mUser = [];
    const plainTxt = [];
    let splitted = "";
    var one_or_two = new RegExp("(" + hashtagRegex.source + "|" + mentionRegex.source + ")");
    splitted = newPlainTextValue?.split(one_or_two);
    splitted?.map((txt, i) => {
      if (txt.match(hashtagRegex)) {
        hTag.push({ index: i, text: txt });
      } else if (txt.match(mentionRegex)) {
        const uId = mentions.filter((data) => data.display.includes(txt)).map((data) => data.id);
        mUser.push({ index: i, text: txt, userId: uId.toString() });
      } else {
        plainTxt.push({ index: i, text: txt });
      }
      return true;
    });
    setMentionedIds(mentions ? mentions.filter((data) => data.display.includes("@")).map((data) => data.id) : []);

    console.log(" hashtag: hTag, mentions: mUser, text: plainTxt ", hTag, mUser, plainTxt)
    setDescriptionForApp({ hashtag: hTag, mentions: mUser, text: plainTxt })
    setTextWithoutHtml(newPlainTextValue)
  };

  // const handleShareOpen = () => {
  //   setShareOpen(true);
  //   setOpen(true);
  // };
  const handleShareClose = () => {
    setOpen(false);
    setShareOpen(false);
  };
  const closeModal = () => {
    // console.log("in sharepost");
    setSharing(false);
    // pitchShare();
    // props.hprops();
  };

  const handleCommentSubmit = (commentText) => {
    postComment(commentText);
    const newComment = {
      commentText: commentText,
      timestamp: new Date()
    };
    const updatedComments = [...commentsLimit, newComment].slice(-4);
    setCommentLimit(updatedComments);
  };

  const MentionStyle = {
    control: {
      backgroundColor: "#fff",
      fontSize: 14,
      fontWeight: "normal",
      overflow: "auto",
    },

    "&multiLine": {
      input: {
        padding: 9,
        border: "1px solid silver",
        "&focused": {
          border: "1px solid black",
        },
      },
    },

    suggestions: {
      marginTop: 40,
      list: {
        fontSize: 14,
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 5px 15px rgba(68, 68, 79, 0.1)",
        borderRadius: 8,
        minWidth: 400,
      },
      item: {
        fontFamily: "Poppins",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: 14,
        lineHeight: 2,
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "#d2effa",
        },
      },
    },
  };

  const onGiphyClick = async (item) => {
    console.log("item", item);
    const giphyUrl = item;
    const commentText = `${giphyUrl}`;
    await setComment(commentText);

    setShowClip(false);
  };

  const renderSuggestions = (entry) => {
    return (
      <div className="suggestions1">
        <div className="suggestions1__image">
          <img
            src={
              entry.photo
                ? entry.photo
                : "https://www.umass.edu/philosophy/sites/default/files/store/img/cfm/user_23.png"
            }
            alt="user-profile"
          />
          {entry.following && (
            <div className="following__mark">
              <svg
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4.4362 1.11473C4.18418 1.11473 3.93462 1.16437 3.70178 1.26081C3.46894 1.35726 3.25738 1.49862 3.07917 1.67683C2.90096 1.85503 2.7596 2.0666 2.66316 2.29944C2.56671 2.53228 2.51707 2.78183 2.51707 3.03385C2.51707 3.28588 2.56671 3.53543 2.66316 3.76827C2.7596 4.00111 2.90096 4.21267 3.07917 4.39088C3.25738 4.56909 3.46894 4.71045 3.70178 4.8069C3.93462 4.90334 4.18418 4.95298 4.4362 4.95298C4.94518 4.95298 5.43332 4.75079 5.79323 4.39088C6.15313 4.03098 6.35533 3.54284 6.35533 3.03385C6.35533 2.52487 6.15313 2.03673 5.79323 1.67683C5.43332 1.31692 4.94518 1.11473 4.4362 1.11473ZM1.96875 3.03385C1.96875 2.37945 2.22871 1.75184 2.69145 1.28911C3.15419 0.826369 3.78179 0.566406 4.4362 0.566406C5.09061 0.566406 5.71821 0.826369 6.18095 1.28911C6.64368 1.75184 6.90365 2.37945 6.90365 3.03385C6.90365 3.68826 6.64368 4.31587 6.18095 4.7786C5.71821 5.24134 5.09061 5.5013 4.4362 5.5013C3.78179 5.5013 3.15419 5.24134 2.69145 4.7786C2.22871 4.31587 1.96875 3.68826 1.96875 3.03385Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.5"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.87207 8.51673C0.87207 7.57147 1.24757 6.66493 1.91597 5.99653C2.58437 5.32814 3.49091 4.95264 4.43616 4.95264C5.38142 4.95264 6.28796 5.32814 6.95636 5.99653C7.62475 6.66493 8.00026 7.57147 8.00026 8.51673V8.67026C8.00026 8.94607 7.80725 9.18404 7.53747 9.24051L6.57736 9.44284C5.61396 9.64572 4.62862 9.70988 3.65151 9.63585C3.57979 9.62933 3.51352 9.59484 3.46702 9.53986C3.42052 9.48487 3.39752 9.41379 3.40299 9.34198C3.40846 9.27017 3.44197 9.2034 3.49627 9.1561C3.55057 9.1088 3.62131 9.08476 3.69319 9.08918C4.61875 9.15936 5.552 9.0985 6.46441 8.90604L7.42452 8.70425C7.43226 8.70273 7.43923 8.69855 7.44423 8.69245C7.44923 8.68635 7.45196 8.6787 7.45193 8.67081V8.51673C7.45193 7.7169 7.1342 6.94982 6.56863 6.38426C6.00307 5.81869 5.23599 5.50096 4.43616 5.50096C3.63633 5.50096 2.86926 5.81869 2.30369 6.38426C1.73812 6.94982 1.42039 7.7169 1.42039 8.51673V8.67026C1.42039 8.68671 1.43136 8.70042 1.44781 8.70425L2.40792 8.90604C2.44317 8.91345 2.47661 8.92774 2.50633 8.94808C2.53606 8.96842 2.56149 8.99442 2.58117 9.02459C2.60085 9.05475 2.61439 9.0885 2.62103 9.12391C2.62766 9.15931 2.62726 9.19567 2.61985 9.23092C2.61243 9.26616 2.59814 9.29961 2.5778 9.32933C2.55746 9.35906 2.53147 9.38449 2.5013 9.40417C2.47113 9.42385 2.43738 9.43739 2.40198 9.44403C2.36658 9.45066 2.33021 9.45026 2.29497 9.44284L1.33485 9.24051C1.20396 9.21306 1.08651 9.14139 1.00223 9.03754C0.917954 8.93369 0.871993 8.804 0.87207 8.67026V8.51673Z"
                  fill="white"
                  stroke="white"
                  stroke-width="0.5"
                />
              </svg>
            </div>
          )}
        </div>
        <div className="suggestions1__fullname">
          <h2>{entry.display}</h2>
        </div>
        {/* <div className="suggestions1__email">
          <p>{entry.email}</p>
        </div> */}
        <div className="suggestions1__followers">
          <svg
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.78301 0.735851C4.44479 0.735851 4.10989 0.802467 3.79741 0.931897C3.48494 1.06133 3.20103 1.25104 2.96187 1.49019C2.72271 1.72935 2.53301 2.01326 2.40358 2.32574C2.27415 2.63821 2.20753 2.97311 2.20753 3.31133C2.20753 3.64954 2.27415 3.98445 2.40358 4.29692C2.53301 4.60939 2.72271 4.89331 2.96187 5.13246C3.20103 5.37162 3.48494 5.56133 3.79741 5.69076C4.10989 5.82019 4.44479 5.8868 4.78301 5.8868C5.46607 5.8868 6.12115 5.61546 6.60414 5.13246C7.08714 4.64947 7.35848 3.99439 7.35848 3.31133C7.35848 2.62827 7.08714 1.97319 6.60414 1.49019C6.12115 1.00719 5.46607 0.735851 4.78301 0.735851ZM1.47168 3.31133C1.47168 2.43311 1.82055 1.59086 2.44154 0.969865C3.06254 0.348871 3.90479 0 4.78301 0C5.66123 0 6.50347 0.348871 7.12447 0.969865C7.74546 1.59086 8.09433 2.43311 8.09433 3.31133C8.09433 4.18955 7.74546 5.03179 7.12447 5.65279C6.50347 6.27378 5.66123 6.62265 4.78301 6.62265C3.90479 6.62265 3.06254 6.27378 2.44154 5.65279C1.82055 5.03179 1.47168 4.18955 1.47168 3.31133Z"
              fill="#718193"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.31369e-07 10.6697C1.31369e-07 9.40121 0.503925 8.18463 1.40092 7.28763C2.29791 6.39064 3.51449 5.88672 4.78303 5.88672C6.05157 5.88672 7.26815 6.39064 8.16514 7.28763C9.06213 8.18463 9.56606 9.40121 9.56606 10.6697V10.8758C9.56606 11.2459 9.30704 11.5653 8.945 11.6411L7.65652 11.9126C6.36363 12.1849 5.04131 12.271 3.73003 12.1716C3.63378 12.1629 3.54484 12.1166 3.48243 12.0428C3.42003 11.969 3.38916 11.8736 3.39651 11.7772C3.40385 11.6809 3.44882 11.5913 3.52169 11.5278C3.59456 11.4643 3.68949 11.432 3.78595 11.438C5.02807 11.5322 6.28048 11.4505 7.50494 11.1922L8.79341 10.9214C8.8038 10.9194 8.81316 10.9138 8.81987 10.9056C8.82658 10.8974 8.83024 10.8871 8.83021 10.8765V10.6697C8.83021 9.59637 8.40381 8.56695 7.64482 7.80796C6.88582 7.04897 5.85641 6.62257 4.78303 6.62257C3.70965 6.62257 2.68023 7.04897 1.92124 7.80796C1.16225 8.56695 0.735851 9.59637 0.735851 10.6697V10.8758C0.735851 10.8979 0.750568 10.9163 0.772643 10.9214L2.06112 11.1922C2.10842 11.2022 2.1533 11.2213 2.19319 11.2486C2.23308 11.2759 2.26721 11.3108 2.29362 11.3513C2.32003 11.3918 2.3382 11.4371 2.34711 11.4846C2.35602 11.5321 2.35548 11.5809 2.34552 11.6282C2.33557 11.6755 2.3164 11.7204 2.2891 11.7603C2.2618 11.8002 2.22692 11.8343 2.18643 11.8607C2.14594 11.8871 2.10065 11.9053 2.05314 11.9142C2.00563 11.9231 1.95683 11.9226 1.90953 11.9126L0.621058 11.6411C0.445399 11.6042 0.287775 11.508 0.174675 11.3687C0.0615764 11.2293 -0.000103895 11.0553 1.31369e-07 10.8758V10.6697Z"
              fill="#718193"
            />
          </svg>
          <p>{entry.followersCount}</p>
        </div>
      </div>
    );
  };
  const tagStyles = {
    // fontFamily: "Poppins",
    // fonStyle: "normal",
    // fontWeight: 500,
    // fontSize: "14px",
    // lineHeight: "21px",
    // color: "#0c5ba0",
  };
  const getUserMentions = async (str, callback) => {
    if (!str) return;
    const nameSearch = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/mention-name-search",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        searchText: str,
        pageNumber: 1,
        pagePerSize: 5,
      },
    };
    axios(nameSearch)
      .then(({ data }) => {
        return data.map((data) => {
          return {
            id: data._id,
            display: "@" + data.firstName + " " + data.lastName,
            photo: data.profilePhoto,
            email: data.email,
            followers: 450,
            runs: 13450,
            followersCount: data.followersCount,
            totalRun: data.totalRun,
            following: data.youFollow,
          };
        });

      })
      .then(callback);
  };
  const getHashtags = async (str, callback) => {
    if (!str) return;
    const nameSearch = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/hash-profile-tag/searchHashTag",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        searchText: str,
        pageNumber: 1,
        pagePerSize: 5,
      },
    };
    axios(nameSearch)
      .then(({ data }) => {
        return data.map((data) => {
          return {
            id: data._id,
            display: "#" + data.hashTagText,
          };
        });
      })
      .then(callback);
  };
  const postComment = async () => {
    console.log("commentpost")

    if (!accessToken) return;
    const submitComment = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/pitch/comment/create",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        commentText: comment,
        description: descriptionForApp,
        pitchId: params.id,
        textWithoutHtml: textWithoutHtml,
        mentionedIds,
      }
    };
    await axios(submitComment)
      .then(() => {
        console.log("commentpost")

        setComment("")
        fetchCommentByPitchId();
      })
      .catch((error) => {
        console.log(error);
        // if (error?.response?.status === 401) {
        //    clearStorage();
        //    navigate("/login");
        // }
      });
  };

  const [showEmojis, setShowEmojis] = useState(false);
  const [showClip, setShowClip] = useState(false);

  const handleEmojiPicker = () => {
    setShowEmojis(!showEmojis);
  };

  const handleClipPicker = () => {
    setShowClip(!showClip);
  };


  const [firstImageURL, setFirstImageURL] = useState("");
  const [vid, setVid] = useState("");

  const [youtube, setYoutube] = useState("");
  const [preview, setPreview] = useState("");

  const handleOpen = () => {
    console.log("postpreview")
    if (!loginDetails) {
      setShowLoginPopup(true);
    }
  };

  const handleOpenRuns = () => {
    console.log("Opening runs popup");
    if (!loginDetails) {
      setShowLoginPopup(true);
    } else {
      setOpen(!open); // Toggle runs popup visibility
    }
  };

  const handleShareOpen = () => {
    console.log("Opening share modal");
    if (!loginDetails) {
      setShowLoginPopup(true);
    } else {
      setSharing(true); // Open the share modal
    }
  };
  const closeLoginPopup = () => {
    setShowLoginPopup(false);
  };

  const sharePost = () => {
    if (sharedisable && shareOriginalPost) {
      const shared = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/pitch/shared",
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
        data: {
          comment: sharedBody ? sharedBody : "",
          pitchId: shareOriginalPost,
        },
      };
      axios(shared)
        .then((response) => {
          dispatch(PathAction.handleCreatedObject({ payload: response.data }));
          closeModal();
          navigate("/pitch");
        })
        .catch((error) => {
          setShowRuns(!showRuns);
          if (error?.response?.status === 401) {
            clearStorage();
            navigate("/login");
          }
        });
    }
  };

  const scorePost = (run) => {

    if (!run && !accessToken) return;
    let addRunOption = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/pitch/run/addRun",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        pitchId: pitchId,
        givenRun: run,
      },
    };

    axios(addRunOption)
      .then(() => {
        // handleupdaterun(run);
        setOpen(false);
        setRunsGivenValue(run)
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };
  const handleClose = (data) => setOpen(data);
  // const [isPostOpen, setIsPostOpen] = useState(false);
  const [popupData, setpopupData] = useState(false);
  const [openAnimation, setOpenAnimation] = useState(false);
  const fetchDataByPitchId = () => {
    axios
      .get(`${baseURL}/pitch/getPitchByPitchIdForPreview/${pitchId}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("response.data.data", response.data);
        setPosts(response.data);
        setFirstImageURL(
          response.data?.postImageURLs &&
            response.data?.postImageURLs.length > 0
            ? response.data?.postImageURLs[0]
            : ""
        );
        setVid(response.data?.video ? response.data?.video : "");
        if (mobileView) fetchSamplePitchesByUserId(posts.userId);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const fetchCommentByPitchId = () => {
    axios
      .get(
        `${baseURL}/pitch/comment/getCommentByByPitchIdForPreview/${pitchId}/1/10`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("response.data", response.data);
        setComments(response.data);
        console.log("this is base url:", `${baseURL}`);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const fetchSamplePitchesByUserId = () => {
    axios
      .post(
        `${baseURL}/pitch/getPitchByUserIdForPreview`,
        {
          pageNumber,
          pagePerSize: "2",
          pitchId,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("response.data.data", response.data);
        setPostsOfUserId(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const URL_REGEX =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
  let youtubeLink;

  function replaceHashtags(description) {
    // Use a regular expression to match hashtags that are not already anchor tags
    const regex = /(?!<a[^>]*>)(#(\w+))(?![^<]*<\/a>)/g;
    // Replace hashtags with anchor tags
    return description?.replace(
      regex,
      '<a class="hashtagged" href="/hashtags?hashtag=$2">$1</a>'
    );
  }
  const checkShare = async () => {
    const sharedata = props?.post?.sharedPost;
    const MainUserId = ActiveUser;
    if (sharedata) {
      const ans = sharedata.filter((i) => i === MainUserId);
      if (ans.length > 0) {
        setSharedisable(false);
      } else {
        setSharedisable(true);
      }
    }
  };

  function youtubeParser(url) {
    if (url !== undefined) {
      var checks = url.split(" ");

      return (
        <p>
          {checks.map((words, index) => {
            var word;

            words.includes("/watch?v=")
              ? (word = words?.replace("/watch?v=", "/embed/"))
              : (word = words?.replace("youtu.be/", "www.youtube.com/embed/"));

            if (word.includes("http://") || word.includes("https://")) {
              youtubeLink = word;
            }

            return words.match(URL_REGEX) ? <></> : words + " ";
          })}
        </p>
      );
    }
  }

  youtubeParser(posts.postMessage);
  useEffect(() => {
    console.log("params", params)
    getComments();
    fetchDataByPitchId();
    fetchCommentByPitchId();
    if (mobileView) {
      postsOfUserId.map((value, index) => {
        if (
          index === 0 &&
          value &&
          value.postImageURLs &&
          value.postImageURLs[0]
        ) {
          setSampleImage1(value.postImageURLs[0]);
        } else if (
          index === 1 &&
          value &&
          value.postImageURLs &&
          value.postImageURLs[0]
        ) {
          setSampleImage2(value.postImageURLs[0]);
        }
      });
    }
  }, []);

  const getSharedBody = (event) => {
    setSharedBody(event.target.value);
  };

  useEffect(() => {
    realTimePitchUpdate();
  }, []);

  useEffect(() => {
    if (props?.post?.sharedDetail) {
      setoriginalPost(props.post.sharedDetail.originalPitchId);
    } else {
      // setoriginalPost(props.post.feed_id);
    }
    checkShare();
    return () => {
      setoriginalPost(null);
      setoriginalPost(null);
      setSharedisable(true);
    };
  }, [props]);

  useEffect(() => {
    const links = [
      ...new Set(posts?.postMessage?.match(/(https?:\/\/[^\s]+)/g) || []),
    ];

    if (links.length > 0) {
      const lastLink = links[links.length - 1];
      links.forEach((link) => {
        posts.postMessage = posts.postMessage.replace(
          link,
          `<a href="${link}" class="next-not_preview-link" target="_blank">${link}</a>`
        );
      });

      const regex =
        /(https?:\/\/(?:www\.|m\.)?youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=|)|https?:\/\/youtu\.be\/)([\w-]{11})/i;
      const isYoutubeLink = lastLink?.match(regex);
      if (isYoutubeLink) {
        const videoId = isYoutubeLink[2];
        setYoutube(
          `<iframe width="100%" height="315" src="https://www.youtube.com/embed/${videoId}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
        );
      } else {
        axios
          .post(global.config.ROOTURL.prod + "/preview", {
            url: lastLink,
          })
          .then((res) => res.data)
          .then((data) => {
            setPreview(`<div class="preview-wrapper">
            <img class="preview-image" src="${data.image}" alt="${data.title}" />
            <a href="${lastLink}" target="_blank" class="preview-see_more">See More</a>
            <h1 class="preview-heading">${data.title}</h1>
            <p class="preview-description">${data.description}</p>
            <a href="${lastLink}" target="_blank" class="preview-link">${lastLink}</a>
            </div>`);
          })
          .catch((err) => console.error(err));
      }
    }
    return () => {
      setPreview(""); // This worked for me
    };
  }, []);

  useEffect(() => {
    if (posts.sharedDetail !== undefined) {
      setNewSharedDetail(posts.sharedDetail);
    }
    return () => {
      setNewSharedDetail([]);
    };
  }, [posts.sharedDetail]);

  useEffect(() => {
    if (runsGivenValue === 6) {
      setRunsImage(new6ball);
    }
    if (runsGivenValue === 4) {
      setRunsImage(new4ball);
    }
    if (runsGivenValue === 2) {
      setRunsImage(new2ball);
    }
    return () => {
      setRunsImage(runs);
    };
  }, [runsGivenValue]);

  const handlePopup = () => {
    setPostOpen(!postOpen);
    setpopupData(true);
  };


  return (
    <>
      {mobileView ? (
        <Box sx={mobileStyle} >
          {loginDetails ? <Header /> : <GuestHeader />}
          <div className="parent">
            <div className="child12">
              <div className="userDetailPost">
                <img
                  className="post__profile"
                  src={
                    posts.userProfilePhoto
                      ? posts.userProfilePhoto
                      : defaultAvatar
                  }
                  alt="user"
                  onClick={handleOpen}
                />

                <div onClick={handleOpen}>
                  {" "}
                  <h3
                    style={{
                      marginTop: "5px",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "24px",
                      letterSpacing: "0.1px",
                      color: "#1a1919",
                    }}
                  >
                    {posts.userName}
                  </h3>
                  <p className="userDetail__align">
                    {" "}
                    {posts?.createdDate && (
                      <ReactTimeAgo
                        date={Date.parse(posts?.createdDate)}
                        locale="en-US"
                      />
                    )}{" "}
                  </p>
                </div>
              </div>
              {/* {posts.sharedDetail && posts.sharedDetail.sharedText ? (
              <p
                className="comment__text1"
                dangerouslySetInnerHTML={{
                  __html: posts.sharedDetail.sharedText,
                }}
              />
            ) : (
              <p
                className="comment__text1"
                dangerouslySetInnerHTML={{ __html: posts.postMessage }}
              />
            )} */}

              {posts.sharedDetail && posts.sharedDetail !== {} && (
                <div
                  className="post-content shared-border"
                  style={{
                    margin: `${posts.sharedDetail.sharedText ? "" : 0}`,
                    padding: `${posts.sharedDetail.sharedText ? "" : 0}`,
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: posts?.sharedDetail?.sharedText,
                    }}
                  />
                  {/* <img
                    className="post-image"
                    src={posts.sharedDetail.postImage}
                    alt=""
                    // onClick={handleOpen}
                    onClick={handleOpen}
                  /> */}
                </div>
              )}
              <div
                className={`post-content ${posts.sharedDetail && posts.sharedDetail.firstName
                  ? "shared-post-content"
                  : ""
                  }`}
                style={{
                  background: "#fbfcff",
                  border: "1px solid #e0e0e0cc",
                  borderRadius: "10px",
                  marginLeft: "17px",
                  padding: "20px",
                  width: "95%",
                }}
              >
                {posts.sharedDetail && posts.sharedDetail.firstName && (
                  <NameCard
                    posts={posts}
                    newSharedDetail={posts.sharedDetail}
                  />
                )}
                <div
                  className={`${posts.sharedDetail && posts.sharedDetail.firstName
                    ? "shared-post-text"
                    : ""
                    }`}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: posts.postMessage,
                    }}
                  />
                </div>
                {youtube ? (
                  <div dangerouslySetInnerHTML={{ __html: youtube }} />
                ) : (
                  " "
                )}
                {preview ? (
                  <div dangerouslySetInnerHTML={{ __html: preview }} />
                ) : (
                  " "
                )}
                {!youtube &&
                  posts.postImageURLs &&
                  posts.postImageURLs?.length > 0 && (
                    <div style={{ marginBottom: "10px" }}>
                      <Slider
                        onClick={handleOpen}
                        images={posts.postImageURLs}
                        height="500px"
                        width="100%"
                      // onClick={handleOpen}
                      />
                    </div>
                  )}
                {!youtube && posts.postImageURL ? (
                  posts.postImageURL.match(regex) ? (
                    <img
                      className="post-image"
                      src={posts.postImageURL}
                      // onClick={handlePopup}
                      onClick={handleOpen}
                      alt=""
                      style={{ cursor: "pointer", marginTop: "10px" }}
                    />
                  ) : (
                    <video
                      className="post-image"
                      onClick={handlePopup}
                      controls
                    >
                      <source
                        src={posts.postImageURL}
                        style={{ cursor: "pointer", marginTop: "10px" }}
                        onClick={handleOpen}
                      />
                    </video>
                  )
                ) : (
                  ""
                )}
                {!youtube && posts.video && (
                  <video className="post-image" onClick={handleOpen} controls>
                    <source
                      src={posts.video}
                      style={{ cursor: "pointer", marginTop: "10px" }}
                      onClick={handleOpen}
                    />
                  </video>
                )}
              </div>
              <div className="child34" onClick={handleOpen}>
                {/* {/start} */}
                <div className="post-footer post-header border post__width">
                  <div className="runs-hld " onClick={handleOpen}>
                    <div className="displayFlex run_icon_post" >
                      <div>
                        <img src={runsImage === null ? runs : runsImage} onClick={handleOpenRuns} />
                        {runsGivenValue == 0 && open && (
                          <div className={`runcard_post `}>
                            <img
                              src={new4ball}
                              className="post_icon_image"
                              onClick={() => {
                                scorePost(4);
                              }}
                              alt=""
                            />
                            <img
                              src={new6ball}
                              className="middlerunimage post_icon_image"
                              onClick={() => {
                                scorePost(6);
                              }}
                              alt=""
                            />
                            <img
                              src={new2ball}
                              className="post_icon_image"
                              onClick={() => {
                                scorePost(2);
                              }}
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="icon_holder_style">Runs</div>
                  </div>

                  <div className="comments-hld" onClick={handleOpen}>
                    <div className="displayFlex">
                      <img
                        src={newcomment}
                        onClick={handleOpen}
                        className="icon-btn post_icon_image"
                        alt=""
                        role="button"
                      />
                      <div
                        className="comments-stat post_count"
                        style={{ paddingLeft: "5px" }}
                      >
                        {/* {updateCommentCount} */}
                      </div>
                      <div className="mobile-comments-stat  post_count">
                        {/* {updateCommentCount} */}
                      </div>
                    </div>
                    <div className="icon_holder_style">Comments</div>
                  </div>

                  {/* <div className="share-hld" onClick={handleOpen}>
                    <div className="share-btn-container">
                      //add new image
                      {sharedisable ? (
                        <img
                          src={emptyreshare}
                          className="icon-btn post_icon_image"
                          alt=""
                          role="button"
                          onClick={handleShareOpen}
                        />
                      ) : (
                        <img
                          src={newreshare}
                          className="icon-btn1 post_icon_image"
                          alt=""
                          role="button"
                          onClick={() => {
                            handleShareOpen();
                          }}
                        />
                      )}{" "}
                      <span className="post__share_count"> {updateRepostCount} </span>
                    </div>
                    <div className="icon_holder_style">Repost</div>
                    <div className={`share-modal ${sharing ? "visible" : "hidden"}`}>
                      <div className="share-modal__container">
                        <div className="share-modal__container__form">
                          <div className="input-textarea">
                            <textarea
                              placeholder="Write Something"
                              id="sharedBody"
                              onChange={getSharedBody}
                            ></textarea>
                          </div>
                          <div className="content leftPost">
                            <div className="avatar">
                              <img className="avatar-image" src={avatar} alt={""} />
                              <div className="avatar-cnt">
                                <p>{posts.sharedDetail.firstName + " " + posts.sharedDetail.lastName}</p> 
                                <p className="date-time">{postObj.createdDate}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="share-modal__container__actions">
                          <ChampButton
                            onClick={closeModal}
                            classes="cancel secondary"
                            label="Cancel"
                          />
                          <ChampButton
                            onClick={() => sharePost()}
                            classes="share primary"
                            label="Share"
                          />
                        </div>
                      </div>
                    </div>

                    Display the repost count 
                     <span className="repostt-count">{updateRepostCount}</span>
                  </div> */}

                </div>
                {/* {/End} */}

                <h5 className="comment__count">
                  {posts.postCommentCount} Comment
                </h5>

                <div className="comments__block">
                  {comments.map((comment, index) => {
                    return (
                      <div
                        className={`comment__section ${comment?.text?.includes("giphy.com/media/") ? "comment__section--gif" : ""
                          }`}
                        key={index}
                        style={{ display: "flex", width: "95%", height: "auto" }}
                      >
                        <div className="poll__user">
                          <div className="comment-image-wrapper">
                            <Grid item className="comment-image">
                              {comment.userProfilePhoto ? (
                                <img
                                  src={comment.userProfilePhoto ? comment.userProfilePhoto : defaultAvatar}
                                  alt="user"
                                  className="userProfile_pp"
                                />
                              ) : (
                                <FaUserCircle className="comments-user-icon" />
                              )}
                            </Grid>
                          </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px", width: "100%", background: "#F9FBFD" }}>
                          <Grid
                            justifyContent="left"
                            item
                            xs
                            zeroMinWidth
                            className="border__comment"
                          >
                            <div className="comment-name">
                              <p style={{ paddingTop: "10px" }}>
                                <Link
                                  style={{
                                    margin: 0,
                                    textAlign: "left",
                                    color: "#1a1919",
                                    fontWeight: "600",
                                    fontFamily: "Inter",
                                    textDecoration: "none",
                                  }}
                                  to={`/profile/${comment.userId}`}
                                >
                                  {comment.userName}
                                </Link>
                              </p>
                              {userId === comment.userId && (
                                <DropdownMenu
                                  isCommentDelete={true}
                                  type={"post-menu"}
                                  userId={userId}
                                  deleteCommentId={comment._id}
                                  postId={comment?.postId}
                                  shareJObId={comment?.shareJobId}
                                />
                              )}
                            </div>
                            <p className="comment-time" style={{ fontWeight: "300" }}>
                              {comment?.createdDate && (
                                <ReactTimeAgo
                                  date={Date.parse(comment?.createdDate)}
                                  locale="en-US"
                                />
                              )}
                            </p>
                            {giphyUrl && (
                              <img src={giphyUrl} alt="Giphy" className="react-giphy__giphs" />
                            )}
                            {/* {count < 50 ? (
                          <div>
                            <p
                              className="comment-text"
                              dangerouslySetInnerHTML={{
                                __html: description.replace(/\n\r?/g, "<br />"),
                              }}
                            />
                          </div>
                        ) : !readMore ? (
                          <div className="comment-text">
                            <p
                              className="comment-text"
                              dangerouslySetInnerHTML={{
                                __html: shortDescription.replace(/\n\r?/g, "<br />"),
                              }}
                            />
                            <span
                              className="read-more"
                              onClick={() => {
                                setReadMore(!readMore);
                              }}
                            >
                              read more
                            </span>
                            ...{" "}
                          </div>
                        ) : (
                          <div>
                            <p
                              className="comment-text"
                              dangerouslySetInnerHTML={{
                                __html: description.replace(/\n\r?/g, "<br /> "),
                              }}
                            />
                            <span
                              className="read-more"
                              onClick={() => {
                                setReadMore(!readMore);
                              }}
                            >
                              show less
                            </span>
                          </div>
                        )} */}
                          </Grid>

                          <div className="user__details">
                            <div>
                              {comment?.text?.includes("giphy.com/media/") ? (
                                <img
                                  src={comment.text.trim()} // Ensure the URL is used correctly
                                  alt="Giphy"
                                  className="react-giphy__giphs"
                                />
                              ) : (
                                <p
                                  className="comment-text"
                                  dangerouslySetInnerHTML={{
                                    __html: comment.text ? comment.text.replace(/\n\r?/g, "<br />") : "",
                                  }}
                                  style={{ marginleft: 0 }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div class="container__preview__post" onClick={handleOpen}>
                  <div class="left-avt" style={{ marginTop: "13px" }}>
                    <img
                      src={avatar ? avatar : defaultAvatar}
                      style={{ height: "40px", width: "40px", borderRadius: "50px" }}
                      alt="user"
                    />
                  </div>

                  <div className="footer-wrapper container-content">
                    <MentionsInput
                      value={comment}
                      inputRef={inputRef}
                      name="message"
                      onChange={(event, newValue, newPlainTextValue, mentions) => {
                        handleInputChange(
                          event,
                          newValue,
                          newPlainTextValue,
                          mentions
                        );
                      }}
                      id="postContent"
                      className="textarea-box text-section"
                      placeholder="Write a comment"
                      style={MentionStyle}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleCommentSubmit(comments);
                        }
                      }}
                      allowSpaceInQuery={true}
                    >
                      <Mention
                        trigger="@"
                        renderSuggestion={renderSuggestions}
                        style={tagStyles}
                        appendSpaceOnAdd={true}
                        data={getUserMentions}
                        markup="<a class='mentioned' href='/profile/__id__'>__display__</a>"
                      />
                      <Mention
                        trigger="#"
                        style={tagStyles}
                        appendSpaceOnAdd={true}
                        data={getHashtags}
                        markup="<a class='hashtagged' href='/hashtags?id=__id__'>__display__</a>"
                      />
                    </MentionsInput>
                    <div className="icon-section">
                      <BsEmojiSmile
                        className="emoji-icon"
                        onClick={handleEmojiPicker}
                      />
                      <GifBoxOutlinedIcon
                        className="clip-icon"
                        onClick={handleClipPicker}
                      />

                      <span className="send-icon">
                        <IconButton
                          disabled={comments !== "" ? false : true}
                          onClick={() => handleCommentSubmit(comments)}
                        >
                          <SendIcon />
                        </IconButton>
                      </span>
                    </div>
                  </div>
                  {showClip ? (
                    <div className="clip-picker" ref={clipRef} style={{ height: "300px", marginLeft: "25px", position: "absolute", marginTop: "-300px", background: "#F9FBFD" }}>

                      <GifComponent getgifdata={onGiphyClick} />

                    </div>
                  ) : null}
                  {showEmojis ? (
                    <div className="emoji-picker" ref={emojiRef} style={{ width: "282px", height: "300px", marginBottom: "-520px", marginLeft: "16px" }}>
                      <EmojiPicker
                        onEmojiClick={(EmojiClickData, MouseEvent) => {
                          handleEmojiClick(EmojiClickData.emoji);
                        }}

                      />

                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {popupData && (
            <PostPopup
              props={props}
              postOpen={postOpen}
              setOpen={setPostOpen}
              handleclose={handleclose}
              sharedetail={newSharedDetail}
              sharedisable={sharedisable}
              originalpost={shareOriginalPost}
              onClick={handleOpen}
            />
          )}
        </Box>
      ) : (
        <>
          {loginDetails ? <Header /> : <GuestHeader />}
          <Box sx={mobileView ? mobileStyle : style} >
            <div className="parent">
              <div className="child12">
                <div className="userDetailPost">
                  <img
                    className="post__profile"
                    src={
                      posts.userProfilePhoto
                        ? posts.userProfilePhoto
                        : defaultAvatar
                    }
                    alt="user"
                    onClick={handleOpen}
                  />

                  <div onClick={handleOpen}>
                    {" "}
                    <h3
                      style={{
                        marginTop: "5px",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "24px",
                        letterSpacing: "0.1px",
                        color: "#1a1919",
                      }}
                    >
                      {posts.userName}
                    </h3>
                    <p className="userDetail__align">
                      {" "}
                      {posts?.createdDate && (
                        <ReactTimeAgo
                          date={Date.parse(posts?.createdDate)}
                          locale="en-US"
                        />
                      )}{" "}
                    </p>
                  </div>
                </div>
                {/* {posts.sharedDetail && posts.sharedDetail.sharedText ? (
                  <p
                    className="comment__text1"
                    dangerouslySetInnerHTML={{
                      __html: posts.sharedDetail.sharedText,
                    }}
                  />
                ) : (
                  <p
                    className="comment__text1"
                    dangerouslySetInnerHTML={{ __html: posts.postMessage }}
                  />
                )} */}

                {posts.sharedDetail && posts.sharedDetail !== {} && (
                  <div
                    className="post-content shared-border"
                    style={{
                      margin: `${posts.sharedDetail.sharedText ? "" : 0}`,
                      padding: `${posts.sharedDetail.sharedText ? "" : 0}`,
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: posts?.sharedDetail?.sharedText,
                      }}
                    />
                    {/* <img
                      className="post-image"
                      src={posts.sharedDetail.postImage}
                      alt=""
                      // onClick={handleOpen}
                      onClick={handleOpen}
                    /> */}
                  </div>
                )}
                <div
                  className={`post-content ${posts.sharedDetail && posts.sharedDetail.firstName
                    ? "shared-post-content"
                    : ""
                    }`}
                  style={{
                    background: "#fbfcff",
                    border: "1px solid #e0e0e0cc",
                    borderRadius: "10px",
                    marginLeft: "17px",
                    padding: "20px",
                    width: "95%",
                  }}
                >
                  {posts.sharedDetail && posts.sharedDetail.firstName && (
                    <NameCard
                      posts={posts}
                      newSharedDetail={posts.sharedDetail}
                    />
                  )}
                  <div
                    className={`${posts.sharedDetail && posts.sharedDetail.firstName
                      ? "shared-post-text"
                      : ""
                      }`}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: posts.postMessage,
                      }}
                    />
                  </div>
                  {youtube ? (
                    <div dangerouslySetInnerHTML={{ __html: youtube }} />
                  ) : (
                    " "
                  )}
                  {preview ? (
                    <div dangerouslySetInnerHTML={{ __html: preview }} />
                  ) : (
                    " "
                  )}
                  {!youtube &&
                    posts.postImageURLs &&
                    posts.postImageURLs?.length > 0 && (
                      <div style={{ marginBottom: "10px" }}>
                        <Slider
                          onClick={handleOpen}
                          images={posts.postImageURLs}
                          height="500px"
                          width="100%"
                        // onClick={handleOpen}
                        />
                      </div>
                    )}
                  {!youtube && posts.postImageURL ? (
                    posts.postImageURL.match(regex) ? (
                      <img
                        className="post-image"
                        src={posts.postImageURL}
                        // onClick={handlePopup}
                        onClick={handleOpen}
                        alt=""
                        style={{ cursor: "pointer", marginTop: "10px" }}
                      />
                    ) : (
                      <video
                        className="post-image"
                        onClick={handlePopup}
                        controls
                      >
                        <source
                          src={posts.postImageURL}
                          style={{ cursor: "pointer", marginTop: "10px" }}
                          onClick={handleOpen}
                        />
                      </video>
                    )
                  ) : (
                    ""
                  )}
                  {!youtube && posts.video && (
                    <video className="post-image" onClick={handleOpen} controls>
                      <source
                        src={posts.video}
                        style={{ cursor: "pointer", marginTop: "10px" }}
                        onClick={handleOpen}
                      />
                    </video>
                  )}
                </div>
              </div>
              <div className="child2" >
                <h5 className="comment__count">
                  {posts.postCommentCount} Comment
                </h5>

                <div className="comments__block">
                  {comments.map((comment, index) => {
                    return (
                      <div
                        className={`comment__section ${comment?.text?.includes("giphy.com/media/") ? "comment__section--gif" : ""
                          }`}
                        key={index}
                        style={{ display: "flex", width: "95%", height: "auto" }}
                      >
                        <div className="poll__user">
                          <div className="comment-image-wrapper">
                            <Grid item className="comment-image">
                              {comment.userProfilePhoto ? (
                                <img
                                  src={comment.userProfilePhoto ? comment.userProfilePhoto : defaultAvatar}
                                  alt="user"
                                  className="userProfile_pp"
                                />
                              ) : (
                                <FaUserCircle className="comments-user-icon" />
                              )}
                            </Grid>
                          </div>
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px", width: "100%", background: "#F9FBFD" }}>
                          <Grid
                            justifyContent="left"
                            item
                            xs
                            zeroMinWidth
                            className="border__comment"
                          >
                            <div className="comment-name">
                              <p style={{ paddingTop: "10px" }}>
                                <Link
                                  style={{
                                    margin: 0,
                                    textAlign: "left",
                                    color: "#1a1919",
                                    fontWeight: "600",
                                    fontFamily: "Inter",
                                    textDecoration: "none",
                                  }}
                                  to={`/profile/${comment.userId}`}
                                >
                                  {comment.userName}
                                </Link>
                              </p>
                              {userId === comment.userId && (
                                <DropdownMenu
                                  isCommentDelete={true}
                                  type={"post-menu"}
                                  userId={userId}
                                  deleteCommentId={comment._id}
                                  postId={comment?.postId}
                                  shareJObId={comment?.shareJobId}
                                />
                              )}
                            </div>
                            <p className="comment-time" style={{ fontWeight: "300" }}>
                              {comment?.createdDate && (
                                <ReactTimeAgo
                                  date={Date.parse(comment?.createdDate)}
                                  locale="en-US"
                                />
                              )}
                            </p>
                            {giphyUrl && (
                              <img src={giphyUrl} alt="Giphy" className="react-giphy__giphs" />
                            )}
                            {/* {count < 50 ? (
                              <div>
                                <p
                                  className="comment-text"
                                  dangerouslySetInnerHTML={{
                                    __html: description.replace(/\n\r?/g, "<br />"),
                                  }}
                                />
                              </div>
                            ) : !readMore ? (
                              <div className="comment-text">
                                <p
                                  className="comment-text"
                                  dangerouslySetInnerHTML={{
                                    __html: shortDescription.replace(/\n\r?/g, "<br />"),
                                  }}
                                />
                                <span
                                  className="read-more"
                                  onClick={() => {
                                    setReadMore(!readMore);
                                  }}
                                >
                                  read more
                                </span>
                                ...{" "}
                              </div>
                            ) : (
                              <div>
                                <p
                                  className="comment-text"
                                  dangerouslySetInnerHTML={{
                                    __html: description.replace(/\n\r?/g, "<br /> "),
                                  }}
                                />
                                <span
                                  className="read-more"
                                  onClick={() => {
                                    setReadMore(!readMore);
                                  }}
                                >
                                  show less
                                </span>
                              </div>
                            )} */}
                          </Grid>

                          <div className="user__details">
                            <div>
                              {comment?.text?.includes("giphy.com/media/") ? (
                                <img
                                  src={comment.text.trim()} // Ensure the URL is used correctly
                                  alt="Giphy"
                                  className="react-giphy__giphs"
                                />
                              ) : (
                                <p
                                  className="comment-text"
                                  dangerouslySetInnerHTML={{
                                    __html: comment.text ? comment.text.replace(/\n\r?/g, "<br />") : "",
                                  }}
                                  style={{ marginleft: 0 }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* {/start} */}
                <div className="post-footer post-header border post__width">
                  <div className="runs-hld " onClick={handleOpen}>
                    <div className="displayFlex run_icon_post">
                      <div>
                        <img src={runsImage === null ? runs : runsImage} onClick={handleOpenRuns} />
                        {runsGivenValue == 0 && open && (
                          <div className={`runcard_post `}>
                            <img
                              src={new4ball}
                              className="post_icon_image"
                              onClick={() => {
                                scorePost(4);
                              }}
                              alt=""
                            />
                            <img
                              src={new6ball}
                              className="middlerunimage post_icon_image"
                              onClick={() => {
                                scorePost(6);
                              }}
                              alt=""
                            />
                            <img
                              src={new2ball}
                              className="post_icon_image"
                              onClick={() => {
                                scorePost(2);
                              }}
                              alt=""
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="icon_holder_style">Runs</div>
                  </div>

                  <div className="comments-hld" style={{ paddingTop: "13px" }}>
                    <div className="displayFlex">
                      <img
                        src={newcomment}
                        onClick={handleOpen}
                        className="icon-btn post_icon_image"
                        alt=""
                        role="button"
                      />
                      <div
                        className="comments-stat post_count"
                        style={{ paddingLeft: "5px" }}
                      >
                        {/* {updateCommentCount} */}
                      </div>
                      <div className="mobile-comments-stat  post_count">
                        {/* {updateCommentCount} */}
                      </div>
                    </div>
                    <div className="icon_holder_style">Comments</div>
                  </div>

                  {/* <div className="share-hld">
                    <div className="share-btn-container">
                      add new image
                      {sharedisable ? (
                        <img
                          src={emptyreshare}
                          className="icon-btn post_icon_image"
                          alt=""
                          role="button"
                          onClick={handleShareOpen}
                        />
                      ) : (
                        <img
                          src={newreshare}
                          className="icon-btn1 post_icon_image"
                          alt=""
                          role="button"
                          onClick={() => {
                            handleShareOpen();
                          }}
                        />
                      )}{" "}
                      <span className="post__share_count"> {updateRepostCount} </span>
                    </div>
                    <div className="icon_holder_style">Repost</div>
                    <div className={`share-modal ${sharing ? "visible" : "hidden"}`}>
                      <div className="share-modal__container">
                        <div className="share-modal__container__form">
                          <div className="input-textarea">
                            <textarea
                              placeholder="Write Something"
                              id="sharedBody"
                              onChange={getSharedBody}
                            ></textarea>
                          </div>
                          <div className="content leftPost">
                            <div className="avatar">
                              <img className="avatar-image" src={avatar} alt={""} />
                              <div className="avatar-cnt">
                                <p>{posts.sharedDetail.firstName + " " + posts.sharedDetail.lastName}</p> 
                                <p className="date-time">{postObj.createdDate}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="share-modal__container__actions">
                          <ChampButton
                            onClick={closeModal}
                            classes="cancel secondary"
                            label="Cancel"
                          />
                          <ChampButton
                            onClick={() => sharePost()}
                            classes="share primary"
                            label="Share"
                          />
                        </div>
                      </div>
                    </div>

                    Display the repost count
                    <span className="repostt-count">{updateRepostCount}</span>
                  </div> */}

                </div>
                {/* {/End} */}

                <div class="container__preview__post">
                  <div class="left-avt" >
                    <img
                      src={avatar ? avatar : defaultAvatar}
                      style={{ height: "40px", width: "40px", borderRadius: "50px" }}
                      alt="user"
                    />
                  </div>

                  <div className="footer-wrapper container-content">
                    <MentionsInput
                      value={comment}
                      inputRef={inputRef}
                      name="message"
                      onChange={(event, newValue, newPlainTextValue, mentions) => {
                        handleInputChange(
                          event,
                          newValue,
                          newPlainTextValue,
                          mentions
                        );
                      }}
                      id="postContent"
                      className="textarea-box text-section"
                      placeholder="Write a comment"
                      style={MentionStyle}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleCommentSubmit(comments);
                        }
                      }}
                      allowSpaceInQuery={true}
                    >
                      <Mention
                        trigger="@"
                        renderSuggestion={renderSuggestions}
                        style={tagStyles}
                        appendSpaceOnAdd={true}
                        data={getUserMentions}
                        markup="<a class='mentioned' href='/profile/__id__'>__display__</a>"
                      />
                      <Mention
                        trigger="#"
                        style={tagStyles}
                        appendSpaceOnAdd={true}
                        data={getHashtags}
                        markup="<a class='hashtagged' href='/hashtags?id=__id__'>__display__</a>"
                      />
                    </MentionsInput>
                    <div className="icon-section">
                      <BsEmojiSmile
                        className="emoji-icon"
                        onClick={handleEmojiPicker}
                      />
                      <GifBoxOutlinedIcon
                        className="clip-icon"
                        onClick={handleClipPicker}
                      />

                      <span className="send-icon">
                        <IconButton
                          disabled={comments !== "" ? false : true}
                          onClick={() => handleCommentSubmit(comments)}
                        >
                          <SendIcon />
                        </IconButton>
                      </span>
                    </div>
                  </div>
                  {showClip ? (
                    <div className={`clip-picker ${isFilterButtonActive ? "active" : ""}`} ref={clipRef} style={{ marginBottom: "35px" }}>

                      <GifComponent getgifdata={onGiphyClick} />

                    </div>
                  ) : null}
                  {showEmojis ? (
                    <div className={`emoji-picker ${isFilterButtonActive ? "active" : ""}`} ref={emojiRef} style={{ width: "340px", marginBottom: "35px" }}>
                      <EmojiPicker
                        onEmojiClick={(EmojiClickData, MouseEvent) => {
                          handleEmojiClick(EmojiClickData.emoji);
                        }}

                      />

                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {popupData && (
              <PostPopup
                props={props}
                postOpen={postOpen}
                setOpen={setPostOpen}
                handleclose={handleclose}
                sharedetail={newSharedDetail}
                sharedisable={sharedisable}
                originalpost={shareOriginalPost}
                onClick={handleOpen}
              />
            )}
          </Box>
        </>
      )}
      {showLoginPopup && <Popup Open={showLoginPopup} handleClose={closeLoginPopup} />}
    </>
  );
};

const return1SecondPlusDate = () => {
  let t = new Date();

  t.setSeconds(t.getSeconds() + 100);

  return t;
};
export default Posts;
