import React, { useState, useEffect, useCallback, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import Comment from "./Comment";
import "./comment.scss";

export default function CommentModal({ onClose, isOpen, highlightId }) {
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const accessToken = getStorageItem("token");

  const observer = useRef();
  const lastCommentElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const fetchComments = useCallback(() => {
    if (!accessToken || loading || !hasMore) return;
    setLoading(true);

    axios({
      method: "GET",
      url: `${global.config.ROOTURL.prod}/highlight/highlightComment/getCommentByHighlightId/${highlightId}/${page}/10`,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const newComments = response.data;
        setComments((prevComments) => [...prevComments, ...newComments]);
        setHasMore(newComments.length === 10);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching comments:", error);
        setLoading(false);
      });
  }, [accessToken, highlightId, page, hasMore, loading]);

  useEffect(() => {
    if (isOpen) {
      fetchComments();
    }
  }, [isOpen, fetchComments]);

  const handleClose = () => {
    onClose();
    setComments([]);
    setPage(1);
    setHasMore(true);
  };

  const handleSend = () => {
    if (!accessToken || !commentText.trim()) return;

    const newComment = {
      _id: `temp-${Date.now()}`, // Temporary ID
      userProfilePhoto: getStorageItem("avatar"),
      userName: getStorageItem("username"),
      commentText: commentText,
      createdAt: new Date().toISOString(),
      isOptimistic: true, // Flag to indicate this is an optimistic update
    };

    // Optimistically update UI
    setComments((prevComments) => [newComment, ...prevComments]);
    setCommentText("");

    axios({
      method: "POST",
      url: `${global.config.ROOTURL.prod}/highlight/highlightComment/createHighLightComment`,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        highlightId: highlightId,
        commentText: newComment.commentText,
      },
    })
      .then((response) => {
        // Update the comment with the actual data from the server
        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment._id === newComment._id
              ? { ...response.data, isOptimistic: false }
              : comment
          )
        );
      })
      .catch((error) => {
        console.error("Error creating comment:", error);
        // Remove the optimistically added comment in case of error
        setComments((prevComments) =>
          prevComments.filter((comment) => comment._id !== newComment._id)
        );
      });
  };

  const handleReply = (commentId) => {
    console.log("Reply to comment:", commentId);
  };

  const handleLike = (commentId) => {
    console.log("Like comment:", commentId);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="comnt-modalContainer">
        <CloseIcon onClick={handleClose} className="comnt-closeIcon" />
        <p>Comments</p>

        <div className="comments-list">
          {comments.map((comment, index) => (
            <div
              key={comment._id}
              ref={index === comments.length - 1 ? lastCommentElementRef : null}
            >
              <Comment
                comment={comment}
                onReply={handleReply}
                onLike={handleLike}
                isOptimistic={comment.isOptimistic}
              />
            </div>
          ))}
          {loading && (
            <Box display="flex" justifyContent="center" my={2}>
              <CircularProgress />
            </Box>
          )}
          {!hasMore && comments.length > 0 && (
            <Box textAlign="center" my={2}>
              No more comments to load.
            </Box>
          )}
          {!loading && comments.length === 0 && (
            <Box textAlign="center" my={2}>
              No comments yet. Be the first to comment!
            </Box>
          )}
        </div>

        <div className="footer-highlightcomment">
          <div className="comnt-userProfile">
            <img src={getStorageItem("avatar")} alt="user-profile" />
          </div>
          <textarea
            aria-label="comment-input"
            placeholder="Write a comment"
            className="comnt-textarea"
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
          ></textarea>
          <IconButton onClick={handleSend} disabled={!commentText.trim()}>
            <SendIcon className="comnt-sendicon" />
          </IconButton>
        </div>
      </Box>
    </Modal>
  );
}