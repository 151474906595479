import React, { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import runs from "../../assets/images/Pitch/Feed/ballIcon.svg";
import emptyreshare from "../../assets/images/Pitch/Feed/notReposted.png";
import newshare from "../../assets/images/Pitch/Feed/shareIcon.svg";
import newcomment from "../../assets/images/Pitch/Feed/commentIcon.svg";
import DropdownMenu from "./../dropdown-menu/dropdownMenu";
import new2ball from "../../assets/images/icons/2runs.svg";
import new4ball from "../../assets/images/icons/4runs.svg";
import new6ball from "../../assets/images/icons/6runs.svg";
import avtarCircle from '../../assets/images/Pitch/Feed/profileRing.svg';
import defaultAvatar from "../../assets/images/profile/default_avatar.png";
import "./index.scss";
import { Button } from "@mui/material";
import axios from "axios";

import LoginPopup from "../guest-pitch/LoginPopup";


const Post = () => {
    const mobileView = useMediaQuery("(max-width:768px)");
    const [open, setOpen] = useState(false);
    const handleClose = (data) => setOpen(data);
    const handleOpen = () => setOpen(true);
    const [posts, setPosts] = useState([]);

    const formatTimeAgo = (date) => {
        const now = new Date();
        const createdDate = new Date(date);
        const diffInSeconds = Math.floor((now - createdDate) / 1000);

        let timeAgo;
        if (diffInSeconds < 60) {
            timeAgo = `${diffInSeconds}s`; // seconds
        } else if (diffInSeconds < 3600) {
            const minutes = Math.floor(diffInSeconds / 60);
            timeAgo = `${minutes}m`; // minutes
        } else if (diffInSeconds < 86400) {
            const hours = Math.floor(diffInSeconds / 3600);
            timeAgo = `${hours}h`; // hours
        } else if (diffInSeconds < 604800) { // 1 week
            const days = Math.floor(diffInSeconds / 86400);
            timeAgo = `${days}d`; // days
        } else {
            const weeks = Math.floor(diffInSeconds / 604800);
            timeAgo = `${weeks}w`; // weeks
        }

        return timeAgo;
    };

    const getGuestPost = async () => {
        const options = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/pitch/getPostWithoutAuth",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        };
        axios(options)
            .then(({ data }) => {
                setPosts(data);
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    console.log(error);
                }
            });
    };
    useEffect(() => {
        getGuestPost();
    }, []);
    return (
        <>
            <div className="posts">
                {posts.map((item, index) => (
                    <div className="post" key={index}>
                        <div className="post-header" onClick={handleOpen}>
                            <div className="leftPost">
                                <div className="avatar">
                                    <img src={avtarCircle} alt=""
                                        style={{
                                            position: 'absolute',
                                            width: mobileView && '39px',
                                            height: mobileView && '39px',
                                            right: mobileView && '-2px'
                                        }}
                                    />
                                    <img
                                        className="avatar-image"
                                        style={{ width: '100%', height: '100%' }}
                                        src={
                                            item.userProfilePhoto
                                                ? item.userProfilePhoto
                                                : defaultAvatar
                                        }
                                        alt=""
                                    />
                                </div>
                                <div className="avatar-cnt">
                                    <p className="shared-link" style={{
                                        textDecoration: "none",
                                        color: "#242424",
                                        fontFamily: "Inter",
                                        fontWeight: 600,
                                        lineHeight: "22px",
                                    }}>

                                        {item.userName}
                                    </p>
                                    <p className="date-time">
                                        {item?.createdDate && (
                                            formatTimeAgo(Date.parse(item.createdDate))
                                        )}
                                        <br />
                                    </p>
                                </div>
                            </div>
                            <div className="right_post">

                                <Button
                                    variant="contained"
                                    className="Pitch_follow_button"

                                >
                                    + Follow
                                </Button>

                                <div className="post-edit">

                                    <DropdownMenu
                                        type="post-menu"

                                    />
                                </div>
                            </div>
                        </div>
                        <div className="post-content shared-border" onClick={handleOpen}
                            style={{
                                margin: `${item?.postMessage ? "" : 0}`,
                                padding: `${item?.postMessage ? "" : 0}`,
                            }}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: item.postMessage,
                                }}
                            />
                            <img
                                className="post-image"
                                src={item.postImageURLs}
                                alt=""
                            />
                        </div>


                        <div className="runs-comment-container" onClick={handleOpen}>
                            <div style={{ display: "flex", alignItems: "center" }}>

                                <div className="post-header border post__width">
                                    <div className="runs-hld ">
                                        <div className="displayFlex run_icon_post">
                                            <div>
                                                <div className="runs-container1">
                                                    <div
                                                        style={{
                                                            margin: "auto",
                                                            display: "flex",
                                                            textAlign: "center",
                                                            position: 'relative',
                                                            top: '-15px'
                                                        }}
                                                    >
                                                        <img
                                                            src={new2ball}
                                                            style={{
                                                                position: 'absolute',
                                                                padding: "0px",
                                                                paddingRight: "3px",
                                                            }}
                                                            alt=""
                                                            className="post_icon_image"
                                                        />
                                                        <img
                                                            src={new4ball}
                                                            style={{
                                                                position: 'absolute',
                                                                left: '15px',
                                                                padding: "0px",
                                                                paddingRight: "3px",
                                                            }}
                                                            alt=""
                                                            className="post_icon_image"
                                                        />

                                                        <img
                                                            src={new6ball}
                                                            style={{
                                                                position: 'absolute',
                                                                left: '30px',
                                                                padding: "0px",
                                                                paddingRight: "3px",
                                                            }}
                                                            alt=""
                                                            className="post_icon_image"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p className="post_count hover_css" >
                                    <span className="post__totalrunscount" style={{ color: "448bca" }}>
                                        {" "}
                                        {item.postRunCount} Runs
                                    </span>
                                </p>
                            </div>

                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div
                                    className="comments-stat post_count"
                                    style={{ marginRight: "20px" }}
                                >
                                    <span
                                        style={{ color: "#6B6B6B", cursor: "pointer" }}
                                    >
                                        {item.postCommentCount} Comments{" "}
                                    </span>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between", gap: "4px" }} >
                                    <div className="share-btn-container">

                                    </div>
                                    <div style={{ color: "#6B6B6B", cursor: "pointer", fontWeight: "500" }}>{item.postReportCount} Repost</div>
                                </div>
                            </div>
                        </div>
                        <div className="post-footer post-header border post__width" onClick={handleOpen}>
                            <div className="runs-hld ">
                                <div className="displayFlex run_icon_post">
                                    <div>
                                        <div className="">
                                            <div
                                                style={{
                                                    margin: "auto",
                                                    display: "flex",
                                                    textAlign: "center",
                                                }}
                                            >
                                                <img
                                                    src={runs}
                                                    style={{

                                                        padding: "0px",
                                                        paddingRight: "3px",
                                                    }}
                                                    alt="img"
                                                    className="post_icon_image"

                                                />
                                            </div>
                                        </div>

                                        <div className="">
                                            <div
                                                style={{
                                                    margin: "auto",
                                                    display: "flex",
                                                    textAlign: "center",
                                                }}
                                            >

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="icon_holder_style">Runs</div>
                            </div>


                            <div className="comments-hld  " >
                                <div className="displayFlex">
                                    <img
                                        src={newcomment}
                                        className="icon-btn post_icon_image"
                                        alt=""
                                        role="button"
                                    />
                                    <span className="comment-count">  </span>

                                </div>
                                <div className="icon_holder_style">Comments</div>
                            </div>


                            <div className="share-hld">
                                <div className="share-btn-container">


                                    <img
                                        src={emptyreshare}
                                        className="icon-btn post_icon_image"
                                        alt=""
                                        role="button"

                                    />


                                    {" "}
                                    <span className="post__share_count">  </span>
                                </div>
                                <div className="icon_holder_style">Repost</div>
                                <div className="share-modal ">
                                    <div className="share-modal__container">
                                        <div className="share-modal__container__form">
                                            <div className="input-textarea">

                                            </div>
                                            <div className="content leftPost">
                                                <div className="avatar">
                                                    <div className="avatar-cnt">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="share-modal__container__actions">

                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div
                                className="comments-hld displayFlex"
                            >
                                <div>
                                    <img
                                        src={newshare}
                                        className="icon-btn post_icon_image"
                                        alt=""
                                        role="button"
                                    />
                                </div>
                                <div className="icon_holder_style">Share</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {open && <LoginPopup open={open} handleClose={handleClose} />}
        </>
    )
};

export default Post;
