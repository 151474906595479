import React, { useState } from "react";
import "./index.css";
import { toast } from "react-toastify";
import { getStorageItem } from "../../utils/sessionStorage";
import { MenuItem, Select } from "@mui/material";
import axios from "axios";

const CreateScore = () => {
  const [team1, setTeam1] = useState("");
  const [team2, setTeam2] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [type, setType] = useState("");
  const [tournament, setTournament] = useState("");
  const [info, setInfo] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const accessToken = getStorageItem("token");

  const uploadFileToS3 = async (file) => {
    if (!file) return null;

    const formData = new FormData();
    formData.append("uploader", file, file.name);

    try {
      const uploadResponse = await axios.post(
        `${global.config.ROOTURL.prod}/upload-file`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (uploadResponse.data && uploadResponse.data.length > 0) {
        return uploadResponse.data[0].location;
      } else {
        throw new Error("No data returned from upload.");
      }
    } catch (error) {
      console.error("Upload error:", error);
      toast.error("Failed to upload image");
      return null;
    }
  };
  
  const handleCardCreation = async () => {
    // Validate inputs
    if (!team1 || !team2 || !info) {
      toast.error("Please fill in all required fields");
      return;
    }

    setIsUploading(true);

    try {
      // Upload images
      const team1ImageUrl = await uploadFileToS3(image1);
      const team2ImageUrl = await uploadFileToS3(image2);

      const data = {
        team1: {
          name: team1,
          image: team1ImageUrl || "" // Use empty string if upload fails
        },
        team2: {
          name: team2,
          image: team2ImageUrl || "" // Use empty string if upload fails
        },
        type: type,
        tournament: tournament,
        information: info,
      };

      const options = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/scorecard/create",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
          role: "Admin",
        },
        data: data,
      };

      const response = await axios(options);

      if (response?.status === 200) {
        // Reset form
        setTeam1("");
        setTeam2("");
        setImage1(null);
        setImage2(null);
        setType("");
        setTournament("");
        setInfo("");
        
        // Clear file inputs
        if (fileInput1.current) fileInput1.current.value = "";
        if (fileInput2.current) fileInput2.current.value = "";

        toast.success("New Card created successfully");
      }
    } catch (error) {
      console.error("Card creation error:", error);
      if (error?.response?.status === 401) {
        toast.error("Unauthorized. Please log in again.");
      } else {
        toast.error("Failed to create scorecard");
      }
    } finally {
      setIsUploading(false);
    }
  };

  // Refs for file inputs
  const fileInput1 = React.useRef(null);
  const fileInput2 = React.useRef(null);

  return (
    <div className="contest-admin-create">
      <div className="contest-admin-create-wrapper">
        <div className="contest-admin-create-wrapper-main">
          <div className="contest-admin-create-wrapper-form">
            <h1>Create ScoreCard</h1>
            
            <div className="contest-create-name-wrapper">
              Team 1 Name- &nbsp;
              <input
                type="text"
                style={{padding:"3px", fontSize:'16px', fontWeight:'500'}}
                value={team1}
                onChange={(event) => {
                  setTeam1(event.target.value);
                }}
                required
              />
            </div>

            <div className="contest-create-name-wrapper">
              Team 1 Logo- &nbsp;
              <input
                type="file"
                ref={fileInput1}
                accept="image/*"
                onChange={(event) => {
                  const file = event.target.files[0];
                  setImage1(file);
                }}
                required
              />
            </div>

            <div className="contest-create-name-wrapper">
              Team 2 Name- &nbsp;
              <input
                type="text"
                style={{padding:"3px", fontSize:'16px', fontWeight:'500'}}
                value={team2}
                onChange={(event) => {
                  setTeam2(event.target.value);
                }}
                required
              />
            </div>

            <div className="contest-create-name-wrapper">
              Team 2 Logo- &nbsp;
              <input
                type="file"
                ref={fileInput2}
                accept="image/*"
                onChange={(event) => {
                  const file = event.target.files[0];
                  setImage2(file);
                }}
                required
              />
            </div>

            <div className="contest-create-name-wrapper">
              Type(optional)- &nbsp;
              <Select
                value={type}
                onChange={(event) => {
                  setType(event.target.value);
                }}
                displayEmpty
                style={{ width: "110px", marginLeft: "5px" }}
              >
                <MenuItem value="">
                  <em>Select Type</em>
                </MenuItem>
                <MenuItem value="T20I">T20I</MenuItem>
                <MenuItem value="ODI">ODI</MenuItem>
                <MenuItem value="Test">Test</MenuItem>
              </Select>
            </div>

            <div className="contest-create-name-wrapper">
              Tournament-
              <input
                type="text"
                className="contest-create-name"
                value={tournament}
                onChange={(event) => {
                  setTournament(event.target.value);
                }}
              />
            </div>

            <div className="contest-create-name-wrapper">
              Information-
              <input
                type="text"
                className="contest-create-name"
                value={info}
                onChange={(event) => {
                  setInfo(event.target.value);
                }}
                required
              />
            </div>

            <button
              onClick={handleCardCreation}
              className="contest-preediction-admin-creation-submit-button"
              disabled={isUploading}
            >
              {isUploading ? "Creating..." : "Confirm"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateScore;