import React, { useRef, useEffect, useState } from "react";
import { IconButton, Button, Typography, colors, useMediaQuery } from "@mui/material";
import {
  Favorite,
  ChatBubbleOutline,
  Share,
  MusicNote,
  ArrowBack,
  CloudUpload,
  VedioUpload,
  BookmarkBorder,
  Bookmark,
} from "@mui/icons-material";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import runLogo from "../../assets/images/fiveRuns-image.svg";
import whiteball from "../../assets/images/icons/clickopennewball.png";
import VideoUploaderWithTrimmer from "./VideoUploaderWithTrimmer";
import ShareToAppsPopupPage from "./shareToOtherApps";
import "./index.scss";
import "./comment.scss";
import savedHighlightsicon from "../../assets/images/posts/savedpost-icon.png";
import unsavedHighkightsicon from "../../assets/images/posts/unsavedpost-icon.png";
import vedioAddUpload from "../../assets/images/Video Add.png";
import runIcon from "../../assets/images/Run Icon.png";
import commentIcon from "../../assets/images/Comment Icon.png";
import unsaveIcon from "../../assets/images/Save Icon.png";
import savedIcon from "../../assets/images/Saved Icon.png";
import shareIcon from "../../assets/images/Shear Icon.png";

const ReelItem = ({
  reel,
  onComment,
  setCurrentReelIndex,
  commentCount,
  checkFollow,
  reelLocation
}) => {
  const highLightsId = reel._id;
  const videoRef = useRef(null);
  const [checkFollowing, setCheckFollowing] = useState(
    checkFollow === "Following" ? true : false
  );
  const [isVisible, setIsVisible] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [runCount, setRunCount] = useState(reel.highlightRunCount);
  const [show6RunsIcon, setShow6RunsIcon] = useState(false);
  const navigate = useNavigate();
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const [url, setUrl] = useState("");
  const [shareToOtherApps, setShareToOtherApps] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const [isSaved, setIsSaved] = useState(false); // Track saved state
  // const [checkFollowing, setCheckFollowing] = useState(false);
  const mobileView = useMediaQuery("(max-width:600px)");
  const [ownProfile, setOwnProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const timer = useRef();
  const handleCloseShareToOtherAppsPopup = () => {
    setShareToOtherApps(false);
  };

  // Toggle video play/pause on click
  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  // Set up the observer to play/pause based on visibility
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.7,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          setCurrentReelIndex();
          videoRef.current
            .play()
            .catch((error) => console.error("Playback failed:", error));
        } else {
          setIsVisible(false);
          videoRef.current.pause();
        }
      });
    }, options);

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, [setCurrentReelIndex]);

  useEffect(() => {
    if (isVisible) {
      setIsMuted(false);
      videoRef.current
        ?.play()
        .catch((error) => console.error("Playback failed:", error));
    } else {
      setIsMuted(true);
      videoRef.current?.pause();
    }
  }, [isVisible]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted;
    }
  }, [isMuted]);

  const handleOpenUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const handleShareToOtherAppsPopup = () => {
    let shareUrl = `${global.config.share_url}/highlight-preview/${highLightsId}`;
    setUrl(shareUrl);
    setShareToOtherApps(true);
  };

  const handleCloseUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const handleBack = () => {
    navigate("/pitch");
  };

  useEffect(() => {
    const fetchRunStatus = async () => {
      try {
        const response = await axios.get(
          `${global.config.ROOTURL.prod}/highlight/highlightRun/getRunByUserIdAndHighlightId/${userId}/${highLightsId}`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        );
        if (response.data > 0) {
          setShow6RunsIcon(true);
        }
      } catch (error) {
        console.error("Error fetching run status:", error);
      }
    };

    fetchRunStatus();
  }, [userId, highLightsId, accessToken]);

  const handleSave = async () => {
    try {
      await axios.post(
        `${global.config.ROOTURL.prod}/highlight/highlights/saveHighlights`,
        { highlightId: highLightsId, userId },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setIsSaved(true);
    } catch (error) {
      console.error("Error saving highlight:", error);
    }
  };

  const handleUnsave = async () => {
    try {
      await axios.delete(
        `${global.config.ROOTURL.prod}/highlight/highlights/unsaveHighlights/${userId}/${highLightsId}`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      setIsSaved(false);
    } catch (error) {
      console.error("Error unsaving highlight:", error);
    }
  };

  useEffect(() => {
    const fetchSavedHighlights = async () => {
      try {
        const response = await axios.get(
          `${global.config.ROOTURL.prod}/highlight/highlights/checkSaveHighlights/${userId}/${highLightsId}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        setIsSaved(response.data.saved);
      } catch (error) {
        console.error("Error checking if highlight is saved:", error);
      }
    };

    fetchSavedHighlights();
  }, [userId, highLightsId, accessToken]);

  const handleRunsClick = () => {
    axios({
      method: "POST",
      url: `${global.config.ROOTURL.prod}/highlight/highlightRun/addHighlightRun`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      data: {
        highlightId: highLightsId,
        profilephoto: reel.profilePhoto,
        username: reel.userName,
        userid: userId,
        totalrun: process.env.HIGHLIGHT_RUN,
      },
    })
      .then((response) => {
        setRunCount(response.data.updatedRunCount);
        setShow6RunsIcon(true);
      })
      .catch((error) => {
        console.error("Error adding highlight run:", error);
      });
  };

  const navigatetoprofile = (userId) => {
    navigate(`/profile/${userId}`);
  };
  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    if (userId === reel.userId) {
      setOwnProfile(true);
    }
  }, [userId, reel.userId, accessToken, ownProfile]);

  const handleFollow = () => {
    if (!userId || !accessToken) return;

    setLoading(true);

    const followConfig = {
      method: "POST",
      url: `${global.config.ROOTURL.prod}/auth/add-following-user/`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        followingUserId: reel.userId,
      },
    };

    if (reel.userId != null) {
      axios(followConfig)
        .then(() => {
          // setCheckFollowing(true);
          setLoading(false);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            navigate("/login");
          }
          setLoading(false);
        });
    }
  };

  const reelRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const video = reelRef.current;
    const canvas = canvasRef.current;


    if (!video || !reel) {
      // console.log(video)
      // console.log(canvas)
      // console.log(reel.HighlightURL)
       console.warn("Video source or elements are missing.");
      return;
    }

    if (video && canvas && reel) {
      // Load the video source
      video.src = reel.HighlightURL;

      // Wait for the video to be ready
      video.onloadeddata = () => {
        // Set the desired timestamp for the frame extraction
        video.currentTime = 1;

        video.onseeked = () => {
          // Set canvas dimensions to match the video
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          // Draw the current video frame onto the canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert the canvas to a data URL (base64 image)
          const imageData = canvas.toDataURL("image/png");
          //console.log("This is the thumbnail for the highlight in the profile section and it is under testing",imageData); // Use this data URL as a thumbnail
        };
      };
    }
  }, [reel]);

  return (
    <div className="reel-item">
      <div className="reel-header">
        <IconButton
          className="back-button"
          onClick={handleBack}
          aria-label="back"
        > 
          <ArrowBack
            style={{ color: "white", width: "30px", height: "30px" }}
          />
  
        </IconButton>
        <p style={{ font:'inter', fontWeight: mobileView ? '400' : '500', fontSize: mobileView ? '20px' : '25px', lineHeight: "41.15px", marginLeft: "50px", color: "#FFFFFF", position: "fixed" }}>Highlights</p> 

        <Button
          className="upload-button-1"
          onClick={handleOpenUploadModal}
          aria-label="upload"
          startIcon={
            <img
              src={vedioAddUpload}
              alt="upload video"
              style={{ width: "clamp(16px, 2vw, 20px)" }}
            />
          }
        >
     
        </Button>
      </div>
      <div className="video-container">
        {reelLocation !== "profile" && (<video
          ref={videoRef}
          src={reel.HighlightURL}
          loop
          playsInline
          muted={isMuted}
          onClick={togglePlayPause} 
        />)}
        {reelLocation === "profile" && (
          <div>
            <video ref={reelRef} src={reel.HighlightURL} style={{ display: "none" }} preload="metadata" />
            <canvas ref={canvasRef} style={{ border: "1px solid #ccc" }} />
          </div>
        )}
      </div>
      <div className="reel-overlay">
        <div className="reel-actions">
          <Button onClick={handleRunsClick}>
            {show6RunsIcon ? (
              <img
                src={runLogo}
                width={35}
                alt="run logo"
                style={{ backgroundColor: "white", borderRadius: "40px" }}
              />
            ) : (
              <img src={runIcon} width={35} alt="white ball icon" style={{}} />
            )}
          </Button>
          <Typography style={{ color: "white" }}>{runCount}</Typography>
          <Button onClick={onComment}>
            <img src={commentIcon} width={35} />
          </Button>
          {/* <Typography style={{color: "white"}}>
            {reel.comments?.length || 0}
            <p>{commentCount}</p>
          </Typography> */}

          <Typography style={{ color: "white" }}>
            {commentCount || 0}
          </Typography>

          <Button onClick={isSaved ? handleUnsave : handleSave}>
            <img
              src={isSaved ? savedIcon : unsaveIcon}
              alt={isSaved ? "Saved  icon" : "Unsave icon"}
              width={35}
            />
          </Button>

          <Button onClick={handleShareToOtherAppsPopup}>
            <img src={shareIcon} width={35} alt="share icon" />
          </Button>
          <Typography style={{ color: "white" }}>0</Typography>
        </div>
<div className="reel-details" style={{marginLeft: mobileView ? "0px" : "550px", marginBottom: mobileView ? "15px" : "25px"}} >
        <div className="reel-description">
          <Typography variant="body2">{reel.highlightTitle}</Typography>
        </div>
        <div className="reel-user-info">
          <img
            src={reel.profilePhoto}
            alt="user-profile"
            className="user-avatar"
          />
          <Typography
            variant="subtitle2"
            className="username"
            onClick={() => navigatetoprofile(reel.userId)}
          >
            {reel.userName}
          </Typography>

          <div className="user-info-right">
            {!ownProfile && !checkFollowing && (
              <Button
                variant="contained"
                className="follow-button-reel"
                onClick={() => {
                  handleFollow();
                }}
                // disabled={loading}
              >
                + Follow
              </Button>
            )}
            {!ownProfile && checkFollowing && (
              <Button variant="contained" className="following-button" disabled>
                Following...
              </Button>
            )}
          </div>
        </div>
        
        <div className="reel-audio">
          <MusicNote fontSize="small" />
          <Typography variant="caption">Original Audio</Typography>
        </div>
      

</div>
      <VideoUploaderWithTrimmer
        open={isUploadModalOpen}
        handleClose={handleCloseUploadModal}
        setIsSuccessModalOpen={setIsSuccessModalOpen}
      />
      {shareToOtherApps && (
        <ShareToAppsPopupPage
          open={shareToOtherApps}
          url={url}
          handleClose={handleCloseShareToOtherAppsPopup}
        />
      )}
    </div>
    </div>
  );
};

export default ReelItem;
