import React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../../utils/auth";
import { Grid, Card, CardMedia, CardContent, Typography, Box, Button, useMediaQuery } from "@mui/material";

import dealBallImg from "../../assets/images/deals/bxs_cricket-ball.svg";
import defaultImg from "../../assets/images/deals/Default Deal.png";
import cardBackground from "../../assets/images/deals/dealbackgroundImg.jpeg";
import { getStorageItem } from "../../utils/sessionStorage";

export default function Vouchers({ sortDeal }) {
    const navigate = useNavigate();
    const [voucherData, setVoucherData] = useState([]);
    const accessToken = getAuthToken();
    const mobileView = useMediaQuery("(max-width:769px)");
    const [originalVoucherList, setOriginalVoucherList] = useState([]);
    let filteredDeal=[...voucherData];

    const getAllVouchers = async () => {
        const getVouchers = {
            method: "GET",
            url: global.config.ROOTURL.prod + `/voucher/getRandomUnredeemedVoucher`,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        await axios(getVouchers)
            .then((response) => {
                console.log(response.data)
                setVoucherData(response.data);
                setOriginalVoucherList(response.data)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        getAllVouchers();
    }, []);

    useEffect(() => {        
        // SORT FUNCTIONALITY
    
        if (sortDeal === 'Price Low to High') {
          filteredDeal = [...filteredDeal].sort((a, b) => a.discount - b.discount)
        }
        else if (sortDeal === 'Price High to Low') {
          filteredDeal = [...filteredDeal].sort((a, b) => b.discount - a.discount)
        }
        else if (sortDeal === 'A to Z') {
          filteredDeal = [...filteredDeal].sort((a, b) => a.description.localeCompare(b.description))
        }
        else if (sortDeal === 'Z to A') {
          filteredDeal = [...filteredDeal].sort((a, b) => b.description.localeCompare(a.description))
        }
        else {
          filteredDeal = [...originalVoucherList]
        }
    
        setVoucherData(filteredDeal !== undefined ? filteredDeal : voucherData)
    },[sortDeal])

    return (
        <>
            {voucherData.map((voucher) => (
                <Grid item xs={6} sm={6} md={3} key={voucher.voucherId}>
                    <Card
                        sx={{
                            height:"293px",
                            width: mobileView ? "100%" : "100%",
                            border: "1px solid rgba(0, 0, 0, 0.1)",
                            borderRadius: 2,
                            backgroundImage: `url(${cardBackground})`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            transition: "transform 0.3s ease",
                            boxShadow: "none",
                            "&:hover": {
                                transform: "scale(1.05)",
                            },
                        }}
                    >
                        <Box sx={{ height: mobileView ? "120px" : "160px", position: "relative", padding: "10px 10px 0 10px" }}>
                            {/* Image Section */}
                            <CardMedia
                                component="img"
                                image={voucher.voucherImage || defaultImg}
                                alt=''
                                sx={{
                                    height: "100%",
                                    borderRadius: "10px"
                                }}
                            />

                            {/* Offer Time (Overlay on Bottom of Image) */}
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                                    color: "white",
                                    padding: "5px",
                                    margin: "0 10px",
                                    borderBottomLeftRadius: "10px",
                                    borderBottomRightRadius: "10px",
                                    textAlign: "center",
                                    fontSize: mobileView ? "12px" : "16px",
                                }}
                            >
                                Discount {voucher.discount || null}%
                            </Box>
                        </Box>
                        {/* Content */}
                        <CardContent sx={{ textAlign: "center" }}>
                            <Typography
                                sx={{
                                    color: "rgba(66, 66, 66, 1)",
                                    fontSize: "18px",
                                    fontFamily: "Helvetica",
                                    fontWeight: "700"
                                }}>
                                {voucher.title.length > 18
                                    ? voucher.title.substring(0, 18) + "..."
                                    : voucher.title || ""}
                            </Typography>
                            <Button
                                onClick={() =>
                                    navigate(`/voucher-description/${voucher.voucherId}`, {
                                        state: { code: voucher.code },
                                    })
                                }
                                variant="contained"
                                disabled={voucher.redeemed}
                                sx={{
                                    backgroundImage: "linear-gradient(90deg, #FECD08, #F74E01, #EB128D)",
                                    "&:hover": {
                                        backgroundImage: "linear-gradient(90deg, #EB128D, #F74E01, #FECD08)", // Reverse the gradient
                                    },
                                    marginTop: "10px",
                                    color: "white",
                                }}
                            >
                                <img src={dealBallImg} style={{ width: "16px", marginRight: "5px" }} /> Claim
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </>
    )
}
