import {
  Grid,
  Button,
  CardMedia,
  Box,
  InputLabel,
  TextField,
  MenuItem,
  Select
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import Compressor from "compressorjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../deal-creation-form/index.css";
import { useNavigate, useParams } from "react-router-dom";
import ImageBackground from '../../assets/images/Image-Background.png';
import moment from "moment/moment";
import JoditEditor from "jodit-react";
import { FormControl } from '@material-ui/core';

const DealUpdateForm = () => {
  const accessToken = getStorageItem("token");
  const { id } = useParams();
  const editor = useRef(null);
  const navigate = useNavigate()
  const [status, setStatus] = useState(true);
  const [runsBack, setRunsBack] = useState();
  const [showRunDealAmount, setShowRunDealAmount] = useState(false);
  const [conditionForUnlock, setConditionForUnlock] = useState({
    post: 0,
    comment: 0,
    highlight: 0,
  });
  const [runcondition, setRuncondition] = useState({
    lowerCount: 0,
    upperCount: Infinity,
  });
  const [submitBtnDisable, setSubmitBtnDisable] = useState(false);
  const [image, setImage] = useState(null);
  const [imageBG, setImageBG] = useState(null);
  const [dealInputs, setDealInputs] = useState({
    name: "",
    redeemrun: 0,
    partnerEmailId: "",
    count: 0,
    categories: "All",
    appOnly: false,
    redeemableOnce: false,
    isLocked: false,
    discount: 0,
    active: true,
    startDateAndTime: "",
    endDateAndTime: "",
    priorityNo: 0,
    encrptedcode: "",
    targetUser: "user"
  })

  const [discountStartDateAndTime, setDiscountStartDateAndTime] = useState("")
  const [discountEndDateAndTime, setDiscountEndDateAndTime] = useState("")
  const [runDealAmount, setRunDealAmount] = useState(0);
  const [desc, setDesc] = useState("")

  const fetchUserInfo = async () => {
    try {
      axios
        .get(`${global.config.ROOTURL.prod}/deal/getDealById/${id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
        }).then((response) => {
          let data = response.data;
          setDealInputs({
            name: data.name,
            redeemrun: data.redeemrun,
            partnerEmailId: data.partnerEmailId,
            count: data.count,
            categories: data?.categories ? data?.categories : "All",
            appOnly: data.appOnly,
            redeemableOnce: data.redeemableOnce,
            isLocked: data.isLocked,
            discount: data.discount,
            active: data.active,
            startDateAndTime: data.startDateAndTime ? moment(data.startDateAndTime).format("YYYY-MM-DDTHH:mm") : "",
            endDateAndTime: data.endDateAndTime ? moment(data.endDateAndTime).format("YYYY-MM-DDTHH:mm") : "",
            priorityNo: data.priorityNo,
            encrptedcode: data.encrptedcode,
            targetUser: data.SubscribedUser ? "subscriber" : "user",
          })
          setImageBG(data.dealImage);
          setConditionForUnlock(data.conditionForUnlock);
          setRuncondition(data.runcondition);
          setDiscountStartDateAndTime(data?.discountStartDateAndTime ? moment(data.discountStartDateAndTime).format("YYYY-MM-DDTHH:mm") : "");
          setDiscountEndDateAndTime(data?.discountEndDateAndTime ? moment(data.discountEndDateAndTime).format("YYYY-MM-DDTHH:mm") : "");
          setDesc(data?.desc ? data.desc : "");
          setRunDealAmount(data?.runDealAmount ? data.runDealAmount : 0)
        })
    } catch (error) {
      console.error("Error fetching deal data:", error);
    }
  };

  const handleFormSubmit = async () => {
    setSubmitBtnDisable(true);

    let formData = {
      dealImage: image,
      name: dealInputs.name,
      redeemrun: dealInputs.redeemrun,
      partnerEmailId: dealInputs.partnerEmailId,
      count: dealInputs.count,
      categories: dealInputs.categories,
      appOnly: dealInputs.appOnly,
      redeemableOnce: dealInputs.redeemableOnce,
      isLocked: dealInputs.isLocked,
      discount: dealInputs.discount,
      active: dealInputs.active,
      startDateAndTime: dealInputs.startDateAndTime,
      endDateAndTime: dealInputs.endDateAndTime,
      priorityNo: dealInputs.priorityNo,
      encrptedcode: dealInputs.encrptedcode,
      targetUser: dealInputs.targetUser,
      conditionForUnlock: JSON.stringify(conditionForUnlock),
      runcondition: JSON.stringify(runcondition),
      runDealAmount,
      discountEndDateAndTime,
      discountStartDateAndTime,
      desc
    }

    const updateEvent = {
      method: "POST",
      url: global.config.ROOTURL.prod + `/deal/updateDeal?dealId=${id}`,
      headers: {
        Accept: "application/json",
        //"Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      data: formData,
    };

    try {
      axios(updateEvent)
        .then((response) => {
          console.log("response.data", response.data);
        })
      navigate("/admin/update-deal")
    } catch (error) {
      console.error("error", error);
    } finally {
      setSubmitBtnDisable(false);
    }
  };

  const onImageChange = (event) => {
    // const file = event.target.files[0];

    // if (!file) return;
    // return;
    // const reader = new FileReader();
    // reader.onloadend = () => {
    //   setImageBG(reader.result);
    // };
    // reader.readAsDataURL(file);
    // setImage(file);

    const { name, value } = event.target;
    if (name === "image") {
      if (event.target.files[0].type.includes("image")) {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
          setImageBG(reader.result);
        };
        reader.readAsDataURL(file);

        const formData = new FormData();
        //...not using compress object to pass service the issue is that after compress object is blob so in service multer is not able to parse it
        new Compressor(file, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: (compressedResult) => {
            if (compressedResult.size > 5000000) {
              setStatus(true);
            } else {
              formData.append("uploader", file);
              console.log("This is upload img function call:");
              axios
                .post(`${global.config.ROOTURL.prod}/upload-file`, formData, {
                  headers: {
                    Authorization: "Bearer " + accessToken,
                  },
                })
                .then((response) => {
                  if (response.data) {
                    console.log("Image saved successfully");
                    setImage(response.data[0].location);
                  } else {
                    console.log("error occured:");
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              setStatus(false);
            }
          },
        });
      }
    }

  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ position: 'relative' }} >
        <CardMedia
          component="img"
          height="300"
          image={imageBG === null ? ImageBackground : imageBG}
          alt="No Image"
          sx={{ borderRadius: '10px' }}
        />
        <Button
          variant="contained"
          color="primary"
          component='label'
          sx={{ position: 'relative', top: '-8vh', left: '75vh', width: '14%' }}
        ><input type="file" accept='image/*' name="image" style={{ display: 'none' }} onChange={onImageChange} />Add Images</Button>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>Title</InputLabel>
            <TextField className="dealInput"
              placeholder="Title Name"
              value={dealInputs.name}
              onChange={(e) => setDealInputs({ ...dealInputs, name: e.target.value })}
            />
          </Box>
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>Email Id</InputLabel>
            <TextField className="dealInput"
              placeholder="Mail ID"
              value={dealInputs.partnerEmailId}
              onChange={(e) => setDealInputs({ ...dealInputs, partnerEmailId: e.target.value })}
            />
          </Box>
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>Category</InputLabel>
            <Select
              style={{
                padding: "2px 0px",
                backgroundColor: "#F3F5F5",
                fontSize: "14px",
                color: "#697D95",
                border: "1px solid #BAC7D5"
              }}
              value={dealInputs.categories}
              onChange={(e) => setDealInputs({ ...dealInputs, categories: e.target.value })}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="E-Commerce">E-Commerce</MenuItem>
              <MenuItem value="Food and Beverages">Food and Beverages</MenuItem>
              <MenuItem value="Exclusive">Exclusive</MenuItem>
              <MenuItem value="TimeLimit">TimeLimit</MenuItem>
              <MenuItem value="Offers">Offers</MenuItem>
            </Select>
          </Box>
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>Redeem Once</InputLabel>
            <Select
              style={{
                padding: "2px 0px",
                backgroundColor: "#F3F5F5",
                fontSize: "14px",
                color: "#697D95",
                border: "1px solid #BAC7D5"
              }}
              value={dealInputs.redeemableOnce}
              onChange={(e) => setDealInputs({ ...dealInputs, redeemableOnce: e.target.value })}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </Box>
          {dealInputs.isLocked &&
            <Box className="form-styling">
              <InputLabel style={{ color: "black", margin: "4px 0" }}>Post</InputLabel>
              <TextField className="dealInput"
                type="number"
                value={conditionForUnlock.post}
                onChange={(e) => setConditionForUnlock({ ...conditionForUnlock, post: parseInt(e.target.value, 10) })}
              />
            </Box>
          }
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>Discount Percentage</InputLabel>
            <TextField className="dealInput"
              type="number"
              placeholder="0"
              value={dealInputs.discount}
              onChange={(e) => setDealInputs({ ...dealInputs, discount: parseInt(e.target.value) })}
            />
          </Box>
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>Start Date & Time</InputLabel>
            <TextField
              className="dealInput"
              type="datetime-local"
              value={dealInputs.startDateAndTime}
              onChange={(e) => setDealInputs({ ...dealInputs, startDateAndTime: e.target.value })}
            />
          </Box>
          {dealInputs.discount > 0 &&
            <Box className="form-styling">
              <InputLabel style={{ color: "black", margin: "4px 0" }}>Discount Start Date & Time</InputLabel>
              <TextField
                className="dealInput"
                type="datetime-local"
                value={discountStartDateAndTime}
                onChange={(e) => setDiscountStartDateAndTime(e.target.value)}
              />
            </Box>
          }
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>Code</InputLabel>
            <TextField
              className="dealInput"
              placeholder="Code"
              value={dealInputs.encrptedcode}
              onChange={(e) => setDealInputs({ ...dealInputs, encrptedcode: e.target.value })}
            />
          </Box>
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>Targeted Users</InputLabel>
            <Select
              style={{
                padding: "2px 0px",
                backgroundColor: "#F3F5F5",
                fontSize: "14px",
                color: "#697D95",
                border: "1px solid #BAC7D5"
              }}
              value={dealInputs.targetUser}
              onChange={(e) => setDealInputs({ ...dealInputs, targetUser: e.target.value })}
            >
              <MenuItem value="user">Users</MenuItem>
              <MenuItem value="subscriber">Subscribers</MenuItem>
            </Select>
          </Box>
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>RunCondition lowerCount</InputLabel>
            <TextField
              className="dealInput"
              type="number"
              value={runcondition.lowerCount}
              placeholder="0"
              onChange={(e) => setRuncondition({ ...runcondition, lowerCount: parseInt(e.target.value, 10) })}
            />
          </Box>
          {dealInputs.active && (
            <Box className="form-styling">
              <InputLabel style={{ color: "black", margin: "4px 0" }}>Run Deal Amount</InputLabel>
              <TextField
                className="dealInput"
                type="number"
                value={runDealAmount}
                placeholder="0"
                onChange={(e) => setRunDealAmount(e.target.value)}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>Runs Required</InputLabel>
            <TextField className="dealInput"
              placeholder="0"
              type="number"
              value={dealInputs.redeemrun}
              onChange={(e) => setDealInputs({ ...dealInputs, redeemrun: parseInt(e.target.value, 10) })}
            />
          </Box>
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>Deal Count</InputLabel>
            <TextField className="dealInput"
              placeholder="0"
              type="number"
              value={dealInputs.count}
              onChange={(e) => setDealInputs({ ...dealInputs, count: parseInt(e.target.value, 10) })}
            />
          </Box>
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>App Only</InputLabel>
            <Select
              style={{
                padding: "2px 0px",
                backgroundColor: "#F3F5F5",
                fontSize: "14px",
                color: "#697D95",
                border: "1px solid #BAC7D5"
              }}
              value={dealInputs.appOnly}
              onChange={(e) => setDealInputs({ ...dealInputs, appOnly: e.target.value })}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </Box>
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>Lock Deal</InputLabel>
            <Select
              style={{
                padding: "2px 0px",
                backgroundColor: "#F3F5F5",
                fontSize: "14px",
                color: "#697D95",
                border: "1px solid #BAC7D5"
              }}
              value={dealInputs.isLocked}
              onChange={(e) => setDealInputs({ ...dealInputs, isLocked: e.target.value })}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </Box>
          {dealInputs.isLocked &&
            <Box className="form-styling">
              <InputLabel style={{ color: "black", margin: "4px 0" }}>Comment</InputLabel>
              <TextField className="dealInput"
                type="number"
                value={conditionForUnlock.comment}
                onChange={(e) => setConditionForUnlock({ ...conditionForUnlock, comment: parseInt(e.target.value, 10) })}
              />
            </Box>
          }
          {(dealInputs.isLocked && dealInputs.appOnly) &&
            <Box className="form-styling">
              <InputLabel style={{ color: "black", margin: "4px 0" }}>Highlight</InputLabel>
              <TextField className="dealInput"
                type="number"
                value={conditionForUnlock.highlight}
                onChange={(e) => setConditionForUnlock({ ...conditionForUnlock, highlight: parseInt(e.target.value, 10) })}
              />
            </Box>
          }
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>Run Deal</InputLabel>
            <Select
              style={{
                padding: "2px 0px",
                backgroundColor: "#F3F5F5",
                fontSize: "14px",
                color: "#697D95",
                border: "1px solid #BAC7D5"
              }}
              value={dealInputs.active}
              onChange={(e) => setDealInputs({ ...dealInputs, active: e.target.value })}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </Box>
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>End Date & Time</InputLabel>
            <TextField className="dealInput"
              type="datetime-local"
              value={dealInputs.endDateAndTime}
              onChange={(e) => setDealInputs({ ...dealInputs, endDateAndTime: e.target.value })}
            />
          </Box>
          {dealInputs.discount > 0 &&
            <Box className="form-styling">
              <InputLabel style={{ color: "black", margin: "4px 0" }}>Discount End Date & Time</InputLabel>
              <TextField
                className="dealInput"
                type="datetime-local"
                value={discountEndDateAndTime}
                onChange={(e) => setDiscountEndDateAndTime(e.target.value)}
              />
            </Box>
          }
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>Priority No</InputLabel>
            <TextField className="dealInput"
              type="number"
              placeholder="0"
              value={dealInputs.priorityNo}
              onChange={(e) => setDealInputs({ ...dealInputs, priorityNo: parseInt(e.target.value, 10) })}
            />
          </Box>
          <Box className="form-styling">
            <InputLabel style={{ color: "black", margin: "4px 0" }}>RunCondition upperCount</InputLabel>
            <TextField className="dealInput"
              type="number"
              placeholder="0"
              value={runcondition.upperCount}
              onChange={(e) => setRuncondition({ ...runcondition, upperCount: parseInt(e.target.value, 10) })}
            />
          </Box>

        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ position: "relative" }}>
        <div className="form-styling">
          <label>Description</label>
          <FormControl>
            <JoditEditor
              ref={editor}
              value={desc}
              tabIndex={1}
              onChange={(desc) => setDesc(desc)}
              fullWidth
              multiline
              rows={5}
              className="editor-main"
              margin="normal"
            />
          </FormControl>
        </div>
      </Grid>
      <div>
        <Button
          variant="contained"
          color="primary"
          sx={{ position: 'relative', top: '4vh', left: '50vh', width: '75vh', padding: '10px 4px', fontSize: '17px', marginBottom: '40px' }}
          onClick={() => handleFormSubmit()}
          disabled={submitBtnDisable}
        >Submit</Button>
      </div>
    </Grid>
  );
};

export default DealUpdateForm;
