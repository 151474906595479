import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import Header from '../../components/header/header-redesign/Header';
import NavigationMenu from '../../components/pitch/NavigationMenu';
import NewLeaderBoard1 from '../../components/pitch-right-side/newLeaderboard1';
import ArenaCarousel from './ArenaCarousel';
import { CardContent, Typography } from '@mui/material';
import Lottie from 'react-lottie';
import { useNavigate } from "react-router-dom";
import { PathAction } from "../../redux/reducers/globalPath";
import { useDispatch } from "react-redux";
import AppContext from "../../context/AppContext";
import Arenabg from "../../assets/images/arena/Arenabg.svg";
import Cardbg from "../../assets/images/arena/Cardbg.svg";
import BookcricketArena from "../../assets/images/arena/BookcricketArena.json";
import ChallengesArena from "../../assets/images/arena/ChallengesArena.json";
import cointtossArena from "../../assets/images/arena/CointossArena.json";
import FantasyleagueArena from "../../assets/images/arena/FantasyleagueArena.json";
import PredictionArena from "../../assets/images/arena/PredictionArena.json";
import MobilecardBg from "../../assets/images/arena/mobileCard.svg";
import MobileArenaBg from "../../assets/images/arena/mobileArenbgimg.svg";

import { getStorageItem } from "../../utils/sessionStorage";
import AdModal from '../coin/adModal';

const Rewards = () => {
    const navigate = useNavigate();
    const accessToken = getStorageItem("token");
    const [canPlay, setCanPlay] = useState(false);
    const [showAdPermisibleModal, setShowAdPermisibleModal] = useState(false);
    const theme = useTheme();
    const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));

    const getTypographyStyles = () => ({
        fontFamily: 'Helvetica',
        fontWeight: 700,
        fontSize: isMobileView ? '12px' : '20px',
        color: isMobileView ? "#00000" : '#FFFFFF',
        textAlign: "center",
        paddingTop: isMobileView ? "0px" : "10px",
    });

    const getSmallTypographyStyles = () => ({
        fontFamily: 'Helvetica',
        fontWeight: 400,
        fontSize: isMobileView ? '10px' : '15px',
        color: isMobileView ? "#00000" : '#FFFFFFB2',
        textAlign: "center",
    });

    const handleOpenCoinToss = () => {
        if (canPlay) {
            navigate("/coin");
        } else {
            setShowAdPermisibleModal(true);
        }
    };

    const handleOpenprediction = () => {
        navigate("/prediction");
    };
    const handleOpenChallenge = () => {
        navigate("/challenge");
    };
    const handleOpenBookCricket = () => {
        navigate("/games/book-cricket");
    };
    const handleOpenFantasy = () => {
        navigate("/fantasy-league");
    };
    //   const handleOpenAuctionAce = () => {
    //     navigate("/auction-ace");
    //   };


    const mobileView = useMediaQuery("(max-width:600px)");
    const path = window.location.pathname;
    const dispatch = useDispatch();
    dispatch(PathAction.handlePath({ payload: path }));

    const appContext = useContext(AppContext);
    const { setShowFooter } = appContext;

    const PlaYNowFunction = async () => {
        if (accessToken) {
            var PlayNow = {
                method: "GET",
                url: global.config.ROOTURL.prod + "/contest/coinToss/checkCanPlay",
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            };
            axios(PlayNow)
                .then(({ data }) => {
                    setCanPlay(data?.canPlay);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    useEffect(() => {
        PlaYNowFunction();
    }, [])

    useEffect(() => {
        setShowFooter(true);
        return () => {
            setShowFooter(false);
        };
    }, []);

    return (
            <div style={{ width: "100%", padding: isMobileView ? "0px 0px" : "60px 110px 0px 110px", background: "rgb(252, 252, 252)"}}>
            <Box>
                <Header />
                <Grid container spacing={0}>
                    {!mobileView && (
                        <Grid item xs={12} sm={3} md={2.5} sx={{ marginTop: isMobileView ? "-65px" : "0px" }}>
                            <NavigationMenu />
                        </Grid>

                    )}

                    <Grid item xs={12} sm={6} md={7} >
                        <ArenaCarousel />
                        <Box>
                            <Box
                                sx={{
                                    backgroundImage: isMobileView ? `url(${MobileArenaBg})` : `url(${Arenabg})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    padding: isMobileView ? "0px" : '16px',
                                    paddingLeft: isMobileView ? "0px" : "16px",
                                    width: "100%",
                                    height: isMobileView ? "66vh" : "100%",
                                    marginTop: isMobileView ? "8rem" : "11rem",
                                    "@media (min-device-width: 414px) and (max-device-width: 896px) and (orientation: portrait)": {
                                        height: "50vh",
                                    },
                                }}
                            >
                                <Grid container spacing={0} justifyContent="center" style={{ marginLeft: "0px", width: isMobileView ? "95%" : "100%" }}>
                                    {/* Header */}
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="h4"
                                            align="center"
                                            sx={{
                                                fontWeight: '700',
                                                background: 'linear-gradient(238.74deg, #0C5BA0 -2.57%, #0C5BA0 28.49%, #F46710 67.55%, #EE9B04 91.55%)',
                                                WebkitBackgroundClip: 'text',
                                                WebkitTextFillColor: 'transparent',
                                                fontSize: isMobileView ? '30px' : '40px',
                                                fontFamily: 'Helvetica',
                                            }}
                                        >
                                            Play <span style={{ color: '#000' }}>& Earn</span>
                                        </Typography>
                                    </Grid>
                                    <Grid container spacing={0} justifyContent="center" style={{ display: isMobileView ? "grid" : "flex", gridTemplateColumns: isMobileView ? "repeat(3, 1fr)" : "repeat(2, 1fr)", marginLeft: isMobileView ? "0px" : "0px", width: isMobileView ? "90%" : "100%", }}>
                                        {/* First Row - 2 Cards */}
                                        <Grid item xs={12} sm={6} md={5} onClick={handleOpenCoinToss}>
                                            <Box
                                                sx={{
                                                    backgroundImage: isMobileView ? `url(${MobilecardBg})` : `url(${Cardbg})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    backgroundRepeat: 'no-repeat',
                                                    padding: isMobileView ? "2px" : '24px',
                                                    width: isMobileView ? '100%' : '300px',
                                                    height: isMobileView ? '135px' : '380px',
                                                    borderRadius: '8px',
                                                    cursor: "pointer",
                                                    paddingTop: isMobileView ? "20px" : "24px",

                                                }}
                                            >
                                                <CardContent>
                                                    <Box
                                                        sx={{
                                                            width: isMobileView ? '50px' : '186.06px',
                                                            height: isMobileView ? '50px' : '141.66px',
                                                            margin: isMobileView ? '0 auto' : '16px auto 16px',
                                                            borderRadius: isMobileView ? '50%' : '8px',
                                                            backgroundColor: isMobileView ? "#144067" : '#FFFFFF',
                                                            position: 'relative',
                                                            overflow: 'hidden',
                                                            marginTop: isMobileView ? "0px" : "16px"
                                                        }}
                                                    >
                                                        <Lottie
                                                            options={{
                                                                animationData: cointtossArena,
                                                                loop: true,
                                                                autoplay: true,
                                                            }}
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                                zIndex: 1,
                                                            }}
                                                        />
                                                    </Box>
                                                    {!isMobileView && (
                                                        <>
                                                            <Typography sx={getTypographyStyles()}>Toss ka Boss</Typography>
                                                            <Typography sx={getSmallTypographyStyles()}>win 2X more</Typography>
                                                        </>
                                                    )}
                                                </CardContent>
                                            </Box>
                                            {isMobileView && (
                                                <Box
                                                    sx={{
                                                        textAlign: 'center',
                                                        marginTop: '-20px',
                                                    }}
                                                >
                                                    <Typography sx={getTypographyStyles()}>Toss ka Boss</Typography>
                                                    <Typography sx={getSmallTypographyStyles()}>win 2X more</Typography>
                                                </Box>
                                            )}
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={5} onClick={handleOpenBookCricket}>
                                            <Box
                                                sx={{
                                                    backgroundImage: isMobileView ? `url(${MobilecardBg})` : `url(${Cardbg})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    backgroundRepeat: 'no-repeat',
                                                    padding: isMobileView ? "2px" : '24px',
                                                    width: isMobileView ? '100%' : '300px',
                                                    height: isMobileView ? '135px' : '380px',
                                                    borderRadius: '8px',
                                                    cursor: "pointer",
                                                    paddingTop: isMobileView ? "20px" : "24px",


                                                }}
                                            >
                                                <CardContent>
                                                    <Box
                                                        sx={{
                                                            width: isMobileView ? '50px' : '186.06px',
                                                            height: isMobileView ? '50px' : '141.66px',
                                                            margin: isMobileView ? '0 auto' : '16px auto 16px',
                                                            borderRadius: isMobileView ? '50%' : '8px',
                                                            backgroundColor: isMobileView ? "#144067" : '#FFFFFF',
                                                            position: 'relative',
                                                            overflow: 'hidden',
                                                            marginTop: isMobileView ? "0px" : "16px"

                                                        }}
                                                    >
                                                        <Lottie
                                                            options={{
                                                                animationData: BookcricketArena,
                                                                loop: true,
                                                                autoplay: true,
                                                            }}
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                                zIndex: 1,
                                                            }}
                                                        />
                                                    </Box>
                                                    {!isMobileView && (
                                                        <>
                                                            <Typography sx={getTypographyStyles()}>Book Cricket</Typography>
                                                            <Typography sx={getSmallTypographyStyles()}>win 2X more</Typography>
                                                        </>
                                                    )}
                                                </CardContent>
                                            </Box>
                                            {isMobileView && (
                                                <Box
                                                    sx={{
                                                        textAlign: 'center',
                                                        marginTop: '-20px',
                                                    }}
                                                >
                                                    <Typography sx={getTypographyStyles()}>Book Cricket</Typography>
                                                    <Typography sx={getSmallTypographyStyles()}>win 2X more</Typography>
                                                </Box>
                                            )}
                                        </Grid>

                                        {/* Second Row - 2 Cards */}
                                        <Grid item xs={12} sm={6} md={5} onClick={handleOpenFantasy}>
                                            <Box
                                                sx={{
                                                    backgroundImage: isMobileView ? `url(${MobilecardBg})` : `url(${Cardbg})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    backgroundRepeat: 'no-repeat',
                                                    padding: isMobileView ? "2px" : '24px',
                                                    width: isMobileView ? '100%' : '300px',
                                                    height: isMobileView ? '135px' : '380px',
                                                    borderRadius: '8px',
                                                    cursor: "pointer",
                                                    paddingTop: isMobileView ? "20px" : "24px",



                                                }}
                                            >
                                                <CardContent>
                                                    <Box
                                                        sx={{
                                                            width: isMobileView ? '50px' : '186.06px',
                                                            height: isMobileView ? '50px' : '141.66px',
                                                            margin: isMobileView ? '0 auto' : '16px auto 16px',
                                                            borderRadius: isMobileView ? '50%' : '8px',
                                                            backgroundColor: isMobileView ? "#144067" : '#FFFFFF',
                                                            position: 'relative',
                                                            overflow: 'hidden',
                                                            marginTop: isMobileView ? "0px" : "16px"

                                                        }}
                                                    >
                                                        <Lottie
                                                            options={{
                                                                animationData: FantasyleagueArena,
                                                                loop: true,
                                                                autoplay: true,
                                                            }}
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                                zIndex: 1,
                                                            }}
                                                        />
                                                    </Box>
                                                    {!isMobileView && (
                                                        <>
                                                            <Typography sx={getTypographyStyles()}>Fantasy League</Typography>
                                                            <Typography sx={getSmallTypographyStyles()}>Play with fav. player</Typography>
                                                        </>
                                                    )}
                                                </CardContent>
                                            </Box>
                                            {isMobileView && (
                                                <Box
                                                    sx={{
                                                        textAlign: 'center',
                                                        marginTop: '-20px',
                                                    }}
                                                >
                                                    <Typography sx={getTypographyStyles()}>Fantasy League</Typography>
                                                    <Typography sx={getSmallTypographyStyles()}>Play with fav. player</Typography>
                                                </Box>
                                            )}
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={5} onClick={handleOpenprediction}>
                                            <Box
                                                sx={{
                                                    backgroundImage: isMobileView ? `url(${MobilecardBg})` : `url(${Cardbg})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    backgroundRepeat: 'no-repeat',
                                                    padding: isMobileView ? "2px" : '24px',
                                                    width: isMobileView ? '100%' : '300px',
                                                    height: isMobileView ? '135px' : '380px',
                                                    borderRadius: '8px',
                                                    cursor: "pointer",
                                                    paddingTop: isMobileView ? "20px" : "24px",


                                                }}
                                            >
                                                <CardContent>
                                                    <Box
                                                        sx={{
                                                            width: isMobileView ? '50px' : '186.06px',
                                                            height: isMobileView ? '50px' : '141.66px',
                                                            margin: isMobileView ? '0 auto' : '16px auto 16px',
                                                            borderRadius: isMobileView ? '50%' : '8px',
                                                            backgroundColor: isMobileView ? "#144067" : '#FFFFFF',
                                                            position: 'relative',
                                                            overflow: 'hidden',
                                                            marginTop: isMobileView ? "0px" : "16px"

                                                        }}
                                                    >
                                                        <Lottie
                                                            options={{
                                                                animationData: PredictionArena,
                                                                loop: true,
                                                                autoplay: true,
                                                            }}
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                                zIndex: 1,
                                                            }}
                                                        />
                                                    </Box>
                                                    {!isMobileView && (
                                                        <>
                                                            <Typography sx={getTypographyStyles()}>Prediction</Typography>
                                                            <Typography sx={getSmallTypographyStyles()}>win 2X more</Typography>
                                                        </>
                                                    )}
                                                </CardContent>
                                            </Box>
                                            {isMobileView && (
                                                <Box
                                                    sx={{
                                                        textAlign: 'center',
                                                        marginTop: '-20px',
                                                    }}
                                                >
                                                    <Typography sx={getTypographyStyles()}>Prediction</Typography>
                                                    <Typography sx={getSmallTypographyStyles()}>win 2X more</Typography>
                                                </Box>
                                            )}
                                        </Grid>

                                        {/* Last Row - 1 Card */}
                                        <Grid item xs={12} md={5} onClick={handleOpenChallenge}>
                                            <Box
                                                sx={{
                                                    backgroundImage: isMobileView ? `url(${MobilecardBg})` : `url(${Cardbg})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    backgroundRepeat: 'no-repeat',
                                                    padding: isMobileView ? "2px" : '24px',
                                                    width: isMobileView ? '100%' : '300px',
                                                    height: isMobileView ? '135px' : '380px',
                                                    borderRadius: '8px',
                                                    cursor: "pointer",
                                                    paddingTop: isMobileView ? "20px" : "24px",

                                                }}
                                            >
                                                <CardContent>
                                                    <Box
                                                        sx={{
                                                            width: isMobileView ? '50px' : '186.06px',
                                                            height: isMobileView ? '50px' : '141.66px',
                                                            margin: isMobileView ? '0 auto' : '16px auto 16px',
                                                            borderRadius: isMobileView ? '50%' : '8px',
                                                            backgroundColor: isMobileView ? "#144067" : '#FFFFFF',
                                                            position: 'relative',
                                                            overflow: 'hidden',
                                                            marginTop: isMobileView ? "0px" : "16px"

                                                        }}
                                                    >
                                                        <Lottie
                                                            options={{
                                                                animationData: ChallengesArena,
                                                                loop: true,
                                                                autoplay: true,
                                                            }}
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                left: 0,
                                                                width: '100%',
                                                                height: '100%',
                                                                zIndex: 1,
                                                            }}
                                                        />
                                                    </Box>
                                                    {!isMobileView && (
                                                        <>
                                                            <Typography sx={getTypographyStyles()}>Challenges</Typography>
                                                            <Typography sx={getSmallTypographyStyles()}>win 2X more</Typography>
                                                        </>
                                                    )}
                                                </CardContent>
                                            </Box>
                                            {isMobileView && (
                                                <Box
                                                    sx={{
                                                        textAlign: 'center',
                                                        marginTop: '-20px',
                                                    }}
                                                >
                                                    <Typography sx={getTypographyStyles()}>Challenges</Typography>
                                                    <Typography sx={getSmallTypographyStyles()}>win 2X more</Typography>
                                                </Box>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={3}
                        md={2.5}
                        sx={{
                            marginTop: isMobileView ? "-65px" : "0px",
                            position: 'sticky',
                            top: isMobileView ? '-50px' : '0px',
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: isMobileView ? "transparent" : "rgba(255, 255, 255, 1)",
                                borderRadius: isMobileView ? "0px" : "8px",
                                width: isMobileView ? "100%" : "350px",
                            }}
                        >
                            <NewLeaderBoard1 />
                        </Box>

                    </Grid>
                </Grid>
            </Box>
            {showAdPermisibleModal && (
                <AdModal
                    showAdPermisibleModal={showAdPermisibleModal}
                    setShowAdPermisibleModal={setShowAdPermisibleModal}
                    PlaYNowFunction={PlaYNowFunction}
                />
            )}
            </div>
    );
};

export default Rewards;
