import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import BonusRunImage from "../../assets/images/bonusRunimage.png";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import StreakOverModal from "./StreakOverModal";
import ProgressBarComponent from "./ProgressBar";
import DailyLoginCompleteModal from "./DailyLoginCompleteModal";
import BonusRunsCoverImage from "../../assets/images/bonusRun-cover.png";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getStorageItem } from '../../utils/sessionStorage';


const style = (isMobile) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: isMobile ? "auto" : "433px",
  minHeight: isMobile ? "426px" : "0px",
  maxHeight: isMobile ? "85vh" : "90vh",
  maxWidth: 400,
  backgroundColor: "#FFFFFF",
  border: "none",
  boxShadow: 24,
  padding: "17px",
  borderRadius: "20px",
  fontSize: "16px",
  color: "#333",
  fontFamily: "Arial, sans-serif",
  fontWeight: "500",
  backgroundRepeat: "no-repeat",
  "@media screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)": {
      maxHeight: "63vh", 
    },
});

const generateBoxStyle = (backgroundColor, isActive, isMobile) => ({
  width: "57px",
  height: "75px",
  position: "relative",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor,
  boxShadow: "0px 4px 8px rgba(108, 108, 108, 0.5)",
  marginBottom: "20px",
  zIndex: isActive ? "2" : "1",
});

const generateBoxStyle1 = (
  backgroundColor,
  isActive,
  isMobile,
  continuousActive
) => ({
  flex: isMobile ? "1 1 calc(33.33% - 10px)" : "1 1 calc(19% - 10px)",
  height: "194px",
  borderRadius: "10px",
  backgroundColor,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  boxShadow: "0px 4px 8px rgba(108, 108, 108, 0.5)",
  zIndex: isActive ? "2" : "1",
  
});

const imageStyle = {
  width: "37px",
  height: "35px",
  marginBottom: "10px",
  
  
};
const imageStyle1 = {
  width: "60px",
  height: "60px",
  marginBottom: "10px",
};

const Card = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);
  const cardStyle = generateBoxStyle(props?.backgroundColor);
  const cardStyle1 = generateBoxStyle1(props?.backgroundColor, false, isMobile);
  const styleBackgroundImage = {
    ...cardStyle,
    backgroundImage: props.activeToday ? `url(${BonusRunsCoverImage})` : "none",
  };

  return (
    <>
      <div
        style={props.day === 7 ? cardStyle1 : styleBackgroundImage}
      >
        <div style={{ position: "relative" }}>
          {props?.indexLess ? (
            <div
              style={{
                position: "absolute",
                width: props.day === 7 ? "71px" : "57px",
                height: props.day === 7 ? "195px" : "75px",
                background: "rgba(204, 204, 204, 0.5)",
                borderRadius: "10px",
                right: props.day === 7 ? "-36px" : "-29px",
                bottom: props.day === 7 ? "-164px" : "",
              }}
            >
              <CheckCircleOutlineIcon
                style={{
                  color: "#6DEC58",
                  position: "absolute",
                  top: "17px",
                  left: "5px",
                }}
              />
            </div>
          ) : null}
        </div>
        <img
          src={BonusRunImage}
          alt="login-runs"
          style={props.day === 7 ? imageStyle1 : imageStyle}
        />
        {props.day === 7 ? (
          <hr style={{ width: "70px", color: "#E9E9E9", height: "1px" }} />
        ) : (
          <hr style={{ width: "57px", color: "#E9E9E9", height: "1px" }} />
        )}
        <p
          className={props.day === 7 ? "bonus_run1" : "bonus_run"}
        >
          {props?.runsgiven}
        </p>
      </div>
      <p
        className={props.day === 7 ? "dailys_day1" : "dailys_day"}
      >
        Day {props?.day}
      </p>
    </>
  );
};

export default function ActiveuserRunModel({ data, Open, handleCloseModel }) {
  const [open, setOpen] = useState(false);
  const [messageData, setMessageData] = useState({});
  const [dailyLoginModalOpen, setDailyLoginModalOpen] = useState(false);
  const [currentStage, setCurrentStage] = useState(1);
  const navigate = useNavigate();
  const userId = getStorageItem('user_id')
  const accessToken = getStorageItem("token");
  const today = new Date().toDateString();

  useEffect(() => {
    const lastOpenedDate = localStorage.getItem("activeUserRunModalDate");

    if (Open && lastOpenedDate !== today) {
      setOpen(true);
      localStorage.setItem("activeUserRunModalDate", today);
    } else {
      setOpen(false);
    }

    setMessageData(data);
    if (data.message === "continuous" && data.continuousActive) {
      setCurrentStage(data.continuousActive);
    }
  }, [Open, data, today]);

  const handleClose = () => {
    setOpen(false);
    handleCloseModel();
  };

  const isMobile = window.innerWidth <= 769;

  const data1 = [
    { backgroundColor: "#F8F8F8" },
    { backgroundColor: "#F8F8F8" },
    { backgroundColor: "#F8F8F8" },
    { backgroundColor: "#F8F8F8" },
    { backgroundColor: "#F8F8F8" },
    { backgroundColor: "#F8F8F8" },
  ];

  const handleClaimReward = async () => {
    const response = await axios.get(global.config.ROOTURL.prod + `/defaultRun/getRunsByName/day${messageData.continuousActive}`,{
      headers:{
        Authorization: "Bearer " + localStorage.getItem("token") 
      }
    })

    if (messageData.message === "completed") {
      handleClose();
      return;
    }
    const handleGiveRuns = async (userId, runs) => {
      if (!userId && !runs) return;
      const giveRuns = {
        method: "POST",
        url: global.config.ROOTURL.prod + "/auth/given-runs/",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
        data: {
          userId: userId,
          givenRun: runs,
        },
      };
      await axios(giveRuns)
        .then((data) => {
          console.log(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    handleGiveRuns(userId, response.data.run)
    handleCloseModel();
    //setDailyLoginModalOpen(true);
  };

  const [dailyRuns, setDailyRuns] = useState([])
  useEffect(()=>{
    async function fetchData(){
      for (let i = 1; i <= 7; i++) {
        const response = await axios.get(global.config.ROOTURL.prod + `/defaultRun/getRunsByName/day${i}`,{
          headers:{
            Authorization: "Bearer " + localStorage.getItem("token") 
          }
        })
        setDailyRuns((prevArray) => [...prevArray, response.data.run])
      }
    }
    fetchData()
  },[])

  return (
    <div>
      {messageData.message === "breaked" && (
        <StreakOverModal
          open={Open}
          onClose={handleCloseModel}
          currentStage={messageData?.continuousActive}
          totalStages={7}
          status={messageData?.message}
        />
      )}

      {messageData.message !== "breaked" && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style(isMobile)}>
              <IconButton
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  color: "#0C5BA0",
                  
                }}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
              <h3
                style={{
                  marginBottom: "39px",
                  textAlign: "center",
                  color: "#0C5BA0",
                }}
              >
                Daily Login
              </h3>
              {messageData.message === "continuous" &&
              messageData.continuousActive ? (
                <>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        rowGap: "26px",
                        
                      }}
                    >
                      {data1?.slice(0, 7).map((item, index) =>
                        index + 1 > messageData?.continuousActive ? (
                          <Card
                            day={index + 1}
                            backgroundColor={item?.backgroundColor}
                            runsgiven={dailyRuns[index]}
                            continuousActive={messageData?.continuousActive}
                          />
              
                        ) : index + 1 < messageData?.continuousActive ? (
                          <Card
                            day={index + 1}
                            indexLess={true}
                            backgroundColor={item?.backgroundColor}
                            runsgiven={dailyRuns[index]}
                            continuousActive={messageData?.continuousActive}
                          />
                        ) : index + 1 === messageData?.continuousActive ? (
                          <Card
                            // activeToday={true}
                            day={index + 1}
                            backgroundColor={item?.backgroundColor}
                            runsgiven={dailyRuns[index]}
                            continuousActive={messageData?.continuousActive}
                          />
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                    <Card
                      day={data1.length + 1}
                      backgroundColor={"#F8F8F8"}
                      runsgiven={dailyRuns[6]}
                      continuousActive={7}
                    />
                  </div>
                  <div style={{ position: "relative", bottom: "5rem" }}>
                    <ProgressBarComponent
                      currentStage={messageData?.continuousActive}
                      totalStages={7}
                      status={messageData.message}
                    />
                  </div>
                  <Button
                    style={{
                      backgroundColor: "#0C5BA0",
                      color: "white",
                      position: "relative",
                      left: "16px",
                      bottom: "5.3rem",
                      width: "90%",
                      height: "43px",
                    }}
                    onClick={handleClaimReward}
                  >
                    Claim Reward
                  </Button>
                </>
              ) : messageData.message === "completed" ? (
                null
              ) : null}
            </Box>
          </Fade>
        </Modal>
      )}

      {/* {dailyLoginModalOpen && (
        <DailyLoginCompleteModal
          open={dailyLoginModalOpen}
          onClose={() => {
            setDailyLoginModalOpen(false);
            handleCloseModel();
          }}
          currentStage={messageData?.continuousActive}
          totalStages={7}
          status="completed"
        />
      )} */}
    </div>
  );
}


