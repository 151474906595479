import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Radio, RadioGroup, FormControlLabel, FormControl, Typography, Button, Box, } from "@mui/material";

import cancelButton from "../../assets/images/deals/cancel.svg";

const SortByPopup = ({ open, onClose, sortDeal, changeSort, ApplySorting }) => {
    const activeColor = "rgba(243, 113, 33, 1)";

    const handleChange = (sortValue) => {
        changeSort(sortValue)
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth sx={{ borderRadius: "18px" }}>
            <DialogTitle sx={{ backgroundColor: "rgba(12, 91, 160, 1)", display:"flex", justifyContent:"space-between" }}>
                <Typography align="center"
                    sx={{
                        color: "rgba(255, 255, 255, 1)",
                        fontSize: "24px",
                        fontFamily: "Helvetica",
                        fontWeight: "700"
                    }}
                >
                    Sort By
                </Typography>

                <Box
                    component="img"
                    src={cancelButton}
                    alt="cancel Logo"
                    onClick={onClose}
                    sx={{cursor:"pointer"}}
                />
            </DialogTitle>

            <DialogContent dividers>
                <FormControl component="fieldset">
                    <RadioGroup value={sortDeal} onChange={(e) => handleChange(e.target.value)}>
                        {[
                            { label: "New", value: "New" },
                            { label: "Popular", value: "Popular" },
                            { label: "Price Low to High", value: "Price Low to High" },
                            { label: "Price High to Low", value: "Price High to Low" },
                            { label: "A to Z", value: "A to Z" },
                            { label: "Z to A", value: "Z to A" },
                        ].map((option) => (
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={
                                    <Radio
                                        sx={{
                                            "&.Mui-checked": { color: activeColor }, // Active radio button color
                                        }}
                                    />
                                }
                                label={
                                    <Typography
                                        sx={{
                                            color:
                                                sortDeal === option.value
                                                    ? activeColor
                                                    : "inherit", // Active text color
                                            fontWeight: sortDeal === option.value ? "bold" : "normal",
                                        }}
                                    >
                                        {option.label}
                                    </Typography>
                                }
                            />
                        ))}
                    </RadioGroup>
                </FormControl>
            </DialogContent>

            {/* Action Buttons */}
            <Box display="flex" justifyContent="center" p={2}>
                <Button
                    onClick={ApplySorting}
                    variant="contained"
                    color="primary"
                >
                    Apply
                </Button>
            </Box>
        </Dialog>
    );
};

export default SortByPopup;
