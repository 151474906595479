import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Button, Avatar } from '@mui/material';
import bg1 from "../../assets/images/Pitch/NavMenu/bg1.svg";
import batman from "../../assets/images/Pitch/NavMenu/cricketer.svg";
import bgBall from "../../assets/images/Pitch/NavMenu/ballBg.svg";
import pitchLogo from "../../assets/images/Pitch/NavMenu/pitch.svg";
import arenaLogo from "../../assets/images/Pitch/NavMenu/arena.svg";
import highlightsLogo from "../../assets/images/Pitch/NavMenu/highlights.svg";
import dealsLogo from "../../assets/images/Pitch/NavMenu/deal.svg";
import dogoutLogo from "../../assets/images/Pitch/NavMenu/dogout.svg";
import marketplaceLogo from "../../assets/images/Pitch/NavMenu/marketplace.svg";
import jobLogo from "../../assets/images/Pitch/NavMenu/job.svg";
import avtarLogo from "../../assets/images/Pitch/NavMenu/avatar.svg";
import trendingLogo from "../../assets/images/Pitch/NavMenu/trending.svg";
import ruleLogo from "../../assets/images/Pitch/NavMenu/rules.svg";
import analyticsLogo from "../../assets/images/Pitch/NavMenu/analytics.svg";
import chatwithchamphunt from "../../assets/images/Pitch/NavMenu/chatwithchamphunt.svg";
import settingsLogo from "../../assets/images/Pitch/NavMenu/setting.svg";
import contactLogo from "../../assets/images/Pitch/NavMenu/contact.svg";
import aboutLogo from "../../assets/images/Pitch/NavMenu/about.svg";
import privacyLogo from "../../assets/images/Pitch/NavMenu/privacy.svg";
import chPluse from "../../assets/images/Pitch/NavMenu/ch+.svg";
import { getStorageItem } from "../../utils/sessionStorage";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import subchampLogo from "../../assets/images/champsubs.png";
import SubscribePlus from "../../assets/images/subscribePlusIcon.png";


const userId = getStorageItem('user_id')

const menuItems = [
    { logo: pitchLogo, name: "Pitch" },
    { logo: arenaLogo, name: "Arena" },
    { logo: highlightsLogo, name: "Highlights" },
    { logo: dealsLogo, name: "Deals" },
    { logo: dogoutLogo, name: "Dugout" },
    { logo: marketplaceLogo, name: "Marketplace" },
    { logo: jobLogo, name: "Job" },
    { logo: trendingLogo, name: "Trending" },
    { logo: ruleLogo, name: "Rules" },
    { logo: analyticsLogo, name: "Analytics" },
    { logo: chatwithchamphunt, name: "Chat With Champhunt" },
    { logo: settingsLogo, name: "Settings" },
    { logo: contactLogo, name: "Contact us" },
    { logo: aboutLogo, name: "About us" },
    { logo: privacyLogo, name: "Privacy Policy" },
]

const routeMapping = {
    "Pitch": "/pitch",
    "Arena": "/rewards",
    "Highlights": "/highlights",
    "Deals": "/deals",
    "Dugout": "/groupchat",
    "Marketplace": "/marketplace",
    "Job": "/jobs",
    "Trending": "/explore-trending",
    "Rules": "/rules",
    "Analytics": "/analytics",
    "Chat With Champhunt": `/profile/chat/${userId}`,
    "Settings": "/settings",
    "Contact us": "/contact-us",
    "About us": "/about-us",
    "Privacy Policy": "/privacy",
};

const NavigationMenu = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedMenuItem, setSelectedMenuItem] = useState(0);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);

    const handleMenuItemClick = (index) => {
        setSelectedMenuItem(index);
        const itemName = menuItems[index].name;
        const route = routeMapping[itemName];
        if (route) {
            navigate(route, { state: { selectedItemName: itemName, id: userId } });
        }
    };

    const handleMouseEnter = (index) => {
        setHoveredItem(index);
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    const toggleMoreOptionsSection = () => {
        setIsMoreOptionsOpen((prev) => !prev);
    };


    useEffect(() => {
        const currentPath = location.pathname;
        const selectedIndex = menuItems.findIndex(item => routeMapping[item.name] === currentPath);
        if (selectedIndex !== -1) {
            setSelectedMenuItem(selectedIndex);
        }
    }, [location]);

    return (
        <Box sx={{
            position: 'fixed',
            overflow: 'auto',
            height: '100vh',
            '&::-webkit-scrollbar': {
                display: 'none'
            },
        }}>
            <Box
                sx={{
                    width: '100%',
                    backgroundColor: '#FFFFFF',
                    height: "auto",
                    position: 'relative',
                    paddingBottom: '80px',
                }}
            >
                <Box sx={{ position: 'relative', borderBottom: "1px solid #0000001A", padding: '10px 20px' }}>
                    <img src={bg1} alt='' style={{ position: 'absolute', zIndex: 0, width: '80%', top: '40px', }} />
                    {menuItems.slice(0, 7).map((item, index) => (
                        <Box
                            onClick={() => handleMenuItemClick(index)}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px 10px',
                                position: 'relative',
                                cursor: 'pointer',
                                backgroundColor: hoveredItem === index
                                    ? '#A0CFF852'
                                    : (hoveredItem === null && selectedMenuItem === index)
                                        ? '#A0CFF852'
                                        : 'transparent',
                                borderRadius: '10px',
                                zIndex: 1,
                                '&:hover': {
                                    backgroundColor: '#A0CFF852',
                                    borderRadius: '10px'
                                }
                            }}
                        >
                            <img src={item.logo} alt={item.name}
                                style={{ width: '25px', height: '25px', marginRight: '15px' }}
                            />
                            <Typography variant="body1"
                                sx={{
                                    color: selectedMenuItem === index ? '#0F6CBD' : '#0F0F0F',
                                    fontWeight: selectedMenuItem === index ? 'bold' : 400,
                                    fontFamily: 'Helvetica',
                                    fontSize: '15px'
                                }}>
                                {item.name}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                <Box
                    sx={{
                        position: 'relative',
                        padding: '22px 30px',
                        borderBottom: '1px solid #0000001A',
                    }}
                >
                    <img
                        src={batman}
                        alt=""
                        style={{
                            position: 'absolute',
                            width: '30%',
                            zIndex: 0,
                            top: '-90px',
                            right: '10px',
                        }}
                    />
                    <Typography
                        variant="h5"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#0F548C',
                            fontWeight: 700,
                            fontSize: '16px',
                            fontFamily: 'Helvetica',
                            cursor: 'pointer',
                        }}
                    >
                        Explore
                    </Typography>
                    {menuItems.slice(7, 11).map((item, index) => (
                        <Box
                            onClick={() => handleMenuItemClick(index + 7)}
                            onMouseEnter={() => handleMouseEnter(index + 7)}
                            onMouseLeave={handleMouseLeave}
                            key={index + 7}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '10px',
                                marginTop: '10px',
                                cursor: 'pointer',
                                borderRadius: '10px',
                                backgroundColor:
                                    hoveredItem === index + 7
                                        ? '#A0CFF852'
                                        : hoveredItem === null && selectedMenuItem === index + 7
                                            ? '#A0CFF852'
                                            : 'transparent',
                                '&:hover': {
                                    backgroundColor: '#A0CFF852',
                                    borderRadius: '10px',
                                },
                            }}
                        >
                            <img
                                src={item.logo}
                                alt={item.name}
                                style={{
                                    width: '25px',
                                    height: '25px',
                                    marginRight: '15px',
                                }}
                            />
                            <Typography
                                variant="body1"
                                sx={{
                                    color: selectedMenuItem === index + 7 ? '#0F6CBD' : '#0F0F0F',
                                    fontWeight: selectedMenuItem === index + 7 ? 'bold' : 400,
                                    fontFamily: 'Helvetica',
                                    fontSize: '15px',
                                }}
                            >
                                {item.name}
                            </Typography>
                        </Box>
                    ))}
                </Box>

                <Box sx={{ padding: '22px 30px', borderBottom: "1px solid #0000001A", }}>
                    <Typography variant="h5"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            color: '#0F548C',
                            fontWeight: 700,
                            fontSize: '16px',
                            fontFamily: 'Helvetica',
                            cursor: 'pointer'
                        }}
                        onClick={toggleMoreOptionsSection}
                    >
                        More Options
                        <ArrowDropDownIcon
                            sx={{
                                color: '#0F548C',
                                marginLeft: '5px',
                                transform: isMoreOptionsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: 'transform 0.3s ease',
                            }}
                        />
                    </Typography>
                    {isMoreOptionsOpen && (
                        menuItems.slice(11).map((item, index) => (
                            <Box
                                onClick={() => handleMenuItemClick(index + 11)}
                                onMouseEnter={() => handleMouseEnter(index + 11)}
                                onMouseLeave={handleMouseLeave}
                                key={index + 11}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '10px',
                                    marginTop: '10px',
                                    cursor: 'pointer',
                                    borderRadius: "10px",
                                    position: 'relative',
                                    zIndex: 2,
                                    backgroundColor: hoveredItem === index + 11
                                        ? '#A0CFF852'
                                        : (hoveredItem === null && selectedMenuItem === index + 11)
                                            ? '#A0CFF852'
                                            : 'transparent',
                                    '&:hover': {
                                        backgroundColor: '#A0CFF852',
                                        borderRadius: '10px'
                                    }
                                }}
                            >
                                <img src={item.logo} alt={item.name}
                                    style={{ width: '25px', height: '25px', marginRight: '15px' }}
                                />
                                <Typography variant="body1"
                                    sx={{
                                        color: selectedMenuItem === index + 11 ? '#0F6CBD' : '#0F0F0F',
                                        fontWeight: selectedMenuItem === index + 11 ? 'bold' : 400,
                                        fontFamily: 'Helvetica',
                                        fontSize: '15px'
                                    }}>
                                    {item.name}
                                </Typography>
                            </Box>
                        ))
                    )}
                </Box>
                <img src={bgBall} alt='' style={{ position: 'absolute', zIndex: 0, width: '47%', bottom: '50px', left: '0px' }} />

                <Box sx={{ padding: '25px 30px', borderBottom: "1px solid #0000001A", background: '#D3E3F857' }}>
                    <Typography variant="h6"
                        sx={{
                            fontFamily: 'Helvetica',
                            color: '#424242',
                            fontSize: '12px',
                            fontWeight: 700,
                            lineHeight: '20px'
                        }}>
                        Unlock the VIP Cricket Experience!
                    </Typography>
                    <Box
                        sx={{
                            position: 'relative',
                            borderRadius: '24px',
                            padding: '2px',
                            background: 'linear-gradient(90deg, #0C5BA0, #F46710, #EE9B04)',
                            marginTop: "20px",
                            width: "80%",
                            height: "40px",
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#07365F',
                                color: '#FFFFFF',
                                fontSize: '14px',
                                fontWeight: 700,
                                textTransform: 'none',
                                borderRadius: '24px',
                                '&:hover': {
                                    backgroundColor: '#07365F',
                                },
                            }}
                            onClick={() => navigate("/champhunt-plus")}
                        >

                            <img
                                src={subchampLogo}
                                alt="Plus Icon"
                                style={{ width: '16px', height: '16px' }}
                            />
                            <img
                                src={SubscribePlus}
                                alt="Plus Icon"
                                style={{ height: '16px', paddingLeft: "10px" }}
                            />
                        </Button>
                    </Box>

                </Box>
            </Box >
        </Box>
    )
}

export default NavigationMenu;
