import React, { useEffect, useState } from "react";
import "./coupon.scss";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { toast } from 'react-toastify';
import couponImg from '../../assets/images/subscription/plus.png';
import successIcon from '../../assets/images/subscription/success-icon.png';
import { getStorageItem } from "../../utils/sessionStorage";
import SuccessModal from "./successModal";

const ActiveCoupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const userEmail = getStorageItem('user_email');
  const userName = getStorageItem('full_name');

  const handleClaim = async (coupon) => {
    try {
      const response = await fetch(global.config.ROOTURL.prod + `/couponRedeem/createRedeemDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          couponId: coupon._id,
          type: coupon.type,
          discount: coupon.discount,
          userId: getStorageItem('user_id')
        })
      });

      if (!response.ok) {
        throw new Error("Failed to claim coupon");
      }

      const updatedCoupons = coupons.map((c) =>
        c._id === coupon._id ? { ...c, redeemed: true } : c
      );

      setCoupons(updatedCoupons);
      toast.success('Coupon claimed');
      closeModal();
      setIsSuccessModalOpen(true);
      await sendEmail(coupon);
    } catch (error) {
      toast.error('Failed to claim coupon');
    }
  };

  const sendEmail = async (coupon) => {
    try {
      const response = await fetch(global.config.ROOTURL.prod + `/couponRedeem/sendRedeemEmail`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          userEmail,
          userName,
          discountCode: coupon.description
        })
      });

      if (!response.ok) {
        throw new Error('Failed to send email');
      }

      toast.success('Confirmation email sent');

    } catch (error) {
      toast.error('Failed to send confirmation email');
    }
  };

  const openModal = (coupon) => {
    setSelectedCoupon(coupon);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedCoupon(null);
  };

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await fetch(global.config.ROOTURL.prod + `/coupons/getCouponsWithStatus/${getStorageItem("user_id")}`, {
          headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch coupons");
        }
        const data = await response.json();
        setCoupons(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCoupons();
  }, []);

  return (
    <>
      {/* <h2 style={{ textAlign: 'center', color: '#00008B', marginTop: '5px' }}>Coupons</h2> */}
      <div className="page-container">
        {coupons.length>0 && (
          <div className="coupon-container">
          {coupons.map((coupon) => (
            <CouponCard
              key={coupon._id}
              coupon={coupon}
              openModal={openModal}
            />
          ))}
        </div>
        )}
        

        {selectedCoupon && (
          <Dialog
            open={isModalOpen}
            onClose={closeModal}
          >
            <DialogTitle>Confirm Claim</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to claim the following coupon?
              </DialogContentText>
              <p>{selectedCoupon.description}</p>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleClaim(selectedCoupon)} color="primary">
                Claim
              </Button>
              <Button onClick={closeModal} color="primary" autoFocus>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )}

        <SuccessModal
          open={isSuccessModalOpen}
          onClose={() => setIsSuccessModalOpen(false)}
        />
      </div>
    </>
  );
};

const CouponCard = ({ coupon, openModal }) => {
  const { _id, discount, description, redeemed } = coupon;

  return (
    <div className="coupon-card">
      <img src={couponImg} alt="champhunt+ discount coupon" />
      <div className="coupon-card-content">
        <p className="coupon-card-title">{description}</p>
        <p className="coupon-card-discount">Discount: {discount} %</p>
        <Button
          variant="contained"
          color="primary"
          onClick={() => openModal(coupon)}
          disabled={redeemed}
          className="couponCard__dp"
        >
          {redeemed ? "Already Claimed" : "Claim"}
        </Button>
      </div>
    </div>
  );
};

export default ActiveCoupons;