import React, { useState, useEffect } from "react";
import axios from 'axios';
import { getStorageItem } from "../../utils/sessionStorage";
import Slider from "react-slick";
import { Box } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import carouselBackground from "../../assets/images/deals/Deal Bg.svg";
import defaultCarouselBg from "../../assets/images/deals/Deal Default Carousel .png";

const DealsCarousel = () => {
    const accessToken = getStorageItem("token");
    const mobileView = useMediaQuery("(max-width:768px)");
    const [carousel, setCarousel] = useState([]);

    const getCarousels = async () => {
        if (!accessToken) return;
        const options = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/carousel/getAll/DealCarousel/1/10",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        };
        axios(options)
            .then(({ data }) => {
                const sortedCarousel = data.sort((a, b) => a.priority - b.priority);
                setCarousel(sortedCarousel);
            })
            .catch((error) => {
                if (error?.response?.status == 401) {
                    console.log(error);
                }
            });
    };

    const handleNavigation = (link) => {
        if (!link) {
            return;
        }
        window.open(link);
    };

    useEffect(() => {
        getCarousels();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 3000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    return (
        <Box sx={{ position: "relative", marginTop: 2 }}>
            <img
                src={carouselBackground}
                alt="Carousel Background"
                style={{
                    width: "100%",
                    height: mobileView ? "250px" : "350px",
                    objectFit: "cover",
                    objectPosition: "top",
                }}
            />

            {/* Carousel */}
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "95%",
                    height: mobileView ? "200px" : "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: 2,
                    overflow: "hidden",
                    border: "3px solid yellow",
                }}
            >
                <Slider {...settings} style={{ width: "100%", height: "100%" }}>
                    {carousel.map((item, index) => (
                        <Box key={index} onClick={() => handleNavigation(item.link)} sx={{ height: mobileView ? "200px" : "300px" }}>
                            {(
                                item.name.endsWith('.gif') || item.name.endsWith('.png') || item.name.endsWith('.jpg') ? (
                                    <img
                                        src={item.name ? item.name : defaultCarouselBg}
                                        alt={`Slide ${index + 1}`}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                            objectPosition: "center",
                                            borderRadius: "inherit",
                                            cursor: "pointer"
                                        }}
                                    />
                                ) : (
                                    <video
                                        key={index}
                                        autoPlay
                                        loop={false}
                                        muted
                                        className="carousel-image"
                                    >
                                        <source src={item.name ? item.name : defaultCarouselBg} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                )
                            )}
                        </Box>
                    ))}
                </Slider>
            </Box>
        </Box>
    );
};

export default DealsCarousel;
