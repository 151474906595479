import React from 'react';
import { useEffect, useState } from "react";
import axios from "axios";
import { Box, Typography } from '@mui/material';
import './style.scss';

import ForwardCircle from "../../assets/images/clarity_circle-arrow-solids.svg"

const NewTournament = () => {
    const [eventOnPitch, setEventOnPitch] = useState([]);

    useEffect(() => {
        const options = {
            method: "GET",
            url: global.config.ROOTURL.prod + "/events/getAll/1/5?ongoing=true",
        };

        axios(options)
            .then(({ data }) => {
                setEventOnPitch([...data].slice(0, 1));
            })
            .catch((error) => {
                console.log(error);
            });

    }, [])

    return (
        <Box className="tournament-small-wrapper" margin="0">
            <Typography
                variant="p"
                fontWeight={600}
                fontSize={20}
                color="#1A1919"
                fontFamily="Helvetica"
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '15px' }}
            >
                Tournaments
            </Typography>

            {eventOnPitch.length > 0 ? (
                eventOnPitch.map((event) => (
                    <Box key={event._id} height="150px" width="100%" className="tournament-small">
                        <img
                            src={event.eventBanner}
                            alt="tournament-banner"
                            height="150px"
                            width="100%"
                            className="tournament-banner-small"
                        />
                        <Box className="tournament-small-overlay"></Box>
                        <Typography
                            variant="h5"
                            fontFamily="Helvetica"
                            fontWeight={600}
                            fontSize="22px"
                            color="#fff"
                            textAlign="center"
                            className="tournament-small-heading"
                        >
                            {event.eventLocation}
                        </Typography>
                        <Box
                            component="img"
                            alt="arrow-img"
                            src={ForwardCircle}
                            className="tournament-small-subheading"
                        />
                    </Box>
                ))
            ) : (
                <Typography fontFamily="Helvetica" fontWeight={400} fontSize="14px" color="#1A1919" textAlign="center">
                    No tournaments available.
                </Typography>
            )}

            < Typography
                fontFamily="Helvetica"
                fontWeight={600}
                fontSize="16px"
                color=" #0c5ba0"
                className="tournament-small-read-more"
            >
                Show More
            </Typography>
        </Box >
    )
}

export default NewTournament;