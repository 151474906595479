import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { getStorageItem } from "../../utils/sessionStorage";
import { Select } from "carbon-components-react";

const AuctionAceAdmin = () => {
    const [playerName, setPlayerName] = useState("");
    const [jerseyNumber, setJerseyNumber] = useState("");
    const [country, setCountry] = useState("");
    const [previousTeam, setPreviousTeam] = useState("")
    const [basePrice, setBasePrice] = useState("");
    const [specialization, setSpecialization] = useState("batsman");
    const [timeTaken, setTimeTaken] = useState("");
    const [playerImage, setPlayerImage] = useState(null);
    const [loading, setLoading] = useState(false);

    const accessToken = getStorageItem("token");

    const handleImageUpload = async (file) => {
        const formData = new FormData();
        formData.append("uploader", file);
        const uploadUrl = `${global.config.ROOTURL.prod}/upload-file`;
        try {
            const response = await axios.post(uploadUrl, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${accessToken}`,
                },
            });
            console.log("response:", response.data[0].location);
            return response.data[0].location;
        } catch (error) {
            console.error("Error uploading image:", error);
            throw new Error("Image upload failed. Please try again.");
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const playerInfo = {
            playerData: {
                name: playerName,
                jerseyNo: parseInt(jerseyNumber, 10),
                country: country,
                basePrice: Number(basePrice),
                specialization: specialization,
                time: parseInt(timeTaken, 10),
                previousTeam: previousTeam,
            }
        };

        try {
            if (playerImage) playerInfo.playerData.image = await handleImageUpload(playerImage);

            const response = await axios.post(
                `${global.config.ROOTURL.prod}/auction/player`,
                playerInfo.playerData,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.status !== 201) throw new Error("Failed to create player info");

            toast.success("Player info created successfully");

            setPlayerName("");
            setJerseyNumber("");
            setCountry("");
            setPreviousTeam("");
            setBasePrice("");
            setSpecialization("");
            setTimeTaken("");
            setPlayerImage(null);
        } catch (error) {
            console.error("Error creating player info:", error);
            toast.error(error.message || "Error creating player info. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="ad-creation-wrapper">
            <h1 className="ad-creation__title">Create Player Info</h1>
            <form className="ad-creation__form" onSubmit={handleFormSubmit}>
                <div>
                    <label htmlFor="title" className="ad-creation__form-label">
                        Player Name
                    </label>
                    <input
                        id="title"
                        type="text"
                        className="ad-creation__form-input"
                        placeholder="player-name"
                        value={playerName}
                        onChange={(e) => setPlayerName(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="description" className="ad-creation__form-label">
                        Jersey No.
                    </label>
                    <input
                        id="description"
                        type="text"
                        className="ad-creation__form-input"
                        placeholder="jersey-number"
                        value={jerseyNumber}
                        onChange={(e) => setJerseyNumber(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="discount" className="ad-creation__form-label">
                        Country
                    </label>
                    <input
                        id="discount"
                        type="text"
                        className="ad-creation__form-input"
                        placeholder="country name"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="discount" className="ad-creation__form-label">
                        Previous Team
                    </label>
                    <input
                        id="discount"
                        type="text"
                        className="ad-creation__form-input"
                        placeholder="previous team"
                        value={previousTeam}
                        onChange={(e) => setPreviousTeam(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="discount" className="ad-creation__form-label">
                        Base Price
                    </label>
                    <input
                        id="discount"
                        type="text"
                        className="ad-creation__form-input"
                        placeholder="base price"
                        value={basePrice}
                        onChange={(e) => setBasePrice(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="expiry" className="ad-creation__form-label">
                        Specialization
                    </label>
                    <select
                        id="expiry"
                        className="ad-creation__form-input"
                        placeholder="specialization"
                        value={specialization}
                        onChange={(e) => setSpecialization(e.target.value)}
                    >
                        <option value="batsman">batsman</option>
                        <option value="bowler">bowler</option>
                        <option value="all-rounder">all-rounder</option>
                    </select>
                </div>
                <div>
                    <label className="ad-creation__form-label">
                        Time
                    </label>
                    <input
                        id="timeTaken"
                        type="text"
                        className="ad-creation__form-input"
                        placeholder="time"
                        value={timeTaken}
                        onChange={(e) => setTimeTaken(e.target.value)}
                    />
                </div>
                <div>
                    <label htmlFor="logoImage" className="ad-creation__form-label">
                        Image
                    </label>
                    <input
                        id="logoImage"
                        type="file"
                        className="ad-creation__form-input"
                        onChange={(e) => setPlayerImage(e.target.files[0])}
                        accept="image/*"
                    />
                </div>

                <div>
                    <button
                        type="submit"
                        className={
                            loading
                                ? "ad-creation-form-submit--disabled"
                                : "ad-creation-form-submit--active"
                        }
                        disabled={loading}
                    >
                        {loading ? "Creating..." : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AuctionAceAdmin;
