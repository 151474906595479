import React, { useEffect } from "react";
import { IconButton } from "@mui/material";
import { List } from "@mui/material";
import { Divider } from "@mui/material";
import { ListItem } from "@mui/material";
import { ListItemText } from "@mui/material";
import { CssBaseline } from "@mui/material";
import { Drawer } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@material-ui/icons/Menu";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { getAuthToken } from "../../utils/auth";
import { useState } from "react";
import Switch from "react-switch";
import "./index.scss";

import {
  clearStorage,
  getStorageItem,
  setStorageItem,
} from "../../utils/sessionStorage";
import InviteModal from "../../commons/invitation";
import { useDispatch } from "react-redux";
import { PathAction } from "../../redux/reducers/pitchselection";
import PersonIcon from "@mui/icons-material/Person";
import tossball from "../../assets/images/rewards/tossball.png";
import Run from "../../components/posts/run";
import Dugout from "../group-chat/NewCode/Dugout";

const user_Id = getStorageItem("user_id");
const drawerWidth = "70%";

const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    flexShrink: 0,
    width: drawerWidth,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "white !important",
    borderRadius: "0px 30px 30px 0px",
    border: "1px solid #FFFFFF",
  },
  menuButton: {
    height: "50px",
    width: "50px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  link: {
    color: "#00298E",
    textDecoration: "none",
  },
}));

export default function App(props) {
  const navigate = useNavigate();
  const loggedUserId = getStorageItem("user_id");
  const { followersCount } = props.userInfo;
  const classes = useStyles();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pendigMessage, setPendingMessage] = useState(0);
  const [checked, setChecked] = useState(false);
  const accessToken = getStorageItem("token");
  const dispatch = useDispatch();
  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(!open);
  };
  const handleMyProfile = () => {
    setOpen(!open);
    navigate(`/profile/${user_Id}`);
  };
  const togglechanged = () => {
    if (!accessToken) return;
    setChecked(!checked);
    let checkmaindata;
    if (!checked) {
      checkmaindata = "ALL";
    } else {
      checkmaindata = "Following";
    }
    dispatch(PathAction.handlePitchSelction({ payload: checkmaindata }));
    const shared = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/auth/updatePitchSelectionType",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        pitchSelectionType: checkmaindata,
      },
    };
    axios(shared)
      .then((response) => {
        setStorageItem("pitchSelectionType", checkmaindata);
        navigate("/pitch");
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
        }
      });
  };

  const setpitchselection = () => {
    if (!accessToken) return;
    const shared = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/auth/getPitchSelectionType",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    axios(shared)
      .then((response) => {
        const checkdata = response.data.pitchSelectionType === "ALL";
        setChecked(checkdata);
        dispatch(
          PathAction.handlePitchSelction({
            payload: response.data.pitchSelectionType,
          })
        );
        // navigate(0)
      })

      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error.response.status);
        }
      });
  };
  const fetchPendingMessage = () => {
    const shared = {
      method: "GET",
      url: global.config.ROOTURL.prod + "/chat/getNotSeenMessageByUser",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };
    axios(shared)
      .then((response) => {
        setPendingMessage(response.data);
      })

      .catch((error) => {
        if (error?.response?.status == 401) {
          console.log(error.response.status);
        }
      });
  };
  useEffect(() => {}, [checked]);
  useEffect(() => {
    setpitchselection();
    fetchPendingMessage();
  }, []);

  const handleShowModalOpen = () => {
    setOpen(!open);
    setShowModal(true);
  };
  const handleShowModalClose = () => {
    setShowModal(false);
  };

  const handleLogout = () => {
    const accessToken = getAuthToken();
    if (!accessToken) return;
    var logOutOptions = {
      method: "post",
      url: global.config.ROOTURL.prod + "/auth/logout/",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    };
    axios(logOutOptions)
      .then((response) => {
        localStorage.removeItem("token");
        window.localStorage.clear();
        navigate("/login");
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          clearStorage();
          navigate("/login");
        }
      });
  };

  const list = [
    {
      page: `/champhunt-plus`,
      label: "Subscription",
    },
    // {
    //   page: "/jobs",
    //   label: "Jobs",
    // },
    {
      page: "/explore-trending",
      label: "Trending",
    },
    {
      page: "/leader-board",
      label: "Leaderboard",
    },
    // {
    //   page: "/groupchat",
    //   label: "Dugout",
    // },
    {
      page: "/suggestions",
      label: "Suggested profiles to follow",
    },

    {
      page: "/about-us",
      label: "About Us",
    },
    {
      page: "/contact-us",
      label: "Contact Us",
    },
    {
      page: "/all-events",
      label: "Events",
    },
    {
      page: "/marketplace",
      label: "Marketplace",
    },
    {
      page: "/groupchat",
      label: "Dugout",
    },
    {
      page: "/highlights",
      label: "Highlights",
    },
    {
      page: `/profile/chat/${user_Id}`,
      label: "Message",
    },
  ];

  console.log("avatar", props.userInfo.avatar);
  console.log("userinfo", props.userInfo);
  return (
    <div className={classes.drawerRoot}>
      <CssBaseline />
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={toggleDrawer}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        className={classes.drawer}
        variant={isMdUp ? "permanent" : "temporary"}
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
      >
        <div className={classes.toolbar} />
        <Divider />
        <List sx={{ pt: 6 }}>
          <ListItem className={classes.link}>
            <div className="avatar">
              <img
                className="avatar-image"
                src={props.userInfo.avatar}
                alt={""}
              />
            </div>{" "}
            <ListItemText
              primary={props.userInfo.firstName + " " + props.userInfo.lastName}
              onClick={handleMyProfile}
            />
          </ListItem>
          <div
            className="followers_count"
            style={{
              width: "65%",
              display: "flex",
              position: "relative",
              left: "5rem",
              bottom: "1.4rem",
            }}
          >
            <div
              style={{
                border: "1px solid #0c5ba0",
                borderRadius: "4px",
                cursor: "pointer",
                height: "19px",
                width: "38%",
                marginRight: "20px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <PersonIcon style={{ width: "22px", height: "16px" }} />
                <p
                  style={{
                    fontSize: "12px",
                    color: "#0c5ba0",
                    fontWeight: "600",
                    paddingLeft: "5px",
                  }}
                >
                  {followersCount}
                </p>
              </div>
            </div>

            <div
              style={{
                border: "1px solid #0c5ba0",
                borderRadius: "4px",
                cursor: "pointer",
                padding: "0px 3px 3px 3px",
                height: "19px",
                width: "38%",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={tossball}
                  alt="ball_icon"
                  style={{ width: "14px", height: "12px" }}
                />
                <div className="run-component">
                  <Run
                    isRunValueNeeded={true}
                    userId={user_Id}
                    formatInK={true}
                    className="run-component"
                  />
                </div>
              </div>
            </div>
          </div>
        </List>
        <Divider />
        <List>
          {list &&
            list.map((o, i) => {
              return (
                <Link
                  to={`${o.page}`}
                  className={classes.link}
                  onClick={() => setOpen(!open)}
                  key={i}
                >
                  <ListItem button style={{ padding: "8px 16px" }}>
                    {o.label === "Message" && pendigMessage > 0 ? (
                      <div>
                        Message
                        <p className="Message1234">{pendigMessage}</p>
                      </div>
                    ) : (
                      <ListItemText primary={`${o.label}`} />
                    )}
                  </ListItem>
                </Link>
              );
            })}
          <ListItem
            button
            className={classes.link}
            onClick={() => handleShowModalOpen()}
            style={{ padding: "8px 16px" }}
          >
            <ListItemText primary={"Invite your friends"} />
          </ListItem>
          <ListItem>
            <label
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={{ color: "#00298E" }}>GlobalPitch</span>
              <Switch
                onChange={togglechanged}
                checked={checked}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={20}
                width={48}
                className="react-switch"
                id="material-switch"
              />
            </label>
          </ListItem>
          <ListItem
            button
            className={classes.link}
            onClick={() => handleLogout()}
            style={{ padding: "8px 16px" }}
          >
            <ListItemText primary={"Logout"} />
          </ListItem>
        </List>
      </Drawer>
      <InviteModal
        open={showModal}
        handleClose={handleShowModalClose}
        handleOpen={handleShowModalOpen}
      />
    </div>
  );
}
