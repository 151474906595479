import React, { useState, useEffect, useContext } from "react";
import NavigationMenu from "../../pitch/NavigationMenu";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Post from "../../posts/post";
import Footer from "../../../components/footer/Footer";
import { Box, Grid, Chip, Paper, useMediaQuery, useTheme } from "@mui/material";
import { BallTriangle } from "react-loader-spinner";
import { getStorageItem } from "../../../utils/sessionStorage";
import moment from "moment";

export default function ExploreTrending() {
  const [trendingHashtags, setTrendingHashtags] = useState();
  const [hashId, setHashId] = useState();
  const [posts, setPosts] = useState([]);
  const [thisWeekPosts, setThisWeekPosts] = useState([]);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [postCount, setPostCount] = useState(0);
  const userId = getStorageItem("user_id");
  const userName = getStorageItem("user_name");
  const avatar = getStorageItem("avatar");
  const accessToken = getStorageItem("token");
  const [pitches, setPitches] = useState([]);
  const [shouldLoadPage, setShouldLoadPage] = useState(false);
  const mobileView = useMediaQuery("(max-width:600px)");
  const [loading, setLoading] = useState(false);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const id = searchParams.get("id");
  const tag = searchParams.get("hashtag");
  const [selectedHashtag, setSelectedHashtag] = useState(null);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [hasFetchedHashtags, setHasFetchedHashtags] = useState(false);

  const CHUNK_SIZE = 10;
  const FETCH_DELAY = 1500;

  const fetchTrendingHashtags = async () => {
    try {
      const response = await axios.get(
        `${global.config.ROOTURL.prod}/hash-profile-tag/getTopTrandingHashTag/1/10`
      );

      setTrendingHashtags(response.data.data);
      if (response.data.data.length > 0 && !hasFetchedHashtags) {
        const firstHashId = response.data.data[0]._id;
        setHashId(firstHashId);
        setSelectedHashtag(response.data.data[0].hashTagText);
        setHasFetchedHashtags(true);
        if (!id) navigate(`/explore-trending?id=${firstHashId}`);
      }
    } catch (error) {
      console.error("Error fetching hashtags:", error);
    }
  };

  const fetchThisWeekPosts = (posts) => {
    const currentDate = moment();
    const oneWeekAgo = currentDate.subtract(7, "days");
    const filteredPosts = posts.filter((post) => {
      return moment(post.createdDate).isAfter(oneWeekAgo);
    });
    setThisWeekPosts(filteredPosts);
  };

  const fetchPostChunk = async (postIds) => {
    if (!accessToken || !postIds.length) return [];

    try {
      const response = await axios.post(
        `${global.config.ROOTURL.prod}/pitch/multiplePitchByIds`,
        { pitchIds: postIds },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching post chunk:", error);
      return [];
    }
  };

  const fetchPostsInChunks = async (pitchData) => {
    if (!pitchData || !pitchData.data) {
      setIsLoading(false);
      return;
    }

    setPitches(pitchData.data);
    const allPostIds = pitchData.data.map((element) => element.pitchId);
    const chunks = [];

    for (let i = 0; i < allPostIds.length; i += CHUNK_SIZE) {
      chunks.push(allPostIds.slice(i, i + CHUNK_SIZE));
    }

    let allPosts = [];

    for (let i = 0; i < chunks.length; i++) {
      const chunkPosts = await fetchPostChunk(chunks[i]);
      allPosts = [...allPosts, ...chunkPosts];
      setPosts(allPosts);

      if (i < chunks.length - 1) {
        await new Promise((resolve) => setTimeout(resolve, FETCH_DELAY));
      }
    }

    fetchThisWeekPosts(allPosts);
    setIsLoading(false);
  };

  async function searchAllPitchesByHashTagId(id) {
    if (!accessToken && !id) return;
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${global.config.ROOTURL.prod}/hash-profile-tag/postHashTag/getPostByHashTagId/${id}/1/10`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      fetchPostsInChunks(response.data);
    } catch (error) {
      console.error("Error searching pitches by hashtag ID:", error);
      setIsLoading(false);
    }
  }

  const findHashTagById = async () => {
    if (!id || !accessToken) return;

    try {
      const response = await axios.get(
        `${global.config.ROOTURL.prod}/hash-profile-tag/getByHashTagId/${id}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setName(response.data.data[0].hashTagText);
      setPostCount(response.data.data[0].totalUsedPitchCount);
    } catch (error) {
      console.error("Error fetching hashtag by ID:", error);
    }
  };

  const findHashTagByTag = async () => {
    if (!accessToken || !tag) return;
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${global.config.ROOTURL.prod}/hash-profile-tag/postHashTag/getPostByHashTagText/${tag}/1/5`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      setName(tag);
      setPostCount(response.data.data.length);
      fetchPostsInChunks(response.data);
    } catch (error) {
      console.error("Error fetching hashtag by tag:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPitches([]);
    setPosts([]);
    if (id) {
      findHashTagById();
      searchAllPitchesByHashTagId(id);
    } else if (tag) {
      findHashTagByTag(tag);
    }
    fetchTrendingHashtags();
  }, [id, tag]);

  return (
    <div style={{width: isMobileView ?  "100%": "",  padding: isMobileView ? "0px 0px" : "60px 110px 0px 110px",  background: "rgb(252, 252, 252)"}}>
      <Box >
        <Grid
          container
          spacing={0}
          // sx={{ marginLeft: isMobileView ? "0px" : "-16px"}}
        >
          {!mobileView && (
            <Grid item xs={12} sm={3} md={2.5}>
              <NavigationMenu />
            </Grid>
          )}
          <Grid item xs={9} md={9.5}>
            <Box
              sx={{
                marginTop: isMobileView ? "70px" : "95px",
                marginLeft: isMobileView ? "0px" : "72px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  marginBottom: "20px",
                  overflowX: "auto",
                  width: isMobileView ? "350px" : "100%",
                  scrollbarWidth: "none",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {trendingHashtags?.map((hashtag, index) => (
                  <Chip
                    key={hashtag._id}
                    label={`#${hashtag.hashTagText}`}
                    onClick={() => {
                      setSelectedHashtag(hashtag.hashTagText);
                      navigate(`/explore-trending?id=${hashtag._id}`);
                    }}
                    sx={{
                      backgroundColor:
                        selectedHashtag === hashtag.hashTagText
                          ? "#0C5BA0"
                          : "#0000000D",
                      color:
                        selectedHashtag === hashtag.hashTagText
                          ? "#fff"
                          : "#1d1c2c",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#0C5BA0",
                        color: "#fff",
                      },
                      borderRadius: "8px",
                    }}
                  />
                ))}
              </Box>
              <Box
                sx={{
                  width: isMobileView ? "360px" : "100%",
                }}
              >
                {posts.length > 0
                  ? posts.map((element, index) => (
                      <Paper
                        className="component posts"
                        key={index}
                        sx={{
                          marginBottom: "30px",
                          borderRadius: "12px",
                          boxShadow: "none",
                          width: "900px",
                        }}
                      >
                        <Post
                          post={element}
                          key={index}
                          hprops={() => {
                            setShouldLoadPage(!shouldLoadPage);
                          }}
                        />
                      </Paper>
                    ))
                  : isLoading && (
                      <BallTriangle
                        height="100"
                        width="100"
                        color="grey"
                        ariaLabel="loading"
                      />
                    )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </div>
  );
}
