import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./index.css";

import timeAnimation from "../../assets/images/coin-toss/timeAnimation.gif";

import { clearStorage, getStorageItem } from "../../utils/sessionStorage";
import { useAdCount } from '../../components/Rewards/Context';

const AdModal = (props) => {
    const { setShowAdPermisibleModal, PlaYNowFunction } = props;
    const navigate = useNavigate();
    const accessToken = getStorageItem("token");
    const userId = getStorageItem("user_id");
    const [ad, setAd] = useState(null);
    const [adWatched, setAdWatched] = useState(false);
    const [showAdModal, setShowAdModal] = useState(false);
    // const { adCount, incrementAdCount } = useAdCount();

    const closeAdModal = () => {
        setShowAdPermisibleModal(false);
        setShowAdModal(false)
    };


    const fetchRandomAd = async () => {
        try {
            const response = await axios.get(`${global.config.ROOTURL.prod}/ads/fetch-random`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Headers": "*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*",
                },
            });
            if (response?.status === 200) {
                setAd(response?.data);
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleWatchFullAd = async () => {
        try {
            // Call the reset-time API here to reset the time and redirect the user back to /coin
            const response = await axios.get(`${global.config.ROOTURL.prod}/contest/coinToss/reset-time/${userId}`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            });
            const viewIncreaseResponse = await axios.post(
                `${global.config.ROOTURL.prod}/ads/increaseViewCount`,
                {
                    _id: ad?._id,
                    userId: userId
                },
                {
                    headers: {
                        Authorization: "Bearer " + accessToken,
                    }
                }
            );
            if (response.status === 200 && viewIncreaseResponse.status === 200) {
                setShowAdModal(false);
                navigate("/rewards");
            }
            else {
                setShowAdModal(false);
                toast.error('Some error occured!');
                setTimeout(() => {
                    navigate("/rewards");
                }, [1000]);
            }
        } catch (error) {
            console.error("Error watching ad:", error);
            setShowAdModal(false);
            setTimeout(() => {
                navigate("/rewards");
            }, [1000]);
        }
    };

    useEffect(() => {
        fetchRandomAd();
    }, []);

    return (
        <div>
            <div className="unique-output-modal-background">
                <div className="unique-output-modal-container">
                    <button
                        className="unique-output-close-button"
                        onClick={() => {
                            setShowAdPermisibleModal(false);
                        }}
                    >
                        <AiOutlineCloseCircle color="#F37121" />
                    </button>
                    <div className="bored__video">
                        <img src={timeAnimation} alt="" />
                    </div>
                    <div className="unique-output-card">
                        <h3 className="unique-output-title">You've Played Once in the Last 4 Hours!</h3>
                        <p className="unique-output-message">
                            Want back in? Watch an ad to play again or subscribe to keep the action going nonstop!
                        </p>
                        <div className="unique-output-btn-container">
                            <button
                                className="unique-output-next-button"
                                onClick={() => {
                                    // setShowAdPermisibleModal(false)
                                    setShowAdModal(true);
                                    // incrementAdCount();
                                }}
                            >
                                Watch an Ad
                            </button>
                            <button
                                className="unique-output-subscribe-button"
                                onClick={() => navigate("/champhunt-plus")}
                            >
                                SUBSCRIBE NOW
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {showAdModal && (
                <div className="modal-backdrop-ads">
                    <div className="modal-content-ad">
                        <div className="modal-header-ad">
                            <h2>{ad?.title}</h2>
                            <button className="close-ad" onClick={closeAdModal}>
                                <AiOutlineCloseCircle />
                            </button>
                        </div>
                        <div className="modal-body-ad">
                            <video
                                src={ad?.url}
                                autoPlay
                                // Call handleWatchFullAd when the video ends
                                onEnded={() => {
                                    setAdWatched(true);
                                    setShowAdPermisibleModal(false)
                                    handleWatchFullAd();
                                    PlaYNowFunction();
                                }}
                            ></video>
                            <p>{ad?.description}</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AdModal;
