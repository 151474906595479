import { useState } from 'react';
import './postSomething.scss'

import shareImgIcon from "../../assets/images/Pitch/Feed/shareImg.svg";
import sharePollIcon from "../../assets/images/Pitch/Feed/sharePoll.svg";
import shareEmojiIcon from "../../assets/images/Pitch/Feed/shareEmoji.svg";
import shareVideoIcon from "../../assets/images/Pitch/Feed/shareVideo.svg";


import {
    Box,
    Avatar,
    Button,
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { MentionsInput, Mention } from "react-mentions";
import "react-toastify/dist/ReactToastify.css";


import LoginPopup from "../guest-pitch/LoginPopup";

const PostSomething = () => {
    const mobileView = useMediaQuery("(max-width:769px)");
    const [open, setOpen] = useState(false);
    const handleClose = (data) => setOpen(data);
    const handleOpen = () => setOpen(true);

    return (
        <Box onClick={handleOpen}>
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                    background: '#FFFFFF',
                    marginTop: '1px',
                    padding: '10px 0px 0px 10px',
                    margin: '10px 0px',
                    borderRadius: "8px",
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                    }}>
                    <Avatar src={null} alt="" />
                    <Box sx={{ width: mobileView ? '95%' : '100%', flexGrow: 1 }}>
                        <MentionsInput
                            name="message"
                            placeholder="What's happening?"
                            className="mentions-input"
                            allowSpaceInQuery={true}
                        >
                            <Mention
                                trigger="@"
                                appendSpaceOnAdd={true}
                                sx={{
                                    color: '#1DA1F2',
                                    fontWeight: 500,
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    },
                                }}
                            />
                            <Mention
                                trigger="#"
                                appendSpaceOnAdd={true}
                                allowSpaceInQuery={false}
                                sx={{
                                    color: '#1DA1F2',
                                    fontWeight: 500,
                                    textDecoration: 'none',
                                    '&:hover': {
                                        textDecoration: 'underline',
                                    },
                                }}
                            />
                        </MentionsInput>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px 40px'
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                        <Box mt={0.9}>
                            <label htmlFor="post--gallery">
                                <img
                                    src={shareImgIcon}
                                    alt=""
                                    style={{ cursor: 'pointer' }}
                                />
                                <input
                                    type="file"
                                    id="post--gallery"
                                    hidden
                                    name="image"
                                    accept="image/*"
                                    multiple
                                />
                            </label>
                        </Box>
                        <img src={sharePollIcon} alt='' style={{ cursor: 'pointer' }} />
                        <img src={shareEmojiIcon} alt='' style={{ cursor: 'pointer' }} />
                        <Box mt={0.9}>
                            <label htmlFor="post--video">
                                <img
                                    src={shareVideoIcon}
                                    alt=""
                                    style={{ cursor: 'pointer' }}
                                />
                                <input
                                    type="file"
                                    hidden
                                    name="image"
                                    id="post--video"
                                    accept="video/*"
                                />
                            </label>
                        </Box>
                    </Box>
                    <Button
                        label="Submit"
                        sx={{
                            background: '#D0E7FB',
                            borderRadius: '50px',
                            textTransform: 'none',
                            fontWeight: 400,
                            fontFamily: 'Inter',
                            color: '#0C5BA0',
                            '&:hover': {
                                background: '#D0E7FB',
                            }
                        }}>
                        Post
                    </Button>
                </Box>
            </Box>
            {open && <LoginPopup open={open} handleClose={handleClose} />}
        </Box>
    )
}

export default PostSomething;