import React from 'react';
import { Grid, Box } from '@mui/material';
import { useMediaQuery } from "@mui/material";
import { Helmet } from "react-helmet";

import NavigationMenu from '../../components/pitch/NavigationMenu';
import NewDealsPage from '../../components/new-deals/newDealsPage';
import Footer from "../../components/footer/Footer.js";

const DealsMain = () => {
    const mobileView = useMediaQuery("(max-width:768px)");

    return (
        <>
            <Helmet>
                <title>Champhunt | Deals</title>
            </Helmet>

            <Box
                sx={{
                    boxSizing: 'border-box',
                    background: '#FCFCFC',
                    overflowX: 'auto !important',
                    marginTop: mobileView ? '29px' : '0px',
                    padding: {
                        xs: '0px',
                        sm: '0px 60px',
                        md: '0px 90px',
                        lg: '60px 110px 0px 110px',
                    }
                }}
            >
                <Grid container spacing={2}>
                    {!mobileView && (
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={3}
                        >
                            <NavigationMenu />
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={9}
                    >
                        <NewDealsPage />
                    </Grid>
                </Grid>
                <Footer />
            </Box >
        </>
    )
}

export default DealsMain;