import React, { useEffect, useContext, useState, useRef } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DescriptionIcon from "@mui/icons-material/Description";
import ImageIcon from "@mui/icons-material/Image";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import EmojiPicker from "emoji-picker-react";
import MicIcon from "@mui/icons-material/Mic";
import StopIcon from "@mui/icons-material/Stop";
import { useNavigate } from "react-router-dom";
import sendBtn from "../../assets/images/chatWithChamhunt/sendButton.png";
import { useMediaQuery } from "@mui/material";
import Chat from "./Chat";
import { getStorageItem } from "../../utils/sessionStorage";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BallTriangle } from "react-loader-spinner";
import "./index.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import AppContext from "../../context/AppContext";
import { PathAction } from "../../redux/reducers/globalPath";
import { useDispatch } from "react-redux";
import { SocketContext } from "../../context/SocketContext";
import backButtonPage from "../../assets/images/All Page Arrow.svg";
import useAudioRecorder from "./useAudioRecorder";

const ChatWithAdmin = () => {
  const accessToken = getStorageItem("token");
  const userId = getStorageItem("user_id");
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [textValue, setTextValue] = useState("");
  const [openType, setOpenType] = useState(null);
  const [NameofotherPerson, setNameofotherPerson] = useState("");
  const [loading, setLoading] = useState(false);
  const [shouldLoadPage, setShouldLoadPage] = useState(false);
  const path = window.location.pathname;
  const dispatch = useDispatch();

  dispatch(PathAction.handlePath({ payload: path }));
  const [socket] = useContext(SocketContext);

  const appContext = useContext(AppContext);
  const { setShowFooter } = appContext;

  const [mediatype, setMediaType] = useState(null);
  const [mediaFile, setMediaFile] = useState(null);
  const [showEmojis, setShowEmojis] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentTime, setCurrentTime] = useState("");

  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:769px)");

  const {
    isRecording,
    isRecordingSuccess,
    setIsRecordingSuccess,
    audioFile,
    setAudioFile,
    startRecording,
    stopRecording,
  } = useAudioRecorder();

  useEffect(() => {
    if (audioFile) {
      setMediaFile(audioFile);
    }
  }, [audioFile]);

  const handleSetInpt = (e) => {
    setTextValue(e.target.value);
    setIsRecordingSuccess(false);
  };

  const handleStopClick = () => {
    stopRecording();
    setMediaType("audio");
  };

  const handleStartRecording = () => {
    setMediaFile(null);
    startRecording();
  };

  const handleFileIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilePopupClose = () => {
    setAnchorEl(null);
  };

  const handleBackPage = () => {
    navigate("/pitch");
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file.name);
    setMediaFile(file);
    if (file.type.startsWith("video")) {
      setMediaType("video");
    }
    if (file.type.startsWith("image")) {
      setMediaType("image");
    }
    if (file.type.startsWith("audio")) {
      setMediaType("audio");
    }
    handleFilePopupClose();
  };

  const handleFileMenuClick = () => {
    document.getElementById("file-input").click();
  };

  const handleEmojiPicker = () => {
    setShowEmojis(!showEmojis);
  };

  const handleEmojiClick = (emoji) => {
    const messageValue = textValue || "";
    const newMessageValue = messageValue + emoji + " ";
    setTextValue(newMessageValue);
  };

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date();
      setCurrentTime(formatTime(date));
    }, 60000); // Update every minute

    setCurrentTime(formatTime(new Date()));

    return () => clearInterval(interval);
  }, []);

  const handleSendMessage = async () => {
    const messageDetails = {
      sendertype: "user",
      userid: id,
      message: textValue,
      mediatype: null,
    };

    if (mediatype) {
      let dataURL = "";
      const formData = new FormData();
      formData.append("uploader", mediaFile);
      const uploadResult = await axios.post(
        global.config.ROOTURL.prod + "/upload-file",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (uploadResult.data && uploadResult.data.length > 0) {
        dataURL = uploadResult.data[0].location;
      }
      messageDetails.message = dataURL;
      messageDetails.mediatype = mediatype;
    }
    socket?.emit("send-msg", messageDetails);
    setTextValue("");
    setMediaFile(null);
    setSelectedImage(null);
    setAudioFile(null);
    setMediaType(null);
    setIsRecordingSuccess(false);
    setShowEmojis(false);
  };

  const setSeencheck = async () => {
    let type = "user";
    if (userId !== id) {
      type = "Admin";
    }
    if (!id) return;

    const onlineuser = {
      method: "POST",
      url: global.config.ROOTURL.prod + "/chat/isSeenCheck",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      data: {
        sendertype: type,
        userid: id,
      },
    };

    await axios(onlineuser)
      .then((data) => { })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchData = async (type) => {
    setLoading(true);
    if (!id) return;

    const onlineuser = {
      method: "GET",
      url:
        global.config.ROOTURL.prod +
        `/chat/getUserAdminChat/${id}/${page}/${global.config.pagePerSize}`,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
    };

    await axios(onlineuser)
      .then((response) => {
        if (type) {
          checkName(type, response.data);
        }
        if (response.data.length === 0) {
          setHasMore(false);
        } else {
          const reversedData = [...response.data].reverse();
          setItems(reversedData);
          setPage((prevPage) => prevPage + 1);
          setShouldLoadPage(true);
        }

        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const checkName = (type, items1) => {
    let name;
    console.log(items1);
    for (let i = 0; i < items1.length; i++) {
      if (items1[i].sendertype !== type) {
        console.log(items1[i].sendertype);
        name = items1[i].Name;
        break;
      }
    }
    console.log(name);
    setNameofotherPerson(name);
  };

  useEffect(() => {
    let type;
    if (userId !== id) {
      type = "Admin";
      setOpenType("Admin");
    } else {
      type = "user";
      setOpenType("user");
    }
    setShowFooter(true);
    setSeencheck();
    fetchData(type);

    return () => {
      setShowFooter(false);
    };
  }, []);

  useEffect(() => {
    if (socket && userId) {
      socket.on("connected", () => {
        console.log("Successfully connected to the socket");
      });
      socket.on("receive-msg", (data) => {
        if (data.userid === userId) {
          setItems((prevMessages) => [...prevMessages, data]);
        }
      });

      return () => {
        socket.off("connected");
        socket.off("receive-msg");
      };
    }
  }, [socket, userId]);

  return (
    <Box
      sx={{
        height: "96vh",
        width: "100%",
        marginTop: "20px",
        position: "sticky",
        backgroundColor: "#01111e",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        "@media (max-width: 768px)": {
          overflow: "hidden !important",
          background: "fixed !important",
        },
      }}
    >
      <Box
        className="back-button"
        onClick={handleBackPage}
        aria-label="back"
        sx={{ borderRadius:"50%", display: "flex", alignItems: "center", backgroundColor:"white", position:"absolute", top:"70px", left:"20px", padding:"6px"}}
      >
        <img src={backButtonPage}
          style={{ color: "white", width: isMobile ? "20px" : "30px", height: isMobile ? "20px" : "30px", cursor: "pointer" }}
        />
      </Box>
      <Box
        sx={{
          width: "80%",
          height: "725px",
          background: "#FAFAFA",
          // position: 'absolute',
          bottom: "0px",
          borderTopRightRadius: "12px",
          borderTopLeftRadius: "12px",
          "@media (max-width: 768px)": {
            width: "95% !important",
            bottom: "50px !important",
            height: "700px !important",
          },
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "20px",
            fontFamily: "Inter",
            fontSize: 16,
            fontW: 400,
            color: "#44474E",
          }}
        >
          Today {currentTime}
        </Typography>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleFilePopupClose}
          PaperProps={{
            sx: {
              mt: -12.2,
              borderRadius: "8px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              overflow: "visible",
              "&:after": {
                content: '""',
                display: "block",
                position: "absolute",
                bottom: 0,
                left: 10,
                width: 20,
                height: 20,
                bgcolor: "white",
                transform: "translateY(50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
        >
          <MenuItem
            onClick={handleFileMenuClick}
            sx={{
              py: 1.5,
              "&:hover": {
                backgroundColor: "#0C5BA0",
                color: "#FFFFFF !important",
              },
            }}
          >
            <DescriptionIcon
              sx={{ mr: 1, color: "#667085", "&:hover": { color: "#FFFFFF" } }}
            />
            <Typography>Send File</Typography>
          </MenuItem>
          <MenuItem onClick={handleFileMenuClick} sx={{ py: 1.5 }}>
            <ImageIcon sx={{ mr: 1, color: "#667085" }} />
            <Typography>Attach a screenshot</Typography>
          </MenuItem>
        </Menu>
        <input
          type="file"
          id="file-input"
          style={{ display: "none" }}
          onChange={handleFileSelect}
        />
        <Box
          sx={{
            width: "100%",
            height: "560px",
            overflowY: "scroll",
            padding: "10px 20px",
          }}
        >
          {shouldLoadPage && (
            <InfiniteScroll
              dataLength={items.length}
              next={fetchData}
              hasMore={hasMore}
              loader={
                <BallTriangle
                  height="100"
                  width="100"
                  color="grey"
                  ariaLabel="loading"
                />
              }
            >
              {items?.map((data, index) => (
                <Chat
                  key={index}
                  chatdata={data}
                  type={openType}
                  number={index}
                />
              ))}
            </InfiniteScroll>
          )}
        </Box>
        <Box
          sx={{
            position: "absolute",
            right: "0px",
            bottom: "77px",
            zIndex: 2,
            "@media (max-width: 768px)": {
              bottom: "40px !important",
            },
          }}
        >
          {showEmojis && (
            <EmojiPicker
              onEmojiClick={(EmojiClickData, MouseEvent) => {
                handleEmojiClick(EmojiClickData?.emoji);
              }}
              width={340}
              height={window.innerWidth <= 768 ? 400 : 450}
            />
          )}
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            background: "#FFFFFF",
            padding: "10px 20px",
            position: "sticky !important",
            bottom: "1px",
            "@media (max-width: 768px)": {
              padding: "5px 10px",
            },
          }}
        >
          <IconButton
            color="primary"
            component="label"
            sx={{
              position: "absolute",
              left: "30px",
              zIndex: "2",
              "@media (max-width: 768px)": {
                left: "10px",
              },
            }}
            onClick={handleFileIconClick}
          >
            <AttachFileIcon
              sx={{
                transform: "rotate(235deg)",
                fontSize: 30,
                color: "#333333",
              }}
            />
          </IconButton>
          <TextField
            fullWidth
            variant="outlined"
            placeholder={
              isRecording
                ? "Recording in progress..."
                : isRecordingSuccess
                  ? "Recording successfull..."
                  : selectedImage
                    ? `${selectedImage}`
                    : "Write a message..."
            }
            value={textValue}
            onChange={handleSetInpt}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSendMessage();
              }
            }}
            InputProps={{
              sx: {
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              },
            }}
            sx={{
              background: "#F8F8F8",
              borderRadius: "4px",
              padding: "0px 50px",
              "@media (max-width: 768px)": {
                padding: "0px 25px",
              },
            }}
          ></TextField>
          <Box
            sx={{
              position: "absolute",
              right: "100px",
              display: "flex",
              gap: "7px",
              "@media (max-width: 768px)": {
                gap: "0px",
                right: "80px",
              },
            }}
          >
            <IconButton onClick={handleEmojiPicker}>
              <EmojiEmotionsIcon sx={{ fontSize: 30, color: "#0C5BA0" }} />
            </IconButton>
            <IconButton
              onClick={() =>
                isRecording ? handleStopClick() : handleStartRecording()
              }
            >
              {isRecording ? (
                <StopIcon sx={{ fontSize: 35, color: "#333333" }} />
              ) : (
                <MicIcon sx={{ fontSize: 35, color: "#333333" }} />
              )}
            </IconButton>
          </Box>
          <img
            src={sendBtn}
            alt="Send"
            style={{ cursor: "pointer" }}
            onClick={handleSendMessage}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChatWithAdmin;
